import React, { Component } from 'react'
import '../contact.css';

import {
  Card,
  InputGroup,
  FormControl 
} from 'react-bootstrap';

import {gouvernorats} from '../../../../../../const/gouvernorateList';

const ADRESS_IMG = require('../../../../../../asset/image/Dashboard/adress.png');



export default class SearchAgency extends Component {

    cityHandler=(event)=>{
        localStorage.setItem('cityBee', event.target.value);
        window.location.assign('/particulier/NotreRéseau')
        
    }
    render() {
        return (
            <Card className="mx-5 px-0 adress-card-container" style={{ width: '20rem' }}>
                <Card.Img variant="top" className="adress-card-img mt-5 pt-5 px-0" src={ADRESS_IMG}/>
                <Card.Body className="px-0 py-5">

                    <Card.Text>
                        <div className="mx-auto" >
                        <p className="px-3"> TROUVEZ UNE AGENCE <br/> PRÉS DE CHEZ VOUS </p>
                        <InputGroup>
                            <FormControl as="select" custom
                                // placeholder="aaa"
                                // aria-describedby="basic-addon2"
                                className="contact-input-select my-3"
                                onChange={this.cityHandler}
                            >
                                <option> --- Gouvernorat </option>
                                {gouvernorats.map(el=>
                                    <option key={el.id} value={el.value}>{el.name}</option>
                                    )}
                            </FormControl>
                        </InputGroup>
                        </div> 
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }
}