import React, { Component } from 'react';
import './map.css';

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

export class ContactMap extends Component {
  constructor() {
    super();

    this.state = {

      showingInfoWindow: false,  //Hides or the shows the infoWindow
      activeMarker: {},          //Shows the active marker upon click
      selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
    };
  }


  //event handlers of info window (when the map and the marker are clicked)
  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = (event) => {


    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  render() {
    return (
      <Map
        google={this.props.google}
        initialCenter={{
          lat: 36.744,
          lng: 10.208
        }}
        zoom={10}
        onClick={this.onMapClicked}
      // className="contactView"
      >

        <Marker
          onClick={this.onMarkerClick}
          name={'Agence Charguia'}
          key={0} id={0}
          adress={'Rue de l\'industrie, Tunis'}
          position={{ lat: 36.833401, lng: 10.209152 }}


        />
        <InfoWindow
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <p className="info-window-contactTitle">{this.state.selectedPlace.name}</p>
            <p className="info-window-contact"> Adresse : {this.state.selectedPlace.adress}</p>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

// export default ContactMap;

export default GoogleApiWrapper({
  apiKey: ('AIzaSyCe3eMb74HI0_hA5Dzmcd2pozsCEP9fZPw')
})(ContactMap);