import React, { useState } from 'react';
import './popup.css';
import {
    Modal,
  } from 'react-bootstrap';

export default function popup(props) {


  return (
      <Modal show={props.show} onHide={props.handleClose} className="popup">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="p-0">
            <p className="popupTitle m-5"> {props.message} </p>
        </Modal.Body>
      </Modal>
  );
}