import React, { Component } from 'react';
import {Accordion} from 'react-accessible-accordion';
import FaqItem from './FaqItem/FaqItem';
import axios from 'axios';
import {config} from '../../../../../config';
import Spinner from '../../../../UI/spinner/spinner';


class FaqItems extends Component {

    state = { 
        items:null
    };
        
    componentDidMount () {
        axios.get(`${config.url}faq`)
        .then( response => {
            this.setState({items:response.data})
        })
        .catch(error=>
            console.log(error)
            )
    }
    
    render() {
        let faqList = this.state.items ? 
        <Accordion allowZeroExpanded="true" >
        {this.state.items.map(el=>{
            return(
                <FaqItem question={el.questions} response={el.reponses}/>
            )
        })}
        </Accordion> 
        : <Spinner/>
        return (      
            <div>
                {faqList}
            </div>
        );
    }
}

export default FaqItems;