import React, { Component } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';


import Input from '../input/input';
import './ClientCoordinates.css';
import { ContactMap } from '../../ContactPage/Map/map';
import CoordMap from './map';

export class ClientCoordinates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 'Latitude',
      lng: 'Longitude'
    };
  }
  handleLatLng = (lat, lng) => {
    this.setState({
      lat: lat,
      lng: lng

    });

    const coordinates = {
      target: {
        value: '' + this.state.lat + ' ' + this.state.lng,
      }
    };

    this.props.handleChange(coordinates, "CodeGPS");
  };

  render() {

    window.addEventListener('click', (e) => {
      if (!e.target.closest(".coordonnees") && this.state.isShowingMap) {
        this.setState({ isShowingMap: false });
      }
    });

    return (
      <Col xl="5" md="5" xs="10" className="coordonnees mx-2 my-2"
        style={{ position: 'relative' }}
        onClick={() => { this.setState({ isShowingMap: true }); }}
      >
        <Form.Label className="subscription-label">
          Vos Coordonnées *:
        </Form.Label>
        <div style={{ display: 'flex' }}>

          <Input
            name={this.props.el.id}
            type={this.props.el.property.inputType}
            placeholder={this.props.el.property.placeholder}
            isValid={this.props.el.property.isValid}
            touched={this.props.el.property.touched}
            errorMessage={this.props.el.property.message}
            changed={this.props.handleChange}
            value={this.state.lat}
          />
          <Input
            name={this.props.el.id}
            type={this.props.el.property.inputType}
            placeholder={this.props.el.property.placeholder}
            isValid={this.props.el.property.isValid}
            touched={this.props.el.property.touched}
            errorMessage={this.props.el.property.message}
            changed={this.props.handleChange}
            value={this.state.lng}
          />
        </div>
        {this.state.isShowingMap && <div className='coord-map__container' >
          <CoordMap handleLatLng={this.handleLatLng} />
        </div>}
      </Col>
    );
  }
}

export default ClientCoordinates;