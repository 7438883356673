export const gouvernorats= [
    { 
            name: "Ariana",
            value: "Ariana"
    },
    {
            name: "Béja",
            value: "Béja"
    },
    {
            name: "Ben Arous",
            value: "Ben Arous"
    },
    {
            name: "Bizerte",
            value: "Bizerte"
    },
    {
            name: "Gabes",
            value: "Gabes"
    },
    {
            name: "Gafsa",
            value: "Gafsa"
    },
    {
            name: "Jendouba",
            value: "Jendouba"
    },
    {
            name: "Kairouan",
            value: "Kairouan"
    },
    {
            name: "Kasserine",
            value: "Kasserine"
    },
    {
            name: "Kebilli",
            value: "Kebilli"
    },
    {
            name: "Kef",
            value: "Kef"
    },
    {
            name: "Mahdia",
            value: "Mahdia"
    },
    {
            name: "Manouba",
            value: "Manouba"
    },
    {
            name: "Medenine",
            value: "Medenine"
    },
    {
            name: "Monastir",
            value: "Monastir"
    },
    {
            name: "Nabeul",
            value: "Nabeul"
    },
    {
            name: "Sfax",
            value: "Sfax"
    },
    {
            name: "Sidi Bouzid",
            value: "Sidi Bouzid"
    },
    {
            name: "Siliana",
            value: "Siliana"
    },
    {
            name: "Sousse",
            value: "Sousse"
    },
    {
            name: "Tataouine",
            value: "Tataouine"
    },
    {
            name: "Tozeur",
            value: "Tozeur"
    },
    {
            name: "Tunis",
            value: "Tunis"
    },
    {
            name: "Zaghouan",
            value: "Zaghouan"
    }
]