import React, { Component } from 'react';
//css
import '../Offres/offre.css';
//react-bootstrap
import {Row, Col, Button, Modal} from 'react-bootstrap';
//react router
import { Link, withRouter } from 'react-router-dom';

import axios from 'axios';
import {config} from '../../../../config';

import file from '../../../../const/إلتزام-.doc';

class PackDetails extends Component {
    state = {
        offerDetails:null,
        show:false
    }
    componentDidMount () {
        axios.get(`${config.url}detailsOffrespack`)
        .then( response => {
            this.setState({offerDetails:response.data})
        })
        .catch(error=>
            console.log(error,"related to offer details")
            )
    }
    //modal handler
    handleModalClose = () => {
        this.setState({show:false});
    }
    modalShow = () => {
        this.setState({show:true});
    }

    render() {
        let offerDetails=this.state.offerDetails?this.state.offerDetails:[];
        let currentOffre = this.props.offres[this.props.match.params.test]?this.props.offres[this.props.match.params.test]:[];
        
        return (
            <>
            <Row className="mx-0 justify-content-center">
                {offerDetails.map((el,id) => {
                    if(el.offresPackgers_id==currentOffre.id)
                    return(
                    !el.Prix_red?(
                    <Col xl="2" lg="2" md="5" xs="10" className="offer-container px-0 m-3" key={id}>
                        <div className="my-3 pt-2 pb-3">
                            <p className="offer-title-flow">
                                <var>{el.Vitesse}<sup> jusqu'a <br/> Mbps </sup></var> 
                            </p>
                            <p className="offer-price">
                                <var> {Math.floor(el.Prix)} <sup>,{Math.round((el.Prix - parseInt(el.Prix)) * 10)}</sup></var> TTC/Mois
                                <br/>
                                {/* {currentOffre.frais?
                                <small className=" offre-price-sup"> + {currentOffre.frais} TND </small>:null} */}
                            </p>
                        </div>
                        <div className="offer-description-container py-4 px-2">
                            <div className="offer-description">
                                <p> {el.Facture} </p>
                                <hr/>
                                <p> {el.description_debit} </p>
                                <hr/>
                                <p> {el.Duree} </p>
                                <hr/>
                                <p> {el.Raccordement} </p>
                            </div>
                            <Link exact to={{
                                pathname: `/particulier/abonnement/pack/${el.id}`,
                                // state: `${currentOffre.nom} ${el.Vitesse} Mbps`
                                state: `${currentOffre.nom}`
                                }}
                                style={{textDecoration:'none'}}
                            >
                                <Button className="command-btn mx-auto"> Commander </Button>
                            </Link>
                        </div>
    
                    </Col>):(

<Col xl="2" lg="2" md="5" xs="10" className="offer-container px-0 m-3" key={id}>
<div className="my-3 pt-2 pb-3">
    <p className="offer-title-flow">
        <var>{el.Vitesse}<sup> jusqu'a <br/> Mbps </sup></var> 
    </p>
    <p className="offer-price">
        <var> {Math.floor(el.Prix_red)} <sup>,{Math.round((el.Prix_red - parseInt(el.Prix_red)) * 10)}</sup></var> TTC/Mois
        <br/>
        {/* {currentOffre.frais?
        <small className=" offre-price-sup"> + {currentOffre.frais} TND </small>:null} */}
    </p>
</div>
<div className="offer-description-container py-4 px-2">
    <div className="offer-description">
        <p> {el.Facture} </p>
        <hr/>
        <p> {el.description_debit} </p>
        <hr/>
        <p> {el.Duree} </p>
        <hr/>
        <p> {el.Raccordement} </p>
    </div>
    <Link exact to={{
        pathname: `/particulier/abonnement/pack/${el.id}`,
        // state: `${currentOffre.nom} ${el.Vitesse} Mbps`
        state: `${currentOffre.nom}`
        }}
        style={{textDecoration:'none'}}
    >
        <Button className="command-btn mx-auto"> Commander </Button>
    </Link>
</div>

</Col>)
                    )
                })}
                {currentOffre.nom==="Bee Home"?
                <Col  xl="7" lg="10" md="11" xs="12" className="engagement-bloc my-3">
                    <p onClick={this.modalShow} className="paper-btn"> Les papiers nécessaires sont </p>
                    
                    <Modal show={this.state.show} onHide={this.handleModalClose} className="beehomeModal">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body className="p-4">
                            <ul>
                                <li> Une copie de votre carte d’identité. </li>
                                <li> La dernière fiche de paie. </li>
                                <li> Le relevé bancaire des trois derniers Mois. </li>
                                <li> 
                                    Une lettre d’engagement   
                                    <a href={file} download="إلتزام" target="_blank" className="engagement-doc">
                                        &nbsp;Télécharger d’ici 
                                    </a>
                                </li>
                            </ul> 
                        </Modal.Body>
                    </Modal>
                </Col>
                :null}
            </Row>
            </>
        );
    }
}
export default withRouter(PackDetails)