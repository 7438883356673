import React, { Component } from 'react';
//css
import './description.css'
import {
  Carousel,
  Button
} from 'react-bootstrap';

import Spinner from '../../../../UI/spinner/spinner';
import axios from 'axios';
import {config} from '../../../../../config';

export default class Description extends Component {

    state = {
      actuality:null
      };
      
    componentDidMount () {
      axios.get(`${config.url}slideactu`)
      .then(response => {
          this.setState({actuality:response.data})
      })
      .catch(error =>
          {console.log(error)})
  }
    render() {
      let {actuality} = this.state;
        return (

          <Carousel slide={false} className="description-slide">
            {actuality?
            actuality.map(el=>{ return(
            <Carousel.Item>
              <img
                className="d-block carousel-img"
                src={config.img+el.Image}
                alt={el.id}
              />
              <Carousel.Caption>
                {(el.Titre && el.Titre!=="#")?
                  <h2 className="description-title"> {el.Titre} </h2>
                :null}
                {(el.Lien && el.Lien!=="#")?
                  <Button className="description-slide-btn px-5" onClick={()=> window.location.replace(el.Lien)}>  {el.Titre_bouton} </Button>
                :null}
              </Carousel.Caption>
            </Carousel.Item>
            )})
            
            : <Spinner/>}
          </Carousel>
        );
    }
}