import React, { Component } from 'react';
import parse from 'html-react-parser';

//css
import './index.css';

import {
    Row,
    Col,
    Image,
    Button
} from 'react-bootstrap';

//component
import listOfNews from './news-list';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../../../config';


export default class NewsList extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    // componentDidMount(){
    //     axios.get(`${config.url}getArticles`)
    //     .then( response => {
    //         this.setState({...this.state,articles:response.data})
    //     }).catch(error=> console.log(error,"related to fetching articles") )
    // }


    render() {
        return (
            <div>
                {this.props.articles && this.props.articles.map((article, index) => {
                    return <Row className="mx-0 px-3">
                        {index % 2 === 0 && <Col xl="6" lg="6" md="12" xs="12" className="px-0">
                            <Image fluid src={config.img + article.Img} />
                        </Col>}
                        <Col xl="6" lg="6" md="12" xs="12" className="m-auto py-3">
                            <small className="news-date"> {article.Date} </small>
                            <h5 className="news-list-title">
                                {article.Titre}
                            </h5>
                            <p className="news-text">
                                {parse(article.Description)}
                            </p>
                            <Link to={`/particulier/actuality/description/${article.id}`}><Button className="news-btn"> LIRE PLUS </Button></Link>
                        </Col>
                        {index % 2 !== 0 &&
                            <Col xl="6" lg={{ span: "6", order: 'last' }} md="12" xs={{ span: "12", order: 'first' }} className="px-0">
                                <Image fluid src={config.img + article.Img} />
                            </Col>}
                    </Row>;
                })}
            </div>
        );
    }
}

