import React, { Component } from 'react'
import './contact.css';

import {
  Row
} from 'react-bootstrap';

import ContactExpert from './ContactExpert/contactExpert';
import SearchAgency from './SearchAgency/searchAgency';

export default class Contact extends Component {

    render() {
        return (
            <Row className="contact-container mx-0 mb-4 justify-content-center">
                {/*************  Contact **************/}
                <ContactExpert/>
               
                <span class="vertical-line pt-5 mt-5"></span>

                {/***********  Search agency **************/}
                <SearchAgency/>
            </Row>

        )
    }
}