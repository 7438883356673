import React, { Component } from 'react';
//css
import './offre.css';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { config } from '../../../../config';
import { offreTag } from '../../../../const/metaTags';
import Helmet from 'react-helmet';
import OfferDetails from './offerDetails/offerDetails';
import Spinner from '../../../UI/spinner/spinner';
import Breadcrumb from '../../../Navigation/breadcrumb/breadcrumb';
import Ribbon from '../../../UI/ribbon/ribbon';


export default class Offre extends Component {

    render() {
        console.log(this.props);

        let category = this.props.category ? this.props.category[this.props.idCat] : null;
        let currentOffer = this.props.offers ? this.props.offers[this.props.idOffre] : null;
        let offers = this.props.offers ? this.props.offers : null;
        return (
            <>
                {!(category && currentOffer && offers) ? <Spinner />
                    : <div className="mx-0">

                        {offreTag.map(el => {
                            if (el.name === currentOffer.nom) {
                                return (
                                    <Helmet>
                                        <title> {el.title} </title>
                                        <meta name="description" content={el.description} />
                                    </Helmet>
                                );
                            }
                        })}

                        {/****************/}
                        {category.image ?
                            <div className="mx-0">
                                <Image fluid src={config.img + category.image} className="" />
                            </div>
                            : null}
                        {/*****************/}

                        {/****************/}
                        <Row className="mx-0 justify-content-center">

                            {/**********breadcrumb */}
                            <Col xl="11" lg="11" md="12" xs="12" className="mt-3 px-0">
                                <Breadcrumb active={currentOffer.nom} />
                            </Col>
                            {/**********breadcrumb */}

                            <Col xl="7" lg="10" md="11" xs="12" className="mx-auto mt-5">
                                <h1 className="offers-title">  NOS OFFRES {category.type}</h1>
                                <p className="offers-text my-4">
                                    {currentOffer.description}
                                </p>
                            </Col>
                            <Col xl="7" lg="10" md="11" xs="12" className="d-flex flex-nowrap mb-5">
                                {offers.map((el, id) => {
                                    if (el.categorie_offres_id == category.id)
                                        return (
                                            <Button
                                                as={NavLink} exact to={`/particulier/offre/${el.nom.replace(/ /g, '')}`}
                                                activeClassName="offer-actif-btn" className="offer-btn"
                                            >
                                                {el.nom}
                                            </Button>
                                        );
                                })}
                            </Col>
                            <Col xl="12" lg="12" md="12" xs="12" className="mb-5 mx-0">
                                <OfferDetails category={category} offer={currentOffer} />
                            </Col>
                        </Row>
                        {/*****************/}
                    </div>}
                <Ribbon />
            </>
        );
    }
}