import React, { Component } from 'react';
//css
import './header.css';

//react-bootstrap
import {
    Row,
    Col,
    Container,
    Image
} from 'react-bootstrap';

//import react router
import {
     NavLink,
     withRouter
  } from "react-router-dom";

//component
import NavParticulier from './Particulier/navbar';
import SearchBar from './searchBar/searchAutocomplete';

//logo
const LOGO =require('../../../asset/image/navigation/logo-bee.png');
const PAIEMENT_LOGO =require('../../../asset/image/navigation/paiement.svg');
const RESEAUX_LOGO =require('../../../asset/image/navigation/reseaux.svg');
const ABOUT_LOGO =require('../../../asset/image/navigation/about-logo.svg');
const CLIENT_LOGO =require('../../../asset/image/navigation/client-logo.svg');



class Header extends Component {

    render() {
        return (
        <Container fluid className="header-container mx-0 px-0">
           <Row className="mx-0 px-xl-5 py-xl-2 py-lg-2">
                <Col xl="3" lg="4" md="6" sm="8" xs="12" className="d-flex bee-logo-container mx-xl-auto mx-lg-auto px-0">
                    <div className="m-auto">
                        <NavLink to="/"><img src={LOGO} className="logo-bee" /></NavLink> 
                    </div>
                    <div className="nav-bee-link my-auto mx-0 px-3">
                        <NavLink 
                            activeClassName={(window.location.pathname==="/"||window.location.pathname.includes("/particulier")) ? "particulier-active-link" : ""} 
                            to="/" >Particulier</NavLink>
                    </div>
                    <div className="nav-bee-link my-auto mx-0 px-3">
                        <NavLink activeClassName="business-active-link" exact to="/business">Business</NavLink>
                    </div>
                </Col>

                <Col 
                xl={{span:4,offset:1, order: 1 }} lg={{span:5, order: 1 }} md={{span:8, order: 1 }} xs={{span:5, order: 2 }} 
                className="nav-bee-container px-0 my-0">
                    <nav className="nav-bee">
                        <ul className="nav">
                            <li className="nav-item">
                                <a className="nav-link my-auto mx-0 px-0 nav-bee-link" href="https://paiementexpress.bee.net.tn/login" target="_blank">
                                    <Image fluid src={PAIEMENT_LOGO} className="pr-2"/>
                                    <span> Paiement express </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link my-auto mx-0 px-0 nav-bee-link" to="/particulier/NotreRéseau" activeClassName="header-active-link">
                                    <Image fluid src={RESEAUX_LOGO} className="pr-2"/>
                                    <span> Notre réseau </span>
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="nav-link my-auto mx-0 px-0 nav-bee-link" to="/particulier/selfcare" activeClassName="header-active-link">
                                    <Image fluid src={CLIENT_LOGO} className="pr-2"/>
                                    <span> Espace Client </span>
                                </NavLink>
                            </li> */}
                        </ul>
                    </nav>
                </Col>

                <Col xl={{span:2, order: 2 }} lg={{span:2, order: 2 }} md={{span:4, order: 2 }} xs={{span:7, order: 1 }}  
                className="my-auto mx-0 search-input-container">
                    <SearchBar offreItems={this.props.offreItems} offreCategories={this.props.offreCategories} 
                        serviceItems={this.props.serviceItems} productItems={this.props.productItems}
                        offrePack={this.props.offrePackCat} 
                        />
                </Col>
            </Row>

            {/** Navbar particulier*/}
            <NavParticulier offreItems={this.props.offreItems} 
                offreCategories={this.props.offreCategories} serviceItems={this.props.serviceItems}
                productItems={this.props.productItems} offrePack={this.props.offrePackCat}/>
                
            {/** Navbar particulier*/}
        </Container>      
        );
    }
}
export default withRouter(Header)
