import React, { Component } from 'react';

//css
import './agencies.css';
//react-bootstrap
import {
  InputGroup,
  FormControl,
  Image,
} from 'react-bootstrap';
//Images 
const MAP_ICON = require('../../../../../asset/image/Particulier/map-icon.png');

export default class Agencies extends Component {

    state = {
        search: null,
        ville: localStorage.getItem('cityBee')
    };
   /******** search agency with name , filter by name */
    searchAgency = (event) => {
        this.setState({search: event.target.value})
  }

  // to remove filter with name
    removeFilter = () => {
        this.setState({ville:null});
        localStorage.removeItem('cityBee');
    }
    render() {
        return (
    <div>
        {/**Search bar  */}
        <div className="mb-4 mx-2 search-agency-container">
            <InputGroup>
                <FormControl placeholder="Recherche" onChange={(e) => this.searchAgency(e)} className="search-input-agency" />
            </InputGroup>
        </div>
        {this.state.ville? 
        <div className="mx-2 city-filter">
            <button onClick={this.removeFilter}> x </button>
             ville : {this.state.ville}
        </div>
        :null}

        {/**Agencies list *****/}
        <div className="agency-container">
            {this.props.agencies
            .filter(
                x => {
                    if(x.Type==="agence" && this.state.ville== null)
                        return x
                    else if(x.Type==="agence" && x.Gouvernerat.toLowerCase()==this.state.ville.toLowerCase())
                        return x
                }
            )
            .filter(
                x => {
                    if(this.state.search == null)
                        return x
                    else if(x.Nom.toLowerCase().indexOf(this.state.search.toLowerCase(),0)!==-1)
                        return x
                    }
                    )
            .map((item) => {
                return(
            <div key={item.id} className="d-flex flex-nowrap agency-box my-2 mr-1">
                <div>
                    <Image fluid src={MAP_ICON} />
                </div>
                <div>
                    <p className="agency-title" onClick={() =>this.props.pickUpMapPosition(item.Latitude,item.Longitude,15)}> {item.Nom} </p>
                    <div className="agency-title-line" />
                    <p className="agency-adress"> Adresse : {item.Adresse} </p>
                    <p className="agency-adress"> Téléphone : {item.Tel} </p>
                    <p className="agency-adress"> Mail : {item.Email} </p>
                </div>  
            </div>
                )
            })}      
        </div>

    </div>
        );
    }
}