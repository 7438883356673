import React, { Component } from 'react';

//css
import './index.css';
//react bootstrap
import {
    Row,
    Col,
    Image,
    InputGroup,
    FormControl,
    Button,
} from 'react-bootstrap';

//router dom
import { Link, Route, Switch } from 'react-router-dom';

//component
import NewsList from './news';
import NewsDescription from './newsDescription';
import Pagination from './Pagination';
import axios from 'axios';
import { config } from '../../../../config';

//images 
const BLOG_IMG = require('../../../../asset/image/Particulier/blog.png');
const INSTA_IMG1 = require('../../../../asset/image/Particulier/instagram1.png');
const INSTA_IMG2 = require('../../../../asset/image/Particulier/instagram2.png');
const INSTA_IMG3 = require('../../../../asset/image/Particulier/instagram3.png');
const INSTA_IMG4 = require('../../../../asset/image/Particulier/instagram4.png');


export default class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            articlesPerPage: 2,
            selectedCategory: 1
        };
    }

    componentDidMount() {
        axios.get(`${config.url}getArticles`)
            .then(response => {
                // const articles = [...response.data, ...response.data, ...response.data, ...response.data, ...response.data, ...response.data, ...response.data].forEach(el => el.id = Math.random() * 100);
                this.setState({ ...this.state, allArticles: response.data });
                this.filterArticlesPerCategoryHandler();
                // this.showingArticlesHandle();
            }).catch(error => console.log(error, "related to fetching articles"));
        axios.get(`${config.url}getCategories`)
            .then(response => this.setState({ ...this.state, categories: response.data }))
            .catch(error => console.log(error, "related to fetching categories"));
    }

    showingArticlesHandle = (page = 0) => {
        const showingArticles = this.state.filteredArticles
            .slice(page, page + this.state.articlesPerPage);
        this.setState(prevState => {
            return { ...prevState, showingArticles: showingArticles };
        });
        this.setState(prevState => {
            return { ...prevState, currentPage: page };
        });
    };

    categoryHandle = (id = 1) => {
        this.setState({ ...this.state, selectedCategory: id }, () => {
            this.filterArticlesPerCategoryHandler();
        });
    };

    filterArticlesPerCategoryHandler = () => {
        const filteredArticles = this.state.allArticles.filter(article => article.categorie_actualites_id == this.state.selectedCategory);
        this.setState(prevState => {
            return { ...prevState, filteredArticles: filteredArticles };
        }, () => {
            this.showingArticlesHandle();
        });
    };

    render() {
        return (
            <Row className="mx-0 justify-content-center mb-5">

                <Col xl="9" lg="12" md="12" xs="12">
                    <p className="news-big-title"> Suivez toute l’actualité en temps réel</p>
                </Col>

                {/**news list and description*/}
                <Col xl="7" lg="9" md="8" xs="12">
                    <Switch>
                        <Route exact path='/particulier/actuality'>
                            <NewsList articles={this.state.showingArticles} />
                            <Pagination showingArticlesHandle={this.showingArticlesHandle} articlesPerPage={this.state.articlesPerPage} currentPage={this.state.currentPage} articles={this.state.filteredArticles} />
                        </Route>
                        <Route exact path='/particulier/actuality/description/:id'>
                            <NewsDescription />
                        </Route>
                    </Switch>
                </Col>
                {/**news list */}

                <Col xl="2" lg="2" md="3" xs="9" className="mx-0 px-0">
                    {/**Search buttom */}
                    <div>
                        <InputGroup>
                            <FormControl placeholder="Recherche" className="search-input-news" />
                        </InputGroup>
                    </div>
                    {/**Search buttom */}

                    {/***Categories */}
                    <div>
                        <p className="news-title"> Categories </p>
                        <div>
                            {this.state.categories && this.state.categories.map((category, i) => {
                                const active = category.id === this.state.selectedCategory;
                                const color = active ? '#58B795' : '#999999';
                                const text = active ? `_____  ${category.Titre}` : category.Titre;
                                return <p className="category-item" style={{ cursor: 'pointer', color: `${color}` }} onClick={() => this.categoryHandle(category.id)}>{text}</p>;
                                // <p className="selected-category-item">______ Lorem </p>
                            })}
                        </div>
                    </div>
                    {/***Categories */}

                    {/**Recent blog post */}
                    <div>
                        <p className="news-title"> Recent blog post </p>
                        {this.state.allArticles && this.state.allArticles.slice(0, 3).map((article, i) => {
                            return <Link to={`/particulier/actuality/description/${article.id}`} style={{ textDecoration: 'none' }}>
                                <div key={i} className="d-flex flex-nowrap my-2" style={{ cursor: 'pointer' }}>
                                    <div>
                                        <Image fluid width={85} height={85} src={config.img + article.Img} />
                                    </div>
                                    <div className="m-auto">
                                        <p className="blogpost-title">{article.Titre}</p>
                                        <small className="blogpost-date">{article.Date}</small>
                                    </div>
                                </div>
                            </Link>;
                        })}
                    </div>
                    {/**Recent blog post */}

                    {/***Instagram */}
                    <div>
                        <p className="news-title"> Instagram </p>
                        <Image fluid width='45%' src={INSTA_IMG1} className="my-1 mr-2" />
                        <Image fluid width='45%' src={INSTA_IMG2} className="my-1 mr-2" />
                        <Image fluid width='45%' src={INSTA_IMG3} className="my-1 mr-2" />
                        <Image fluid width='45%' src={INSTA_IMG4} className="my-1 mr-2" />
                    </div>
                    {/***Instagram */}

                    {/***Popular tags */}
                    <div>
                        <p className="news-title"> Popular tags </p>
                        <div>
                            <p className="tags"> lorem &nbsp; lorem &nbsp; lorem &nbsp; lorem &nbsp; lorem</p>
                            <p className="tags"> lorem &nbsp; lorem &nbsp; lorem &nbsp; lorem &nbsp; lorem</p>
                            <p className="tags"> lorem &nbsp; lorem &nbsp; lorem &nbsp; lorem &nbsp; lorem</p>
                            <p className="tags"> lorem </p>
                        </div>

                    </div>
                    {/***Popular tags */}
                </Col>

            </Row>
        );
    }
}
