import React, { Component } from 'react';
import { 
    Row,
    Col,
    Container

} from 'react-bootstrap';
import './productDescription.css';


export default class ProductDescription extends Component {
    render() {
        return (
            <Container>
            <Row className="mx-0 px-0 my-5">
                <Col xl="12" md="12" xs="12" className="product-description-container p-5 mx-0">  
                    <p className="product-description-text">
                        {this.props.product.Description_tech.split(/\r\n|\n|\r/gm).map(line => {
                        return <> {line}<br /> </>
                        })}
                    </p>
                </Col>
            </Row>
            </Container>
        );
    }
}