import React, { Component } from 'react';
//react elastic carousel
import Carousel, {consts} from 'react-elastic-carousel';
//css
import './about.css';
//react-bootstrap
import {
  Row,
  Col,
  Image,
  Card
} from 'react-bootstrap';

import {tag} from '../../../../const/metaTags';
import Helmet from 'react-helmet';

//Images 
// const ABOUT_IMG =require('../../../../asset/image/Particulier/about-img.png');
// const ABOUT_IMG =require('../../../../asset/image/Particulier/desktop2.png');
const ABOUT_IMG =require('../../../../asset/image/Particulier/desktop2.png');
const LEFT_ARROW =require('../../../../asset/image/Particulier/left-arrow.svg');
const RIGHT_ARROW =require('../../../../asset/image/Particulier/right-arrow.svg');

export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
        this.value = [
            {title: "La satisfaction client",
            text: "La satisfaction de nos clients est au cœur de notre stratégie de développement. Nos équipes s’engagent pour vous offrir une prestation répondant à vos attentes." ,
            logo: require('../../../../asset/image/Particulier/value1.png'),
            },
            {title: "La Communication",
            text: "La transparence dans tous les échanges avec nos collaborateurs permet à chacun de situer ses actions au sein de l’entreprise et de renforcer la cohésion globale.",
            logo: require('../../../../asset/image/Particulier/value2.png'),
            },
            {title: "La prise d’initiatives",
            text: " L’initiative est à l’origine de notre progrès du fait que nos collaborateurs peuvent prendre des décisions rapidement face à des enjeux éminents.",
            logo: require('../../../../asset/image/Particulier/value3.png'),
            },
            {title: "Le sentiment d’appartenance",
            text: "Chacun de nos collaborateurs est reconnu et considéré comme un ambassadeur de l’entreprise qui véhicule et partage les mêmes valeurs.",
            logo: require('../../../../asset/image/Particulier/value4.png'),
            }
        ];
        this.strategic= [
            {title: "Meilleure qualité",
             logo:require('../../../../asset/image/Particulier/strategic1.png'),
             text:"Toujours à l’écoute de ses clients pour proposer des services offrant un meilleur rapport qualité prix."
            },
            {title: "Différenciation de l’offre",
             logo:require('../../../../asset/image/Particulier/strategic2.png'),
             text:"Toujours à l'affût de la technologie pour proposer de nouveaux services à ses clients. "
            },
            {title: "Développement local et international",
             logo:require('../../../../asset/image/Particulier/strategic3.png'),
             text:"Grâce à des partenaires compétents qui la poussent constamment à améliorer ses services, Bee se développe sur le marché local et international."
            },
        ]
    }
//function to customize arrows carousel for small device
myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? 
    <Image src={LEFT_ARROW}/>:<Image width="30px" src={RIGHT_ARROW}/>
    return (
      <button className={`${type}-button`}  onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    )
  }

    render() {
        // document.querySelector("meta[name='description']")
        // .content="Bee fournisseur d’Internet en Tunisie propose des offres Internet à très haut débit et des services complémentaires pour les particuliers et professionnels.";
        return (

        <div className="">
            <Helmet>
                    <title> {tag.about.title} </title>
                    <meta name="description" content={tag.about.description} />
            </Helmet>
            {/*************** A propos */}
            <Row className="about-container mt-5 mx-0">
                <Col xl="4" lg="5" md="5" xs="12" className="about-img-container px-0 mx-0">
                    <Image fluid  className="about-img px-0 mx-auto" src={ABOUT_IMG} />
                </Col>
                <Col xl="6" lg="7" md="7" xs="12" className="my-auto mx-0 px-2">
                    <h1 className="about-title"> A propos </h1>
                    <p className="about-text-title"> Bee - Change for the better  </p>
                    <p className="about-text">
Internet Smart Solution SA, plus connue sous le nom commercial Bee est un acteur qui a un poids important dans le secteur de la télécommunication. Elle est le 4 ème fournisseur d’accès Internet en Tunisie.
De par sa licence en IoT et sa capacité d’adaptation aux nouvelles technologies, Bee se veut innovante de par ses services qui à la fois vous accompagnent au quotidien dans votre utilisation des télécommunications, et vous proposent des dispositifs pour soutenir votre activité.
Nous offrons un accès internet et des services complémentaires qui répondent au mieux aux besoins et attentes des particuliers et des professionnels.
<br/> <br/> Notre destinée est de fournir une meilleure connexion. « Be better » vise à raccourcir les distances créées et de permettre une liaison entre les membres de la famille, les amis, les collègues et les entreprises.
Nous sommes conscients du développement technologique. A travers notre Business, nous souhaitons mettre en oeuvre les nouvelles technologies pour construire un monde digital meilleur et permettre à tous d’en profiter.
                    </p>
                </Col>
            </Row>
            {/**************** A propos */}

            {/***************Nos Valeurs */}
            <Row className="mx-0 p-5 values-container">
                <Col xl="12" className="my-3">
                    <p className="value-title"> NOS VALEURS </p>
                    <hr className="value-line"/>
                </Col>
                {this.value.map( item =>{
                return(
                <Col xl="3" lg="3" md="3" className="value-item">
                    <div className="value-container">
                        <div className="value-logo-container my-auto">
                            <Image className="" fluid  src={item.logo} />
                        </div>
                        <div className="value-logo-title py-2">
                            <p> {item.title} </p>
                        </div>
                    </div>
                    <div className="value-text my-4">
                        {item.text}
                    </div>
                </Col>
                )}
                )} 
                <Col xs="12" className="values-carousel m-0 p-0">
                    <Carousel itemPosition={1} pagination={false} itemsToShow={1} renderArrow={this.myArrow} className="m-0 p-0">
                    {this.value.map( item =>{
                    return(
                    <div className="m-5">
                    <div className="value-container">
                        <div className="value-logo-container pt-4">
                            <Image fluid  src={item.logo} />
                        </div>
                        <div className="value-logo-title py-2">
                            <p> {item.title} </p>
                        </div>
                    </div>
                    <div className="value-text my-4">
                        {item.text}
                    </div>
                    </div>
                    )}
                    )} 
                    </Carousel>
                </Col>  
            </Row>
            {/****************Nos Valeurs */}

            {/** Orientations stratégiques  */}
            <Row className="mx-0 p-5 justify-content-center">

                <Col xl="12">
                    <p className="value-title"> ORIENTATIONS STRATEGIQUES  </p>
                    <hr className="strategic-line"/>
                </Col>
            {this.strategic.map( item =>{
                return(
                <Col xl="3" lg="3" md="4" xs="12" className="strategic-container mx-xl-3 mx-lg-3">
                    <div className="px-5 py-4">
                        <Image fluid src={item.logo} />
                    </div>
                    <div className="strategic-title">
                        <p> {item.title} </p>
                    </div>
                    <div className="strategic-text">
                        <p>{item.text}</p>
                    </div>
                </Col>
                )}
                )}
            </Row>
            {/** Orientations stratégiques  */}

        </div>
        );
    }
}
