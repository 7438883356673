import React, { Component, useEffect }  from 'react';
import './App.css';

//import react router
import {
  Route,
  BrowserRouter as Router} from "react-router-dom";

//component
import Particulier from './App/Home/Particulier/index';

/***  google analytics key ******/
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-163822329-1'); // add your tracking id here.
// ReactGA.pageview(window.location.pathname + window.location.search);


class App extends Component {

  render() {
      return (
      <Router>
        <Route path='/' component={Particulier}/>
      </Router>

      );
  }
}

export default App;