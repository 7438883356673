import React from 'react';
import './faqItem.css';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const faqItem = (props) => {
    
    return(
        <AccordionItem className="faq-item my-3">
            <AccordionItemHeading>
                <AccordionItemButton className="faq-label">
                    {props.question}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="faq-text mx-5">
                <p>
                    {props.response}
                </p>
            </AccordionItemPanel>
        </AccordionItem>
        )
}

export default faqItem;