import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import '../debit.css'


function valuetext(value) {
  return`${value} Mo`;
  
}
export default class InputSlider extends Component {

render() {
  let marks=[];
  let arr=[];
  for(let key in this.props.flows){
    if(this.props.flows[key].offres_id==this.props.idOffre){
      marks.push({
          value:this.props.flows[key].Vitesse,
          label:this.props.flows[key].Vitesse+'Mo'
      })
      arr.push(this.props.flows[key].Vitesse)}
  }
  let min = Math.min(...arr);
  let max = Math.max(...arr);
  let defaultValue=Number(this.props.defaultFlow)
  return (
    <div className="slider-container px-2">
      <Slider
        key={defaultValue}
        defaultValue={defaultValue}
        name="flowRange"
        min={min}
        max={max}
        getAriaValueText={valuetext}
        onChange={this.props.changed("flowRange")}
        step={null}
        valueLabelDisplay="off"
        marks={marks}
      />
    </div>
  );
}
}