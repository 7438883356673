import React, { Component } from 'react';
import { Row,Col,Form,Button, Image } from 'react-bootstrap';

const UPLOAD_ICON=require('../../../../../asset/image/Particulier/upload.png')

export default class InputImage extends Component {

    render(){
        return (
           
            <>
                <Form.Label className="subscription-label"> 
                {this.props.label} : 
                </Form.Label>
                <Form.Control 
                    name={this.props.name}
                    size="sm" className=" image-input" 
                    type="file" 
                    accept="image/png, image/jpeg" 
                    isInvalid={!this.props.isValid && this.props.touched}
                    onChange={(event) => this.props.changed(event, this.props.name)}
                />
                <Image src={UPLOAD_ICON} className="m-auto upload-icon" />  
                <Form.Control.Feedback type="invalid" className="px-2"> {this.props.errorMessage}</Form.Control.Feedback>
            </>
        );
      } }

                        
