import React, { Component } from 'react';
import { 
    Form
} from 'react-bootstrap';
import './filtreCat.css';

export default class CategoryFiltre extends Component {
    
    render() {
        let {items}=this.props;
        return (
                <div className="productFilter">
                    <h1 className="productCatFiltre-title"> Catégories </h1>
                    {items.map(el=>{
                    return(
                        <Form.Check 
                            name="category"
                            value={el.id}
                            // defaultChecked={el.defaultChecked} 
                            type="checkbox" 
                            label={el.type}
                            className="productCatFiltre-item ml-3"
                            onChange={(event) => this.props.changed(event, "category")}
                        />
                    )
                })}  
                </div>
        );
    }
}