import React, { Component, useEffect, useState } from 'react';



// const Pagination = ({ allArticles, showingArticlesHandle }) => {
//   const [paginationNumbers, setPaginationNumbers] = useState([]);

//   const generatePaginationFn = () => {
//     if (allArticles) {
//       let numbers = [];
//       for (let index = 0; index < Math.ceil(allArticles.length / 3); index++) {
//         numbers.push(index);
//       }
//       setPaginationNumbers(numbers);
//     }
//   };



//   useEffect(() => {
//     generatePaginationFn();
//   }, [allArticles]);


//   return (
//     <div style={{ display: 'flex', justifyContent: 'end', padding: '1rem', gap: '5px' }}>
//       {paginationNumbers && paginationNumbers.map(el => <div onClick={() => showingArticlesHandle(el + 2, el + 5)} style={{ padding: '5px', border: '1px solid black', cursor: 'pointer' }}>{el + 1}</div>)}
//     </div>
//   );
// };


// export default Pagination;

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let pageNumbers = [];
    if (this.props.articles) {
      for (let index = 0; index < this.props.articles.length; index += this.props.articlesPerPage) {
        pageNumbers.push(index);
      }
    }
    return <div style={{ display: 'flex', justifyContent: 'end', padding: '1rem', gap: '10px' }}>
      {pageNumbers && pageNumbers.map((number, i) => {
        return <div onClick={() => this.props.showingArticlesHandle(number)} style={{ padding: '5px 10px', border: `2px solid ${number == this.props.currentPage ? '#58B795' : '#D9D9D9'}`, cursor: 'pointer', borderRadius: '5px' }}>{i + 1}</div>;
      })
      }
    </div>;
  }
}