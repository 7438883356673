import React, { Component } from 'react';
//css
import '../subscription.css';
import axios from 'axios';
import {config} from '../../../../../config';
import Breadcrumb from '../../../../Navigation/breadcrumb/breadcrumb';
import { Col, Image, Row } from 'react-bootstrap';



export default class SelectedOffer extends Component {

    state ={
        flowList:[],
        flowListPack:[],
        offerItems:[]
    }

    componentDidMount() {
            axios.get(`${config.url}detailsOffres`)
            .then( response => {
                this.setState({...this.state,flowList:response.data})            
            }).catch(error=> console.log(error, "related to offre detail") )

            axios.get(`${config.url}detailsOffrespack`)
            .then( response => {
                this.setState({...this.state,flowListPack:response.data})
            }).catch(error=> console.log(error, "related to offre pack detail") )

            axios.get(`${config.url}offres`)
            .then(response => {
                this.setState({ ...this.state,offerItems: response.data });
            }).catch(error => console.log(error, "related to offre"));
    }





    render(){
        const categoryImage = (this.state.offerItems.find(offer => offer.id === this.props.offerId))?.image
        let items=this.props.type==="offre"?
        [{name:this.props.offre,path:`/particulier/offre/${this.props.offre.replace(/ /g, '')}`}]
        :this.props.offre=="Bee Home"?
        [{name:this.props.offre,path:`/particulier/offre-packagée/${this.props.offre.replace(/ /g, '')}`}]
        :[{name:"Bitdefender",path:`/particulier/offre-packagée/Bitdefender`}]
        return (
        <> 
        <Row className="mx-0 px-0 justify-content-center">
            <Col xl="10" md="10" xs="12"className="mt-5">
                <Breadcrumb active="Demande Abonnement" items={items}/>
            </Col>
            
            <Col xl="10" md="10" xs="12"className="">
                <p className="selected-offre my-5"> 
                    Vous avez choisi l'offre 
                </p>
            </Col>
            <Col xl="10" md="10" xs="12"className="card_container" > 
            {this.props.type=='offre'?
                this.state.flowList.map(el =>{
                    const selectedOffer = el.reference === this.props.reference
                    if(el.offres_id==this.props.offerId)
                    return <div onClick={()=>this.props.changed(el.reference)} className="p-4 d-flex flex-column align-items-center" style={{backgroundColor:`${selectedOffer ? 'white':'#F8F8F7'}`,boxShadow:`${selectedOffer?'16px 16px 24px #0000001A':'none' }`,borderRadius:'44px',cursor:'pointer',width:'200px' ,height:'max-content'}}>
                        <Image src={config.img+categoryImage} alt="" style={{width:'55%',marginBottom:"12px"}}/>
                        <p className="offer-title-flow">
                            <var style={{fontStyle:'normal'}}>{el.Vitesse} 
                            <sup style={{fontStyle:'normal'}}>  
                                jusqu'a <br/>  Mbps        
                            </sup>
                            </var> 
                        </p>
                        <p className="offer-price">
                            <var> {Math.floor(el.Prix)} <sup>,{Math.round((el.Prix - parseInt(el.Prix)) * 10)}</sup></var> <span style={{color:'#314294',fontFamily:'Neo Regular'}}> TTC/Mois</span>
                        </p>
                    </div>
                })
                :this.props.type=='pack'?
                this.state.flowListPack.map(el =>{
                    if(el.offresPackgers_id==this.props.offerPackId)
                    return <div></div>
                })
                :null}
            </Col>
        </Row>
        </>
        );
      } }

    //   <select 
    //                 name="debit"
    //                 value={this.props.reference}
    //                 className="selected-offre-select"
    //                 onChange={(event) => this.props.changed(event, "debit")}
    //             >

    //                 {this.props.type=='offre'?
    //                 this.state.flowList.map(el =>{
    //                     if(el.offres_id==this.props.offerId)
    //                     return <option key={el.id} value={el.reference}>  {el.Vitesse} Mbps </option>
    //                 })

                    
    //                 :this.props.type=='pack'?
    //                 this.state.flowListPack.map(el =>{
    //                     if(el.offresPackgers_id==this.props.offerPackId)
    //                     return <option key={el.id} value={el.reference}>  {el.Vitesse} Mbps </option>
    //                 })

    //                 :null}

    //             </select>