import React, { Component } from 'react'
import './couvertureZone.css';

import {
    InputGroup,
    FormControl
} from 'react-bootstrap';


export default class ZoneCoverage extends Component {
    render() {
        return (
        <div className="mx-0 my-5 flex-wrap">
            <div className="zone-coverage-title mx-auto">
                <h2> VÉRIFIEZ LA COUVERTURE DE VOTRE ZONE PAR: ADSL/VDSL </h2>
            </div>
            <div className="zone-coverage-text mx-auto">
                <p> Entrez votre n° de téléphone pour vérifier la couverture de votre zone</p>
            </div>
            <div className="num-adsl-container d-flex mx-auto">
                <InputGroup>
                    <FormControl  placeholder="216" className="num-adsl-input" />
                </InputGroup>
            </div>  
        </div> 
        )
    }
}