import React, { Component } from 'react';
import { withRouter } from "react-router";
//css
import './index.css';

import {
    Row,
    Image,
} from 'react-bootstrap';
import axios from 'axios';
import { config } from '../../../../config';
import parse from 'html-react-parser';

//images 
const IMG = require('../../../../asset/image/Particulier/news-img.png');
const IMG2 = require('../../../../asset/image/Particulier/news-img3.png');


class NewsDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        console.log('render');
        axios.get(`${config.url}getArticles`)
            .then(response => {
                const article = response.data.find(el => el.id == this.props.match.params.id);
                this.setState({ ...this.state, article });
            }).catch(error => console.log(error, "related to fetching articles"));
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            axios.get(`${config.url}getArticles`)
                .then(response => {
                    const article = response.data.find(el => el.id == this.props.match.params.id);
                    this.setState({ ...this.state, article });
                }).catch(error => console.log(error, "related to fetching articles"));
        }
    }
    render() {
        return (
            this.state.article ? <Row className="mx-0 px-3">
                <div className="mx-0">
                    <h5 className="news-list-title">
                        {this.state.article.Titre}
                    </h5>
                    <br />
                    <p className="news-date">{this.state.article.Date}</p>
                    <Image fluid src={config.img + this.state.article.Img} />
                </div>
                <div className="mx-0 my-4">
                    <div className="news-text">
                        {parse(this.state.article.Contenu)}
                    </div>
                </div>
            </Row> : null
        );
    }
}

export default withRouter(NewsDescription);