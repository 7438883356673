import React, { Component } from 'react';
//css
import './debit.css';
//react-bootstrap
import { Row, Col, Image, Button, } from 'react-bootstrap';
import axios from 'axios';
import {config} from '../../../../../config';
//component 
import ServiceBox from './serviceBox/service';
import FlowBox from './flowBox/flow';
import { Link } from 'react-router-dom';

//Images 
const OFFRE_IMG = require('../../../../../asset/image/Particulier/offre.png');
const EXPERT_IMG = require('../../../../../asset/image/Particulier/expert-logo.png');
const LINK_LOGO = require('../../../../../asset/image/Particulier/link-logo.png');

export default class Debit extends Component {

        state = { 
            totalPrice:null,
            //service
            servicePrice:0,
            //offre
            idOffre:null,
            //debit
            flowValues:null,
            currentFlowValue:null,
            flowCurrentPrice:null
         };

    componentDidMount() {
            //get offer details
            const idOffre = this.props.match.params.idOffre;
            const idDebit=this.props.match.params.idDebit;
            axios.get(`${config.url}detailsOffres`)
            .then( response => {
                this.setState({flowValues:response.data,currentFlowValue:response.data[idDebit].Vitesse,flowCurrentPrice:response.data[idDebit].Prix})
            })
            .catch(error=>
                console.log(error,"related to details offer")
                )
            axios.get(`${config.url}offres`)
            .then( response => {
                this.setState({idOffre:response.data[idOffre].id})
                })
            .catch(error=>
                console.log(error,"related to offer")
                )

    }
    //récupération du prix de service
    handleServiceChange=(event) => {
        this.setState({servicePrice:event.target.value})

    }
    //recupération du débit et son prix
      handleFlowChange = (name) => (e, value) => {
          this.setState({currentFlowValue:value});
          this.state.flowValues.map(el=>{
            if(el.Vitesse==value){
                this.setState({flowCurrentPrice:el.Prix});
            }
        })
          
      }
    render() {
        let {
            currentFlowValue,
            flowCurrentPrice,
            servicePrice
        } = this.state;
        let serviceList=this.props.services?this.props.services:[];
        let flows=this.state.flowValues?this.state.flowValues:[];
        let totalPrice=Number(servicePrice)+Number(flowCurrentPrice);

    return (
        <div className="mx-0">
            {/******* image header *********/}
            <Row className="mx-0">
                <Image fluid src={OFFRE_IMG} />
            </Row>
            {/* <Row className="flow-text-header mx-0">
                <p className="mx-auto my-5"> 
                  En souscrivant à notre offre ADSL BEE SPEED vous bénéficiez d’un de nos services gratuitement
                </p>
            </Row> */}

            {/********* flow choice ********/}
            <Row className="mx-0 flow-container justify-content-center">
                <Col xl="10" lg="11" md="12" xs="12" className="flow-content pt-5 mx-0 px-0">

                <Row className="mx-0 justify-content-center">
                {/**************  Partie Debit  **************/}
                <Col xl="4" lg="5" md="5" xs="11" className="flow-box-container mx-0 my-0 py-0 px-0">
                    <FlowBox
                        handleFlowChange={this.handleFlowChange}
                        currentFlowValue={this.state.currentFlowValue}
                        idOffre={this.state.idOffre}
                        flowPrice={flowCurrentPrice}
                        flows={flows}
                    />
                </Col>
                {/**************  Link image **************/}
                <Col xl="1" lg="1" md="1" xs="11" className="my-auto py-0 mx-0 px-0 flow-link-container">
                    <Image fluid className="flow-link" src={LINK_LOGO} />
                </Col>
                {/**************  Partie Services **************/}
                <Col xl="4" lg="5" md="5" xs="11" className="flow-box-container my-0 py-0 mx-0 px-0">
                    <ServiceBox
                        serviceList={serviceList}
                        changed={this.handleServiceChange}
                        price={servicePrice}

                    />
                </Col>     
                </Row>
                {/******Total Price and offer text********/}
                <Row className="mx-0 px-0 mt-5 justify-content-center total-price-container">
                    <Col xl="4" lg="5" md="5" xs="12" className="my-auto">
                        <p className="offre-client-text"> 
                            Offre ADSL Bee Speed {currentFlowValue}Mo + Bee Safe
                        </p>
                    </Col>
                    <Col xl="6" lg="6" md="6" xs="12" className="text-center my-3">
                        <p className="offer-price-title"> Total mensuel</p>
                        <p className="offer-client-price"> 
                            <strong> <var> {Math.floor(totalPrice)} <sup>,{Math.round((totalPrice - parseInt(totalPrice)) * 10)}</sup></var> </strong> TTC/Mois
                        </p>
                        <Button className="flow-btn px-4 py-1"> Commander </Button>
                    </Col>
                </Row>
                </Col>
            </Row> 

            {/**** footer ****/}
            <Row className="mx-0 my-5">
                <Col xl="3" lg="4" md="5" xs="10" className="text-center mx-auto">
                    <Image  fluid src={EXPERT_IMG} />
                    <Link to='/particulier/contact' className="flow-contact-title"> CONTACTEZ UN EXPERT EN LIGNE </Link>
                    <small className="flow-contact-text"> Notre assistance en ligne est à votre disposition</small>
                </Col>
            </Row>
        </div>
        );
    }
}