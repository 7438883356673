import React, { Component } from 'react';
import { Row, Col, Form, Button, Image } from 'react-bootstrap';

export default class Input extends Component {

    render() {
        return (
            <>
                <Form.Label className="subscription-label">
                    {this.props.label}
                </Form.Label>
                <Form.Control
                    name={this.props.name}
                    size="sm" className={this.props.touched ? "subscription-input touched px-4" : "subscription-input px-4"}
                    type={this.props.type} placeholder={this.props.placeholder}
                    value={this.props.value}
                    isInvalid={!this.props.isValid && this.props.touched}
                    onChange={(event) => this.props.changed(event, this.props.name)}
                />
                <Form.Control.Feedback type="invalid"> {this.props.errorMessage} </Form.Control.Feedback>
            </>
        );
    }
}