import React, { Component } from 'react';

//css
import './network.css';
//react-bootstrap
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';

import axios from 'axios';
import {config} from '../../../../config';
import {tag} from '../../../../const/metaTags';
import Helmet from 'react-helmet';

//component
import Map from './Map/map';
import Agencies from './Agencies/agencies';
import Spinner from '../../../UI/spinner/spinner';


export default class Network extends Component {

        state = { 
            //btn view forsmall device 
            mapViewBtn:"btnClicked",
            agenciesViewBtn:"btnNonClicked",
            mapResponsive:"",
            agenciesResponsive:"responsive",
            //map position prop
            mapPosition: {
                lat: 36.444,
                lng: 10.176
              },
            zoom : 9,
            //agencies list
            agencies:null

         };
    componentDidMount () {
        axios.get(`${config.url}agences`)
        .then( response => {
            this.setState({agencies:response.data})
        })
        .catch(error=>
            console.log(error, "related to agencies")
            )
    }
    // for responsive design
    balanceBtn = () => {
        if(this.state.mapViewBtn==="btnNonClicked"){
            this.setState({mapViewBtn:"btnClicked"});
            this.setState({agenciesViewBtn:"btnNonClicked"});
            this.setState({mapResponsive:""});
            this.setState({agenciesResponsive:"responsive"});
        }
        else if(this.state.agenciesViewBtn==="btnNonClicked"){
            this.setState({agenciesViewBtn:"btnClicked"});
            this.setState({mapViewBtn:"btnNonClicked"});
            this.setState({mapResponsive:"responsive"});
            this.setState({agenciesResponsive:""});
        }
    }

    //////change the mapPosition
    handleMapPosition = (x,y,z) => {
        this.setState({mapPosition:{lat:x,lng:y}, zoom:z})
    }

    render() {
        let {
            mapViewBtn,
            agenciesViewBtn,
            mapResponsive,
            agenciesResponsive,
            agencies
        } = this.state;
        return (

        <div className="mx-0 px-0">
            <Helmet>
                <title> {tag.network.title} </title>
                <meta name="description" content={tag.network.description} />
            </Helmet>
            {/********network header********/}
            <Row className="network-header justify-content-center mx-0 mb-4">
                <Col xl="8" md="10" xs="10" className="mt-auto">
                    <h1 className="network-header-title"> Bee, toujours proche de vous </h1>
                    <p className="network-header-text">  Retrouvez l’agence Bee, le revendeur ou la Poste la plus proche de vous ! </p>
                </Col>
            </Row>
            {/*********network header********/}

            {/*********network maps and agencies ********/}
            {agencies ? 
            <Row className="m-0 p-0 network-agencies-container">
                {/**balance from agencies to maps in small devices */}
                <Col xl="12" md="11" xs="11" className="responsive-container mx-0 px-0 mt-5">
                    <Button className={mapViewBtn} onClick={()=>this.balanceBtn()}> Map </Button>
                    <Button className={agenciesViewBtn} onClick={()=>this.balanceBtn()} style={{width:'45%'}}> Liste de nos agences </Button>
                </Col> 
                {/*************************** agencies */}
                <Col xl="3" lg="4" md="11" xs="11" className={`${agenciesResponsive} agencies-container ml-auto py-5`}>
                    <Agencies 
                        pickUpMapPosition={this.handleMapPosition}
                        agencies={agencies}
                    />
                </Col>
                {/*************************** agencies */}

                {/**************************** maps */}
                <Col xl="8" lg="8" md="11" xs="11" className={`${mapResponsive} map-container mx-0 px-0 py-5`}>
                    <Map 
                        handleMapPosition={this.handleMapPosition}
                        mapPosition={this.state.mapPosition}
                        agencies={agencies}
                        zoom={this.state.zoom}
                    />
                </Col>
                {/*************************** maps */}
            </Row>
            :<Spinner/>}
            {/*********network maps and agencies ********/}

            {/*********network footer********/}
            <Row className="mx-0 my-5">
                <div className="mx-auto network-footer">
                    <p> Pour devenir un revendeur Bee, <br/>
                        envoyez vos coordonnées à l’adresse <br/>
                    <a href="mailto:revendeurs@bee.net.tn" > revendeurs@bee.net.tn </a>
                    </p>
                </div>
            </Row>
            {/*********network footer********/}
        </div>
        );
    }
}