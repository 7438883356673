import React, { Component } from 'react';

//css
// import './offre.css';
import {Row, Col, Image, Button } from 'react-bootstrap';
import {NavLink, Redirect} from 'react-router-dom';

import axios from 'axios'
import {config} from '../../../../config';
import {offrePackTag} from '../../../../const/metaTags';
import Helmet from 'react-helmet';
import PackDetails from './détails';
import Spinner from '../../../UI/spinner/spinner';
import Breadcrumb from '../../../Navigation/breadcrumb/breadcrumb';
import Ribbon from '../../../UI/ribbon/ribbon';


export default class OffrePack extends Component {
    state={
        category:null
    }
    componentDidMount() {
        let offreCat=this.props.idPack
        axios.get(`${config.url}catoffrespack?id=${offreCat}`)
        .then( response => {
            this.setState({category:response.data})
        }).catch(error=> console.log(error,"related to offre pack cat") )
    }
    componentDidUpdate(prevProps) {
        if (this.props.idPack !== prevProps.idPack) {
            let offreCat=this.props.idPack
            axios.get(`${config.url}catoffrespack?id=${offreCat}`)
            .then( response => {
                this.setState({category:response.data})
            }).catch(error=> console.log(error,"related to offre pack cat") )
        }
      }

    render() {
        let category = this.state.category;
        let currentOffre =this.props.offres[this.props.idOffrePack]?this.props.offres[this.props.idOffrePack]:null;
        let offres =this.props.offres;
        return (
        <>
        {(category&&currentOffre)?
        <div className="mx-0">
            {/**Meta tags */}
            {offrePackTag.map(el=>{
            if(el.name===category.type){
            return(
            <Helmet>
                <title> {el.title} </title>
                <meta name="description" content={el.description} />
            </Helmet>
            )}
                
            })}

            {/****************/}
            <div className="mx-0">
                <Image fluid src={config.img+category.image} />
            </div>
            {/*****************/}

            {/**********breadcrumb */}
            <Col xl="11" lg="11" md="12" xs="12" className="mt-3 px-0 mx-auto">
                <Breadcrumb active={category.type}/>   
            </Col>
            {/**********breadcrumb */}

            {/****************/}
            <Row className="mx-0 justify-content-center">
                <Col xl="7" lg="10" md="11" xs="12" className="mx-auto mt-5">
                    <h1 className="offers-title"> Offres Packagées </h1>
                    <p className="offers-text my-4"> 
                        {category.description}
                    </p>
                </Col>

                <Col xl="7" lg="10" md="11" xs="12" className="d-flex flex-nowrap mb-5">
                    {
                    offres.map((el,id)=>{
                    if(el.categorie_offresPackgers_id==category.id &&el.nom==category.type)
                        return(
                        <>
                        <Redirect to={`/particulier/offre-packagée/${category.type.replace(/ /g,'')}/${id}`} />
                        <Button 
                        as={NavLink} to={`/particulier/offre-packagée/${category.type.replace(/ /g,'')}/${id}`}
                        activeClassName="offerPack-actif-btn" className="offerPack-btn"
                        >
                            <Image fluid src={config.img+el.image} />
            
                        </Button>
                        </>
                                )
                    else if(el.categorie_offresPackgers_id==category.id)
                        return(
                    <Button 
                        as={NavLink} to={`/particulier/offre-packagée/${category.type.replace(/ /g,'')}/${id}`}
                        activeClassName="offerPack-actif-btn" className="offerPack-btn"
                    > 
                    <Image fluid src={config.img+el.image} />
    
                    </Button>
                        )
                    })}
                </Col>
                
                <Col xl="12" lg="12" md="12" xs="12" className="mb-5 mx-0">
                    {this.props.match.params.test?
                    <PackDetails category={category} offres={offres} idOffrePack={this.props.idOffrePack}/>
                    :null}
                </Col>
                
            </Row>
            {/*****************/}
            {this.props.match.params.test?            
            <Ribbon/>
            :null}
        </div>
        :<Spinner/>}
        </>
        );
    }
}