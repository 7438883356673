import React, { Component } from 'react';
//css
import './contact.css';
import ContactForm from './contactForm';
import ContactHeader from './contactHeader';
import { tag } from '../../../../const/metaTags';
import Helmet from 'react-helmet';


export default class Contact extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <title> {tag.contact.title} </title>
                    <meta name="description" content={tag.contact.description} />
                </Helmet>
                <ContactHeader />
                <ContactForm />
            </div>
        );
    }
}