export const ville = [
    { "Gouvernorat": "Ariana", "Ville": ["Sidi Thabet","La Soukra","Raoued","Kalaat Landlous","Ariana Ville","Ettadhamen","Mnihla"] },
    { "Gouvernorat": "Béja", "Ville": ["Teboursouk","Mejez El Bab","Beja Nord","Testour","Nefza","Beja Sud","Thibar","Amdoun","Goubellat"] },
    { "Gouvernorat": "Ben Arous", "Ville": ["El Mourouj","Bou Mhel El Bassa","Fouchana","Hammam Lif","Mohamadia","Rades","Mornag","Megrine","Hammam Chatt","Nouvelle Medina","Ezzahra","Ben Arous" ]},
    { "Gouvernorat": "Bizerte", "Ville": ["Bizerte Sud","Sejnane","Ghar El Melh","Menzel Jemil","Menzel Bourguiba","Ras Jebel","Ghezala","Joumine","Utique","Bizerte Nord","El Alia","Mateur","Jarzouna","Tinja"] },
    { "Gouvernorat": "Gabes", "Ville": ["Nouvelle Matmat","Mareth","Gabes Medina","Gabes Ouest","El Hamma","El Metouia","Menzel Habib","Matmata","Gabes Sud","Ghannouche"] },
    { "Gouvernorat": "Gafsa", "Ville": ["El Guettar","Moulares","Metlaoui","Sned" ,"El Ksar","Sidi Aich","Gafsa Sud" ,"Belkhir","Gafsa Nord","Redeyef","El Mdhilla"] },
    { "Gouvernorat": "Jendouba", "Ville": ["Ain Draham","Balta Bou Aouene","Bou Salem","Fernana","Ghardimaou","Jendouba Nord","Oued Mliz","Tabarka"] },
    { "Gouvernorat": "Kairouan", "Ville": ["Bou Hajla","Chebika","Cherarda","El Ala","Haffouz","Hajeb El Ayoun","Kairouan Nord","Kairouan Sud","Nasrallah","Oueslatia","Sbikha"] },
    { "Gouvernorat": "Kasserine", "Ville": ["El Ayoun","Ezzouhour","Feriana","Foussana","Haidra","Hassi El Frid","Jediliane","Kasserine Nord","Kasserine Sud","Mejel Bel Abbes","Sbeitla","Sbiba","Thala","Feriana"] },
    { "Gouvernorat": "Kebilli", "Ville": ["Douz","El Faouar","Kebili Nord","Kebili Sud","Souk El Ahad"] },
    { "Gouvernorat": "Kef", "Ville": ["Dahmani","Jerissa","El Ksour","Sers","Kalaa El Khasba","Kalaat Sinane","Le Kef Est","Le Kef Ouest","Nebeur","Sakiet Sidi Youssef","Tajerouine"] },
    { "Gouvernorat": "Mahdia", "Ville": ["Bou Merdes","Chebba","Chorbane","El Jem","Souassi","Ksour Essaf","Mahdia","Melloulech","Ouled Chamakh","Sidi Alouene"] },
    { "Gouvernorat": "Manouba", "Ville": ["Borj El Amri","Douar Hicher","El Battan","Jedaida","Mannouba","Mornaguia","Oued Ellil","Tebourba"] },
    { "Gouvernorat": "Medenine", "Ville": ["Ajim","Ben Guerdane","Beni Khedache","Houmet Essouk","Medenine Nord","Medenine Sud","Midoun","Sidi Makhlouf","Zarzis"] },
    { "Gouvernorat": "Monastir", "Ville": ["Bekalta","Bembla","Beni Hassen","Jemmal","Ksar Helal","Ksibet El Medioun","Moknine","Monastir","Ouerdanine","Sahline","Sayada Lamta Bou","Teboulba","Zeramdine"] },
    { "Gouvernorat": "Nabeul", "Ville": ["Beni Khalled","Beni Khiar","Bou Argoub","Dar Chaabane Elfe","El Haouaria","El Mida","Grombalia","Hammam El Ghez","Hammamet","Kelibia","Korba","Menzel Bouzelfa","Menzel Temime","Nabeul","Soliman","Takelsa"] },
    { "Gouvernorat": "Sfax", "Ville": ["Agareb","Bir Ali Ben Khelifa","El Amra","El Hencha","Esskhira","Ghraiba","Jebeniana","Kerkenah","Mahras","Menzel Chaker","Sakiet Eddaier","Sakiet Ezzit","Sfax Est","Sfax Sud","Sfax Ville"] },
    { "Gouvernorat": "Sidi Bouzid", "Ville": ["Ben Oun","Bir El Haffey","Cebbala","Jilma","Maknassy","Menzel Bouzaiene","Mezzouna","Ouled Haffouz","Regueb","Sidi Bouzid Est","Sidi Bouzid Ouest","Souk Jedid"] },
    { "Gouvernorat": "Siliana", "Ville": ["Bargou","Bou Arada","El Aroussa","Gaafour","Kesra","Le Krib","Makthar","Rohia","Sidi Bou Rouis","Siliana Nord","Siliana Sud"] },
    { "Gouvernorat": "Sousse", "Ville": ["Akouda","Bou Ficha","Enfidha","Hammam Sousse","Hergla","Kalaa El Kebira","Kalaa Essghira","Kondar","Msaken","Sidi Bou Ali","Sidi El Heni","Sousse Jaouhara","Sousse Riadh","Sousse Ville"] },
    { "Gouvernorat": "Tataouine", "Ville": ["Bir Lahmar","Dhehiba","Ghomrassen","Remada","Smar","Tataouine Nord","Tataouine Sud"] },
    { "Gouvernorat": "Tozeur", "Ville": ["Degueche","Hezoua","Nefta","Tameghza","Tozeur"] },
    { "Gouvernorat": "Tunis", "Ville": ["Bab Bhar","Bab Souika","Carthage","Cite El Khadra","El Hrairia","El Menzah","El Kabbaria","El Kram","El Omrane","El Omrane Superi","El Ouerdia","Essijoumi","Ettahrir","Ezzouhour (Tunis)","Jebel Jelloud","La Goulette","La Marsa","La Medina","Le Bardo","Sidi El Bechir","Sidi Hassine"] },
    { "Gouvernorat": "Zaghouan", "Ville": ["Bir Mcherga","El Fahs","Ennadhour","Hammam Zriba","Saouef","Zaghouan"] },
]