import React, { Component } from 'react';
import {Form} from 'react-bootstrap';
import { ville } from '../../../../../const/délégationsListe';
import { cities } from '../../../../../const/cities';


export default class Select extends Component {

    render(){
        return (
                <>
               
                    <Form.Label className="subscription-label"> {this.props.label} : </Form.Label>
                    <Form.Control
                    name={this.props.name}
                    size="sm" as="select"
                    className={(this.props.touched&&this.props.isValid)?"subscription-select touched px-4":"subscription-select px-4"} 
                    value={this.props.value} 
                    onChange={(event) => this.props.changed(event, this.props.name)}
                    >
                        {this.props.options==="ville"?
                        ville.map(el => {
                            if(this.props.gouvernorat===el.Gouvernorat){
                                return(
                                    <>
                                    <option value="" hidden>  Ville </option>
                                    {el.Ville.map(el=>
                                    <option key={el} value={el}>{el}</option>
                                    )}
                                    </>
                                    )
                            }
                        })
                        :this.props.options==="cité"?
                        <>
                        <option value="" hidden>  Cité </option>
                        {cities.map(el => {
                            if(this.props.ville===el.Ville){
                                return(
                                    <option key={el.Cite} value={el.Cite}>{el.Cite}</option>
                                    )
                            }
                        })}
                        </>
                        :this.props.options.map(el=>
                            <option key={el.id} value={el.value}>{el.name}</option>
                        )}

                    </Form.Control>
                </>
        );
      } }