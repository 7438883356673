import React, { Component } from 'react';
//react elastic carousel
import Carousel, { consts } from 'react-elastic-carousel';
//css
import './Offre.css';
//react-bootstrap
import { Container, Card, Button, Image } from 'react-bootstrap';
//import react router
import { Link } from "react-router-dom";

import axios from 'axios';
import { config } from '../../../../../config';

const LEFT_ARROW = require('../../../../../asset/image/Particulier/left-arrow.svg');
const RIGHT_ARROW = require('../../../../../asset/image/Particulier/right-arrow.svg');


export default class Offre extends Component {

  //customize arrow of carousel
  myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ?
      <Image className="prev" src={LEFT_ARROW} /> : <Image className="next" width="30px" src={RIGHT_ARROW} />;
    return (
      <button className={`${type}-btn`} onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  }


  render() {
    let breakPoints = [
      { width: 1, itemsToShow: 1, pagination: false },
      { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false },
      { width: 850, itemsToShow: 3, itemsToScroll: 1, pagination: false },
      { width: 1150, itemsToShow: 3, itemsToScroll: 1, pagination: false },
      { width: 1450, itemsToShow: 3, itemsToScroll: 1, pagination: false },
      { width: 1750, itemsToShow: 3, itemsToScroll: 1, pagination: false },
    ];
    let items = [...this.props.offerItems, ...this.props.offrePack];
    let categories = this.props.offrePackCat;
    console.log(this.props);
    return (
      <Container>
        <div className="pt-5 offre-title">
          <h2> NOS OFFRES </h2>
          <p> Découvrez nos packs d'accès internet à haut débit allant jusqu'à 100 Mbps </p>
        </div>
        <Carousel className="mx-2 offer-carousel" breakPoints={breakPoints} renderArrow={this.myArrow}>
          {items.map((el, id) => {
            return (
              <Card className="offre-card-container pt-5 px-2 mx-3 my-5">
                <Card.Img variant="top" src={config.img + el.image} className="offre-card-logo" />
                <Card.Body>
                  <Card.Title className="offre-card-title"> {el.nom} </Card.Title>
                  <Card.Text className="offre-card-text">
                    <p>
                      {el.description_accueil} <br />
                    </p>
                    {el.categorie_offresPackgers_id ?
                      categories.map((x, idCat) => {
                        if (el.categorie_offresPackgers_id == x.id)
                          return (
                            <Link to={`/particulier/offre-packagée/${x.type.replace(/ /g, '')}`}>
                              <Button className="card-offre-btn"> Découvrir</Button>
                            </Link>);
                      })
                      : <Link to={`/particulier/offre/${el.nom.replace(/ /g, '')}`}>
                        <Button className="card-offre-btn"> Découvrir</Button>
                      </Link>}
                  </Card.Text>
                </Card.Body>
              </Card>
            );
          }
          )}
        </Carousel>
      </Container>
    );
  }
}
/******************* */
