import React, { Component } from 'react';
import './faq.css';

import { tag } from '../../../../const/metaTags';
import Helmet from 'react-helmet';
//react-bootstrap
import {
    Row,
    Col,
} from 'react-bootstrap';

import FaqItems from './FaqItems/FaqItems';

class Faq extends Component {


    render() {
        return (
            <Row className="px-0 mx-0 justify-content-center mb-4">
                <Helmet>
                    <title> {tag.faq.title} </title>
                    <meta name="description" content={tag.faq.description} />
                </Helmet>
                <Col xl="12" lg="12" md="12" xs="12" className="faq-header">
                    <h1> FAQ </h1>
                </Col>

                <Col xl="12" lg="12" md="12" xs="12" className="faq-title">
                    <p> Questions fréquemment posées </p>
                    {/* <small> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </small> */}
                </Col>

                <Col xl="9" lg="9" md="10" xs="12">
                    <FaqItems />
                </Col>
            </Row>
        );
    }
}

export default Faq;