import React, { Component } from 'react';
import './breadcrumb.css';
import { 
    Row,
    Col,
    Image,
    Container
} from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';


class Breadcrumb extends Component {
    
    render() {
        return (
            
                <Row className="mx-0 my-0 px-0 breadcrumb-bee">
                    {/* <Col xl="10" md="10" xs="10" className="d-flex mx-0 pl-3"> */}
                    <Col xl="10" md="10" xs="12" className="d-flex mx-0 pl-3">
                        <Link className="breadcrumb-link my-auto" to='/'> Home &gt;</Link>
                        <Link className="breadcrumb-link my-auto" to='/particulier'> Particulier &gt; </Link>

                        {this.props.items?this.props.items.map(el =>
                        <Link className="breadcrumb-link my-auto" to={el.path}> {el.name} &gt;</Link>
                            ):null}
                        
                        {/****active breadcrumb */}
                        <p className="breadcrumb-active-link my-auto"> {this.props.active} </p>
                    </Col>
                    
                    {/* <Col xl="2" md="2" xs="2" className="my-auto mx-0 px-0">
                        <button  className="breadcrumb-back px-4" onClick={this.props.history.goBack}>
                        &larr; <span> Retour </span>
                        </button>  
                    </Col> */}
                </Row>
        );
    }
}

export default withRouter(Breadcrumb)