export const checkValidity = (value, rules,props) => {
    let isValid = true;
    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }
    if (rules.imageSize) {
        isValid = value.size<3000000 && value!=='' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        rules.required? isValid = pattern.test(value) && isValid :isValid = value==''|| (pattern.test(value) && isValid) 
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.identifier) {
        if(props.Type_identifiant.value==='0'){
            const pattern = /^\d+$/;
            isValid = value.trim() !== '' && value.length ==8 && pattern.test(value) && isValid;
        }
        if(props.Type_identifiant.value==='1'){
            const pattern = /^[a-zA-Z0-9]*$/
            isValid = value.trim() !== '' && pattern.test(value) && isValid;    
        }
    }
    if(rules.isTelRequired) {
        const pattern = /^\d+$/;
        isValid = value.trim() !== '' && value.length ==8 && pattern.test(value) && isValid;
    }
    if(rules.isTelNonRequired) {
        const pattern = /^\d+$/;
        isValid = value.trim() == '' || (value.length ==8 && pattern.test(value) && isValid);
    }
    if(rules.isFixeNonRequired) {
        const pattern = /^(7)[0-9]{7}$/;
        isValid = value.trim() == '' || (value.length ==8 && pattern.test(value) && isValid);
    }

    return isValid;
}
