export const cities=
[
    
    {"Ville":"Sidi Thabet","Cite":"Cebalet Ben Ammar","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Cite Ezzouaidia","CodePostal":"2036"},
    {"Ville":"Sidi Thabet","Cite":"Chorfech 12","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Cite Farhat","CodePostal":"2036"},
    {"Ville":"Sidi Thabet","Cite":"Cite Dridi","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Cite Fateh","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite El Mouaouiet","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Cite El Bokri","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Cite Fonciere","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite El Mountazeh","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Cite El Frachich","CodePostal":"2032"},
    {"Ville":"Raoued","Cite":"Complexe Technologique","CodePostal":"2088"},
    {"Ville":"La Soukra","Cite":"Cite Snit","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite Ennour Jaafar","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Cite El Ghribi","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Cite Touilia","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite Erriadh","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Cite El Methalithe","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Dar Fadhal","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite Essaada","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Cite Messouda","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"La Soukra","CodePostal":"2036"},
    {"Ville":"Kalaat Landlous","Cite":"Borj El Hadj","CodePostal":"2061"},
    {"Ville":"Raoued","Cite":"Cite Sidi Slimene","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Cite Ouvriere","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Mosque Erraoudha","CodePostal":"2036"},
    {"Ville":"Kalaat Landlous","Cite":"El Misra","CodePostal":"2061"},
    {"Ville":"Raoued","Cite":"Cite Snit","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Cite Sidi Marouane","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Residence 26-26","CodePostal":"2036"},
    {"Ville":"Kalaat Landlous","Cite":"Ferme Hababou","CodePostal":"2061"},
    {"Ville":"Raoued","Cite":"Douar El Hendi","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Ezzaouia","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Residence Ahlem","CodePostal":"2036"},
    {"Ville":"Sidi Thabet","Cite":"Bejaoua 2","CodePostal":"2020"},
    {"Ville":"Kalaat Landlous","Cite":"Ferme Mustapha","CodePostal":"2061"},
    {"Ville":"Raoued","Cite":"El Hessiene","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Jabbes","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Residence Meriem","CodePostal":"2036"},
    {"Ville":"Sidi Thabet","Cite":"Cite 18 Janvier","CodePostal":"2020"},
    {"Ville":"Kalaat Landlous","Cite":"Henchir Touba","CodePostal":"2061"},
    {"Ville":"Raoued","Cite":"Oued El Khayat","CodePostal":"2081"},
    {"Ville":"Sidi Thabet","Cite":"Jabbes El Borj","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Sidi Frej","CodePostal":"2036"},
    {"Ville":"Sidi Thabet","Cite":"Cite Afh","CodePostal":"2020"},
    {"Ville":"Kalaat Landlous","Cite":"Henchir Toubias","CodePostal":"2061"},
    {"Ville":"Raoued","Cite":"Oued El Makhzen","CodePostal":"2081"},
    {"Ville":"La Soukra","Cite":"Sidi Salah","CodePostal":"2036"},
    {"Ville":"Sidi Thabet","Cite":"Cite Des Agriculteurs","CodePostal":"2020"},
    {"Ville":"Kalaat Landlous","Cite":"Pont De Bizerte","CodePostal":"2061"},
    {"Ville":"Raoued","Cite":"Residence El Ayech","CodePostal":"2081"},
    {"Ville":"La Soukra","Cite":"Sidi Soufiene","CodePostal":"2036"},
    {"Ville":"Ariana Ville","Cite":"Ariana","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Cite Des Oranges","CodePostal":"2020"},
    {"Ville":"Raoued","Cite":"Residence El Ouns","CodePostal":"2081"},
    {"Ville":"La Soukra","Cite":"Village Essahli","CodePostal":"2036"},
    {"Ville":"Ariana Ville","Cite":"Cite Des Roses","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Cite El Gouazine","CodePostal":"2020"},
    {"Ville":"Raoued","Cite":"Residence Ennarjes","CodePostal":"2081"},
    {"Ville":"Raoued","Cite":"Residence Essalem","CodePostal":"2081"},
    {"Ville":"Ariana Ville","Cite":"Centre Commercial Ikram","CodePostal":"2037"},
    {"Ville":"Ariana Ville","Cite":"Cite Du Jardin","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Cite El Mbarka","CodePostal":"2020"},
    {"Ville":"Raoued","Cite":"Residence Rim","CodePostal":"2081"},
    {"Ville":"Ariana Ville","Cite":"Cite Ennasr 1","CodePostal":"2037"},
    {"Ville":"Ariana Ville","Cite":"Cite Du Printemps","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Cite El Mrezgua","CodePostal":"2020"},
    {"Ville":"Raoued","Cite":"Route Ezzaouia","CodePostal":"2081"},
    {"Ville":"Ariana Ville","Cite":"Cite Ennasr 2","CodePostal":"2037"},
    {"Ville":"Ariana Ville","Cite":"Cite Ennouzha","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Cite Essaada","CodePostal":"2020"},
    {"Ville":"Raoued","Cite":"Ariana Essoughra","CodePostal":"2081"},
    {"Ville":"Ariana Ville","Cite":"El Menzah 7","CodePostal":"2037"},
    {"Ville":"Ariana Ville","Cite":"Cite Essaada (Ariana)","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Cite Ettbaieb","CodePostal":"2020"},
    {"Ville":"Raoued","Cite":"Borj Touil","CodePostal":"2081"},
    {"Ville":"Ariana Ville","Cite":"El Menzah 8","CodePostal":"2037"},
    {"Ville":"Ariana Ville","Cite":"Cite Jaafar","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Cite Jardins","CodePostal":"2020"},
    {"Ville":"Raoued","Cite":"Bou Hnech","CodePostal":"2081"},
    {"Ville":"Ariana Ville","Cite":"Residence Ennour ( Naser 1)","CodePostal":"2037"},
    {"Ville":"Ariana Ville","Cite":"Nouvelle Ariana","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Cite Mongi Slim","CodePostal":"2020"},
    {"Ville":"Ariana Ville","Cite":"Residence Ennour ( Naser 2)","CodePostal":"2037"},
    {"Ville":"Ariana Ville","Cite":"Residence Ennour (Ariana)","CodePostal":"2080"},
    {"Ville":"Sidi Thabet","Cite":"Sidi Thabet","CodePostal":"2020"},
    {"Ville":"Raoued","Cite":"Cite Chaker","CodePostal":"2081"},
    {"Ville":"Raoued","Cite":"Cite De La Mosque","CodePostal":"2081"},
    {"Ville":"Ettadhamen","Cite":"Cite 18 Janvier","CodePostal":"2041"},
    {"Ville":"Ariana Ville","Cite":"Cite Belvedere 2","CodePostal":"2091"},
    {"Ville":"Ettadhamen","Cite":"Cite Ben Yerfes","CodePostal":"2041"},
    {"Ville":"Ariana Ville","Cite":"Cite Du Soleil","CodePostal":"2091"},
    {"Ville":"Ettadhamen","Cite":"Cite El Houda","CodePostal":"2041"},
    {"Ville":"La Soukra","Cite":"Borj Louzir","CodePostal":"2073"},
    {"Ville":"Ariana Ville","Cite":"Cite El Intissar 1","CodePostal":"2091"},
    {"Ville":"Ettadhamen","Cite":"Cite Ennasser","CodePostal":"2041"},
    {"Ville":"La Soukra","Cite":"Cite Ben Kilani","CodePostal":"2073"},
    {"Ville":"Ariana Ville","Cite":"Cite El Intissar 2","CodePostal":"2091"},
    {"Ville":"Ettadhamen","Cite":"Cite Ettadhamen","CodePostal":"2041"},
    {"Ville":"La Soukra","Cite":"Cite De La Sante","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Aeroport Tunis Carthage","CodePostal":"2035"},
    {"Ville":"Ariana Ville","Cite":"Cite Jamil","CodePostal":"2091"},
    {"Ville":"Ettadhamen","Cite":"Cite Ettayarene","CodePostal":"2041"},
    {"Ville":"La Soukra","Cite":"Cite El Behi Ladghem","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Charguia 1","CodePostal":"2035"},
    {"Ville":"Ariana Ville","Cite":"El Menzah 5","CodePostal":"2091"},
    {"Ville":"Ettadhamen","Cite":"Cite Sfar","CodePostal":"2041"},
    {"Ville":"Kalaat Landlous","Cite":"Cite Bir El Araies","CodePostal":"2022"},
    {"Ville":"La Soukra","Cite":"Cite El Feth","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Charguia 2","CodePostal":"2035"},
    {"Ville":"Ariana Ville","Cite":"El Menzah 6","CodePostal":"2091"},
    {"Ville":"Ettadhamen","Cite":"Cite Snit 2","CodePostal":"2041"},
    {"Ville":"Raoued","Cite":"Cite 7 Novembre","CodePostal":"2056"},
    {"Ville":"Kalaat Landlous","Cite":"Cite Des Martyrs","CodePostal":"2022"},
    {"Ville":"La Soukra","Cite":"Cite El Hana","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Station Marchandises Fret","CodePostal":"2035"},
    {"Ville":"Ettadhamen","Cite":"Cite Snit Nagra","CodePostal":"2041"},
    {"Ville":"Raoued","Cite":"Cite Aeroport","CodePostal":"2056"},
    {"Ville":"Kalaat Landlous","Cite":"Cite El Fejja","CodePostal":"2022"},
    {"Ville":"La Soukra","Cite":"Cite El Mansoura","CodePostal":"2073"},
    {"Ville":"Raoued","Cite":"Cite Chargui","CodePostal":"2056"},
    {"Ville":"Kalaat Landlous","Cite":"Cite El Mourouj","CodePostal":"2022"},
    {"Ville":"La Soukra","Cite":"Cite El Ouroud","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Chotrana 1","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite Des Juges","CodePostal":"2056"},
    {"Ville":"Kalaat Landlous","Cite":"Cite El Oulja","CodePostal":"2022"},
    {"Ville":"La Soukra","Cite":"Cite Ennacim","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Chotrana 2","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite El Yamama","CodePostal":"2056"},
    {"Ville":"Kalaat Landlous","Cite":"Cite Essibous","CodePostal":"2022"},
    {"Ville":"La Soukra","Cite":"Cite Ennouzha 3","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Chotrana 3","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite Sidi Salah","CodePostal":"2056"},
    {"Ville":"Kalaat Landlous","Cite":"Cite Tarek Ibn Zied","CodePostal":"2022"},
    {"Ville":"La Soukra","Cite":"Cite Ettaamir 5","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Cite Astree","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"Cite Zghab","CodePostal":"2056"},
    {"Ville":"Kalaat Landlous","Cite":"Kalaat Landlous","CodePostal":"2022"},
    {"Ville":"La Soukra","Cite":"Cite Ghouzaila","CodePostal":"2073"},
    {"Ville":"La Soukra","Cite":"Cite Belabla","CodePostal":"2036"},
    {"Ville":"Raoued","Cite":"El Brarja","CodePostal":"2056"},
    {"Ville":"La Soukra","Cite":"Cite Hedi Nouira","CodePostal":"2073"},
    {"Ville":"Raoued","Cite":"Cite De La Rtt 2","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite Ben Hessine","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite Ali Bourguiba","CodePostal":"2094"},
    {"Ville":"Raoued","Cite":"Raoued","CodePostal":"2056"},
    {"Ville":"La Soukra","Cite":"Cite Jebira","CodePostal":"2073"},
    {"Ville":"Raoued","Cite":"Cite El Abadla","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite Bou Fares","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite De La Republique","CodePostal":"2094"},
    {"Ville":"Sidi Thabet","Cite":"Chorfech","CodePostal":"2057"},
    {"Ville":"La Soukra","Cite":"Cite Snit","CodePostal":"2073"},
    {"Ville":"Raoued","Cite":"Cite El Amel","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite Chouachia","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite El Bassatine1","CodePostal":"2094"},
    {"Ville":"Sidi Thabet","Cite":"Chorfech 2","CodePostal":"2057"},
    {"Ville":"La Soukra","Cite":"Cite Star","CodePostal":"2073"},
    {"Ville":"Raoued","Cite":"Cite El Ghazala 1","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite Chouchene","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite El Bassatine2","CodePostal":"2094"},
    {"Ville":"Sidi Thabet","Cite":"Chorfech 24","CodePostal":"2057"},
    {"Ville":"La Soukra","Cite":"Residence El Ouns","CodePostal":"2073"},
    {"Ville":"Raoued","Cite":"Cite El Ghazala 2","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite De L'Aeroport","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite El Bassatine3","CodePostal":"2094"},
    {"Ville":"Sidi Thabet","Cite":"Chorfech 8","CodePostal":"2057"},
    {"Ville":"La Soukra","Cite":"Residence Ennesrine","CodePostal":"2073"},
    {"Ville":"Raoued","Cite":"Cite El Wafa","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite De La Terre Libre","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite El Gouabsia","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Cite Borj Turki 1","CodePostal":"2058"},
    {"Ville":"Raoued","Cite":"Cite Ennasr","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite Des Juges","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite El Ouard","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Cite Borj Turki 2","CodePostal":"2058"},
    {"Ville":"Ariana Ville","Cite":"Borj El Baccouche","CodePostal":"2027"},
    {"Ville":"Raoued","Cite":"Cite Ennkhilet","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite El Azzef","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite Ennasr","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Cite De La Sante","CodePostal":"2058"},
    {"Ville":"Raoued","Cite":"Cite Essahafa","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite El Boustene 1","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite Essaada","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Cite Essaada (Riadh Andalous)","CodePostal":"2058"},
    {"Ville":"Raoued","Cite":"Cite Ibn Rachik","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite El Boustene 2","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite Rafaha","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Cite Karim","CodePostal":"2058"},
    {"Ville":"Raoued","Cite":"Cite Mehrzia 2","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite El Henaya","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Cite Rous El Harayek","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Cite Mehrzia 1","CodePostal":"2058"},
    {"Ville":"Raoued","Cite":"Jaafar 1","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite El Maid","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Essanhaji","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Residence El Ouns","CodePostal":"2058"},
    {"Ville":"Raoued","Cite":"Jaafar 2","CodePostal":"2083"},
    {"Ville":"La Soukra","Cite":"Cite Elyes","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Mnihla","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Residence Ibn Zeidoun","CodePostal":"2058"},
    {"Ville":"La Soukra","Cite":"Cite Ettaamir","CodePostal":"2036"},
    {"Ville":"Mnihla","Cite":"Residence Ennasr","CodePostal":"2094"},
    {"Ville":"Ariana Ville","Cite":"Residence Ichbilia","CodePostal":"2058"},
    {"Ville":"Sidi Thabet","Cite":"Borj El Khoukha","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Cite Ezzitoun 1","CodePostal":"2036"},
    {"Ville":"Ariana Ville","Cite":"Residence Kortoba","CodePostal":"2058"},
    {"Ville":"Sidi Thabet","Cite":"Borj Youssef","CodePostal":"2032"},
    {"Ville":"La Soukra","Cite":"Cite Ezzitoun 2","CodePostal":"2036"},
    {"Ville":"Ariana Ville","Cite":"Riadh Landlous","CodePostal":"2058"},
    {"Ville":"Teboursouk","Cite":"Ain El Melliti","CodePostal":"9040"},
    {"Ville":"Mejez El Bab","Cite":"Argoub Ezzaatar","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Cite Taieb Mhiri","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Erraoudha","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Ain Jemmala","CodePostal":"9040"},
    {"Ville":"Mejez El Bab","Cite":"Cite Baccar","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Cite Usine Du Sucre1","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Essayadine","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Bir Ettouta","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Ain Ksar Hadid","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite De La Sante","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Cite Usine Du Sucre2","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Fedden Jemaa","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Bouzaida","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Beja","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Des Proffesseurs","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"El Hamrounia","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Jabbess","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite Ain El Karma","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Bou Keslane","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Dhamene","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"El Mounchar","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Kichtilou","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite Ain Mrad","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Charchara","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Du Gouvernorat","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"El Yousri","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Ouled Slimane","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite De La Republique","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Afh","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Azima 1","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Fatnassa","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Sidi Khouili","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite Des Martyrs","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Ain Bazaz","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Azima 2","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Hajar Amor","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Tafercha","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite Du Stade","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Ain Chemes","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Azima 3","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Kasseb","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Tijani","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite El Bassatine","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Ain El Ghoula","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Bahrine","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Lafarke","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"El Mellah","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite El Glai","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Beja El Jadida","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Behi","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Nagachia","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Esslouguia","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite El Gourjani","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Belle Vue","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Fateh","CodePostal":"9070"},
    {"Ville":"Beja Nord","Cite":"Tarhouni","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Jebel Fellous","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite El Hadj Abdallah","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Bir Hofra","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Ferdaous 1","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Beni Said","CodePostal":"9010"},
    {"Ville":"Testour","Cite":"Ouljet Doukhane","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Cite El Ksar","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Boutraa","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Hana","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite De La Liberte","CodePostal":"9010"},
    {"Ville":"Mejez El Bab","Cite":"El Ksar","CodePostal":"9015"},
    {"Ville":"Teboursouk","Cite":"Cite Ennacim","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Cnrps","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Manar","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite De La Poste","CodePostal":"9010"},
    {"Ville":"Beja Sud","Cite":"Mastouta","CodePostal":"9021"},
    {"Ville":"Teboursouk","Cite":"Cite Ennacim 1","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite De La Police","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite El Menchari","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Des Carrieres","CodePostal":"9010"},
    {"Ville":"Beja Sud","Cite":"Mastouta Gare","CodePostal":"9021"},
    {"Ville":"Teboursouk","Cite":"Cite Ennacim 2","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite De La Sante 1","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Erriadh","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Des Roses","CodePostal":"9010"},
    {"Ville":"Beja Sud","Cite":"Sidi Smail","CodePostal":"9021"},
    {"Ville":"Teboursouk","Cite":"Cite Ezzayatine 1","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite De La Sante 2","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Essedk","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite El Mhiri","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Cite 2 Mars","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Cite Ezzayatine 2","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Des Mimosas","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Ettadhamen","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Erriadh","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Cite Commerciale","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Cite Ibn Khaldoun","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Des Proffesseurs","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Ezzouhour","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Essaada","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Cite Des Ouvriers","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Cite Ibn Sina","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Des Roses","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Jallazia","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Ezzouhour","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Cite El Olfa","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Cite Industrielle","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Dhamene","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite Majerda","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Independance","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Cite Ennour","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Cite Jardins","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Du Gouvernorat","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Gueriche El Oued","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Mixte","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Cite Essalama","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Cite Meska","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Echifa","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Hidous","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Mohsen Limam","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Cite Hached","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Cite Oued Essah","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Economie Du Nord","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Iden","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Rim","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Cite Ibn Khaldoun","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Cite Sidi Bagga","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Edhahbia 1","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Mejez El Bab","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Cite Sidi Saad","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Ennechima","CodePostal":"9022"},
    {"Ville":"Teboursouk","Cite":"Dougga Rouines","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite Edhahbia 2","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Sidi Ahmed Jedidi","CodePostal":"9070"},
    {"Ville":"Nefza","Cite":"Dar El Hit","CodePostal":"9010"},
    {"Ville":"Thibar","Cite":"Thibar","CodePostal":"9022"},
    {"Ville":"Beja Sud","Cite":"Cite El Amel","CodePostal":"9023"},
    {"Ville":"Teboursouk","Cite":"El Ayedh","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite El Baraka","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Cite 02 Mars","CodePostal":"9071"},
    {"Ville":"Nefza","Cite":"El Amamria","CodePostal":"9010"},
    {"Ville":"Beja Sud","Cite":"El Maagoula","CodePostal":"9023"},
    {"Ville":"Teboursouk","Cite":"El Faouar","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite El Biadha","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"El Griaat","CodePostal":"9071"},
    {"Ville":"Nefza","Cite":"El Ayayada Jamila","CodePostal":"9010"},
    {"Ville":"Mejez El Bab","Cite":"Toukabeur","CodePostal":"9024"},
    {"Ville":"Teboursouk","Cite":"El Matria","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite El Boukhari","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"Chaouech","CodePostal":"9072"},
    {"Ville":"Nefza","Cite":"El Mazdour","CodePostal":"9010"},
    {"Ville":"Beja Sud","Cite":"Sidi Frej","CodePostal":"9029"},
    {"Ville":"Teboursouk","Cite":"Naffet","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite El Boustene","CodePostal":"9000"},
    {"Ville":"Mejez El Bab","Cite":"El Herri","CodePostal":"9075"},
    {"Ville":"Nefza","Cite":"Fatnassa","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Adailia","CodePostal":"9030"},
    {"Ville":"Teboursouk","Cite":"Ouled Marai","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite El Habib","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Bir El Euch","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Jbel Diss","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Ain El Goussa","CodePostal":"9030"},
    {"Ville":"Teboursouk","Cite":"Rihana","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite El Habibia","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Briouig","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Jemil","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Ain Ghenem","CodePostal":"9030"},
    {"Ville":"Teboursouk","Cite":"Teboursouk","CodePostal":"9040"},
    {"Ville":"Beja Nord","Cite":"Cite El Haouari","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cheik El Ouediane El Guebli","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Nefza","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Beni Melek","CodePostal":"9030"},
    {"Ville":"Thibar","Cite":"Ain Eddefali","CodePostal":"9042"},
    {"Ville":"Beja Nord","Cite":"Cite El Khadhra","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cite Des Fonctionnaires","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Oued Bouzenna","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Bouris","CodePostal":"9030"},
    {"Ville":"Thibar","Cite":"Djebba","CodePostal":"9042"},
    {"Ville":"Beja Nord","Cite":"Cite El Khadhra Superieur","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cite El Hana","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Oued Damous","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Cite De La Poste","CodePostal":"9030"},
    {"Ville":"Thibar","Cite":"El Henancha","CodePostal":"9042"},
    {"Ville":"Beja Nord","Cite":"Cite El Manar 1","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cite El Izdihar","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Ouled Houimel","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Cite Erraoudha","CodePostal":"9030"},
    {"Ville":"Mejez El Bab","Cite":"Sidi Nasser","CodePostal":"9044"},
    {"Ville":"Beja Nord","Cite":"Cite El Manar 2","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cite El Kamh","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Ouled Kacem","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Cite Hached","CodePostal":"9030"},
    {"Ville":"Teboursouk","Cite":"Fadden Essouk","CodePostal":"9046"},
    {"Ville":"Beja Nord","Cite":"Cite El Manar 3","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cite Essaada","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Oum Laabid","CodePostal":"9010"},
    {"Ville":"Amdoun","Cite":"Cite Taieb Mhiri","CodePostal":"9030"},
    {"Ville":"Beja Sud","Cite":"Hammam Sayala","CodePostal":"9052"},
    {"Ville":"Beja Nord","Cite":"Cite El Manar 4","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cite Essanabel","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Tababa","CodePostal":"9011"},
    {"Ville":"Amdoun","Cite":"Eddghabjia","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Ain Younes","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite El Manar 5","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cite Ezzouhour","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Ain Zakkar","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"Edhhirat","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite 20 Mars","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite El Mekmda","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Cite Populaire","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Bou Garnouna","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"El Fraijia","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite 26 Fevrier","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite El Mhella","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Dour Ismail","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"El Hemaidia","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"El Ghorfa","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite Des Andalous","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite El Moustakbel","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"El Fedhailia","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"El Mecherga","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"El Graia","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite El Bassatine","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite El Mzara","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"El Gammarti","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"El Oulija","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"El Hlikat","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite El Folla","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite El Olfa","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"El Ogla","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Ettaref","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"El Jouza","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite El Izdihar","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Ennouzha","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Ettraifa","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Ezzouaraa","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"El Mjales","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite El Malouf","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Erriadh 2","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Goubellat","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Ouechtata","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"Gaat El Mouhdi","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite Ennacim","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Erriadhi 1","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Khacheb","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Ouled Salem","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"Ghouzia","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite Gharnata 1","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Essalama","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Khenguet Eddihenne","CodePostal":"9080"},
    {"Ville":"Nefza","Cite":"Zaga","CodePostal":"9012"},
    {"Ville":"Amdoun","Cite":"Maghraoua","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite Gharnata 2","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Essalem","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Tella","CodePostal":"9080"},
    {"Ville":"Mejez El Bab","Cite":"Cite De La Poste","CodePostal":"9013"},
    {"Ville":"Amdoun","Cite":"Menzel Hammed","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite Ibn Zeidoun","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Essanaouber","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Zone Industrielle","CodePostal":"9080"},
    {"Ville":"Mejez El Bab","Cite":"Cite Education","CodePostal":"9013"},
    {"Ville":"Amdoun","Cite":"Remadhnia","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Cite Spiniair","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Ezzouhour","CodePostal":"9000"},
    {"Ville":"Goubellat","Cite":"Grame","CodePostal":"9083"},
    {"Ville":"Mejez El Bab","Cite":"Cite Enfance","CodePostal":"9013"},
    {"Ville":"Amdoun","Cite":"Sidi Mbarek","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"El Glaa","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Garde Nationale","CodePostal":"9000"},
    {"Ville":"Amdoun","Cite":"El Ayayda","CodePostal":"9084"},
    {"Ville":"Mejez El Bab","Cite":"Louatia","CodePostal":"9013"},
    {"Ville":"Amdoun","Cite":"Sidi Saadoun","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Jlass Bou Issa","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Ibn Khaldoun","CodePostal":"9000"},
    {"Ville":"Amdoun","Cite":"El Homr","CodePostal":"9084"},
    {"Ville":"Mejez El Bab","Cite":"Oued Ezzitoun","CodePostal":"9013"},
    {"Ville":"Amdoun","Cite":"Sobah","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Mezougha","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Jardins","CodePostal":"9000"},
    {"Ville":"Amdoun","Cite":"Menzel El Gourchi","CodePostal":"9084"},
    {"Ville":"Mejez El Bab","Cite":"Oued Zarga","CodePostal":"9013"},
    {"Ville":"Amdoun","Cite":"Zahret Mediene","CodePostal":"9030"},
    {"Ville":"Testour","Cite":"Oued Jedra","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Jebel El Akhdhar","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Bab Bled","CodePostal":"9014"},
    {"Ville":"Beja Nord","Cite":"El Mzara","CodePostal":"9031"},
    {"Ville":"Testour","Cite":"Ouled Slama","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Ksar Bardo","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Bahrnia","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Borj Brahim","CodePostal":"9032"},
    {"Ville":"Testour","Cite":"Rhiba","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Pont Trageon","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Beharnia","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Douga","CodePostal":"9032"},
    {"Ville":"Testour","Cite":"Testour","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite S.R.Transport","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Ben Karouia","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Khalled","CodePostal":"9032"},
    {"Ville":"Testour","Cite":"Zeldou","CodePostal":"9060"},
    {"Ville":"Beja Nord","Cite":"Cite Salah El Maghraoui","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Ben Othman","CodePostal":"9014"},
    {"Ville":"Mejez El Bab","Cite":"Sidi Mediene","CodePostal":"9034"},
    {"Ville":"Testour","Cite":"Esskhira","CodePostal":"9061"},
    {"Ville":"Beja Nord","Cite":"Cite Salambo","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite Dar Echabeb","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Ain El Hammam","CodePostal":"9040"},
    {"Ville":"Testour","Cite":"Henchir Bou Sofra","CodePostal":"9061"},
    {"Ville":"Beja Nord","Cite":"Cite Sidi Frej","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite El Gadhoum","CodePostal":"9014"},
    {"Ville":"Teboursouk","Cite":"Ain El Kehoul","CodePostal":"9040"},
    {"Ville":"Testour","Cite":"Ain Tounga","CodePostal":"9062"},
    {"Ville":"Beja Nord","Cite":"Cite Snit","CodePostal":"9000"},
    {"Ville":"Testour","Cite":"Cite El Mendra","CodePostal":"9014"},
    {"Ville":"El Mourouj","Cite":"Cite El Foll","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Ben Gamra","CodePostal":"2097"},
    {"Ville":"Fouchana","Cite":"Naassen","CodePostal":"1135"},
    {"Ville":"Hammam Lif","Cite":"Cite Ennasr","CodePostal":"2050"},
    {"Ville":"El Mourouj","Cite":"Bir El Kassaa","CodePostal":"2059"},
    {"Ville":"El Mourouj","Cite":"Cite El Mourouj 3 Bis","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Ben Joud","CodePostal":"2097"},
    {"Ville":"Hammam Lif","Cite":"Cite Ennouzha","CodePostal":"2050"},
    {"Ville":"El Mourouj","Cite":"Cite El Wifak","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Bou Mhel","CodePostal":"2097"},
    {"Ville":"Hammam Lif","Cite":"Cite Essanaoubria","CodePostal":"2050"},
    {"Ville":"Mohamadia","Cite":"Cite Bou Akroucha","CodePostal":"1145"},
    {"Ville":"El Mourouj","Cite":"Cite Ennakhil","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Boutouria","CodePostal":"2097"},
    {"Ville":"Hammam Lif","Cite":"Cite Hached","CodePostal":"2050"},
    {"Ville":"Mohamadia","Cite":"Cite Bourbai","CodePostal":"1145"},
    {"Ville":"El Mourouj","Cite":"Cite Ennarjes 1","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Chebil","CodePostal":"2097"},
    {"Ville":"Hammam Lif","Cite":"Cite Kortoba","CodePostal":"2050"},
    {"Ville":"Mohamadia","Cite":"Cite Cebbalet Chikh","CodePostal":"1145"},
    {"Ville":"El Mourouj","Cite":"Cite Ennarjes 2","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite De L'Interieur","CodePostal":"2097"},
    {"Ville":"Hammam Lif","Cite":"Cite Mongi Slim","CodePostal":"2050"},
    {"Ville":"Mohamadia","Cite":"Cite Chaabane","CodePostal":"1145"},
    {"Ville":"Rades","Cite":"Cite Snit","CodePostal":"2018"},
    {"Ville":"El Mourouj","Cite":"Cite Ennasr 1","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Des Juges","CodePostal":"2097"},
    {"Ville":"Hammam Lif","Cite":"Cite Office Cereale","CodePostal":"2050"},
    {"Ville":"Mohamadia","Cite":"Cite Chebbi","CodePostal":"1145"},
    {"Ville":"Rades","Cite":"Decouppage Haj Aleya","CodePostal":"2018"},
    {"Ville":"El Mourouj","Cite":"Cite Ennasr 2","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Diar Tounes","CodePostal":"2097"},
    {"Ville":"Hammam Lif","Cite":"Cite Thermale","CodePostal":"2050"},
    {"Ville":"Mohamadia","Cite":"Cite De La Carriere 1","CodePostal":"1145"},
    {"Ville":"Rades","Cite":"Rades 7 Novembre","CodePostal":"2018"},
    {"Ville":"El Mourouj","Cite":"Cite Errachid 1","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Ahmadi","CodePostal":"2097"},
    {"Ville":"Hammam Lif","Cite":"Hammam Lif","CodePostal":"2050"},
    {"Ville":"Mohamadia","Cite":"Cite De La Carriere 2","CodePostal":"1145"},
    {"Ville":"Rades","Cite":"Village Mediterranee","CodePostal":"2018"},
    {"Ville":"El Mourouj","Cite":"Cite Errachid 2","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Ahmed Zaied","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Amen","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Du Lycee","CodePostal":"1145"},
    {"Ville":"Rades","Cite":"Ville Sportive","CodePostal":"2018"},
    {"Ville":"El Mourouj","Cite":"Cite Erraoudha","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Ben Nouiji","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Assil","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Amen","CodePostal":"1145"},
    {"Ville":"El Mourouj","Cite":"Cite Erriadh","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Borj Essoufi","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Bassatine Ancien","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Atiq","CodePostal":"1145"},
    {"Ville":"El Mourouj","Cite":"Cite Erriadh 2","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Bou Jerdga","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Ferchichi","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Baccouch","CodePostal":"1145"},
    {"Ville":"Megrine","Cite":"Megrine","CodePostal":"2033"},
    {"Ville":"El Mourouj","Cite":"Cite Essalem","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Chala","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Iklil","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Bassatine","CodePostal":"1145"},
    {"Ville":"Megrine","Cite":"Megrine Coteau","CodePostal":"2033"},
    {"Ville":"El Mourouj","Cite":"Cite Ibn Jazzar 2","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Chamine","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Kenani","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Elmi 1","CodePostal":"1145"},
    {"Ville":"Megrine","Cite":"Megrine Superieur","CodePostal":"2033"},
    {"Ville":"El Mourouj","Cite":"Cite Ibn Mandhour","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Cite Ennasr","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Khalij","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Elmi 2","CodePostal":"1145"},
    {"Ville":"Hammam Chatt","Cite":"Hammam Chatt","CodePostal":"1164"},
    {"Ville":"Megrine","Cite":"Sidi Rezig","CodePostal":"2033"},
    {"Ville":"El Mourouj","Cite":"Cite Jardins","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Douar La Porte","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Khalledi","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Haddad","CodePostal":"1145"},
    {"Ville":"Nouvelle Medina","Cite":"Nouvelle Medina","CodePostal":"2063"},
    {"Ville":"Ezzahra","Cite":"Cite 18 Janvier","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Cite Moncef Bey","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Douar Salem","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Mahrajene 1","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Hana","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"Cite Air Nouvelle","CodePostal":"2064"},
    {"Ville":"Ezzahra","Cite":"Cite Borj Louzir","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Cite Taher Haddad","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"El Alelgua","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Mahrajene 2","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Ksar","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"Cite Ettayarane","CodePostal":"2064"},
    {"Ville":"Ezzahra","Cite":"Cite Du Port","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Cite Yargoula","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"El Araba","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Mouna","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Omri 1","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"El Abebsa","CodePostal":"2064"},
    {"Ville":"Ezzahra","Cite":"Cite Du Wagon","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"El Alia 1","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"El Haouamed","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite El Wafa","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite El Omri 2","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"El Fendria","CodePostal":"2064"},
    {"Ville":"Ezzahra","Cite":"Cite El Ahlem","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"El Alia 2","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"El Ksibi","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Cite 20 Mars","CodePostal":"1125"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Ennacim 1","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Ellouata","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"El Kabouti","CodePostal":"2064"},
    {"Ville":"Fouchana","Cite":"Cite 20 Mars","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite El Akacia","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"El Alia 3","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Ettiayra","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Cite Ennour","CodePostal":"1125"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Ennacim 2","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Ennacim 1","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"Jebel Ersass","CodePostal":"2064"},
    {"Ville":"Fouchana","Cite":"Cite Brim","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite El Amen","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"El Mourouj","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Ferme Marquee","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Rades Saline","CodePostal":"1125"},
    {"Ville":"Rades","Cite":"Chouchet Rades","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Essafa","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Ennacim 2","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"Jourjou","CodePostal":"2064"},
    {"Ville":"Fouchana","Cite":"Cite Chebbi","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite El Ezz","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"La Belle Residence","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Henchir Bouchta","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Zone Industrielle 1","CodePostal":"1125"},
    {"Ville":"Rades","Cite":"Cite Mohamed Ali","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Essalem","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Ennasr","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"Mirghna","CodePostal":"2064"},
    {"Ville":"Fouchana","Cite":"Cite El Amel","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite El Oulija","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence Chebbi","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Henchir El Ghobar","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Zone Industrielle 2","CodePostal":"1125"},
    {"Ville":"Rades","Cite":"Cite Rades 2","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Hadrich","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Ennouzha","CodePostal":"1145"},
    {"Ville":"Mornag","Cite":"Sidi Salem El Garci","CodePostal":"2064"},
    {"Ville":"Fouchana","Cite":"Cite El Amel 2","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite El Ouns","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence Chourouk 1","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Henchir Khelil","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Zone Petroliere","CodePostal":"1125"},
    {"Ville":"Rades","Cite":"Cite Sfaxi","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Jlassi","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Erriadh","CodePostal":"1145"},
    {"Ville":"Ezzahra","Cite":"Cite Ben Miled","CodePostal":"2065"},
    {"Ville":"Fouchana","Cite":"Cite El Banefsej","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Ennakhil 1","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence Chourouk 2","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Huilerie Bayrem","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Cite Sncft","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Mezriou","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Essaada 1","CodePostal":"1145"},
    {"Ville":"Ezzahra","Cite":"Cite El Habib","CodePostal":"2065"},
    {"Ville":"Fouchana","Cite":"Cite El Fateh","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Ennakhil 2","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence Chourouk 3","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Mornag","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Cite Taieb Mhiri 1","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Plein Air","CodePostal":"2097"},
    {"Ville":"Mohamadia","Cite":"Cite Essaada 2","CodePostal":"1145"},
    {"Ville":"Ezzahra","Cite":"Cite El Houl","CodePostal":"2065"},
    {"Ville":"Fouchana","Cite":"Cite El Hana","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Errachid","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence De L'Horizon","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Ouzra","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Cite Taieb Mhiri 2","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Rabiaa","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"Ain Regad","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Essaada 3","CodePostal":"1145"},
    {"Ville":"Ezzahra","Cite":"Cite El Ward","CodePostal":"2065"},
    {"Ville":"Fouchana","Cite":"Cite El Hidhab","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Erriadh","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence De La Verdure","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Sidi Saad","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Cite Taieb Mhiri 3","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Snit","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"Barrouta","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Essalem","CodePostal":"1145"},
    {"Ville":"Ezzahra","Cite":"Cite El Yasmine","CodePostal":"2065"},
    {"Ville":"Fouchana","Cite":"Cite El Izdihar","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Hedi Khfacha","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence Du Printemps","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Tarhouna 1","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Rades","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Cite Sprols","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"Bir Jedid","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Ettayari","CodePostal":"1145"},
    {"Ville":"Ezzahra","Cite":"Cite Ennasr","CodePostal":"2065"},
    {"Ville":"Fouchana","Cite":"Cite El Misk","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Imen","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence El Foll","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Tarhouna 2","CodePostal":"2090"},
    {"Ville":"Rades","Cite":"Rades Plage","CodePostal":"2040"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Residence Du Mediterrane","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"El Gounna","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Ettouahria","CodePostal":"1145"},
    {"Ville":"Ezzahra","Cite":"Ezzahra El Habib","CodePostal":"2065"},
    {"Ville":"Fouchana","Cite":"Cite El Moustakbel","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Industrielle","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence El Khalil","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Zaouit Mornag","CodePostal":"2090"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Residence El Imara","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"Henchir Kharrouba","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Ezzitoun 2","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite El Ward","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Mrad 1","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence El Mourjene","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Residence Ennacim","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"Khelidia","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Fattouma Bourguiba","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite El Wifak","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Mrad 2","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence El Ouard","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Residence Ezzouhour","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"Kouenjel","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Hached 1","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite Ennasr","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Cite Panorama","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence El Wafa","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Residence Sarra","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"Sidi Messaoud","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Hached 2","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite Ennouzha","CodePostal":"2082"},
    {"Ville":"Ezzahra","Cite":"Ezzahra","CodePostal":"2034"},
    {"Ville":"El Mourouj","Cite":"Residence Ennacim","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Residence Sirine","CodePostal":"2097"},
    {"Ville":"Mornag","Cite":"Sidi Othman Landari","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Jaouhara","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite Erriadh","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Ennaoures","CodePostal":"2074"},
    {"Ville":"Rades","Cite":"Cite El Ezz","CodePostal":"2098"},
    {"Ville":"Mornag","Cite":"Sondage","CodePostal":"2054"},
    {"Ville":"Mohamadia","Cite":"Cite Jardins","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite Ezzitoun 1","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Ennesrine","CodePostal":"2074"},
    {"Ville":"Rades","Cite":"Cite Nouvelle","CodePostal":"2098"},
    {"Ville":"Hammam Chatt","Cite":"Bir El Bey","CodePostal":"2055"},
    {"Ville":"Mohamadia","Cite":"Cite Mongi Slim 1","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite Khouaja","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Erraboua","CodePostal":"2074"},
    {"Ville":"Rades","Cite":"Rades El Mrah","CodePostal":"2098"},
    {"Ville":"Mohamadia","Cite":"Cite Mongi Slim 2","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite Snit","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Essaad","CodePostal":"2074"},
    {"Ville":"Rades","Cite":"Rades Foret","CodePostal":"2098"},
    {"Ville":"Mohamadia","Cite":"Cite Oudhna","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Cite Trabelsi","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Essaada","CodePostal":"2074"},
    {"Ville":"Rades","Cite":"Rades Medina","CodePostal":"2098"},
    {"Ville":"Mohamadia","Cite":"Cite Taieb Mhiri","CodePostal":"1145"},
    {"Ville":"Mohamadia","Cite":"Cite Tnich","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"El Menaychia","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Essraya","CodePostal":"2074"},
    {"Ville":"Rades","Cite":"Rades Meliane","CodePostal":"2098"},
    {"Ville":"Mohamadia","Cite":"Immeubles De L'Interieur","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Fouchana","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Ibn Khaldoun","CodePostal":"2074"},
    {"Ville":"Rades","Cite":"Rades Mongil","CodePostal":"2098"},
    {"Ville":"Mohamadia","Cite":"Mohamadia","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Meghira Centre","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Ibn Zeidoun","CodePostal":"2074"},
    {"Ville":"Hammam Lif","Cite":"Cite Boussouffara","CodePostal":"2099"},
    {"Ville":"Mohamadia","Cite":"Residence El Ferdaous","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Meghira Inzel","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Ines","CodePostal":"2074"},
    {"Ville":"Hammam Lif","Cite":"Bou Kornine","CodePostal":"2093"},
    {"Ville":"Mohamadia","Cite":"Residence El Moustakbel","CodePostal":"1145"},
    {"Ville":"Fouchana","Cite":"Zone Industrielle El Meghira","CodePostal":"2082"},
    {"Ville":"El Mourouj","Cite":"Residence Jinene El Mourouj","CodePostal":"2074"},
    {"Ville":"Hammam Lif","Cite":"Cite Bou Kornine 2","CodePostal":"2093"},
    {"Ville":"Mohamadia","Cite":"Residence El Ouns","CodePostal":"1145"},
    {"Ville":"El Mourouj","Cite":"Residence Meriem","CodePostal":"2074"},
    {"Ville":"Mohamadia","Cite":"Residence Ettaoufik","CodePostal":"1145"},
    {"Ville":"El Mourouj","Cite":"Residence Sidi Bou Said","CodePostal":"2074"},
    {"Ville":"Ben Arous","Cite":"Ben Arous Sud","CodePostal":"2043"},
    {"Ville":"El Mourouj","Cite":"Residence Type","CodePostal":"2074"},
    {"Ville":"Mornag","Cite":"Errissala","CodePostal":"2044"},
    {"Ville":"Megrine","Cite":"Megrine Chaker","CodePostal":"2024"},
    {"Ville":"El Mourouj","Cite":"Residence Zahret El Mourouj","CodePostal":"2074"},
    {"Ville":"Megrine","Cite":"Megrine Jaouhara","CodePostal":"2024"},
    {"Ville":"Ben Arous","Cite":"Ben Arous","CodePostal":"2013"},
    {"Ville":"El Mourouj","Cite":"Cite Alyssa 1","CodePostal":"2074"},
    {"Ville":"Megrine","Cite":"Cite Founiz","CodePostal":"2014"},
    {"Ville":"El Mourouj","Cite":"Cite Alyssa 2","CodePostal":"2074"},
    {"Ville":"Megrine","Cite":"Megrine Riadh","CodePostal":"2014"},
    {"Ville":"El Mourouj","Cite":"Cite Azza 1","CodePostal":"2074"},
    {"Ville":"Megrine","Cite":"Saint Gobin","CodePostal":"2014"},
    {"Ville":"El Mourouj","Cite":"Cite Azza 2","CodePostal":"2074"},
    {"Ville":"Hammam Lif","Cite":"Cite 7 Novembre","CodePostal":"2050"},
    {"Ville":"Megrine","Cite":"Zone Industrielle Saint Gobin","CodePostal":"2014"},
    {"Ville":"El Mourouj","Cite":"Cite Commerciale Abou Sofiane","CodePostal":"2074"},
    {"Ville":"Hammam Lif","Cite":"Cite Casino","CodePostal":"2050"},
    {"Ville":"El Mourouj","Cite":"Cite Commerciale Boukhriss","CodePostal":"2074"},
    {"Ville":"Hammam Lif","Cite":"Cite Dar El Bey","CodePostal":"2050"},
    {"Ville":"El Mourouj","Cite":"Cite Des Oasis","CodePostal":"2074"},
    {"Ville":"Hammam Lif","Cite":"Cite De La Municipalite","CodePostal":"2050"},
    {"Ville":"El Mourouj","Cite":"Cite El Bassatine","CodePostal":"2074"},
    {"Ville":"Hammam Chatt","Cite":"Borj Cedria","CodePostal":"2084"},
    {"Ville":"Fouchana","Cite":"Bir Zendala","CodePostal":"1135"},
    {"Ville":"Hammam Lif","Cite":"Cite Des Instituteurs","CodePostal":"2050"},
    {"Ville":"El Mourouj","Cite":"Cite El Bochra","CodePostal":"2074"},
    {"Ville":"Nouvelle Medina","Cite":"El Yasminette","CodePostal":"2096"},
    {"Ville":"Fouchana","Cite":"Chebedda","CodePostal":"1135"},
    {"Ville":"Hammam Lif","Cite":"Cite Des Ouvriers","CodePostal":"2050"},
    {"Ville":"El Mourouj","Cite":"Cite El Faouz","CodePostal":"2074"},
    {"Ville":"Bou Mhel El Bassa","Cite":"Bou Mhel","CodePostal":"2097"},
    {"Ville":"Fouchana","Cite":"Douar El Houch","CodePostal":"1135"},
    {"Ville":"Hammam Lif","Cite":"Cite Ennahdha","CodePostal":"2050"},
    {"Ville":"Bizerte Sud","Cite":"Bizerte Hached","CodePostal":"7071"},
    {"Ville":"Sejnane","Cite":"Essmayria","CodePostal":"7010"},
    {"Ville":"Ghar El Melh","Cite":"Zouaouine","CodePostal":"7024"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Makarem","CodePostal":"7035"},
    {"Ville":"Menzel Bourguiba","Cite":"Menzel Bourguiba Ennajah","CodePostal":"7072"},
    {"Ville":"Sejnane","Cite":"Haddada","CodePostal":"7010"},
    {"Ville":"Ras Jebel","Cite":"Sounine","CodePostal":"7025"},
    {"Ville":"Menzel Jemil","Cite":"Cite Ettahrir","CodePostal":"7035"},
    {"Ville":"Ras Jebel","Cite":"Beni Atta","CodePostal":"7075"},
    {"Ville":"Sejnane","Cite":"Sejnane","CodePostal":"7010"},
    {"Ville":"Menzel Jemil","Cite":"Cite 7 Novembre","CodePostal":"7026"},
    {"Ville":"Menzel Jemil","Cite":"Cite Ettakaddoum","CodePostal":"7035"},
    {"Ville":"Menzel Jemil","Cite":"Cite Bir Remel","CodePostal":"7080"},
    {"Ville":"Sejnane","Cite":"Sidi Machreg","CodePostal":"7010"},
    {"Ville":"Menzel Jemil","Cite":"Cite Des Oranges","CodePostal":"7026"},
    {"Ville":"Menzel Jemil","Cite":"Cite Independance","CodePostal":"7035"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Ahlem","CodePostal":"7080"},
    {"Ville":"Sejnane","Cite":"Touajnia","CodePostal":"7010"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Habib (El Azib)","CodePostal":"7026"},
    {"Ville":"Menzel Jemil","Cite":"El Ain El Kebira","CodePostal":"7035"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Baali","CodePostal":"7080"},
    {"Ville":"Bizerte Sud","Cite":"Cite El Mourouj","CodePostal":"7011"},
    {"Ville":"Menzel Jemil","Cite":"Cite Rurale 1","CodePostal":"7026"},
    {"Ville":"Menzel Jemil","Cite":"Menzel Abderrahman","CodePostal":"7035"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Batan","CodePostal":"7080"},
    {"Ville":"Bizerte Sud","Cite":"Cite Militaire","CodePostal":"7011"},
    {"Ville":"Menzel Jemil","Cite":"Cite Rurale 2","CodePostal":"7026"},
    {"Ville":"Bizerte Sud","Cite":"Teskraya","CodePostal":"7036"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Habib (Menzel Jemil)","CodePostal":"7080"},
    {"Ville":"Bizerte Sud","Cite":"La Pecherie","CodePostal":"7011"},
    {"Ville":"Menzel Jemil","Cite":"El Azib","CodePostal":"7026"},
    {"Ville":"Ghezala","Cite":"Ain Rekoub","CodePostal":"7040"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Haddadia","CodePostal":"7080"},
    {"Ville":"Joumine","Cite":"Bazina","CodePostal":"7012"},
    {"Ville":"Menzel Jemil","Cite":"Henchir El Moukaouimine","CodePostal":"7026"},
    {"Ville":"Ghezala","Cite":"Beni Brahim","CodePostal":"7040"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Hbess","CodePostal":"7080"},
    {"Ville":"Utique","Cite":"Ain Ghelal","CodePostal":"7013"},
    {"Ville":"Menzel Jemil","Cite":"Maghraoua","CodePostal":"7026"},
    {"Ville":"Ghezala","Cite":"Bou Jrir","CodePostal":"7040"},
    {"Ville":"Menzel Jemil","Cite":"Cite Ennouzha","CodePostal":"7080"},
    {"Ville":"Utique","Cite":"Ain Smara","CodePostal":"7013"},
    {"Ville":"Bizerte Sud","Cite":"Methline","CodePostal":"7027"},
    {"Ville":"Ghezala","Cite":"Cite Afh","CodePostal":"7040"},
    {"Ville":"Menzel Jemil","Cite":"Cite Ettaffala","CodePostal":"7080"},
    {"Ville":"Utique","Cite":"El Besbassia","CodePostal":"7013"},
    {"Ville":"Bizerte Nord","Cite":"Ennadhour","CodePostal":"7029"},
    {"Ville":"Ghezala","Cite":"Cite Borj Deheb","CodePostal":"7040"},
    {"Ville":"Menzel Jemil","Cite":"Cite Habib Arifa","CodePostal":"7080"},
    {"Ville":"Utique","Cite":"El Bhalil Chargui","CodePostal":"7013"},
    {"Ville":"Bizerte Nord","Cite":"Fejjet Errih","CodePostal":"7029"},
    {"Ville":"Ghezala","Cite":"Cite Rurale","CodePostal":"7040"},
    {"Ville":"Menzel Jemil","Cite":"Cite Nouvelle","CodePostal":"7080"},
    {"Ville":"Utique","Cite":"El Brij","CodePostal":"7013"},
    {"Ville":"Bizerte Nord","Cite":"Ksar Lahmar","CodePostal":"7029"},
    {"Ville":"Ghezala","Cite":"Cite Snit","CodePostal":"7040"},
    {"Ville":"Menzel Jemil","Cite":"Menzel Jemil","CodePostal":"7080"},
    {"Ville":"Utique","Cite":"El Fejja","CodePostal":"7013"},
    {"Ville":"Bizerte Nord","Cite":"Marnissa","CodePostal":"7029"},
    {"Ville":"Ghezala","Cite":"El Atiba","CodePostal":"7040"},
    {"Ville":"El Alia","Cite":"Ezzitoune","CodePostal":"7081"},
    {"Ville":"Ghar El Melh","Cite":"Cite 7 Novembre","CodePostal":"7014"},
    {"Ville":"Bizerte Nord","Cite":"Sidi Ahmed","CodePostal":"7029"},
    {"Ville":"Ghezala","Cite":"El Garia","CodePostal":"7040"},
    {"Ville":"El Alia","Cite":"Khetmine","CodePostal":"7081"},
    {"Ville":"Ghar El Melh","Cite":"Cite 9 Avril","CodePostal":"7014"},
    {"Ville":"Bizerte Sud","Cite":"Ain El Berda","CodePostal":"7029"},
    {"Ville":"Ghezala","Cite":"El Jafna","CodePostal":"7040"},
    {"Ville":"El Alia","Cite":"Sidi Ali Chebab","CodePostal":"7093"},
    {"Ville":"Ghar El Melh","Cite":"Cite El Amen","CodePostal":"7014"},
    {"Ville":"Bizerte Sud","Cite":"Ain Jenane","CodePostal":"7029"},
    {"Ville":"Ghezala","Cite":"Ghezala","CodePostal":"7040"},
    {"Ville":"Bizerte Sud","Cite":"Borj Challouf","CodePostal":"7094"},
    {"Ville":"Ghar El Melh","Cite":"Cite El Mourouj","CodePostal":"7014"},
    {"Ville":"Bizerte Sud","Cite":"El Mrazig","CodePostal":"7029"},
    {"Ville":"Ghezala","Cite":"Henchir Ettouila","CodePostal":"7040"},
    {"Ville":"Sejnane","Cite":"El Guetma","CodePostal":"7097"},
    {"Ville":"Ghar El Melh","Cite":"Cite Khaled Ibn El Walid","CodePostal":"7014"},
    {"Ville":"Mateur","Cite":"Cite De La Fraternite","CodePostal":"7030"},
    {"Ville":"Ghezala","Cite":"Laouilia","CodePostal":"7040"},
    {"Ville":"Ras Jebel","Cite":"El Garia Ras Jebal","CodePostal":"7098"},
    {"Ville":"Ghar El Melh","Cite":"Cite Nouvelle","CodePostal":"7014"},
    {"Ville":"Mateur","Cite":"Cite De La Gare","CodePostal":"7030"},
    {"Ville":"Ghezala","Cite":"Oued Ezzitoun","CodePostal":"7040"},
    {"Ville":"Ghar El Melh","Cite":"El Aousja","CodePostal":"7014"},
    {"Ville":"Mateur","Cite":"Cite El Manar","CodePostal":"7030"},
    {"Ville":"Ghezala","Cite":"Ouled El May","CodePostal":"7040"},
    {"Ville":"Ras Jebel","Cite":"Cite El Amel","CodePostal":"7015"},
    {"Ville":"Mateur","Cite":"Cite El Omrane 1","CodePostal":"7030"},
    {"Ville":"Bizerte Sud","Cite":"Louata","CodePostal":"7041"},
    {"Ville":"Ras Jebel","Cite":"Cite Taieb Mhiri","CodePostal":"7015"},
    {"Ville":"Mateur","Cite":"Cite El Omrane 2","CodePostal":"7030"},
    {"Ville":"Menzel Bourguiba","Cite":"Jouaouda","CodePostal":"7042"},
    {"Ville":"Ras Jebel","Cite":"Rafraf","CodePostal":"7015"},
    {"Ville":"Mateur","Cite":"Cite El Omrane 3","CodePostal":"7030"},
    {"Ville":"Utique","Cite":"Bach Hamba","CodePostal":"7043"},
    {"Ville":"El Alia","Cite":"Cite Independance","CodePostal":"7016"},
    {"Ville":"Mateur","Cite":"Cite El Omrane 4","CodePostal":"7030"},
    {"Ville":"Ras Jebel","Cite":"Rafraf Plage","CodePostal":"7045"},
    {"Ville":"Bizerte Nord","Cite":"Bizerte","CodePostal":"7000"},
    {"Ville":"El Alia","Cite":"El Alia","CodePostal":"7016"},
    {"Ville":"Mateur","Cite":"Cite Ennahdha","CodePostal":"7030"},
    {"Ville":"Mateur","Cite":"Henchir El Berna","CodePostal":"7050"},
    {"Ville":"Bizerte Nord","Cite":"Cite Ain Mariem","CodePostal":"7000"},
    {"Ville":"El Alia","Cite":"El Hariza","CodePostal":"7016"},
    {"Ville":"Mateur","Cite":"Cite Ennasr","CodePostal":"7030"},
    {"Ville":"Menzel Bourguiba","Cite":"Cite Ben Aleya","CodePostal":"7050"},
    {"Ville":"Bizerte Nord","Cite":"Cite Belvedere","CodePostal":"7000"},
    {"Ville":"El Alia","Cite":"Impasse Ezzouhour","CodePostal":"7016"},
    {"Ville":"Mateur","Cite":"Cite Erraja 1","CodePostal":"7030"},
    {"Ville":"Menzel Bourguiba","Cite":"Cite De La Douane","CodePostal":"7050"},
    {"Ville":"Bizerte Nord","Cite":"Cite Bougatfa","CodePostal":"7001"},
    {"Ville":"Joumine","Cite":"Joumine","CodePostal":"7020"},
    {"Ville":"Mateur","Cite":"Cite Erraja 2","CodePostal":"7030"},
    {"Ville":"Menzel Bourguiba","Cite":"Cite La Ruche","CodePostal":"7050"},
    {"Ville":"Bizerte Nord","Cite":"Cite De La Municipalite","CodePostal":"7001"},
    {"Ville":"Joumine","Cite":"Sayah","CodePostal":"7020"},
    {"Ville":"Mateur","Cite":"Cite Erraja 3","CodePostal":"7030"},
    {"Ville":"Menzel Bourguiba","Cite":"Cite Nouvelle","CodePostal":"7050"},
    {"Ville":"Bizerte Nord","Cite":"Cite Erriadh","CodePostal":"7001"},
    {"Ville":"Joumine","Cite":"Smadah","CodePostal":"7020"},
    {"Ville":"Mateur","Cite":"Cite Essadaka","CodePostal":"7030"},
    {"Ville":"Menzel Bourguiba","Cite":"Cite Sidi Yahya","CodePostal":"7050"},
    {"Ville":"Bizerte Nord","Cite":"Cite Hassen Nouri","CodePostal":"7001"},
    {"Ville":"Jarzouna","Cite":"Cite Ben Arous","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Cite Ezzouhour","CodePostal":"7030"},
    {"Ville":"Menzel Bourguiba","Cite":"Menzel Bourguiba","CodePostal":"7050"},
    {"Ville":"Bizerte Nord","Cite":"Cite Nouvelle Plage","CodePostal":"7001"},
    {"Ville":"Jarzouna","Cite":"Cite Bouchoucha 2","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Cite Khemouma","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Bizerte Bougatfa","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Ksiba","CodePostal":"7001"},
    {"Ville":"Jarzouna","Cite":"Cite Calyptus","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Cite Montazah","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Cite Afh","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Cite Militaire","CodePostal":"7002"},
    {"Ville":"Jarzouna","Cite":"Cite Du Soleil","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Cite Snit","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Cite Ain Meriem","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Cite Chourouk Sidi Salem","CodePostal":"7003"},
    {"Ville":"Jarzouna","Cite":"Cite Ennacim","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Cite Zarrouk","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Cite Bougatfa 2","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Cite De La Sante","CodePostal":"7003"},
    {"Ville":"Jarzouna","Cite":"Cite Erraja 1","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"El Aouana","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Cite De La Police","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Cite De Paris","CodePostal":"7003"},
    {"Ville":"Jarzouna","Cite":"Cite Erraja 2","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Jebel Ichkeul","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Cite El Korniche 1","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Cite El Hana","CodePostal":"7003"},
    {"Ville":"Jarzouna","Cite":"Cite Erraja 3","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Mateur","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Cite El Korniche 2","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Cite Ennacim","CodePostal":"7003"},
    {"Ville":"Jarzouna","Cite":"Cite Errimel","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Sidi Mansour","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Cite El Korniche 3","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Cite Erraouabi","CodePostal":"7003"},
    {"Ville":"Jarzouna","Cite":"Cite Errsass","CodePostal":"7021"},
    {"Ville":"Mateur","Cite":"Sidi Nsir","CodePostal":"7030"},
    {"Ville":"Bizerte Nord","Cite":"Cite El Morjene 3","CodePostal":"7053"},
    {"Ville":"Bizerte Nord","Cite":"Oued El Marj","CodePostal":"7003"},
    {"Ville":"Jarzouna","Cite":"Cite Mohamed Chehimi","CodePostal":"7021"},
    {"Ville":"Tinja","Cite":"Cite El Fateh","CodePostal":"7032"},
    {"Ville":"Utique","Cite":"Cite De La Poste","CodePostal":"7060"},
    {"Ville":"Sejnane","Cite":"Cite El Bassatine","CodePostal":"7010"},
    {"Ville":"Jarzouna","Cite":"Cite Nouvelle","CodePostal":"7021"},
    {"Ville":"Tinja","Cite":"Cite El Fouladh","CodePostal":"7032"},
    {"Ville":"Utique","Cite":"Cite Ejjamaa","CodePostal":"7060"},
    {"Ville":"Sejnane","Cite":"Cite El Ghabette","CodePostal":"7010"},
    {"Ville":"Jarzouna","Cite":"Jarzouna","CodePostal":"7021"},
    {"Ville":"Tinja","Cite":"Cite El Khadhraoui","CodePostal":"7032"},
    {"Ville":"Utique","Cite":"Cite Ezzayatine","CodePostal":"7060"},
    {"Ville":"Sejnane","Cite":"Cite El Mouasker","CodePostal":"7010"},
    {"Ville":"Mateur","Cite":"Borj El Adouani","CodePostal":"7022"},
    {"Ville":"Tinja","Cite":"Cite Ikbal","CodePostal":"7032"},
    {"Ville":"Utique","Cite":"Cite Houmet Souk","CodePostal":"7060"},
    {"Ville":"Sejnane","Cite":"Cite Essaada","CodePostal":"7010"},
    {"Ville":"Mateur","Cite":"Cite Hached","CodePostal":"7022"},
    {"Ville":"Tinja","Cite":"Cite Sidi Rezig","CodePostal":"7032"},
    {"Ville":"Utique","Cite":"Ghornata","CodePostal":"7060"},
    {"Ville":"Sejnane","Cite":"Cite Ezzouhour","CodePostal":"7010"},
    {"Ville":"Mateur","Cite":"Ettaref","CodePostal":"7022"},
    {"Ville":"Tinja","Cite":"Cite Sprols","CodePostal":"7032"},
    {"Ville":"Utique","Cite":"Lahouidh","CodePostal":"7060"},
    {"Ville":"Sejnane","Cite":"Cite Ezzouhour 2","CodePostal":"7010"},
    {"Ville":"Mateur","Cite":"Henchir El Kahla","CodePostal":"7022"},
    {"Ville":"Tinja","Cite":"Cite Zghaba","CodePostal":"7032"},
    {"Ville":"Utique","Cite":"Utique","CodePostal":"7060"},
    {"Ville":"Sejnane","Cite":"Cite Ibn Khaldoun","CodePostal":"7010"},
    {"Ville":"Mateur","Cite":"Jabouz","CodePostal":"7022"},
    {"Ville":"Tinja","Cite":"Kharrata","CodePostal":"7032"},
    {"Ville":"Bizerte Nord","Cite":"Bizerte Bab Mateur","CodePostal":"7061"},
    {"Ville":"Sejnane","Cite":"Cite Khaled Ibn El Walid","CodePostal":"7010"},
    {"Ville":"Mateur","Cite":"Mateur Hached","CodePostal":"7022"},
    {"Ville":"Tinja","Cite":"Tinja","CodePostal":"7032"},
    {"Ville":"Utique","Cite":"Utique Nouvelle","CodePostal":"7063"},
    {"Ville":"Sejnane","Cite":"Cite Mougaad","CodePostal":"7010"},
    {"Ville":"Mateur","Cite":"Oum Jenna","CodePostal":"7022"},
    {"Ville":"Ghar El Melh","Cite":"Bajou","CodePostal":"7033"},
    {"Ville":"Bizerte Sud","Cite":"Bou Zaria","CodePostal":"7064"},
    {"Ville":"Sejnane","Cite":"Cite Sidi Mansour","CodePostal":"7010"},
    {"Ville":"Mateur","Cite":"Sidi Salem","CodePostal":"7022"},
    {"Ville":"Ghar El Melh","Cite":"Ghar El Melh","CodePostal":"7033"},
    {"Ville":"Ras Jebel","Cite":"Cite Ain Charchara","CodePostal":"7070"},
    {"Ville":"Sejnane","Cite":"Cite Taieb Mhiri","CodePostal":"7010"},
    {"Ville":"Utique","Cite":"Degma","CodePostal":"7023"},
    {"Ville":"Ras Jebel","Cite":"Cap Zbib","CodePostal":"7034"},
    {"Ville":"Ras Jebel","Cite":"Cite Bir Ezzaarour","CodePostal":"7070"},
    {"Ville":"Sejnane","Cite":"Dhouahria","CodePostal":"7010"},
    {"Ville":"Utique","Cite":"El Mabtouh","CodePostal":"7023"},
    {"Ville":"Ras Jebel","Cite":"Metline","CodePostal":"7034"},
    {"Ville":"Ras Jebel","Cite":"Cite El Benzarti","CodePostal":"7070"},
    {"Ville":"Ras Jebel","Cite":"Cite Ezzayatine","CodePostal":"7070"},
    {"Ville":"Sejnane","Cite":"El Abebsa","CodePostal":"7010"},
    {"Ville":"Utique","Cite":"Essakak","CodePostal":"7023"},
    {"Ville":"Menzel Jemil","Cite":"Cite Bir Hmem","CodePostal":"7035"},
    {"Ville":"Ras Jebel","Cite":"Cite Kaa El Ballout","CodePostal":"7070"},
    {"Ville":"Sejnane","Cite":"El Hania","CodePostal":"7010"},
    {"Ville":"Utique","Cite":"Sidi Othman","CodePostal":"7023"},
    {"Ville":"Menzel Jemil","Cite":"Cite Chamelle","CodePostal":"7035"},
    {"Ville":"Ras Jebel","Cite":"Ras Jebel","CodePostal":"7070"},
    {"Ville":"Sejnane","Cite":"El Mrifeg","CodePostal":"7010"},
    {"Ville":"Ghar El Melh","Cite":"Touibia","CodePostal":"7024"},
    {"Ville":"Menzel Jemil","Cite":"Cite Driss Ben Frej","CodePostal":"7035"},
    {"Ville":"Bizerte Sud","Cite":"Bizerte Hached","CodePostal":"7071"},
    {"Ville":"Sejnane","Cite":"Essmayria","CodePostal":"7010"},
    {"Ville":"Ghar El Melh","Cite":"Zouaouine","CodePostal":"7024"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Makarem","CodePostal":"7035"},
    {"Ville":"Menzel Bourguiba","Cite":"Menzel Bourguiba Ennajah","CodePostal":"7072"},
    {"Ville":"Sejnane","Cite":"Haddada","CodePostal":"7010"},
    {"Ville":"Ras Jebel","Cite":"Sounine","CodePostal":"7025"},
    {"Ville":"Menzel Jemil","Cite":"Cite Ettahrir","CodePostal":"7035"},
    {"Ville":"Ras Jebel","Cite":"Beni Atta","CodePostal":"7075"},
    {"Ville":"Sejnane","Cite":"Sejnane","CodePostal":"7010"},
    {"Ville":"Menzel Jemil","Cite":"Cite 7 Novembre","CodePostal":"7026"},
    {"Ville":"Menzel Jemil","Cite":"Cite Ettakaddoum","CodePostal":"7035"},
    {"Ville":"Menzel Jemil","Cite":"Cite Bir Remel","CodePostal":"7080"},
    {"Ville":"Sejnane","Cite":"Sidi Machreg","CodePostal":"7010"},
    {"Ville":"Menzel Jemil","Cite":"Cite Des Oranges","CodePostal":"7026"},
    {"Ville":"Menzel Jemil","Cite":"Cite Independance","CodePostal":"7035"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Ahlem","CodePostal":"7080"},
    {"Ville":"Sejnane","Cite":"Touajnia","CodePostal":"7010"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Habib (El Azib)","CodePostal":"7026"},
    {"Ville":"Menzel Jemil","Cite":"El Ain El Kebira","CodePostal":"7035"},
    {"Ville":"Menzel Jemil","Cite":"Cite El Baali","CodePostal":"7080"},
    {"Ville":"Bizerte Sud","Cite":"Cite El Mourouj","CodePostal":"7011"},
    {"Ville":"Menzel Jemil","Cite":"Cite Rurale 1","CodePostal":"7026"},
    {"Ville":"Menzel Jemil","Cite":"Menzel Abderrahman","CodePostal":"7035"},
    {"Ville":"Nouvelle Matmat","Cite":"Douiouira","CodePostal":"6044"},
    {"Ville":"Mareth","Cite":"Mareth","CodePostal":"6080"},
    {"Ville":"Gabes Medina","Cite":"Cite Commerciale","CodePostal":"6000"},
    {"Ville":"Nouvelle Matmat","Cite":"El Amra","CodePostal":"6025"},
    {"Ville":"Nouvelle Matmat","Cite":"Nouvelle Matmata","CodePostal":"6044"},
    {"Ville":"Mareth","Cite":"Mazraa Ben Slama","CodePostal":"6080"},
    {"Ville":"Gabes Medina","Cite":"Cite Des Oasis","CodePostal":"6000"},
    {"Ville":"Nouvelle Matmat","Cite":"Ezzahra","CodePostal":"6025"},
    {"Ville":"Mareth","Cite":"Toujane","CodePostal":"6045"},
    {"Ville":"Mareth","Cite":"Mejni","CodePostal":"6080"},
    {"Ville":"Gabes Medina","Cite":"Cite El Hana","CodePostal":"6000"},
    {"Ville":"Nouvelle Matmat","Cite":"Mzaten","CodePostal":"6025"},
    {"Ville":"Mareth","Cite":"Sidi Touati","CodePostal":"6046"},
    {"Ville":"Mareth","Cite":"Oued El Ghirane","CodePostal":"6080"},
    {"Ville":"Gabes Medina","Cite":"Cite El Khalij","CodePostal":"6000"},
    {"Ville":"Mareth","Cite":"Zarat","CodePostal":"6026"},
    {"Ville":"Gabes Ouest","Cite":"Nahal","CodePostal":"6051"},
    {"Ville":"Mareth","Cite":"Ourifen","CodePostal":"6080"},
    {"Ville":"Gabes Medina","Cite":"Cite El Korniche","CodePostal":"6000"},
    {"Ville":"El Hamma","Cite":"Bou Attouche","CodePostal":"6027"},
    {"Ville":"El Metouia","Cite":"Cite Ennacim","CodePostal":"6052"},
    {"Ville":"Mareth","Cite":"Zmorten","CodePostal":"6080"},
    {"Ville":"Gabes Medina","Cite":"Cite El Mansoura","CodePostal":"6000"},
    {"Ville":"Menzel Habib","Cite":"El Fejij","CodePostal":"6030"},
    {"Ville":"El Metouia","Cite":"Cite Essalem","CodePostal":"6052"},
    {"Ville":"Mareth","Cite":"Betahra","CodePostal":"6081"},
    {"Ville":"Gabes Medina","Cite":"Cite El Ouns","CodePostal":"6000"},
    {"Ville":"Menzel Habib","Cite":"El Mida","CodePostal":"6030"},
    {"Ville":"El Metouia","Cite":"Cite Kheireddine","CodePostal":"6052"},
    {"Ville":"El Metouia","Cite":"Metouia El Maya","CodePostal":"6089"},
    {"Ville":"Gabes Medina","Cite":"Cite Mohamed Ali","CodePostal":"6000"},
    {"Ville":"Menzel Habib","Cite":"Hejri","CodePostal":"6030"},
    {"Ville":"El Metouia","Cite":"El Mida","CodePostal":"6052"},
    {"Ville":"El Hamma","Cite":"Bechima","CodePostal":"6092"},
    {"Ville":"Gabes Medina","Cite":"El Mouazir","CodePostal":"6000"},
    {"Ville":"Menzel Habib","Cite":"Mehamla","CodePostal":"6030"},
    {"Ville":"El Metouia","Cite":"Ouali","CodePostal":"6052"},
    {"Ville":"El Hamma","Cite":"Chenchou","CodePostal":"6095"},
    {"Ville":"Gabes Medina","Cite":"Gabes","CodePostal":"6000"},
    {"Ville":"Menzel Habib","Cite":"Menzel Habib","CodePostal":"6030"},
    {"Ville":"El Metouia","Cite":"Oudhref","CodePostal":"6052"},
    {"Ville":"Gabes Medina","Cite":"Gabes El Hidaya","CodePostal":"6099"},
    {"Ville":"Gabes Medina","Cite":"Limaoua","CodePostal":"6000"},
    {"Ville":"Menzel Habib","Cite":"Ouali","CodePostal":"6030"},
    {"Ville":"Matmata","Cite":"Tamezrat","CodePostal":"6054"},
    {"Ville":"Gabes Medina","Cite":"Oued Khelifa Mahmoud","CodePostal":"6000"},
    {"Ville":"Menzel Habib","Cite":"Oued Ezzitoun","CodePostal":"6030"},
    {"Ville":"Mareth","Cite":"Dekhilet Toujane","CodePostal":"6055"},
    {"Ville":"Gabes Medina","Cite":"Remathi","CodePostal":"6000"},
    {"Ville":"Menzel Habib","Cite":"Ouled Dhaou","CodePostal":"6030"},
    {"Ville":"Mareth","Cite":"Ezzerkine","CodePostal":"6056"},
    {"Ville":"Gabes Medina","Cite":"Gabes Hached","CodePostal":"6001"},
    {"Ville":"Menzel Habib","Cite":"Zograta","CodePostal":"6030"},
    {"Ville":"El Hamma","Cite":"El Hamma Sud","CodePostal":"6060"},
    {"Ville":"El Metouia","Cite":"Cite El Fankar","CodePostal":"6010"},
    {"Ville":"Gabes Ouest","Cite":"Bou Chemma","CodePostal":"6031"},
    {"Ville":"Gabes Medina","Cite":"Chatt Essalem","CodePostal":"6061"},
    {"Ville":"El Metouia","Cite":"Cite El Maya","CodePostal":"6010"},
    {"Ville":"Gabes Ouest","Cite":"Cite 2 Mars","CodePostal":"6031"},
    {"Ville":"Gabes Medina","Cite":"Cite 26-26","CodePostal":"6061"},
    {"Ville":"El Metouia","Cite":"Cite El Mrachda","CodePostal":"6010"},
    {"Ville":"Gabes Ouest","Cite":"Cite Ettahrir","CodePostal":"6031"},
    {"Ville":"Gabes Medina","Cite":"Cite El Bled","CodePostal":"6061"},
    {"Ville":"El Metouia","Cite":"Cite Ennacim","CodePostal":"6010"},
    {"Ville":"Gabes Sud","Cite":"Cite 7 Novembre","CodePostal":"6032"},
    {"Ville":"Gabes Medina","Cite":"Cite El Ftarcha","CodePostal":"6061"},
    {"Ville":"El Metouia","Cite":"Cite Ezzouhour","CodePostal":"6010"},
    {"Ville":"Gabes Sud","Cite":"Cite Bedis","CodePostal":"6032"},
    {"Ville":"El Hamma","Cite":"Beni Ghilouf","CodePostal":"6062"},
    {"Ville":"El Metouia","Cite":"Cite Nehila Farhat","CodePostal":"6010"},
    {"Ville":"Gabes Sud","Cite":"Cite El Wafa","CodePostal":"6032"},
    {"Ville":"Matmata","Cite":"Bou Dhafer","CodePostal":"6070"},
    {"Ville":"El Metouia","Cite":"El Aouinette","CodePostal":"6010"},
    {"Ville":"Gabes Sud","Cite":"Cite Hached","CodePostal":"6032"},
    {"Ville":"Matmata","Cite":"Chouabet Esmaala","CodePostal":"6070"},
    {"Ville":"El Metouia","Cite":"El Imarat","CodePostal":"6010"},
    {"Ville":"Gabes Sud","Cite":"Teboulbou","CodePostal":"6032"},
    {"Ville":"Matmata","Cite":"Cite El Azezna","CodePostal":"6070"},
    {"Ville":"El Metouia","Cite":"El Metouia","CodePostal":"6010"},
    {"Ville":"Gabes Sud","Cite":"Cite El Amel","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Cite Hafi Rassa","CodePostal":"6070"},
    {"Ville":"El Metouia","Cite":"Oued El Ghram","CodePostal":"6010"},
    {"Ville":"Gabes Sud","Cite":"Cite El Amel 1","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Cite Hedi Chaker","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite El Izdihar","CodePostal":"6011"},
    {"Ville":"Gabes Sud","Cite":"Cite El Amel 2","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Cite Independance","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite El Medina","CodePostal":"6011"},
    {"Ville":"Gabes Sud","Cite":"Cite El Amel 3","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Cite Mohamed Ali","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite Medenine","CodePostal":"6011"},
    {"Ville":"Gabes Sud","Cite":"Cite El Amel 4","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Cite Ras El Oued","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Gabes El Menara","CodePostal":"6011"},
    {"Ville":"Gabes Sud","Cite":"Cite El Amel 5","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Haddej","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite Afh","CodePostal":"6012"},
    {"Ville":"Gabes Sud","Cite":"Cite El Amel 6","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Ksar Beni Aissa","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite Ancien Militaire","CodePostal":"6012"},
    {"Ville":"Gabes Sud","Cite":"Cite El Maarifa","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Matmata","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite Bidani","CodePostal":"6012"},
    {"Ville":"Gabes Sud","Cite":"Cite Ennakhil","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Taoujout","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite El Hana Boudoura","CodePostal":"6012"},
    {"Ville":"Gabes Sud","Cite":"Cite Ennour","CodePostal":"6033"},
    {"Ville":"Matmata","Cite":"Tijma","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite El Misk","CodePostal":"6012"},
    {"Ville":"Matmata","Cite":"Techine","CodePostal":"6034"},
    {"Ville":"Matmata","Cite":"Zriba","CodePostal":"6070"},
    {"Ville":"Gabes Sud","Cite":"Cite Militaire Dimassi","CodePostal":"6012"},
    {"Ville":"Mareth","Cite":"Ain Tounine","CodePostal":"6035"},
    {"Ville":"Gabes Medina","Cite":"Gabes Port","CodePostal":"6071"},
    {"Ville":"Gabes Sud","Cite":"Cite Rhouma","CodePostal":"6012"},
    {"Ville":"Mareth","Cite":"Cite Populaire","CodePostal":"6036"},
    {"Ville":"Gabes Sud","Cite":"Cite Afh","CodePostal":"6072"},
    {"Ville":"Gabes Sud","Cite":"Sidi Boulbaba","CodePostal":"6012"},
    {"Ville":"Mareth","Cite":"Kettana","CodePostal":"6036"},
    {"Ville":"Gabes Sud","Cite":"Cite El Ahrach","CodePostal":"6072"},
    {"Ville":"El Hamma","Cite":"Sombat","CodePostal":"6013"},
    {"Ville":"Mareth","Cite":"Oued El Hajel","CodePostal":"6036"},
    {"Ville":"Gabes Sud","Cite":"Cite El Guefercha","CodePostal":"6072"},
    {"Ville":"Gabes Sud","Cite":"Cite Azaiez","CodePostal":"6014"},
    {"Ville":"Mareth","Cite":"Sidi Slam","CodePostal":"6036"},
    {"Ville":"Gabes Sud","Cite":"Cite Snit","CodePostal":"6072"},
    {"Ville":"Gabes Sud","Cite":"Cite De La Famille","CodePostal":"6014"},
    {"Ville":"Mareth","Cite":"Zrig El Barrania","CodePostal":"6036"},
    {"Ville":"Gabes Sud","Cite":"Cite Sprols","CodePostal":"6072"},
    {"Ville":"Gabes Sud","Cite":"Cite Des Enseignants","CodePostal":"6014"},
    {"Ville":"Mareth","Cite":"Zrig El Ghandri","CodePostal":"6036"},
    {"Ville":"Gabes Sud","Cite":"Zrig","CodePostal":"6072"},
    {"Ville":"Gabes Sud","Cite":"Cite Des Infirmiers","CodePostal":"6014"},
    {"Ville":"Gabes Medina","Cite":"Gabes Republique","CodePostal":"6040"},
    {"Ville":"Mareth","Cite":"Ancien Bled","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite El Mzahid","CodePostal":"6014"},
    {"Ville":"Gabes Ouest","Cite":"Barguia","CodePostal":"6041"},
    {"Ville":"Mareth","Cite":"Azeiza","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite El Yasmine","CodePostal":"6014"},
    {"Ville":"Gabes Ouest","Cite":"Chenini Gabes","CodePostal":"6041"},
    {"Ville":"Mareth","Cite":"Betahra Sud","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite Ennozh","CodePostal":"6014"},
    {"Ville":"Gabes Ouest","Cite":"Maita","CodePostal":"6041"},
    {"Ville":"Mareth","Cite":"Braouka","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite Esseptia","CodePostal":"6014"},
    {"Ville":"El Metouia","Cite":"El Akarit","CodePostal":"6042"},
    {"Ville":"Mareth","Cite":"Cite Commerciale","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite Ezzouhour 1","CodePostal":"6014"},
    {"Ville":"El Metouia","Cite":"El Hicha","CodePostal":"6042"},
    {"Ville":"Mareth","Cite":"Cite Debaba","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite Ezzouhour 2","CodePostal":"6014"},
    {"Ville":"Nouvelle Matmat","Cite":"Laffam","CodePostal":"6043"},
    {"Ville":"Mareth","Cite":"Cite El Amel","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite Hached 1","CodePostal":"6014"},
    {"Ville":"Nouvelle Matmat","Cite":"Oued El Harika","CodePostal":"6043"},
    {"Ville":"Mareth","Cite":"Cite El Argoub","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite Hached 2","CodePostal":"6014"},
    {"Ville":"Nouvelle Matmat","Cite":"Zaten Beni Zelten","CodePostal":"6043"},
    {"Ville":"Mareth","Cite":"Cite El Charfia","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Cite Ibn Khaldoun","CodePostal":"6014"},
    {"Ville":"Nouvelle Matmat","Cite":"Zaten Haddej","CodePostal":"6043"},
    {"Ville":"Mareth","Cite":"Cite El Ghardek1","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"Mtorrech","CodePostal":"6014"},
    {"Ville":"Nouvelle Matmat","Cite":"Bou Sbah","CodePostal":"6044"},
    {"Ville":"Mareth","Cite":"Cite El Ghardek2","CodePostal":"6080"},
    {"Ville":"Mareth","Cite":"Ayoune Ezzerkine","CodePostal":"6015"},
    {"Ville":"Nouvelle Matmat","Cite":"Chobet Chemlali","CodePostal":"6044"},
    {"Ville":"Mareth","Cite":"Cite El Ghardek3","CodePostal":"6080"},
    {"Ville":"Mareth","Cite":"Arram","CodePostal":"6016"},
    {"Ville":"Nouvelle Matmat","Cite":"Cite Edechaicha","CodePostal":"6044"},
    {"Ville":"Mareth","Cite":"Cite El Hafaya","CodePostal":"6080"},
    {"Ville":"El Hamma","Cite":"El Hamma","CodePostal":"6020"},
    {"Ville":"Nouvelle Matmat","Cite":"Cite El Izdihar","CodePostal":"6044"},
    {"Ville":"Mareth","Cite":"Cite El Kribia","CodePostal":"6080"},
    {"Ville":"Ghannouche","Cite":"Cite El Izdihar","CodePostal":"6021"},
    {"Ville":"Nouvelle Matmat","Cite":"Cite Guedouala","CodePostal":"6044"},
    {"Ville":"Mareth","Cite":"Cite El Masla","CodePostal":"6080"},
    {"Ville":"Ghannouche","Cite":"Cite Errimel","CodePostal":"6021"},
    {"Ville":"Mareth","Cite":"Cite El Melayha","CodePostal":"6080"},
    {"Ville":"Ghannouche","Cite":"Cite Ettahrir","CodePostal":"6021"},
    {"Ville":"Mareth","Cite":"Cite Ennouzha","CodePostal":"6080"},
    {"Ville":"Ghannouche","Cite":"Ghannouche","CodePostal":"6021"},
    {"Ville":"Mareth","Cite":"Cite Garde Nationale","CodePostal":"6080"},
    {"Ville":"Gabes Sud","Cite":"El Mdou","CodePostal":"6022"},
    {"Ville":"Mareth","Cite":"Cite Snit","CodePostal":"6080"},
    {"Ville":"Mareth","Cite":"El Alaya","CodePostal":"6023"},
    {"Ville":"Mareth","Cite":"El Akhmes","CodePostal":"6080"},
    {"Ville":"Nouvelle Matmat","Cite":"Ancienne Zraoua","CodePostal":"6024"},
    {"Ville":"Mareth","Cite":"El Frada","CodePostal":"6080"},
    {"Ville":"Nouvelle Matmat","Cite":"Nouvelle Zraoua","CodePostal":"6024"},
    {"Ville":"Mareth","Cite":"El Kaouakba","CodePostal":"6080"},
    {"Ville":"Nouvelle Matmat","Cite":"Sidi Gnaou","CodePostal":"6024"},
    {"Ville":"Nouvelle Matmat","Cite":"Cite Ouled Slama","CodePostal":"6044"},
    {"Ville":"Nouvelle Matmat","Cite":"Dhokara","CodePostal":"6044"},
    {"Ville":"Mareth","Cite":"Graa Beiri","CodePostal":"6080"},
    {"Ville":"Gabes Medina","Cite":"Cite Ben Kilani","CodePostal":"6000"},
    {"Ville":"Nouvelle Matmat","Cite":"Beni Zelten","CodePostal":"6025"},
    {"Ville":"El Guettar","Cite":"Cite Orbata Est","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite Des Jeunes","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Aouled Radhouane","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"Cite Orbata Ouest","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite Des Ouvriers","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Centrale","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"Cite Populaire","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite El Amel","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Du Laboratoire","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"Cite Populaire Route Gabes","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite Essoualmia","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite El Amel","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"Cite Sprols","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite Kouceila","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Ennacim","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"El Amaiem","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite Marocain","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Ennasr","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"El Guettar","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite Moderne","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Essaada 1","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"Majni","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite Okba Ibn Nafaa","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Essaada 2","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"Nchiou","CodePostal":"2181"},
    {"Ville":"Moulares","Cite":"Cite Sprols","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Essaada Ouest","CodePostal":"2130"},
    {"Ville":"El Guettar","Cite":"Lortes","CodePostal":"2183"},
    {"Ville":"Moulares","Cite":"Moulares","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Europeen","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Bahloula","CodePostal":"2190"},
    {"Ville":"Moulares","Cite":"Tabedit","CodePostal":"2110"},
    {"Ville":"Metlaoui","Cite":"Cite Ezzouhour","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite De La Gout","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Bab Ettoub","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Cite Ezzouhour 1","CodePostal":"2130"},
    {"Ville":"Metlaoui","Cite":"Cite Ezzouhour 2","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Des Martyrs","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Batiments","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Cite Ezzouhour 3","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Ennajah","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Ben Doula","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Cite Ezzouhour 4","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Ennasr 1","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Ben Issaoui","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Cite Ibn Khaldoun","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Ennasr 2","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Ben Slimene","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Cite Moderne","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Ennour","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Bouranene","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Cite Presidentielle","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Erriadh","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite De La Gare","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Cite Thelja","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Ezzitouna 1","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Des Cadres","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Metlaoui","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Ezzitouna 2","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Ecole","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Richet Ennaam","CodePostal":"2130"},
    {"Ville":"Sned","Cite":"Cite Ezzouhour 1","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite El Ajama","CodePostal":"2111"},
    {"Ville":"Sidi Aich","Cite":"Cite Dhebebnia 1","CodePostal":"2131"},
    {"Ville":"Sned","Cite":"Cite Ezzouhour 2","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite El Kaouafel","CodePostal":"2111"},
    {"Ville":"Sidi Aich","Cite":"Cite Dhebebnia 2","CodePostal":"2131"},
    {"Ville":"Sned","Cite":"Cite Taieb Mhiri","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite El Khil","CodePostal":"2111"},
    {"Ville":"Sidi Aich","Cite":"Cite Du Lycee","CodePostal":"2131"},
    {"Ville":"Sned","Cite":"Dhoukara","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite El Mancher","CodePostal":"2111"},
    {"Ville":"Sidi Aich","Cite":"Cite Ecole","CodePostal":"2131"},
    {"Ville":"Sned","Cite":"El Goussa","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Essouani","CodePostal":"2111"},
    {"Ville":"Sidi Aich","Cite":"Cite Logement Populaire","CodePostal":"2131"},
    {"Ville":"Sned","Cite":"Essmayria","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Cite Ragouba","CodePostal":"2111"},
    {"Ville":"Sidi Aich","Cite":"El Garia","CodePostal":"2131"},
    {"Ville":"Sned","Cite":"Jebel Sned","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"El Ksar","CodePostal":"2111"},
    {"Ville":"Sidi Aich","Cite":"Sidi Aich","CodePostal":"2131"},
    {"Ville":"Sned","Cite":"Menzel Chihaoui","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Gafsa Gare","CodePostal":"2111"},
    {"Ville":"Sidi Aich","Cite":"Sidi Aich Est","CodePostal":"2131"},
    {"Ville":"Sned","Cite":"Sned","CodePostal":"2190"},
    {"Ville":"El Ksar","Cite":"Ouled Chrait","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"Cite El Magroun","CodePostal":"2132"},
    {"Ville":"Sned","Cite":"Majoura","CodePostal":"2192"},
    {"Ville":"El Ksar","Cite":"Sbat","CodePostal":"2111"},
    {"Ville":"Metlaoui","Cite":"El Kaina","CodePostal":"2132"},
    {"Ville":"Sned","Cite":"Majourat Eddakhla","CodePostal":"2192"},
    {"Ville":"Gafsa Sud","Cite":"Sidi Ahmed Zarrouk","CodePostal":"2112"},
    {"Ville":"Metlaoui","Cite":"Metlaoui Mines","CodePostal":"2132"},
    {"Ville":"Sned","Cite":"Abdessadok","CodePostal":"2193"},
    {"Ville":"Metlaoui","Cite":"Metlaoui Gare","CodePostal":"2113"},
    {"Ville":"Gafsa Sud","Cite":"Gafsa Cite Des Jeunes","CodePostal":"2133"},
    {"Ville":"Sned","Cite":"El Biadha","CodePostal":"2193"},
    {"Ville":"Moulares","Cite":"Ouglet Ahmed","CodePostal":"2114"},
    {"Ville":"Metlaoui","Cite":"El Mziraa","CodePostal":"2134"},
    {"Ville":"Sned","Cite":"Henchir El Afrah","CodePostal":"2193"},
    {"Ville":"Moulares","Cite":"Sidi Boubaker","CodePostal":"2114"},
    {"Ville":"Metlaoui","Cite":"Metlaoui Thalja","CodePostal":"2134"},
    {"Ville":"Sned","Cite":"Alim","CodePostal":"2195"},
    {"Ville":"Belkhir","Cite":"Belkhir","CodePostal":"2115"},
    {"Ville":"Belkhir","Cite":"Baten Zammour","CodePostal":"2135"},
    {"Ville":"Gafsa Nord","Cite":"Echabiba","CodePostal":"2196"},
    {"Ville":"Belkhir","Cite":"Borj El Haffey","CodePostal":"2115"},
    {"Ville":"Belkhir","Cite":"Bou Blel","CodePostal":"2135"},
    {"Ville":"Gafsa Nord","Cite":"Ouled Zid","CodePostal":"2196"},
    {"Ville":"Belkhir","Cite":"Cite Ouled Bel Hassen","CodePostal":"2115"},
    {"Ville":"Belkhir","Cite":"Boukou","CodePostal":"2135"},
    {"Ville":"Belkhir","Cite":"Cite Ouled Ben Jeddou","CodePostal":"2115"},
    {"Ville":"Belkhir","Cite":"Haouel El Oued","CodePostal":"2135"},
    {"Ville":"Belkhir","Cite":"Cite Ouled Haj Ali","CodePostal":"2115"},
    {"Ville":"Belkhir","Cite":"Jebilet El Oust","CodePostal":"2135"},
    {"Ville":"Belkhir","Cite":"Cite Ouled Hmida","CodePostal":"2115"},
    {"Ville":"Belkhir","Cite":"Ouled Zaied","CodePostal":"2135"},
    {"Ville":"Belkhir","Cite":"Cite Ouled Saad","CodePostal":"2115"},
    {"Ville":"Belkhir","Cite":"El Akteb","CodePostal":"2139"},
    {"Ville":"Belkhir","Cite":"Ouled El Haj","CodePostal":"2115"},
    {"Ville":"Belkhir","Cite":"El Ayaicha","CodePostal":"2139"},
    {"Ville":"Sned","Cite":"El Araria","CodePostal":"2116"},
    {"Ville":"Belkhir","Cite":"Ezzitouna","CodePostal":"2139"},
    {"Ville":"Sned","Cite":"Jedida","CodePostal":"2116"},
    {"Ville":"Belkhir","Cite":"Jebel Ayaicha","CodePostal":"2139"},
    {"Ville":"Sned","Cite":"Oum El Aleg","CodePostal":"2116"},
    {"Ville":"Redeyef","Cite":"Redeyef Gare","CodePostal":"2140"},
    {"Ville":"Sned","Cite":"Zannouche","CodePostal":"2116"},
    {"Ville":"Gafsa Nord","Cite":"Menzel Mimoun","CodePostal":"2141"},
    {"Ville":"Gafsa Sud","Cite":"Cite Bayech","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite De La Gare","CodePostal":"2120"},
    {"Ville":"Gafsa Sud","Cite":"Ennadhour","CodePostal":"2142"},
    {"Ville":"Gafsa Sud","Cite":"Cite Cherif","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite De La Poste","CodePostal":"2120"},
    {"Ville":"Gafsa Sud","Cite":"Kef Derbi","CodePostal":"2142"},
    {"Ville":"Gafsa Sud","Cite":"Cite Des Jeunes","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Du Souk","CodePostal":"2120"},
    {"Ville":"Gafsa Sud","Cite":"Doualy Gafsa","CodePostal":"2143"},
    {"Ville":"Gafsa Sud","Cite":"Cite Des Jeunes 1","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Essouafa","CodePostal":"2120"},
    {"Ville":"El Guettar","Cite":"Ouled Bou Saad","CodePostal":"2145"},
    {"Ville":"Gafsa Sud","Cite":"Cite Des Jeunes 2","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Ettahrir","CodePostal":"2120"},
    {"Ville":"El Ksar","Cite":"Gafsa Aeroport","CodePostal":"2151"},
    {"Ville":"Gafsa Sud","Cite":"Cite El Afrane","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Hsouna Ben Tahar","CodePostal":"2120"},
    {"Ville":"Moulares","Cite":"Moulares Gare","CodePostal":"2161"},
    {"Ville":"Gafsa Sud","Cite":"Cite El Kaouafel 1","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Ouled Bou Oune","CodePostal":"2120"},
    {"Ville":"El Ksar","Cite":"Erragouba","CodePostal":"2169"},
    {"Ville":"Gafsa Sud","Cite":"Cite El Kaouafel 2","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Ouled Majed","CodePostal":"2120"},
    {"Ville":"El Mdhilla","Cite":"Cite Des Jeunes","CodePostal":"2170"},
    {"Ville":"Gafsa Sud","Cite":"Cite El Kitna","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Ouvriere","CodePostal":"2120"},
    {"Ville":"El Mdhilla","Cite":"Cite Ennacim","CodePostal":"2170"},
    {"Ville":"Gafsa Sud","Cite":"Cite El Moula","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Sidi Abdelkader","CodePostal":"2120"},
    {"Ville":"El Mdhilla","Cite":"Cite Essaada","CodePostal":"2170"},
    {"Ville":"Gafsa Sud","Cite":"Cite Ennouhoudh","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Sprols","CodePostal":"2120"},
    {"Ville":"El Mdhilla","Cite":"Cite Ouest","CodePostal":"2170"},
    {"Ville":"Gafsa Sud","Cite":"Cite Garde Nationale","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Cite Trabelsia","CodePostal":"2120"},
    {"Ville":"El Mdhilla","Cite":"Cite Ouvriere 2","CodePostal":"2170"},
    {"Ville":"Gafsa Sud","Cite":"Cite Industrielle","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Redeyef","CodePostal":"2120"},
    {"Ville":"El Mdhilla","Cite":"Cite Stade Ouvriere Est","CodePostal":"2170"},
    {"Ville":"Gafsa Sud","Cite":"El Garia","CodePostal":"2100"},
    {"Ville":"El Ksar","Cite":"Lala","CodePostal":"2121"},
    {"Ville":"El Mdhilla","Cite":"Jebel Mdhilla","CodePostal":"2170"},
    {"Ville":"Gafsa Sud","Cite":"Gafsa","CodePostal":"2100"},
    {"Ville":"Redeyef","Cite":"Zomra Redeyef","CodePostal":"2122"},
    {"Ville":"El Mdhilla","Cite":"Borj El Mdhilla","CodePostal":"2173"},
    {"Ville":"Moulares","Cite":"Cite De La Fraternite","CodePostal":"2110"},
    {"Ville":"Gafsa Sud","Cite":"Gafsa Cite Ennour","CodePostal":"2123"},
    {"Ville":"El Guettar","Cite":"Bir Saad","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite De La Gare","CodePostal":"2110"},
    {"Ville":"Gafsa Sud","Cite":"Cite Essourour","CodePostal":"2124"},
    {"Ville":"El Guettar","Cite":"Cite Okba","CodePostal":"2180"},
    {"Ville":"Moulares","Cite":"Cite De Le Liberte","CodePostal":"2110"},
    {"Ville":"El Guettar","Cite":"Bou Omrane","CodePostal":"2125"},
    {"Ville":"Jendouba","Cite":"El Melga","CodePostal":"8153"},
    {"Ville":"Jendouba","Cite":"Mitil","CodePostal":"8195"},
    {"Ville":"Balta Bou Aouene","Cite":"Balta","CodePostal":"8126"},
    {"Ville":"Ghardimaou","Cite":"Ain Soltane","CodePostal":"8160"},
    {"Ville":"Jendouba","Cite":"Cite Ettataouer","CodePostal":"8196"},
    {"Ville":"Tabarka","Cite":"Brirem","CodePostal":"8127"},
    {"Ville":"Ghardimaou","Cite":"Cite Des Combattants","CodePostal":"8160"},
    {"Ville":"Tabarka","Cite":"El Khedhairia","CodePostal":"8128"},
    {"Ville":"Ghardimaou","Cite":"Cite Des Jardins","CodePostal":"8160"},
    {"Ville":"Tabarka","Cite":"Jaballah","CodePostal":"8128"},
    {"Ville":"Ghardimaou","Cite":"Cite El Mayara","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Ain Draham","CodePostal":"8130"},
    {"Ville":"Ghardimaou","Cite":"Cite Ennouhoudh 1","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"El Atatfa","CodePostal":"8130"},
    {"Ville":"Ghardimaou","Cite":"Cite Ennouhoudh 2","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Sidi Abdallah","CodePostal":"8130"},
    {"Ville":"Ghardimaou","Cite":"Cite Ennouhoudh 3","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Sidi Mhamed","CodePostal":"8130"},
    {"Ville":"Ghardimaou","Cite":"Cite Ennouhoudh 4","CodePostal":"8160"},
    {"Ville":"Jendouba","Cite":"Souk Essebt","CodePostal":"8131"},
    {"Ville":"Ghardimaou","Cite":"Cite Ennouhoudh 5","CodePostal":"8160"},
    {"Ville":"Jendouba Nord","Cite":"Souk Jemaa","CodePostal":"8132"},
    {"Ville":"Ghardimaou","Cite":"Cite Ennouhoudh 6","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Ain El Hamraya","CodePostal":"8134"},
    {"Ville":"Ghardimaou","Cite":"Cite Ennouhoudh 7","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"El Houmrane","CodePostal":"8134"},
    {"Ville":"Ghardimaou","Cite":"Cite Erraja","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Oued Ezzene","CodePostal":"8134"},
    {"Ville":"Ghardimaou","Cite":"Cite Erraoudha","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Tebainia","CodePostal":"8134"},
    {"Ville":"Ghardimaou","Cite":"Cite Essaada 1","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Esseloul","CodePostal":"8135"},
    {"Ville":"Ghardimaou","Cite":"Cite Essaada 2","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Ouled Helal","CodePostal":"8135"},
    {"Ville":"Ghardimaou","Cite":"Cite Ezzouhour","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Adissa","CodePostal":"8136"},
    {"Ville":"Ghardimaou","Cite":"Cite Ines","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Hammam Bourguiba","CodePostal":"8136"},
    {"Ville":"Ghardimaou","Cite":"El Biadha","CodePostal":"8160"},
    {"Ville":"Ain Draham","Cite":"Rouai","CodePostal":"8136"},
    {"Ville":"Ghardimaou","Cite":"El Feija","CodePostal":"8160"},
    {"Ville":"Jendouba","Cite":"Ain El Khararib","CodePostal":"8100"},
    {"Ville":"Ain Draham","Cite":"Tegma","CodePostal":"8136"},
    {"Ville":"Ghardimaou","Cite":"Esraya","CodePostal":"8160"},
    {"Ville":"Jendouba","Cite":"Ain El Ksir","CodePostal":"8100"},
    {"Ville":"Fernana","Cite":"Argoub Rihane","CodePostal":"8140"},
    {"Ville":"Ghardimaou","Cite":"Ezzraibia","CodePostal":"8160"},
    {"Ville":"Jendouba","Cite":"Cite Administrative","CodePostal":"8100"},
    {"Ville":"Fernana","Cite":"Betaha","CodePostal":"8140"},
    {"Ville":"Ghardimaou","Cite":"Ghardimaou","CodePostal":"8160"},
    {"Ville":"Jendouba","Cite":"Cite Ennour 1","CodePostal":"8100"},
    {"Ville":"Fernana","Cite":"Cite Beni Mazen","CodePostal":"8140"},
    {"Ville":"Ghardimaou","Cite":"Ouechtata","CodePostal":"8160"},
    {"Ville":"Jendouba","Cite":"Cite Ennour 2","CodePostal":"8100"},
    {"Ville":"Fernana","Cite":"Cite De La Rnta","CodePostal":"8140"},
    {"Ville":"Ghardimaou","Cite":"Zitounet Oum Lahneche","CodePostal":"8160"},
    {"Ville":"Jendouba","Cite":"Cite Snit","CodePostal":"8100"},
    {"Ville":"Fernana","Cite":"Cite El Frajnia","CodePostal":"8140"},
    {"Ville":"Ghardimaou","Cite":"Ouerguech","CodePostal":"8161"},
    {"Ville":"Jendouba","Cite":"El Ganara","CodePostal":"8100"},
    {"Ville":"Fernana","Cite":"Cite El Intilaka","CodePostal":"8140"},
    {"Ville":"Ghardimaou","Cite":"Oued El Maaden","CodePostal":"8162"},
    {"Ville":"Jendouba","Cite":"Jendouba","CodePostal":"8100"},
    {"Ville":"Fernana","Cite":"Cite El Mouftarek","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Beni Mhamed","CodePostal":"8170"},
    {"Ville":"Jendouba Nord","Cite":"Jerif","CodePostal":"8100"},
    {"Ville":"Fernana","Cite":"Cite El Mourouj","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Bou Laaba","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Ain Essnoussi","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"Cite Ennacim","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Bou Salem","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Chaaouania","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"Cite Essaada","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite 7 Novembre 1","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Des Arts","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"Cite Essanaouber","CodePostal":"8140"},
    {"Ville":"Fernana","Cite":"Cite Ezzouhour","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite 7 Novembre 2","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Du Soleil","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"Cite Oum Kethir","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite 7 Novembre 3","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Eddachra","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"Cite Sprols","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite De La Municipalite","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite El Mourjene","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"Echtaibia","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Diamonta","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Erriadh","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"Ejouablia","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite El Bhairia","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Kraimia","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"El Alayeg","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite El Hafsia","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Taieb Mhiri","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"El Fazzen","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite El Hana","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"El Farech","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"El Fejouj","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite El Khalij","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Sidi Rouine","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"El Haouamd","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Ennour","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Tabarka","CodePostal":"8110"},
    {"Ville":"Fernana","Cite":"El Houjjaj","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Erriadh","CodePostal":"8170"},
    {"Ville":"Jendouba","Cite":"Cite Ezzouhour","CodePostal":"8111"},
    {"Ville":"Fernana","Cite":"El Magroun","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Essourour","CodePostal":"8170"},
    {"Ville":"Jendouba Nord","Cite":"Ben Bechir","CodePostal":"8111"},
    {"Ville":"Fernana","Cite":"Eroouaba","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Fatouma Bourguiba","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Ain Essobh","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Essmaibia","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Hached","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Bou Terfes","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Fernana","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Hedi Khelil","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Populaire 1","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Grioua","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Hopital","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Populaire 2","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Halima","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Khzama 1","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cite Populaire 3","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Oued Gherib","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Khzama 2","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Cooperative Centrale Des Grandes","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Ouled Mfedda","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Lamine","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Ouled Yahya","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Rabia","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Marche Municipal","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Ras Errajel","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Rmila","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Sprols","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Sidi Asker","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Sidi Ammar","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Cite Zone Industrielle","CodePostal":"8170"},
    {"Ville":"Tabarka","Cite":"Touajnia","CodePostal":"8112"},
    {"Ville":"Fernana","Cite":"Sidi Said","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"El Aouaoudha","CodePostal":"8170"},
    {"Ville":"Fernana","Cite":"Beni Mtir","CodePostal":"8114"},
    {"Ville":"Fernana","Cite":"Zaaroura","CodePostal":"8140"},
    {"Ville":"Bou Salem","Cite":"Essoumrane","CodePostal":"8173"},
    {"Ville":"Fernana","Cite":"Cite Essalah","CodePostal":"8114"},
    {"Ville":"Fernana","Cite":"Bou Hertma","CodePostal":"8141"},
    {"Ville":"Tabarka","Cite":"Tabarka Aeroport","CodePostal":"8181"},
    {"Ville":"Oued Mliz","Cite":"Cite El Ayachi","CodePostal":"8115"},
    {"Ville":"Fernana","Cite":"Jentoura","CodePostal":"8141"},
    {"Ville":"Oued Mliz","Cite":"Edkhailia","CodePostal":"8185"},
    {"Ville":"Oued Mliz","Cite":"Oued Mliz","CodePostal":"8115"},
    {"Ville":"Fernana","Cite":"Ain Charchara","CodePostal":"8142"},
    {"Ville":"Jendouba Nord","Cite":"Cite El Ouns","CodePostal":"8189"},
    {"Ville":"Balta Bou Aouene","Cite":"Bou Aouene","CodePostal":"8116"},
    {"Ville":"Fernana","Cite":"Ain El Beya","CodePostal":"8142"},
    {"Ville":"Jendouba Nord","Cite":"Cite Ezzahoua","CodePostal":"8189"},
    {"Ville":"Balta Bou Aouene","Cite":"Cite Jebil","CodePostal":"8116"},
    {"Ville":"Fernana","Cite":"Bou Halleb","CodePostal":"8142"},
    {"Ville":"Jendouba Nord","Cite":"Cite Hedi Ben Hassine","CodePostal":"8189"},
    {"Ville":"Balta Bou Aouene","Cite":"Ouled Hassen","CodePostal":"8116"},
    {"Ville":"Fernana","Cite":"Cite El Marja","CodePostal":"8142"},
    {"Ville":"Jendouba Nord","Cite":"Jendouba Nord","CodePostal":"8189"},
    {"Ville":"Jendouba Nord","Cite":"Bellarigia","CodePostal":"8117"},
    {"Ville":"Fernana","Cite":"Jouaouda","CodePostal":"8142"},
    {"Ville":"Tabarka","Cite":"El Haouamdia","CodePostal":"8192"},
    {"Ville":"Ain Draham","Cite":"Babouch","CodePostal":"8121"},
    {"Ville":"Bou Salem","Cite":"Badrouna","CodePostal":"8143"},
    {"Ville":"Oued Mliz","Cite":"Sidi Meskine","CodePostal":"8193"},
    {"Ville":"Jendouba","Cite":"Cite Cnrps","CodePostal":"8122"},
    {"Ville":"Fernana","Cite":"Hedhil","CodePostal":"8145"},
    {"Ville":"Oued Mliz","Cite":"Hakim","CodePostal":"8194"},
    {"Ville":"Jendouba","Cite":"Essanabel","CodePostal":"8122"},
    {"Ville":"Jendouba","Cite":"El Azima","CodePostal":"8153"},
    {"Ville":"Jendouba","Cite":"Essaada","CodePostal":"8195"},
    {"Ville":"Bou Salem","Cite":"El Marja","CodePostal":"8124"},
    {"Ville":"Kairouan Nord","Cite":"Cite Ichbilia","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite El Moez","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"Eddikhila","CodePostal":"3110"},
    {"Ville":"Kairouan Nord","Cite":"Cite Okba","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite Ennasr","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"El Friouette","CodePostal":"3110"},
    {"Ville":"Kairouan Nord","Cite":"Cite Ouled Manaa","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite Ennour","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"El Gatifa","CodePostal":"3110"},
    {"Ville":"Kairouan Nord","Cite":"Cite Sahbi 3","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite Essaada","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"El Gfey","CodePostal":"3110"},
    {"Ville":"Sbikha","Cite":"El Khodher","CodePostal":"3110"},
    {"Ville":"Kairouan Nord","Cite":"Cite Sahbi 4","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite Ezzouhour","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"Oued Nebhana","CodePostal":"3110"},
    {"Ville":"Kairouan Nord","Cite":"Cite Sahbi 5","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite Nouvelle","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"Sbikha","CodePostal":"3110"},
    {"Ville":"Kairouan Nord","Cite":"Cite Sahnoun","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite Okba","CodePostal":"3180"},
    {"Ville":"Kairouan Nord","Cite":"El Moutbasta","CodePostal":"3111"},
    {"Ville":"Kairouan Nord","Cite":"Cite Snit","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"El Fateh","CodePostal":"3180"},
    {"Ville":"Chebika","Cite":"Ben Salem","CodePostal":"3112"},
    {"Ville":"Kairouan Nord","Cite":"Dhraa Ettammar","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Ennasr","CodePostal":"3180"},
    {"Ville":"Chebika","Cite":"Gragaya","CodePostal":"3112"},
    {"Ville":"Kairouan Nord","Cite":"Kairouan Okba","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Ouled Achour","CodePostal":"3180"},
    {"Ville":"Chebika","Cite":"Hammed","CodePostal":"3112"},
    {"Ville":"Kairouan Nord","Cite":"Sidi Dhameni","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Bir Eddaoula","CodePostal":"3181"},
    {"Ville":"Oueslatia","Cite":"Ain Jelloula","CodePostal":"3113"},
    {"Ville":"Kairouan Nord","Cite":"El Baten","CodePostal":"3142"},
    {"Ville":"Kairouan Nord","Cite":"Cite Ennasr","CodePostal":"3182"},
    {"Ville":"Nasrallah","Cite":"El Hamidette","CodePostal":"3114"},
    {"Ville":"Kairouan Sud","Cite":"Ain El Khazzazia","CodePostal":"3143"},
    {"Ville":"Chebika","Cite":"Abida Cherguia","CodePostal":"3183"},
    {"Ville":"Nasrallah","Cite":"Menzel Mhiri","CodePostal":"3114"},
    {"Ville":"Cherarda","Cite":"Ouled Farjallah","CodePostal":"3145"},
    {"Ville":"Kairouan Sud","Cite":"Raccada","CodePostal":"3191"},
    {"Ville":"Nasrallah","Cite":"Touila","CodePostal":"3114"},
    {"Ville":"El Ala","Cite":"Ain Sayada","CodePostal":"3150"},
    {"Ville":"Kairouan Sud","Cite":"Argoub El Khadhra","CodePostal":"3192"},
    {"Ville":"Nasrallah","Cite":"Sidi Saad","CodePostal":"3115"},
    {"Ville":"El Ala","Cite":"Cite Essanaouber","CodePostal":"3150"},
    {"Ville":"Kairouan Sud","Cite":"El Khadhra","CodePostal":"3192"},
    {"Ville":"Cherarda","Cite":"Cheraitia","CodePostal":"3116"},
    {"Ville":"El Ala","Cite":"Cite Nouvelle","CodePostal":"3150"},
    {"Ville":"Kairouan Nord","Cite":"El Ghabette","CodePostal":"3193"},
    {"Ville":"Cherarda","Cite":"Cherarda","CodePostal":"3116"},
    {"Ville":"El Ala","Cite":"Cite Ouled Ahmed","CodePostal":"3150"},
    {"Ville":"Sbikha","Cite":"Dhriaat","CodePostal":"3194"},
    {"Ville":"Cherarda","Cite":"Cite El  Mached","CodePostal":"3116"},
    {"Ville":"El Ala","Cite":"Cite Populaire","CodePostal":"3150"},
    {"Ville":"Sbikha","Cite":"Sidi Messaoud","CodePostal":"3195"},
    {"Ville":"Cherarda","Cite":"Cite El Koucha","CodePostal":"3116"},
    {"Ville":"El Ala","Cite":"Cite Rezagnia","CodePostal":"3150"},
    {"Ville":"Sbikha","Cite":"Hendi Zitouna","CodePostal":"3196"},
    {"Ville":"Cherarda","Cite":"El Aouyed","CodePostal":"3116"},
    {"Ville":"El Ala","Cite":"Cite Rurale","CodePostal":"3150"},
    {"Ville":"Kairouan Sud","Cite":"Cite Tabene","CodePostal":"3198"},
    {"Ville":"Cherarda","Cite":"El Ksour","CodePostal":"3116"},
    {"Ville":"El Ala","Cite":"Ecole Ettoual","CodePostal":"3150"},
    {"Ville":"Kairouan Sud","Cite":"El Borji","CodePostal":"3198"},
    {"Ville":"Oueslatia","Cite":"Ain Mastour","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Ecole Jouamia","CodePostal":"3150"},
    {"Ville":"Kairouan Sud","Cite":"Cite Ibn El Jazzar","CodePostal":"3199"},
    {"Ville":"Oueslatia","Cite":"Cite Bou Assida","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Ecole Trazza","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Cite Chebbi","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"El Ala","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Cite Commerciale","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"El Fjejria","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Cite Des Ouvriers","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Ghidhifette","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Cite Du Stade","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Oued El Jabbes","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Cite Ejjamaa","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Ouled Ali","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Cite Essanaouber","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Ouled Ennaguez","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Cite Ettahrir","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Ouled Mehrez","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Oued El Guessab","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Sayada Nord","CodePostal":"3150"},
    {"Ville":"Oueslatia","Cite":"Oueslatia","CodePostal":"3120"},
    {"Ville":"El Ala","Cite":"Sayada Sud","CodePostal":"3150"},
    {"Ville":"Chebika","Cite":"Chebika","CodePostal":"3121"},
    {"Ville":"Kairouan Sud","Cite":"Zaafrana","CodePostal":"3151"},
    {"Ville":"Chebika","Cite":"Cite Sabrine","CodePostal":"3121"},
    {"Ville":"Bou Hajla","Cite":"Bir Ahmed","CodePostal":"3152"},
    {"Ville":"Chebika","Cite":"Rouissette","CodePostal":"3121"},
    {"Ville":"El Ala","Cite":"El Msaid","CodePostal":"3153"},
    {"Ville":"Chebika","Cite":"Sidi Abdallah  Belhaj","CodePostal":"3121"},
    {"Ville":"El Ala","Cite":"Messiouta","CodePostal":"3154"},
    {"Ville":"Oueslatia","Cite":"Ksar Lamsa","CodePostal":"3124"},
    {"Ville":"Hajeb El Ayoun","Cite":"Ain Majouna","CodePostal":"3160"},
    {"Ville":"Sbikha","Cite":"El Alem","CodePostal":"3125"},
    {"Ville":"Hajeb El Ayoun","Cite":"Cite 7 Novembre","CodePostal":"3160"},
    {"Ville":"Bou Hajla","Cite":"Jehina","CodePostal":"3126"},
    {"Ville":"Hajeb El Ayoun","Cite":"Cite Ennasr","CodePostal":"3160"},
    {"Ville":"Kairouan Nord","Cite":"Cite El Hajjam","CodePostal":"3129"},
    {"Ville":"Hajeb El Ayoun","Cite":"Cite Ennouhoudh","CodePostal":"3160"},
    {"Ville":"Haffouz","Cite":"Ain El Ghrab","CodePostal":"3130"},
    {"Ville":"Hajeb El Ayoun","Cite":"Cite Errouki","CodePostal":"3160"},
    {"Ville":"Haffouz","Cite":"Cherichira","CodePostal":"3130"},
    {"Ville":"Hajeb El Ayoun","Cite":"Cite Essaada","CodePostal":"3160"},
    {"Ville":"Haffouz","Cite":"Cite Le Mourouj","CodePostal":"3130"},
    {"Ville":"Hajeb El Ayoun","Cite":"Cite Ezzayatine","CodePostal":"3160"},
    {"Ville":"Haffouz","Cite":"El Alia","CodePostal":"3130"},
    {"Ville":"Hajeb El Ayoun","Cite":"Cite Ezzouhour","CodePostal":"3160"},
    {"Ville":"Haffouz","Cite":"El Aoudid","CodePostal":"3130"},
    {"Ville":"Hajeb El Ayoun","Cite":"Cite Independance","CodePostal":"3160"},
    {"Ville":"Haffouz","Cite":"Ezzorg","CodePostal":"3130"},
    {"Ville":"Hajeb El Ayoun","Cite":"El Ghouiba","CodePostal":"3160"},
    {"Ville":"Haffouz","Cite":"Haffouz","CodePostal":"3130"},
    {"Ville":"Hajeb El Ayoun","Cite":"El Manar","CodePostal":"3160"},
    {"Ville":"Kairouan Sud","Cite":"Kairouan Sud","CodePostal":"3131"},
    {"Ville":"Hajeb El Ayoun","Cite":"El Menassa","CodePostal":"3160"},
    {"Ville":"Sbikha","Cite":"Dar Ben Aicha","CodePostal":"3132"},
    {"Ville":"Hajeb El Ayoun","Cite":"Hajeb El Ayoun","CodePostal":"3160"},
    {"Ville":"Sbikha","Cite":"Dar Jamiya","CodePostal":"3132"},
    {"Ville":"Hajeb El Ayoun","Cite":"Hajeb El Ayoun Gare","CodePostal":"3160"},
    {"Ville":"Sbikha","Cite":"El Aouitha","CodePostal":"3132"},
    {"Ville":"Hajeb El Ayoun","Cite":"Ouled Abbes","CodePostal":"3160"},
    {"Ville":"Sbikha","Cite":"Sisseb","CodePostal":"3132"},
    {"Ville":"Hajeb El Ayoun","Cite":"Ouled Ameur","CodePostal":"3160"},
    {"Ville":"Chebika","Cite":"Ain El Beydha","CodePostal":"3133"},
    {"Ville":"Hajeb El Ayoun","Cite":"Thallaja","CodePostal":"3160"},
    {"Ville":"Kairouan Sud","Cite":"Cite El Moez","CodePostal":"3100"},
    {"Ville":"Chebika","Cite":"El Karma","CodePostal":"3133"},
    {"Ville":"Chebika","Cite":"El Aouamria","CodePostal":"3161"},
    {"Ville":"Kairouan Sud","Cite":"Cite Militaire Ancien","CodePostal":"3100"},
    {"Ville":"Chebika","Cite":"Ouled Khalfallah","CodePostal":"3133"},
    {"Ville":"Nasrallah","Cite":"El Ajabna","CodePostal":"3170"},
    {"Ville":"Kairouan Sud","Cite":"Cite Rim","CodePostal":"3100"},
    {"Ville":"Chebika","Cite":"Ouled Khelif","CodePostal":"3133"},
    {"Ville":"Nasrallah","Cite":"El Ksour","CodePostal":"3170"},
    {"Ville":"Kairouan Sud","Cite":"Cite Sabri","CodePostal":"3100"},
    {"Ville":"Chebika","Cite":"Ouled Nasser","CodePostal":"3133"},
    {"Ville":"Nasrallah","Cite":"Nasrallah","CodePostal":"3170"},
    {"Ville":"Kairouan Sud","Cite":"El Argoub Lahrech","CodePostal":"3100"},
    {"Ville":"Haffouz","Cite":"Khit El Oued","CodePostal":"3134"},
    {"Ville":"Nasrallah","Cite":"El Kabbara","CodePostal":"3171"},
    {"Ville":"Kairouan Sud","Cite":"El Makhsouma","CodePostal":"3100"},
    {"Ville":"Sbikha","Cite":"Ain Boumourra","CodePostal":"3135"},
    {"Ville":"Nasrallah","Cite":"Erragouba El Hamra","CodePostal":"3173"},
    {"Ville":"Kairouan Sud","Cite":"El Mrazig","CodePostal":"3100"},
    {"Ville":"Kairouan Nord","Cite":"Cite Dar El Amen","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Bir Lahjar","CodePostal":"3180"},
    {"Ville":"Kairouan Sud","Cite":"Kairouan","CodePostal":"3100"},
    {"Ville":"Kairouan Nord","Cite":"Cite El Baloui 1","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Bou Hajla","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"Chorfa","CodePostal":"3110"},
    {"Ville":"Kairouan Nord","Cite":"Cite El Baloui 2","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite 7 Novembre","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"Chougafia","CodePostal":"3110"},
    {"Ville":"Kairouan Nord","Cite":"Cite El Baloui 3","CodePostal":"3140"},
    {"Ville":"Bou Hajla","Cite":"Cite De La Republique","CodePostal":"3180"},
    {"Ville":"Sbikha","Cite":"Daloussi","CodePostal":"3110"},
    {"Ville":"Foussana","Cite":"Essatour","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"El Gounna","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Foussana","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"El Hammar","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Ouled Boughanem","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Mghila","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Ouled Mansour","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Sbeitla","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Ouled Zid","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Echraya","CodePostal":"1251"},
    {"Ville":"Foussana","Cite":"Oum Lahouadh","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Errahayet","CodePostal":"1252"},
    {"Ville":"Haidra","Cite":"Cite El Bassatine","CodePostal":"1221"},
    {"Ville":"Kasserine Nord","Cite":"Bou Laaba","CodePostal":"1253"},
    {"Ville":"Haidra","Cite":"Cite El Fateh","CodePostal":"1221"},
    {"Ville":"Sbeitla","Cite":"Zaouiet Ben Ammar","CodePostal":"1254"},
    {"Ville":"Haidra","Cite":"Cite El Intilaka","CodePostal":"1221"},
    {"Ville":"Sbeitla","Cite":"Oued Miou","CodePostal":"1255"},
    {"Ville":"Haidra","Cite":"Cite Ennasr","CodePostal":"1221"},
    {"Ville":"Sbeitla","Cite":"Henchir El Assal","CodePostal":"1256"},
    {"Ville":"Haidra","Cite":"Cite Ettadhamen","CodePostal":"1221"},
    {"Ville":"Thala","Cite":"Thala Sud","CodePostal":"1261"},
    {"Ville":"Haidra","Cite":"Cite Ibn Charef","CodePostal":"1221"},
    {"Ville":"Sbeitla","Cite":"Cite Essourour","CodePostal":"1263"},
    {"Ville":"Haidra","Cite":"Cite Ouled Ounallah","CodePostal":"1221"},
    {"Ville":"Sbiba","Cite":"Cite Air Nouvelle","CodePostal":"1270"},
    {"Ville":"Haidra","Cite":"El Mrira","CodePostal":"1221"},
    {"Ville":"Sbiba","Cite":"Cite Des Rouines Romaines","CodePostal":"1270"},
    {"Ville":"Haidra","Cite":"Errmila","CodePostal":"1221"},
    {"Ville":"Sbiba","Cite":"Cite Du Lycee","CodePostal":"1270"},
    {"Ville":"Haidra","Cite":"Essri","CodePostal":"1221"},
    {"Ville":"Sbiba","Cite":"Cite Eddamous","CodePostal":"1270"},
    {"Ville":"Haidra","Cite":"Gargara","CodePostal":"1221"},
    {"Ville":"Sbiba","Cite":"Cite El Fateh","CodePostal":"1270"},
    {"Ville":"Haidra","Cite":"Haidra","CodePostal":"1221"},
    {"Ville":"Sbiba","Cite":"Cite El Khadhra 1","CodePostal":"1270"},
    {"Ville":"Haidra","Cite":"Tebaga","CodePostal":"1221"},
    {"Ville":"Sbiba","Cite":"Cite El Khadhra 2","CodePostal":"1270"},
    {"Ville":"Kasserine Nord","Cite":"Doughra","CodePostal":"1200"},
    {"Ville":"Foussana","Cite":"Sahraoui","CodePostal":"1222"},
    {"Ville":"Sbiba","Cite":"Cite Ennakhla","CodePostal":"1270"},
    {"Ville":"Kasserine Nord","Cite":"Kasserine","CodePostal":"1200"},
    {"Ville":"Feriana","Cite":"Cekhirate","CodePostal":"1223"},
    {"Ville":"Sbiba","Cite":"Cite Erriadh","CodePostal":"1270"},
    {"Ville":"Kasserine Nord","Cite":"Magdoudech","CodePostal":"1200"},
    {"Ville":"Thala","Cite":"Eddachra","CodePostal":"1224"},
    {"Ville":"Sbiba","Cite":"Cite Essalama","CodePostal":"1270"},
    {"Ville":"Thala","Cite":"Chafai","CodePostal":"1210"},
    {"Ville":"Haidra","Cite":"El Ajred","CodePostal":"1225"},
    {"Ville":"Sbiba","Cite":"Cite Ezzouhour","CodePostal":"1270"},
    {"Ville":"Thala","Cite":"Cite Ain Ahmed","CodePostal":"1210"},
    {"Ville":"Mejel Bel Abbes","Cite":"Barrouka","CodePostal":"1226"},
    {"Ville":"Sbiba","Cite":"Dhraa","CodePostal":"1270"},
    {"Ville":"Thala","Cite":"Cite Bain Maure Ben Azouz","CodePostal":"1210"},
    {"Ville":"Kasserine Nord","Cite":"Kasserine Nour","CodePostal":"1230"},
    {"Ville":"Sbiba","Cite":"El Hasnaoui","CodePostal":"1270"},
    {"Ville":"Thala","Cite":"Cite Ben Cherif","CodePostal":"1210"},
    {"Ville":"Thala","Cite":"Sidi Shil","CodePostal":"1231"},
    {"Ville":"Sbiba","Cite":"Kounbitra","CodePostal":"1270"},
    {"Ville":"Thala","Cite":"Cite Des Ouvriers","CodePostal":"1210"},
    {"Ville":"Foussana","Cite":"Bou Deryes","CodePostal":"1232"},
    {"Ville":"Sbiba","Cite":"Ouljet Aguil","CodePostal":"1270"},
    {"Ville":"Thala","Cite":"Cite Du Battoire","CodePostal":"1210"},
    {"Ville":"Kasserine Sud","Cite":"Bou Zguem","CodePostal":"1233"},
    {"Ville":"Sbiba","Cite":"Sbiba","CodePostal":"1270"},
    {"Ville":"Thala","Cite":"Cite El Borni","CodePostal":"1210"},
    {"Ville":"El Ayoun","Cite":"El Grine","CodePostal":"1234"},
    {"Ville":"Sbiba","Cite":"Themed","CodePostal":"1270"},
    {"Ville":"Thala","Cite":"Cite El Mhiri","CodePostal":"1210"},
    {"Ville":"Thala","Cite":"Zelfane","CodePostal":"1235"},
    {"Ville":"Sbiba","Cite":"Ain Khemaissia","CodePostal":"1271"},
    {"Ville":"Thala","Cite":"Cite Ennadhour","CodePostal":"1210"},
    {"Ville":"Kasserine Nord","Cite":"Cite Ouvriere","CodePostal":"1237"},
    {"Ville":"Sbiba","Cite":"Sidi Brahim Ezzaher","CodePostal":"1273"},
    {"Ville":"Thala","Cite":"Cite Errahba","CodePostal":"1210"},
    {"Ville":"Feriana","Cite":"Cite Cheab","CodePostal":"1240"},
    {"Ville":"Ezzouhour","Cite":"Kassernie Ezzouhour","CodePostal":"1279"},
    {"Ville":"Thala","Cite":"Cite Garde Nationale","CodePostal":"1210"},
    {"Ville":"Feriana","Cite":"Cite Du Battoire","CodePostal":"1240"},
    {"Ville":"Jediliane","Cite":"Ain Oum Jdour","CodePostal":"1280"},
    {"Ville":"Thala","Cite":"Cite Khazna Jedida","CodePostal":"1210"},
    {"Ville":"Feriana","Cite":"Cite El Izdihar","CodePostal":"1240"},
    {"Ville":"Jediliane","Cite":"Cite 7 Novembre","CodePostal":"1280"},
    {"Ville":"Thala","Cite":"Cite Mongi Slim","CodePostal":"1210"},
    {"Ville":"Feriana","Cite":"Cite Hached","CodePostal":"1240"},
    {"Ville":"Jediliane","Cite":"Cite El Intilaka","CodePostal":"1280"},
    {"Ville":"Thala","Cite":"Cite Snit","CodePostal":"1210"},
    {"Ville":"Feriana","Cite":"Cite Hardoub","CodePostal":"1240"},
    {"Ville":"Jediliane","Cite":"Cite Ettaamir","CodePostal":"1280"},
    {"Ville":"Thala","Cite":"Henchir Goumria","CodePostal":"1210"},
    {"Ville":"Feriana","Cite":"Cite Pierre Noire","CodePostal":"1240"},
    {"Ville":"Jediliane","Cite":"Cite Ezzouhour","CodePostal":"1280"},
    {"Ville":"Thala","Cite":"Ouled Ghida","CodePostal":"1210"},
    {"Ville":"Feriana","Cite":"Feriana","CodePostal":"1240"},
    {"Ville":"Jediliane","Cite":"Frej Terbah","CodePostal":"1280"},
    {"Ville":"Thala","Cite":"Thala","CodePostal":"1210"},
    {"Ville":"Feriana","Cite":"Oum Ali","CodePostal":"1240"},
    {"Ville":"Jediliane","Cite":"Jediliane","CodePostal":"1280"},
    {"Ville":"Thala","Cite":"Bou Lahnech","CodePostal":"1211"},
    {"Ville":"Hassi El Frid","Cite":"Hassi El Frid","CodePostal":"1241"},
    {"Ville":"Jediliane","Cite":"Ain El Hmadna","CodePostal":"1281"},
    {"Ville":"Foussana","Cite":"Khemouda","CodePostal":"1212"},
    {"Ville":"Feriana","Cite":"Lahouache","CodePostal":"1242"},
    {"Ville":"Haidra","Cite":"Ain Eddefla","CodePostal":"1285"},
    {"Ville":"Feriana","Cite":"Bou Chebka","CodePostal":"1213"},
    {"Ville":"Feriana","Cite":"Garaat Ennaam","CodePostal":"1243"},
    {"Ville":"Mejel Bel Abbes","Cite":"Garaat Ennadhour","CodePostal":"1293"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite Commerciale","CodePostal":"1214"},
    {"Ville":"Hassi El Frid","Cite":"El Kamour","CodePostal":"1245"},
    {"Ville":"Mejel Bel Abbes","Cite":"El Fekka","CodePostal":"1294"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite Des Martyrs","CodePostal":"1214"},
    {"Ville":"Hassi El Frid","Cite":"Khanguet El Jazia","CodePostal":"1247"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite Du Lycee","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite Des Enseignants","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite Eddachra 1","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite Dhraa Bou Ouaj","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite Eddachra 2","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite Du Lycee","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite El Guerayria","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite Du Stade","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite Nouvelle 1","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite El Fateh","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite Nouvelle 2","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite El Ghabette","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Cite Populaire","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite El Khadhra","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Henchir Oum El Khir","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite El Oulja","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Mejel Bel Abbes","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite Errahba","CodePostal":"1250"},
    {"Ville":"Mejel Bel Abbes","Cite":"Ouled Marzoug","CodePostal":"1214"},
    {"Ville":"Sbeitla","Cite":"Cite Essourour Echergui","CodePostal":"1250"},
    {"Ville":"Feriana","Cite":"Thelepte","CodePostal":"1215"},
    {"Ville":"Sbeitla","Cite":"Cite Essourour El Gharbi","CodePostal":"1250"},
    {"Ville":"El Ayoun","Cite":"El Ayoun","CodePostal":"1216"},
    {"Ville":"Sbeitla","Cite":"Cite Ezzayatine","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Ain Janan","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Cite Hopital","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Cite Bou Lila","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Cite Huillerie","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Cite El Bassatine","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Cite Industrielle","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Cite El Fateh","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Cite Militaire 1","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Cite El Intilaka","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Cite Militaire 2","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"Cite Erriadh","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"Cite Militaire 3","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"El Adhira","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"El Garaa","CodePostal":"1250"},
    {"Ville":"Foussana","Cite":"El Brika","CodePostal":"1220"},
    {"Ville":"Sbeitla","Cite":"El Gharadek","CodePostal":"1250"},
    {"Ville":"Douz","Cite":"Ghelissia","CodePostal":"4260"},
    {"Ville":"Douz","Cite":"Ksar Ghilene","CodePostal":"4260"},
    {"Ville":"Douz","Cite":"Zaafrane","CodePostal":"4261"},
    {"Ville":"Kebili Sud","Cite":"Jersine","CodePostal":"4263"},
    {"Ville":"Kebili Sud","Cite":"Beni Mhamed","CodePostal":"4200"},
    {"Ville":"El Faouar","Cite":"Cite El Houda","CodePostal":"4264"},
    {"Ville":"Kebili Sud","Cite":"Cite Afh","CodePostal":"4200"},
    {"Ville":"El Faouar","Cite":"Cite El Moustakbel","CodePostal":"4264"},
    {"Ville":"Kebili Sud","Cite":"Cite Du Lycee","CodePostal":"4200"},
    {"Ville":"El Faouar","Cite":"Cite Ennour","CodePostal":"4264"},
    {"Ville":"Kebili Sud","Cite":"Cite Militaire","CodePostal":"4200"},
    {"Ville":"El Faouar","Cite":"Cite Essalem","CodePostal":"4264"},
    {"Ville":"Kebili Sud","Cite":"El Gataya","CodePostal":"4200"},
    {"Ville":"El Faouar","Cite":"El Faouar","CodePostal":"4264"},
    {"Ville":"Kebili Sud","Cite":"El Msaid","CodePostal":"4200"},
    {"Ville":"El Faouar","Cite":"Gherib","CodePostal":"4264"},
    {"Ville":"Kebili Sud","Cite":"Kebili","CodePostal":"4200"},
    {"Ville":"El Faouar","Cite":"Ghidma","CodePostal":"4264"},
    {"Ville":"Kebili Sud","Cite":"Mazraa Neji","CodePostal":"4200"},
    {"Ville":"El Faouar","Cite":"Sabria","CodePostal":"4264"},
    {"Ville":"Kebili Sud","Cite":"Oum El Farth","CodePostal":"4200"},
    {"Ville":"Kebili Nord","Cite":"Staftimi","CodePostal":"4273"},
    {"Ville":"Kebili Sud","Cite":"Radhouane","CodePostal":"4200"},
    {"Ville":"Kebili Nord","Cite":"Limaguez","CodePostal":"4274"},
    {"Ville":"El Faouar","Cite":"Rejime Maatoug","CodePostal":"4210"},
    {"Ville":"Kebili Nord","Cite":"Kebili Beyez","CodePostal":"4280"},
    {"Ville":"Kebili Sud","Cite":"Rahmet","CodePostal":"4211"},
    {"Ville":"Souk El Ahad","Cite":"Chouchet Negga","CodePostal":"4283"},
    {"Ville":"Souk El Ahad","Cite":"Oum Somaa","CodePostal":"4212"},
    {"Ville":"Souk El Ahad","Cite":"Negga","CodePostal":"4283"},
    {"Ville":"Souk El Ahad","Cite":"Zaouiet El Anes","CodePostal":"4213"},
    {"Ville":"Kebili Nord","Cite":"El Mansoura","CodePostal":"4293"},
    {"Ville":"Kebili Sud","Cite":"Jemna","CodePostal":"4214"},
    {"Ville":"Kebili Nord","Cite":"Jedida","CodePostal":"4293"},
    {"Ville":"Douz","Cite":"Cite Douz Chargui","CodePostal":"4215"},
    {"Ville":"Kebili Nord","Cite":"Toura Mansoura","CodePostal":"4293"},
    {"Ville":"Douz","Cite":"Cite El Abadla","CodePostal":"4215"},
    {"Ville":"Kebili Nord","Cite":"Saidane","CodePostal":"4294"},
    {"Ville":"Douz","Cite":"Cite Populaire","CodePostal":"4215"},
    {"Ville":"Douz","Cite":"Douz Chargui","CodePostal":"4215"},
    {"Ville":"Douz","Cite":"Cite Ben Hamroun","CodePostal":"4216"},
    {"Ville":"Douz","Cite":"Cite El Athemna","CodePostal":"4216"},
    {"Ville":"Douz","Cite":"Cite Ouled Abdallah","CodePostal":"4216"},
    {"Ville":"Douz","Cite":"Cite Ouled Amor","CodePostal":"4216"},
    {"Ville":"Douz","Cite":"Douz Aouina","CodePostal":"4216"},
    {"Ville":"Douz","Cite":"El Aouina","CodePostal":"4216"},
    {"Ville":"Douz","Cite":"Bechni","CodePostal":"4222"},
    {"Ville":"Douz","Cite":"Eddorgine","CodePostal":"4222"},
    {"Ville":"Douz","Cite":"Nouaiel","CodePostal":"4222"},
    {"Ville":"Souk El Ahad","Cite":"Fatnassa","CodePostal":"4223"},
    {"Ville":"Kebili Sud","Cite":"Bazma","CodePostal":"4224"},
    {"Ville":"Souk El Ahad","Cite":"Gliaa","CodePostal":"4230"},
    {"Ville":"Souk El Ahad","Cite":"Jazira","CodePostal":"4230"},
    {"Ville":"Souk El Ahad","Cite":"Jazira El Baiida","CodePostal":"4230"},
    {"Ville":"Souk El Ahad","Cite":"Menchia","CodePostal":"4230"},
    {"Ville":"Souk El Ahad","Cite":"Souk El Ahad","CodePostal":"4230"},
    {"Ville":"Souk El Ahad","Cite":"Zaouiet El Harth","CodePostal":"4230"},
    {"Ville":"Souk El Ahad","Cite":"Bechri","CodePostal":"4231"},
    {"Ville":"Kebili Nord","Cite":"Tenbib","CodePostal":"4232"},
    {"Ville":"Kebili Nord","Cite":"Rabta","CodePostal":"4233"},
    {"Ville":"Douz","Cite":"Cite 7 Novembre","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Cite Commerciale","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Cite Des Oasis","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Cite El Izdihar","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Cite El Ouroud","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Cite Ennour","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Cite Essaada","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Cite Ezzouhour","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Cite Ibn Khaldoun","CodePostal":"4234"},
    {"Ville":"Douz","Cite":"Golaa","CodePostal":"4234"},
    {"Ville":"Kebili Nord","Cite":"Tombar","CodePostal":"4235"},
    {"Ville":"Souk El Ahad","Cite":"Bou Abdallah","CodePostal":"4236"},
    {"Ville":"Kebili Nord","Cite":"Telmine","CodePostal":"4237"},
    {"Ville":"Kebili Sud","Cite":"Janaoura","CodePostal":"4242"},
    {"Ville":"Kebili Sud","Cite":"Blidette","CodePostal":"4243"},
    {"Ville":"Kebili Sud","Cite":"Barghouthia","CodePostal":"4253"},
    {"Ville":"Kebili Sud","Cite":"Bechelli","CodePostal":"4253"},
    {"Ville":"Kebili Sud","Cite":"Kelouemen","CodePostal":"4253"},
    {"Ville":"Douz","Cite":"Cite Jelaila","CodePostal":"4260"},
    {"Ville":"Douz","Cite":"Cite Ouled Oun","CodePostal":"4260"},
    {"Ville":"Douz","Cite":"Douz","CodePostal":"4260"},
    {"Ville":"Sers","Cite":"Cite El Bassatine","CodePostal":"7180"},
    {"Ville":"Nebeur","Cite":"Cite Hadj Ahmed","CodePostal":"7110"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite Ennour 1","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Garn El Halfaya","CodePostal":"7150"},
    {"Ville":"Sers","Cite":"Cite Ennacim","CodePostal":"7180"},
    {"Ville":"Nebeur","Cite":"Cite Nouvelle","CodePostal":"7110"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite Ennour 2","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"La Gare","CodePostal":"7150"},
    {"Ville":"Sers","Cite":"Cite Ennour","CodePostal":"7180"},
    {"Ville":"Nebeur","Cite":"Nebeur","CodePostal":"7110"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite Ettahrir","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Sidi Abdelbasset","CodePostal":"7150"},
    {"Ville":"Sers","Cite":"Cite Ennour 2","CodePostal":"7180"},
    {"Ville":"Nebeur","Cite":"Zone Industrielle","CodePostal":"7110"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite Moniments","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Tajerouine","CodePostal":"7150"},
    {"Ville":"Sers","Cite":"Cite Ennouzha","CodePostal":"7180"},
    {"Ville":"Nebeur","Cite":"Oued Mellegue","CodePostal":"7111"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite Sakmo","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Tajerouine Gare","CodePostal":"7150"},
    {"Ville":"Sers","Cite":"Cite Erriadh","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Bir Ben Cherifa","CodePostal":"7112"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite Sprols","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Menzel Salem","CodePostal":"7151"},
    {"Ville":"Sers","Cite":"Cite Essanaouber","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Cite El Izdihar","CodePostal":"7112"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Essakia","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Jazza","CodePostal":"7153"},
    {"Ville":"Sers","Cite":"Cite Ezzouhour","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Cite Ennour","CodePostal":"7112"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Farchen","CodePostal":"7120"},
    {"Ville":"El Ksour","Cite":"Ain El Fedhil","CodePostal":"7160"},
    {"Ville":"Sers","Cite":"El Argoub","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Cite Essaada","CodePostal":"7112"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Jeradou","CodePostal":"7120"},
    {"Ville":"El Ksour","Cite":"Ain Ksiba","CodePostal":"7160"},
    {"Ville":"Sers","Cite":"El Mellaha","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Gargour","CodePostal":"7112"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"La Mine","CodePostal":"7120"},
    {"Ville":"El Ksour","Cite":"Banou","CodePostal":"7160"},
    {"Ville":"Sers","Cite":"Elles","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Ladhieb","CodePostal":"7112"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Place De La Republique","CodePostal":"7120"},
    {"Ville":"El Ksour","Cite":"Cite Ali El Bahloul","CodePostal":"7160"},
    {"Ville":"Sers","Cite":"Labar","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Mellala","CodePostal":"7112"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Sidi Rabeh","CodePostal":"7120"},
    {"Ville":"El Ksour","Cite":"Cite Attouche","CodePostal":"7160"},
    {"Ville":"Sers","Cite":"Sers","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Ouljet Essedra","CodePostal":"7112"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Tabia","CodePostal":"7120"},
    {"Ville":"El Ksour","Cite":"Cite Centre Ville","CodePostal":"7160"},
    {"Ville":"Sers","Cite":"Le Vieux Sers","CodePostal":"7180"},
    {"Ville":"Touiref","Cite":"Touiref","CodePostal":"7112"},
    {"Ville":"Le Kef Est","Cite":"Borj El Aifa","CodePostal":"7122"},
    {"Ville":"El Ksour","Cite":"Cite El Borj","CodePostal":"7160"},
    {"Ville":"Sers","Cite":"Lorbous","CodePostal":"7180"},
    {"Ville":"Kalaa El Khasba","Cite":"Cite El Mellessine","CodePostal":"7113"},
    {"Ville":"Kalaa El Khasba","Cite":"Sidi Ahmed Essalah","CodePostal":"7123"},
    {"Ville":"El Ksour","Cite":"Cite El Hella","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Barnoussa","CodePostal":"7100"},
    {"Ville":"Sers","Cite":"Lorbous Gare","CodePostal":"7180"},
    {"Ville":"Kalaa El Khasba","Cite":"Cite Errouka","CodePostal":"7113"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Ain El Karma","CodePostal":"7125"},
    {"Ville":"El Ksour","Cite":"Cite Essaada","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Bou Meftah","CodePostal":"7100"},
    {"Ville":"Sers","Cite":"Sidi Nasser","CodePostal":"7180"},
    {"Ville":"Kalaa El Khasba","Cite":"Cite Essouitir","CodePostal":"7113"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Bourguiba","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Cite Mohamed Ali","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite 1Er Mai","CodePostal":"7100"},
    {"Ville":"Sers","Cite":"Tricha","CodePostal":"7180"},
    {"Ville":"Kalaa El Khasba","Cite":"Kalaa El Khasba","CodePostal":"7113"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Du Jardin","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Cite Safia 1","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite 2 Mars","CodePostal":"7100"},
    {"Ville":"Le Kef Est","Cite":"Oued Souani","CodePostal":"7194"},
    {"Ville":"Jerissa","Cite":"Ain El Garsa","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Du Stade","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Cite Safia 2","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite 3 Aout","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Ahmed Tlili","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite El Bassatine","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Cite Sidi Ali Mahjoub","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite 7 Novembre","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Ali Ben Ghedhahom","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Ennacim","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Cite Sidi Mansour","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Ain Mnekh","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Ali Ben Khlifa","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Ennour","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Echaabna","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Ammar Ayari","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Bourguiba","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Essanabel","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Eddhila","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Bahri Barbouche","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Chahid El Aid 1","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Essanaouber Bou Arara","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"El Afset","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Bayedh","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Chahid El Aid 2","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Ezzouhour Bou Arara","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"El Hmarna","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Bir Thelj","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite De La Gare","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Mongi Slim","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"El Houilat","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Bourayou","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Des Ingenieurs","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Cite Yougharta","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"El Ksour","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Cherichi","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Des Instituteurs","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"El Felta","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Kirata","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite De La Gare","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Des Martyrs","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Errebiba","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Louata","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite De La Liberte","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Des Ouvriers","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Kalaat Sinane","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Ouled Bouraoui","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Des Enseignants","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Du Souk","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Safsaf","CodePostal":"7130"},
    {"Ville":"El Ksour","Cite":"Ouled Ghana","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite Eddyr","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Echahid Belgacem","CodePostal":"7114"},
    {"Ville":"Nebeur","Cite":"Sidi Khiar","CodePostal":"7131"},
    {"Ville":"El Ksour","Cite":"Ouled Zid","CodePostal":"7160"},
    {"Ville":"Le Kef Est","Cite":"Cite El Bassatine","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Echahid Sadok","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Mahjouba","CodePostal":"7132"},
    {"Ville":"Dahmani","Cite":"Abida","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite El Farah","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Errouki","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Sidi Mtir","CodePostal":"7133"},
    {"Ville":"Dahmani","Cite":"Adissi","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite El Hana","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Essaada","CodePostal":"7114"},
    {"Ville":"Le Kef Est","Cite":"Zaafrane","CodePostal":"7134"},
    {"Ville":"Dahmani","Cite":"Ain Meskhia","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite El Izdihar","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Essanaouber Ancienne","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Ain Sinan","CodePostal":"7135"},
    {"Ville":"Dahmani","Cite":"Charket Essaboun","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite El Ouns","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Essanaouber Nouvelle","CodePostal":"7114"},
    {"Ville":"Kalaat Sinane","Cite":"Bou Jabeur","CodePostal":"7136"},
    {"Ville":"Dahmani","Cite":"Cite 1 Mai 1","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Ennacim","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Essarouel","CodePostal":"7114"},
    {"Ville":"Nebeur","Cite":"Tell Ghouzlane","CodePostal":"7141"},
    {"Ville":"Dahmani","Cite":"Cite 1 Mai 2","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Ennouhoudh 1","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Ettahrir","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite 2 Mars","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite 2 Mars 1","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Ennouhoudh 2","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Ezzouhour","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite 7 Novembre","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite 2 Mars 2","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Erriadh","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Hached Nord","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Afh","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Ben Ammar 1","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Essaada 1","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Hached Sud","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Ain El Bar","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Ben Ammar 2","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Essaada 2","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Mohamed Ali","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Bourguiba 1","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Ben Hafdhallah","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Essakhra","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Mohamed Ali Nlle","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Bourguiba 2","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite El Azima","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Ezzitouna","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Sidi Yahya","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Bourguiba 3","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite El Intilak","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Fourati","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Cite Taieb Mhiri","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Ciok","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Ennasr","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Hached","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"El Gorraia","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite De La Palestine","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Ennour","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Harrouch","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"El Hayadra","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Des Martyrs","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Erriadh","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Ibn Abi Dhiaf","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Ennaiem","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Du Souk","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Essanabel","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Ibn Khaldoun","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Esbiaat","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite Du Stade","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Essanaouber 1","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Souani Laaneb 1","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Fej Ettamr","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite El Ain","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Essanaouber 2","CodePostal":"7170"},
    {"Ville":"Dahmani","Cite":"Cite Ettenmia","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Souani Laaneb 2","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Henchir Sarrat","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite El Bassatine","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Hopital","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Taieb Mhiri","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Jerissa","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite El Besma 1","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Ibn Khaldoun","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Cite Tounsi Arnous","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Jerissa Gare","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite El Besma 2","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Mohamed Ali","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Ennaima","CodePostal":"7100"},
    {"Ville":"Jerissa","Cite":"Oum El Kelil","CodePostal":"7114"},
    {"Ville":"Tajerouine","Cite":"Cite El Fouroussia","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Cite Sidi Dahmani","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Le Kef","CodePostal":"7100"},
    {"Ville":"El Ksour","Cite":"Ezzitouna","CodePostal":"7115"},
    {"Ville":"Tajerouine","Cite":"Cite Errahba","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Dahmani","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Oued Erraml","CodePostal":"7100"},
    {"Ville":"Le Kef Est","Cite":"Bahra","CodePostal":"7116"},
    {"Ville":"Tajerouine","Cite":"Cite Essakia","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Sidi Asker","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Oued Tessa","CodePostal":"7100"},
    {"Ville":"Le Kef Ouest","Cite":"Kef Ouest","CodePostal":"7117"},
    {"Ville":"Tajerouine","Cite":"Cite Essanaouber","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Sidi Baraket Nord","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Salah El Bahri","CodePostal":"7100"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite 8 Fevrier 1","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Cite Ezzitouna","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Sidi Baraket Sud","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Semana","CodePostal":"7100"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite 8 Fevrier 2","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Cite Habib Thameur","CodePostal":"7150"},
    {"Ville":"Dahmani","Cite":"Zouarine","CodePostal":"7170"},
    {"Ville":"Le Kef Est","Cite":"Sidi Mansour","CodePostal":"7100"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite 8 Fevrier 3","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Cite Hopital","CodePostal":"7150"},
    {"Ville":"Sers","Cite":"Bir Heddi","CodePostal":"7180"},
    {"Ville":"Le Kef Est","Cite":"Zone Industrielle","CodePostal":"7100"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite Des Ouvriers","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Cite Jebel","CodePostal":"7150"},
    {"Ville":"Sers","Cite":"Bou Sliaa","CodePostal":"7180"},
    {"Ville":"Nebeur","Cite":"Ain El Henchir","CodePostal":"7110"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite El Intilaka","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"Cite Taieb Mhiri","CodePostal":"7150"},
    {"Ville":"Sers","Cite":"Cite Du Pont","CodePostal":"7180"},
    {"Ville":"Nebeur","Cite":"Cite Ennasr","CodePostal":"7110"},
    {"Ville":"Sakiet Sidi Youssef","Cite":"Cite El Kerfeh","CodePostal":"7120"},
    {"Ville":"Tajerouine","Cite":"El Hodh","CodePostal":"7150"},
    {"Ville":"Souassi","Cite":"Cite Industrielle","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"Bou Slim","CodePostal":"5120"},
    {"Ville":"Souassi","Cite":"Cite Populaire","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"El Ajilette","CodePostal":"5120"},
    {"Ville":"Ouled Chamakh","Cite":"Essetoute","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"El Manaa","CodePostal":"5120"},
    {"Ville":"Souassi","Cite":"Ethouabtia","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"El Mharza Est","CodePostal":"5120"},
    {"Ville":"Souassi","Cite":"Ezzeirate","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"Ouled Bouzid","CodePostal":"5120"},
    {"Ville":"Souassi","Cite":"Gdarat","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"Ouled Chamakh","CodePostal":"5120"},
    {"Ville":"Souassi","Cite":"Jemiaat","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"Somra","CodePostal":"5120"},
    {"Ville":"Souassi","Cite":"Ouled Amor","CodePostal":"5140"},
    {"Ville":"Mahdia","Cite":"Cite Nouvelle","CodePostal":"5121"},
    {"Ville":"Souassi","Cite":"Ouled Bou Helal","CodePostal":"5140"},
    {"Ville":"Mahdia","Cite":"Cite Populaire","CodePostal":"5121"},
    {"Ville":"Souassi","Cite":"Ouled Khelifa","CodePostal":"5140"},
    {"Ville":"Mahdia","Cite":"Rejiche","CodePostal":"5121"},
    {"Ville":"Souassi","Cite":"Ouled Moulahoum","CodePostal":"5140"},
    {"Ville":"Hbira","Cite":"Menzel Hached","CodePostal":"5122"},
    {"Ville":"Souassi","Cite":"Ouled Moulahoum Sud","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"Chehimet","CodePostal":"5123"},
    {"Ville":"Souassi","Cite":"Rejibet","CodePostal":"5140"},
    {"Ville":"Ouled Chamakh","Cite":"Ouled Amor","CodePostal":"5123"},
    {"Ville":"Souassi","Cite":"Sidi Bou Helal","CodePostal":"5140"},
    {"Ville":"El Jem","Cite":"El Achaba","CodePostal":"5124"},
    {"Ville":"Souassi","Cite":"Sidi Naceur Nord","CodePostal":"5140"},
    {"Ville":"El Jem","Cite":"Tlelsa","CodePostal":"5124"},
    {"Ville":"Souassi","Cite":"Souassi","CodePostal":"5140"},
    {"Ville":"El Jem","Cite":"Touahra","CodePostal":"5124"},
    {"Ville":"Mahdia","Cite":"Chiba","CodePostal":"5141"},
    {"Ville":"Bou Merdes","Cite":"Bou Helal Sud","CodePostal":"5125"},
    {"Ville":"Souassi","Cite":"Jemamla","CodePostal":"5144"},
    {"Ville":"Mahdia","Cite":"Cite Afh","CodePostal":"5100"},
    {"Ville":"Ksour Essaf","Cite":"Cite Douira","CodePostal":"5126"},
    {"Ville":"Souassi","Cite":"El Mansoura Souassi","CodePostal":"5145"},
    {"Ville":"Mahdia","Cite":"Cite Bourguiba","CodePostal":"5100"},
    {"Ville":"Ksour Essaf","Cite":"Cite El Hajeb","CodePostal":"5126"},
    {"Ville":"Ksour Essaf","Cite":"Rechercha","CodePostal":"5146"},
    {"Ville":"Mahdia","Cite":"Cite El Moez","CodePostal":"5100"},
    {"Ville":"Ksour Essaf","Cite":"Cite El Imarat","CodePostal":"5126"},
    {"Ville":"Mahdia","Cite":"Mahdia Republique","CodePostal":"5150"},
    {"Ville":"Mahdia","Cite":"Cite Ettaamir","CodePostal":"5100"},
    {"Ville":"Ksour Essaf","Cite":"Cite El Menagua","CodePostal":"5126"},
    {"Ville":"Sidi Alouene","Cite":"El Braghtia","CodePostal":"5151"},
    {"Ville":"Mahdia","Cite":"Cite Hached","CodePostal":"5100"},
    {"Ville":"Ksour Essaf","Cite":"Cite Populaire","CodePostal":"5126"},
    {"Ville":"Sidi Alouene","Cite":"Zorda","CodePostal":"5151"},
    {"Ville":"Mahdia","Cite":"Cite Taher Sfar","CodePostal":"5100"},
    {"Ville":"Ksour Essaf","Cite":"Salakta","CodePostal":"5126"},
    {"Ville":"El Jem","Cite":"El Aitha","CodePostal":"5153"},
    {"Ville":"Mahdia","Cite":"Mahdia","CodePostal":"5100"},
    {"Ville":"Mahdia","Cite":"Essaad","CodePostal":"5127"},
    {"Ville":"El Jem","Cite":"Ouled El Haj","CodePostal":"5153"},
    {"Ville":"Bou Merdes","Cite":"Bou Merdes","CodePostal":"5110"},
    {"Ville":"Mahdia","Cite":"Mahdia Essouk","CodePostal":"5129"},
    {"Ville":"El Jem","Cite":"Riadh Bou Helal","CodePostal":"5153"},
    {"Ville":"Bou Merdes","Cite":"Chouaria","CodePostal":"5110"},
    {"Ville":"Chorbane","Cite":"Charaf","CodePostal":"5130"},
    {"Ville":"Hbira","Cite":"Meharza 18","CodePostal":"5154"},
    {"Ville":"Bou Merdes","Cite":"El Hous","CodePostal":"5110"},
    {"Ville":"Chorbane","Cite":"Chorbane","CodePostal":"5130"},
    {"Ville":"Souassi","Cite":"Ksesba","CodePostal":"5155"},
    {"Ville":"Bou Merdes","Cite":"Ennaima","CodePostal":"5110"},
    {"Ville":"Chorbane","Cite":"Cite Nouvelle","CodePostal":"5130"},
    {"Ville":"El Jem","Cite":"Beni Thabet","CodePostal":"5160"},
    {"Ville":"Bou Merdes","Cite":"Errouadhi","CodePostal":"5110"},
    {"Ville":"Chorbane","Cite":"El Bassora","CodePostal":"5130"},
    {"Ville":"El Jem","Cite":"Cite Ibn Sina","CodePostal":"5160"},
    {"Ville":"Bou Merdes","Cite":"Menzel Hamza Est","CodePostal":"5110"},
    {"Ville":"Chorbane","Cite":"El Gouassem","CodePostal":"5130"},
    {"Ville":"El Jem","Cite":"Cite Nouvelle","CodePostal":"5160"},
    {"Ville":"Bou Merdes","Cite":"Menzel Hamza Ouest","CodePostal":"5110"},
    {"Ville":"Chorbane","Cite":"El Gradha Est","CodePostal":"5130"},
    {"Ville":"El Jem","Cite":"El Jem","CodePostal":"5160"},
    {"Ville":"Bou Merdes","Cite":"Zerata","CodePostal":"5110"},
    {"Ville":"Chorbane","Cite":"El Gradha Ouest","CodePostal":"5130"},
    {"Ville":"El Jem","Cite":"El Mechelette","CodePostal":"5160"},
    {"Ville":"Mahdia","Cite":"Cite Des Infirmiers","CodePostal":"5111"},
    {"Ville":"Chorbane","Cite":"El Khiour","CodePostal":"5130"},
    {"Ville":"El Jem","Cite":"Ouled Farhat","CodePostal":"5160"},
    {"Ville":"Mahdia","Cite":"Cite Ennour","CodePostal":"5111"},
    {"Ville":"Chorbane","Cite":"Henchir Bouaziz","CodePostal":"5130"},
    {"Ville":"El Jem","Cite":"Sidi Bou Helal","CodePostal":"5160"},
    {"Ville":"Mahdia","Cite":"Cite Sidi Messaoud","CodePostal":"5111"},
    {"Ville":"Chorbane","Cite":"Kheradna","CodePostal":"5130"},
    {"Ville":"El Jem","Cite":"Zeghabna","CodePostal":"5160"},
    {"Ville":"Mahdia","Cite":"Eddikhila 3","CodePostal":"5111"},
    {"Ville":"Chorbane","Cite":"Maati Cheraf","CodePostal":"5130"},
    {"Ville":"La Chebba","Cite":"Cite De La Plage","CodePostal":"5170"},
    {"Ville":"Mahdia","Cite":"Mahdia Hiboun","CodePostal":"5111"},
    {"Ville":"Chorbane","Cite":"Maati Henchir Bou Aziz","CodePostal":"5130"},
    {"Ville":"La Chebba","Cite":"Ferahta","CodePostal":"5170"},
    {"Ville":"Bou Merdes","Cite":"Bou Helal El Ali Nord","CodePostal":"5112"},
    {"Ville":"Chorbane","Cite":"Ouled Abdennebi","CodePostal":"5130"},
    {"Ville":"La Chebba","Cite":"La Chebba","CodePostal":"5170"},
    {"Ville":"Bou Merdes","Cite":"Cite Administrative","CodePostal":"5112"},
    {"Ville":"Chorbane","Cite":"Ouled Ahmed","CodePostal":"5130"},
    {"Ville":"La Chebba","Cite":"Merkez Chaabna","CodePostal":"5170"},
    {"Ville":"Bou Merdes","Cite":"Cite Ghrissine","CodePostal":"5112"},
    {"Ville":"Chorbane","Cite":"Ouled Cherifa","CodePostal":"5130"},
    {"Ville":"Ksour Essaf","Cite":"Cite Commerciale","CodePostal":"5180"},
    {"Ville":"Bou Merdes","Cite":"Cite Jebel","CodePostal":"5112"},
    {"Ville":"Chorbane","Cite":"Ouled El Hannachi","CodePostal":"5130"},
    {"Ville":"Ksour Essaf","Cite":"Cite Dhamene","CodePostal":"5180"},
    {"Ville":"Bou Merdes","Cite":"Cite Rurale","CodePostal":"5112"},
    {"Ville":"Chorbane","Cite":"Ouled Sghaier","CodePostal":"5130"},
    {"Ville":"Ksour Essaf","Cite":"Cite El Bassatine","CodePostal":"5180"},
    {"Ville":"Bou Merdes","Cite":"Cite Snit","CodePostal":"5112"},
    {"Ville":"Mahdia","Cite":"El Hekaima","CodePostal":"5131"},
    {"Ville":"Ksour Essaf","Cite":"Cite El Fatimi","CodePostal":"5180"},
    {"Ville":"Bou Merdes","Cite":"El Aouadhbia","CodePostal":"5112"},
    {"Ville":"Mahdia","Cite":"Jouaouda","CodePostal":"5131"},
    {"Ville":"Ksour Essaf","Cite":"Cite El Malaji","CodePostal":"5180"},
    {"Ville":"Bou Merdes","Cite":"El Melahma","CodePostal":"5112"},
    {"Ville":"Mahdia","Cite":"Merkez Ouled Dhaouadi","CodePostal":"5131"},
    {"Ville":"Ksour Essaf","Cite":"Cite El Wafa","CodePostal":"5180"},
    {"Ville":"Bou Merdes","Cite":"Errebibette","CodePostal":"5112"},
    {"Ville":"Mahdia","Cite":"Zouaouine","CodePostal":"5131"},
    {"Ville":"Ksour Essaf","Cite":"Cite Ezzouhour","CodePostal":"5180"},
    {"Ville":"Bou Merdes","Cite":"Kerker","CodePostal":"5112"},
    {"Ville":"Sidi Alouene","Cite":"El Bassatine","CodePostal":"5132"},
    {"Ville":"Ksour Essaf","Cite":"Cite Salah Ben Ali","CodePostal":"5180"},
    {"Ville":"Hbira","Cite":"Agba","CodePostal":"5113"},
    {"Ville":"Sidi Alouene","Cite":"Essaada","CodePostal":"5132"},
    {"Ville":"Ksour Essaf","Cite":"Ksour Essaf","CodePostal":"5180"},
    {"Ville":"Hbira","Cite":"Chahda Ouest","CodePostal":"5113"},
    {"Ville":"Sidi Alouene","Cite":"Merkez Ouled Haj Khelifa","CodePostal":"5132"},
    {"Ville":"Ksour Essaf","Cite":"Ksour Essaf Hached","CodePostal":"5189"},
    {"Ville":"Hbira","Cite":"Chatt Ouled Ncib","CodePostal":"5113"},
    {"Ville":"Sidi Alouene","Cite":"Oued Guelat","CodePostal":"5132"},
    {"Ville":"Sidi Alouene","Cite":"Baajla","CodePostal":"5190"},
    {"Ville":"Hbira","Cite":"El Hajjara","CodePostal":"5113"},
    {"Ville":"Sidi Alouene","Cite":"Sakiet El Khadem","CodePostal":"5132"},
    {"Ville":"Sidi Alouene","Cite":"Cite Dar El Hadad","CodePostal":"5190"},
    {"Ville":"Hbira","Cite":"Hbira","CodePostal":"5113"},
    {"Ville":"Sidi Alouene","Cite":"Sidi Ali Ghedir","CodePostal":"5132"},
    {"Ville":"Sidi Alouene","Cite":"Sidi Alouene","CodePostal":"5190"},
    {"Ville":"Hbira","Cite":"Regaiga","CodePostal":"5113"},
    {"Ville":"Chorbane","Cite":"Chahda","CodePostal":"5133"},
    {"Ville":"Sidi Alouene","Cite":"Ennouzha","CodePostal":"5192"},
    {"Ville":"Melloulech","Cite":"Beni Tourch","CodePostal":"5114"},
    {"Ville":"Souassi","Cite":"Chaara","CodePostal":"5134"},
    {"Ville":"Sidi Alouene","Cite":"Ouled Aicha","CodePostal":"5192"},
    {"Ville":"Melloulech","Cite":"Melloulech","CodePostal":"5114"},
    {"Ville":"Souassi","Cite":"Sidi Zid","CodePostal":"5134"},
    {"Ville":"Sidi Alouene","Cite":"Zelba","CodePostal":"5192"},
    {"Ville":"Melloulech","Cite":"Ouled Abdallah","CodePostal":"5114"},
    {"Ville":"Chorbane","Cite":"Neffatia","CodePostal":"5135"},
    {"Ville":"Ksour Essaf","Cite":"El Ghedhabna","CodePostal":"5136"},
    {"Ville":"Sidi Alouene","Cite":"Zelba El Henchir","CodePostal":"5192"},
    {"Ville":"Melloulech","Cite":"Ouled Mabrouk","CodePostal":"5114"},
    {"Ville":"Ksour Essaf","Cite":"El Khmara","CodePostal":"5136"},
    {"Ville":"Sidi Alouene","Cite":"El Henichette","CodePostal":"5193"},
    {"Ville":"Ksour Essaf","Cite":"El Bradaa","CodePostal":"5115"},
    {"Ville":"Souassi","Cite":"Cite 9 Avril","CodePostal":"5140"},
    {"Ville":"Sidi Alouene","Cite":"El Mejria","CodePostal":"5193"},
    {"Ville":"Ksour Essaf","Cite":"El Hessinette","CodePostal":"5115"},
    {"Ville":"Souassi","Cite":"Cite Afh","CodePostal":"5140"},
    {"Ville":"Sidi Alouene","Cite":"Oued Beja","CodePostal":"5193"},
    {"Ville":"Ksour Essaf","Cite":"Ouled Salah","CodePostal":"5116"},
    {"Ville":"Souassi","Cite":"Cite Commerciale","CodePostal":"5140"},
    {"Ville":"Mahdia","Cite":"Cite Essaada","CodePostal":"5199"},
    {"Ville":"Ksour Essaf","Cite":"Sidi Assaker","CodePostal":"5116"},
    {"Ville":"Souassi","Cite":"Cite Ettaamir","CodePostal":"5140"},
    {"Ville":"Mahdia","Cite":"Cite Essalem","CodePostal":"5199"},
    {"Ville":"Melloulech","Cite":"El Houd","CodePostal":"5117"},
    {"Ville":"Souassi","Cite":"Cite Ezzouhour","CodePostal":"5140"},
    {"Ville":"Mahdia","Cite":"Mahdia Ezzahra","CodePostal":"5199"},
    {"Ville":"Melloulech","Cite":"El Mansoura","CodePostal":"5117"},
    {"Ville":"Souassi","Cite":"Cite Ibn Khaldoun","CodePostal":"5140"},
    {"Ville":"Melloulech","Cite":"Ouled Jaballah","CodePostal":"5117"},
    {"Ville":"Douar Hicher","Cite":"Douar Hicher","CodePostal":"2086"},
    {"Ville":"Jedaida","Cite":"Cite Ennour","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"Gueffaya","CodePostal":"1144"},
    {"Ville":"Jedaida","Cite":"Cite Erriadh","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"Sidi Abdelbasset","CodePostal":"1144"},
    {"Ville":"Jedaida","Cite":"Cite Essaroula","CodePostal":"1124"},
    {"Ville":"Jedaida","Cite":"Cite Guichba","CodePostal":"1124"},
    {"Ville":"Jedaida","Cite":"Cite Habib El Ayari","CodePostal":"1124"},
    {"Ville":"Jedaida","Cite":"Cite Hached","CodePostal":"1124"},
    {"Ville":"Jedaida","Cite":"Cite Hamdi","CodePostal":"1124"},
    {"Ville":"Mornaguia","Cite":"Bou Regba","CodePostal":"1110"},
    {"Ville":"Jedaida","Cite":"Cite Hammed Nouvelle","CodePostal":"1124"},
    {"Ville":"Mornaguia","Cite":"Cite Bir Ben Njima","CodePostal":"1110"},
    {"Ville":"Jedaida","Cite":"Cite Jebel","CodePostal":"1124"},
    {"Ville":"Mornaguia","Cite":"Cite De La Rtt 3","CodePostal":"1110"},
    {"Ville":"Jedaida","Cite":"Cite Mokhtar","CodePostal":"1124"},
    {"Ville":"Mornaguia","Cite":"Cite Toumia","CodePostal":"1110"},
    {"Ville":"Jedaida","Cite":"Cite Najiba","CodePostal":"1124"},
    {"Ville":"Mornaguia","Cite":"El Hamayem","CodePostal":"1110"},
    {"Ville":"Jedaida","Cite":"Cite Rurale","CodePostal":"1124"},
    {"Ville":"Mornaguia","Cite":"Ghedaouna","CodePostal":"1110"},
    {"Ville":"Jedaida","Cite":"Cite Sidi Salem","CodePostal":"1124"},
    {"Ville":"Mornaguia","Cite":"Mornaguia","CodePostal":"1110"},
    {"Ville":"Jedaida","Cite":"Cite Tarek Ibn Zied","CodePostal":"1124"},
    {"Ville":"Jedaida","Cite":"Cite Touhami Nefzi","CodePostal":"1124"},
    {"Ville":"Jedaida","Cite":"Cite Trabelsi","CodePostal":"1124"},
    {"Ville":"Borj El Amri","Cite":"Aouilia (Km 37)","CodePostal":"1113"},
    {"Ville":"Jedaida","Cite":"Cite Yougharta","CodePostal":"1124"},
    {"Ville":"Borj El Amri","Cite":"Bir Ettouil","CodePostal":"1113"},
    {"Ville":"Jedaida","Cite":"El Henna","CodePostal":"1124"},
    {"Ville":"Borj El Amri","Cite":"Borj Ennour","CodePostal":"1113"},
    {"Ville":"Jedaida","Cite":"Ezzahra","CodePostal":"1124"},
    {"Ville":"Borj El Amri","Cite":"El Messaidine","CodePostal":"1113"},
    {"Ville":"Jedaida","Cite":"Henchir Dheniba","CodePostal":"1124"},
    {"Ville":"El Battan","Cite":"Cite 7 Novembre","CodePostal":"1114"},
    {"Ville":"Jedaida","Cite":"Jedaida","CodePostal":"1124"},
    {"Ville":"El Battan","Cite":"Cite Ben Ghenia","CodePostal":"1114"},
    {"Ville":"El Battan","Cite":"Cite Brik","CodePostal":"1114"},
    {"Ville":"El Battan","Cite":"Cite El Houda","CodePostal":"1114"},
    {"Ville":"Oued Ellil","Cite":"Cite 7 Novembre","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"Cite Ennasr","CodePostal":"1114"},
    {"Ville":"Oued Ellil","Cite":"Cite Afh","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"Cite Ennour","CodePostal":"1114"},
    {"Ville":"Oued Ellil","Cite":"Cite Bejoua 2","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"Cite Ennouzha","CodePostal":"1114"},
    {"Ville":"Oued Ellil","Cite":"Cite Ben Arfa","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"El Battan","CodePostal":"1114"},
    {"Ville":"Tebourba","Cite":"Argoub Erroumi","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite Ben Jemaa","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"El Mansoura 2","CodePostal":"1114"},
    {"Ville":"Tebourba","Cite":"Bir Ezzitoun","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite Bennour Bejoua 1","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"El Mehrine","CodePostal":"1114"},
    {"Ville":"Tebourba","Cite":"Cite 7 Novembre","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite Bouzid","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"Ezzouitina","CodePostal":"1114"},
    {"Ville":"Tebourba","Cite":"Cite Chebbi","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite Dhamene 2","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"Ferme N¦7","CodePostal":"1114"}
    ,
    {"Ville":"Tebourba","Cite":"Cite De France","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite El Boustene 1","CodePostal":"2021"},
    {"Ville":"El Battan","Cite":"Saint Joseph","CodePostal":"1114"},
    {"Ville":"Tebourba","Cite":"Cite De La Palestine","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite El Boustene 2","CodePostal":"2021"},
    {"Ville":"Tebourba","Cite":"Cite El Argoub","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite El Izdihar Bejoua","CodePostal":"2021"},
    {"Ville":"Tebourba","Cite":"Cite El Bassatine","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite El Moezzia","CodePostal":"2021"},
    {"Ville":"Mornaguia","Cite":"Sidi Ali El Hattab","CodePostal":"2071"},
    {"Ville":"Tebourba","Cite":"Cite El Kef Lahmar","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite El Moultaka 1","CodePostal":"2021"},
    {"Ville":"Tebourba","Cite":"Cite El Kharrouba","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite Ettourjmene","CodePostal":"2021"},
    {"Ville":"Tebourba","Cite":"Cite El Malja","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite Marseille","CodePostal":"2021"},
    {"Ville":"Tebourba","Cite":"Cite El Malja Hamra","CodePostal":"1130"},
    {"Ville":"Oued Ellil","Cite":"Cite Militaire","CodePostal":"2021"},
    {"Ville":"Oued Ellil","Cite":"Oued Ellil","CodePostal":"2021"},
    {"Ville":"Tebourba","Cite":"Cite El Mandra","CodePostal":"1130"},
    {"Ville":"Tebourba","Cite":"Cite El Mellassine","CodePostal":"1130"},
    {"Ville":"Tebourba","Cite":"Cite Ennajah","CodePostal":"1130"},
    {"Ville":"Tebourba","Cite":"Cite Erraja","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Borj El Amri","CodePostal":"1142"},
    {"Ville":"Tebourba","Cite":"Cite Errimel","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Cite El Hafsia","CodePostal":"1142"},
    {"Ville":"Tebourba","Cite":"Cite Essaada","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Cite El Intilaka","CodePostal":"1142"},
    {"Ville":"Tebourba","Cite":"Cite Essaada 2","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Cite El Manai","CodePostal":"1142"},
    {"Ville":"Tebourba","Cite":"Cite Khelif Jalled","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Cite Ennouzha 1","CodePostal":"1142"},
    {"Ville":"Mornaguia","Cite":"Mornaguia 20 Mars","CodePostal":"1116"},
    {"Ville":"Tebourba","Cite":"Cite Laroussia","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Cite Ennouzha 2","CodePostal":"1142"},
    {"Ville":"Tebourba","Cite":"Cite Populaire","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Cite Taoufik","CodePostal":"1142"},
    {"Ville":"Tebourba","Cite":"Cite Route Chouigui","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Drijette","CodePostal":"1142"},
    {"Ville":"Tebourba","Cite":"Cite Souyah","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Enfaiedh","CodePostal":"1142"},
    {"Ville":"Mannouba","Cite":"La Mannouba","CodePostal":"2010"},
    {"Ville":"Tebourba","Cite":"El Aroussia","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Ksar Hadid","CodePostal":"1142"},
    {"Ville":"Mannouba","Cite":"Denden","CodePostal":"2011"},
    {"Ville":"Jedaida","Cite":"El Mansoura","CodePostal":"2075"},
    {"Ville":"Tebourba","Cite":"Goumriane","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Menzel Habib","CodePostal":"1142"},
    {"Ville":"Jedaida","Cite":"El Habibia","CodePostal":"2012"},
    {"Ville":"Tebourba","Cite":"Tebourba","CodePostal":"1130"},
    {"Ville":"Borj El Amri","Cite":"Teboltech","CodePostal":"1142"},
    {"Ville":"Mornaguia","Cite":"El Fejja","CodePostal":"1153"},
    {"Ville":"Jedaida","Cite":"Cite Aboubaker Esseddik","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"Borj Ettoumi","CodePostal":"1143"},
    {"Ville":"Jedaida","Cite":"Cite Bel Hay","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"El Mahfoura","CodePostal":"1143"},
    {"Ville":"Mornaguia","Cite":"Bou Hanech","CodePostal":"2028"},
    {"Ville":"Jedaida","Cite":"Cite Ben Hassine","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"Toungar","CodePostal":"1143"},
    {"Ville":"Mornaguia","Cite":"El Bassatine","CodePostal":"2028"},
    {"Ville":"Jedaida","Cite":"Cite De La Rtt","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"Ain El Karma","CodePostal":"1144"},
    {"Ville":"Oued Ellil","Cite":"Cite Ennacim","CodePostal":"2031"},
    {"Ville":"Jedaida","Cite":"Cite Des Andalous","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"Chouigui","CodePostal":"1133"},
    {"Ville":"Tebourba","Cite":"Ain Zammit","CodePostal":"1144"},
    {"Ville":"Oued Ellil","Cite":"Cite Erriadh Zone C","CodePostal":"2031"},
    {"Ville":"Jedaida","Cite":"Cite Dhamene","CodePostal":"1124"},
    {"Ville":"Jedaida","Cite":"Chaouat","CodePostal":"1134"},
    {"Ville":"Tebourba","Cite":"Eddikhila","CodePostal":"1144"},
    {"Ville":"Oued Ellil","Cite":"Cite Erriadh Zone F","CodePostal":"2031"},
    {"Ville":"Jedaida","Cite":"Cite Dhniba","CodePostal":"1124"},
    {"Ville":"Jedaida","Cite":"Cite Ecole Primaire","CodePostal":"1134"},
    {"Ville":"Tebourba","Cite":"El Ansarine","CodePostal":"1144"},
    {"Ville":"Oued Ellil","Cite":"Essaida","CodePostal":"2031"},
    {"Ville":"Jedaida","Cite":"Cite El Baraka","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"El Haouaria","CodePostal":"1144"},
    {"Ville":"Jedaida","Cite":"Cite El Hidaya","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"El Mellaha","CodePostal":"1144"},
    {"Ville":"Douar Hicher","Cite":"Cite Khaled Ibn El Walid","CodePostal":"2086"},
    {"Ville":"Jedaida","Cite":"Cite Ennajet","CodePostal":"1124"},
    {"Ville":"Tebourba","Cite":"Gosset El Bey","CodePostal":"1144"},
    {"Ville":"Medenine Sud","Cite":"Medenine Perseverance","CodePostal":"4127"},
    {"Ville":"Ajim","Cite":"Oued Zbib","CodePostal":"4166"},
    {"Ville":"Ben Guerdane","Cite":"Jemila","CodePostal":"4193"},
    {"Ville":"Medenine Nord","Cite":"Medenine El Jedida","CodePostal":"4130"},
    {"Ville":"Zarzis","Cite":"Beni Fetaiel","CodePostal":"4170"},
    {"Ville":"Zarzis","Cite":"Hamadi El Guebli","CodePostal":"4194"},
    {"Ville":"Medenine Sud","Cite":"Hassi Amor","CodePostal":"4131"},
    {"Ville":"Zarzis","Cite":"Bou Jlida","CodePostal":"4170"},
    {"Ville":"Houmet Essouk","Cite":"Guechiine","CodePostal":"4195"},
    {"Ville":"Beni Khedache","Cite":"Halg Jemal","CodePostal":"4132"},
    {"Ville":"Zarzis","Cite":"Bou Teffaha","CodePostal":"4170"},
    {"Ville":"Midoun","Cite":"Dar Jerba","CodePostal":"4199"},
    {"Ville":"Midoun","Cite":"Robbana","CodePostal":"4133"},
    {"Ville":"Zarzis","Cite":"Cite Chokrbane","CodePostal":"4170"},
    {"Ville":"Zarzis","Cite":"Chammakh","CodePostal":"4134"},
    {"Ville":"Zarzis","Cite":"Cite Darb El Bab","CodePostal":"4170"},
    {"Ville":"Zarzis","Cite":"Cite El Kriba","CodePostal":"4134"},
    {"Ville":"Zarzis","Cite":"Cite Ksar Ouled Mhamed","CodePostal":"4170"},
    {"Ville":"Zarzis","Cite":"Cite Populaire","CodePostal":"4134"},
    {"Ville":"Zarzis","Cite":"Cite Ksar Ouled Said","CodePostal":"4170"},
    {"Ville":"Zarzis","Cite":"Cite Zrig","CodePostal":"4134"},
    {"Ville":"Zarzis","Cite":"Cite Sidi El Kebir","CodePostal":"4170"},
    {"Ville":"Medenine Nord","Cite":"Cite El Khrachfa","CodePostal":"4100"},
    {"Ville":"Zarzis","Cite":"El Khriba","CodePostal":"4134"},
    {"Ville":"Zarzis","Cite":"El Marathia","CodePostal":"4170"},
    {"Ville":"Medenine Nord","Cite":"Cite Ennour","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Mezrane","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Kaoui El Kadir","CodePostal":"4170"},
    {"Ville":"Medenine Nord","Cite":"Cite Ezzouhour","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Ajim","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Khaoui El Ghedir","CodePostal":"4170"},
    {"Ville":"Medenine Nord","Cite":"Cite Haddada","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Bou Smail","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Ksar Ezzaouia","CodePostal":"4170"},
    {"Ville":"Medenine Nord","Cite":"Cite Jouamaa","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Chaouch","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Ouglet Souihel","CodePostal":"4170"},
    {"Ville":"Medenine Nord","Cite":"Cite Nouvelle","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Cite Populaire","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Ras Edhahra","CodePostal":"4170"},
    {"Ville":"Medenine Nord","Cite":"Cite Sidi Ezzitouni","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Ghandri","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Sahbi","CodePostal":"4170"},
    {"Ville":"Medenine Sud","Cite":"Amra Nouvelle","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Houmet Ben Harzallh","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Sanchou","CodePostal":"4170"},
    {"Ville":"Medenine Sud","Cite":"Beni Ghezeyel","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Houmet Bou Hastine","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Zarzis","CodePostal":"4170"},
    {"Ville":"Medenine Sud","Cite":"Cite Cheraiha","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Houmet Issa","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Jedaria","CodePostal":"4172"},
    {"Ville":"Medenine Sud","Cite":"Cite Des Abricots","CodePostal":"4100"},
    {"Ville":"Ajim","Cite":"Khenensa","CodePostal":"4135"},
    {"Ville":"Zarzis","Cite":"Cite Beni Fetaiel 1","CodePostal":"4173"},
    {"Ville":"Medenine Sud","Cite":"Cite El Ahras","CodePostal":"4100"},
    {"Ville":"Houmet Essouk","Cite":"Sedghiane","CodePostal":"4136"},
    {"Ville":"Zarzis","Cite":"Cite Beni Fetaiel 2","CodePostal":"4173"},
    {"Ville":"Medenine Sud","Cite":"Cite Ennacim","CodePostal":"4100"},
    {"Ville":"Zarzis","Cite":"Zarzis Zone Franche","CodePostal":"4137"},
    {"Ville":"Zarzis","Cite":"Cite El Mansoura","CodePostal":"4173"},
    {"Ville":"Medenine Sud","Cite":"Cite Erraja","CodePostal":"4100"},
    {"Ville":"Medenine Sud","Cite":"Bou Ghrara","CodePostal":"4141"},
    {"Ville":"Zarzis","Cite":"Cite Sangho","CodePostal":"4173"},
    {"Ville":"Medenine Sud","Cite":"Cite Gammoudi Cheref","CodePostal":"4100"},
    {"Ville":"Medenine Nord","Cite":"Medenine El Maarifa","CodePostal":"4142"},
    {"Ville":"Zarzis","Cite":"Cite Sidi Saad","CodePostal":"4173"},
    {"Ville":"Medenine Sud","Cite":"Cite Touta","CodePostal":"4100"},
    {"Ville":"Medenine Sud","Cite":"El Maghraouia","CodePostal":"4143"},
    {"Ville":"Zarzis","Cite":"Lella Mariem","CodePostal":"4173"},
    {"Ville":"Medenine Sud","Cite":"El Amra","CodePostal":"4100"},
    {"Ville":"Zarzis","Cite":"El Mouensa","CodePostal":"4144"},
    {"Ville":"Zarzis","Cite":"Ouled Abdennebi","CodePostal":"4173"},
    {"Ville":"Medenine Sud","Cite":"Essaadane","CodePostal":"4100"},
    {"Ville":"Midoun","Cite":"Cedouikeche","CodePostal":"4145"},
    {"Ville":"Zarzis","Cite":"Souihel","CodePostal":"4173"},
    {"Ville":"Medenine Sud","Cite":"Medenine","CodePostal":"4100"},
    {"Ville":"Midoun","Cite":"El Krachoua","CodePostal":"4145"},
    {"Ville":"Zarzis","Cite":"Hassi Jerbi","CodePostal":"4174"},
    {"Ville":"Medenine Sud","Cite":"Oued Esseder","CodePostal":"4100"},
    {"Ville":"Midoun","Cite":"Ouersighen","CodePostal":"4145"},
    {"Ville":"Midoun","Cite":"El May","CodePostal":"4175"},
    {"Ville":"Medenine Sud","Cite":"Souitir","CodePostal":"4100"},
    {"Ville":"Midoun","Cite":"Tafartast","CodePostal":"4145"},
    {"Ville":"Midoun","Cite":"Houmet Hazem","CodePostal":"4175"},
    {"Ville":"Medenine Sud","Cite":"Tajerjimet","CodePostal":"4100"},
    {"Ville":"Midoun","Cite":"Tomogret","CodePostal":"4145"},
    {"Ville":"Midoun","Cite":"Arkou","CodePostal":"4176"},
    {"Ville":"Medenine Sud","Cite":"Touicha","CodePostal":"4100"},
    {"Ville":"Houmet Essouk","Cite":"Cite Snit","CodePostal":"4146"},
    {"Ville":"Houmet Essouk","Cite":"Sidi Mehrez","CodePostal":"4179"},
    {"Ville":"Beni Khedache","Cite":"Beni Khedache","CodePostal":"4110"},
    {"Ville":"Houmet Essouk","Cite":"Erriadh","CodePostal":"4146"},
    {"Ville":"Houmet Essouk","Cite":"Bazim","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Cite Ennour","CodePostal":"4110"},
    {"Ville":"Houmet Essouk","Cite":"Houmet Larbeh","CodePostal":"4146"},
    {"Ville":"Houmet Essouk","Cite":"Cite Ben Issa","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Cite Erriadh","CodePostal":"4110"},
    {"Ville":"Ajim","Cite":"Methania","CodePostal":"4150"},
    {"Ville":"Houmet Essouk","Cite":"Cite Bou Okkazine","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Cite Ettahrir","CodePostal":"4110"},
    {"Ville":"Beni Khedache","Cite":"Battouma","CodePostal":"4151"},
    {"Ville":"Houmet Essouk","Cite":"Cite De La Police","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Cite Ezzouhour","CodePostal":"4110"},
    {"Ville":"Beni Khedache","Cite":"Eddikhila","CodePostal":"4151"},
    {"Ville":"Houmet Essouk","Cite":"Cite Des Enseignants","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Cite Ibn Khaldoun","CodePostal":"4110"},
    {"Ville":"Beni Khedache","Cite":"El Behira","CodePostal":"4151"},
    {"Ville":"Houmet Essouk","Cite":"Cite Erriadh","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Cite Sidi Mansour","CodePostal":"4110"},
    {"Ville":"Beni Khedache","Cite":"Foum Ennagueb","CodePostal":"4151"},
    {"Ville":"Houmet Essouk","Cite":"Cite Jouamaa","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Ksar El Hallouf","CodePostal":"4110"},
    {"Ville":"Beni Khedache","Cite":"Ksar Jedid","CodePostal":"4151"},
    {"Ville":"Houmet Essouk","Cite":"Cite Ouvriere 1","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Ksar El Kherachfa","CodePostal":"4110"},
    {"Ville":"Beni Khedache","Cite":"Ksar Krikria","CodePostal":"4151"},
    {"Ville":"Houmet Essouk","Cite":"Cite Ouvriere 2","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Ksar Jouamaa","CodePostal":"4110"},
    {"Ville":"Ben Guerdane","Cite":"Ras Jedir","CodePostal":"4153"},
    {"Ville":"Houmet Essouk","Cite":"Cite Populaire","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Zammour","CodePostal":"4110"},
    {"Ville":"Zarzis","Cite":"El Ghrabate","CodePostal":"4154"},
    {"Ville":"Houmet Essouk","Cite":"Cite Sidi El Bahri","CodePostal":"4180"},
    {"Ville":"Medenine Nord","Cite":"Oum Ettamar","CodePostal":"4111"},
    {"Ville":"Ajim","Cite":"Tlet","CodePostal":"4155"},
    {"Ville":"Houmet Essouk","Cite":"Fatou","CodePostal":"4180"},
    {"Ville":"Beni Khedache","Cite":"Ouerjijene","CodePostal":"4112"},
    {"Ville":"Ajim","Cite":"El Guebline","CodePostal":"4155"},
    {"Ville":"Houmet Essouk","Cite":"Hachene","CodePostal":"4180"},
    {"Ville":"Midoun","Cite":"Ouled Amor","CodePostal":"4113"},
    {"Ville":"Ajim","Cite":"Guellala","CodePostal":"4155"},
    {"Ville":"Houmet Essouk","Cite":"Jerba","CodePostal":"4180"},
    {"Ville":"Zarzis","Cite":"Erraja","CodePostal":"4114"},
    {"Ville":"Ajim","Cite":"Houmet El Fahmine","CodePostal":"4155"},
    {"Ville":"Sidi Makhlouf","Cite":"El Bayaz","CodePostal":"4181"},
    {"Ville":"Houmet Essouk","Cite":"Mellita Jerba","CodePostal":"4115"},
    {"Ville":"Houmet Essouk","Cite":"Ghizen","CodePostal":"4156"},
    {"Ville":"Sidi Makhlouf","Cite":"El Ghabbay","CodePostal":"4181"},
    {"Ville":"Midoun","Cite":"Aghir","CodePostal":"4116"},
    {"Ville":"Beni Khedache","Cite":"Chouamekh","CodePostal":"4159"},
    {"Ville":"Sidi Makhlouf","Cite":"Erragouba","CodePostal":"4181"},
    {"Ville":"Midoun","Cite":"El Hadadda","CodePostal":"4116"},
    {"Ville":"Ben Guerdane","Cite":"Ben Guerdane","CodePostal":"4160"},
    {"Ville":"Sidi Makhlouf","Cite":"Erragouba El Gharbia","CodePostal":"4181"},
    {"Ville":"Midoun","Cite":"Jamaa El Gaied","CodePostal":"4116"},
    {"Ville":"Ben Guerdane","Cite":"Bou Hamed","CodePostal":"4160"},
    {"Ville":"Houmet Essouk","Cite":"Sarandi","CodePostal":"4182"},
    {"Ville":"Midoun","Cite":"Khazroun","CodePostal":"4116"},
    {"Ville":"Ben Guerdane","Cite":"Chareb Errajel","CodePostal":"4160"},
    {"Ville":"Ben Guerdane","Cite":"Ouersnia","CodePostal":"4183"},
    {"Ville":"Midoun","Cite":"Midoun","CodePostal":"4116"},
    {"Ville":"Ben Guerdane","Cite":"Cite El Baath","CodePostal":"4160"},
    {"Ville":"Houmet Essouk","Cite":"Cite De La Douane","CodePostal":"4185"},
    {"Ville":"Midoun","Cite":"Sayagh","CodePostal":"4116"},
    {"Ville":"Ben Guerdane","Cite":"Cite Ennour","CodePostal":"4160"},
    {"Ville":"Houmet Essouk","Cite":"Cite Sidi Zaied","CodePostal":"4185"},
    {"Ville":"Midoun","Cite":"Sidi Yati","CodePostal":"4116"},
    {"Ville":"Ben Guerdane","Cite":"Cite Essourour","CodePostal":"4160"},
    {"Ville":"Houmet Essouk","Cite":"Souani","CodePostal":"4185"},
    {"Ville":"Midoun","Cite":"Zone Hoteliere","CodePostal":"4116"},
    {"Ville":"Ben Guerdane","Cite":"Cite Populaire","CodePostal":"4160"},
    {"Ville":"Houmet Essouk","Cite":"Mezraya","CodePostal":"4186"},
    {"Ville":"Sidi Makhlouf","Cite":"Jorf","CodePostal":"4117"},
    {"Ville":"Ben Guerdane","Cite":"El Maamrate","CodePostal":"4160"},
    {"Ville":"Houmet Essouk","Cite":"Sidi Zaied","CodePostal":"4190"},
    {"Ville":"Houmet Essouk","Cite":"Jerba Aeroport","CodePostal":"4120"},
    {"Ville":"Ben Guerdane","Cite":"Neffatia","CodePostal":"4160"},
    {"Ville":"Sidi Makhlouf","Cite":"Bedoui","CodePostal":"4191"},
    {"Ville":"Medenine Nord","Cite":"Koutine","CodePostal":"4121"},
    {"Ville":"Ben Guerdane","Cite":"Oued Errabaya","CodePostal":"4160"},
    {"Ville":"Sidi Makhlouf","Cite":"Cite 9 Avril","CodePostal":"4191"},
    {"Ville":"Beni Khedache","Cite":"Ksar El Jira","CodePostal":"4122"},
    {"Ville":"Ben Guerdane","Cite":"Sayah","CodePostal":"4160"},
    {"Ville":"Sidi Makhlouf","Cite":"El Gosba","CodePostal":"4191"},
    {"Ville":"Houmet Essouk","Cite":"Oualegh","CodePostal":"4123"},
    {"Ville":"Ben Guerdane","Cite":"Tabai","CodePostal":"4160"},
    {"Ville":"Sidi Makhlouf","Cite":"El Grine","CodePostal":"4191"},
    {"Ville":"Zarzis","Cite":"El Hichem","CodePostal":"4124"},
    {"Ville":"Ben Guerdane","Cite":"Chehbania","CodePostal":"4163"},
    {"Ville":"Sidi Makhlouf","Cite":"El Morra","CodePostal":"4191"},
    {"Ville":"Ajim","Cite":"El Groo","CodePostal":"4125"},
    {"Ville":"Zarzis","Cite":"Gribis","CodePostal":"4164"},
    {"Ville":"Sidi Makhlouf","Cite":"Sidi Makhlouf","CodePostal":"4191"},
    {"Ville":"Ajim","Cite":"Jabira","CodePostal":"4125"},
    {"Ville":"Midoun","Cite":"Mahboubine","CodePostal":"4165"},
    {"Ville":"Ben Guerdane","Cite":"Jalel","CodePostal":"4192"},
    {"Ville":"Midoun","Cite":"Beni Maaguel","CodePostal":"4126"},
    {"Ville":"Jemmal","Cite":"Cite Independance","CodePostal":"5020"},
    {"Ville":"Jemmal","Cite":"Bir Taieb","CodePostal":"5043"},
    {"Ville":"Teboulba","Cite":"Teboulba","CodePostal":"5080"},
    {"Ville":"Jemmal","Cite":"Cite Jaouhara","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Sidi Bannour","CodePostal":"5044"},
    {"Ville":"Monastir","Cite":"Cite El Bassatine","CodePostal":"5089"},
    {"Ville":"Jemmal","Cite":"Cite Jardins","CodePostal":"5020"},
    {"Ville":"Zeramdine","Cite":"Mzaougha","CodePostal":"5045"},
    {"Ville":"Bekalta","Cite":"Baghdadi","CodePostal":"5090"},
    {"Ville":"Jemmal","Cite":"Cite Operations Du Sahel","CodePostal":"5020"},
    {"Ville":"Zeramdine","Cite":"Mlichette","CodePostal":"5046"},
    {"Ville":"Bekalta","Cite":"Bekalta","CodePostal":"5090"},
    {"Ville":"Jemmal","Cite":"Cite Sidi Messaoud","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite 7 Novembre","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Cite El Bassatine","CodePostal":"5090"},
    {"Ville":"Jemmal","Cite":"Jemmal","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite Chahed 1","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Cite El Izdihar","CodePostal":"5090"},
    {"Ville":"Bembla","Cite":"Bembla","CodePostal":"5021"},
    {"Ville":"Moknine","Cite":"Cite Chahed 2","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Cite Nouvelle","CodePostal":"5090"},
    {"Ville":"Bembla","Cite":"Cite El Khadhra","CodePostal":"5021"},
    {"Ville":"Moknine","Cite":"Cite Chahed 3","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Guealla","CodePostal":"5090"},
    {"Ville":"Bembla","Cite":"Cite El Mandra","CodePostal":"5021"},
    {"Ville":"Moknine","Cite":"Cite Erriadh","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Ouled Hedda","CodePostal":"5090"},
    {"Ville":"Bembla","Cite":"Cite Ennabka","CodePostal":"5021"},
    {"Ville":"Moknine","Cite":"Cite Gribaa","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Ouled Issa","CodePostal":"5090"},
    {"Ville":"Bembla","Cite":"Cite Populaire","CodePostal":"5021"},
    {"Ville":"Moknine","Cite":"Cite Hmada Bacha","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Ouled Touibi","CodePostal":"5090"},
    {"Ville":"Bembla","Cite":"Cite 2 Mars","CodePostal":"5022"},
    {"Ville":"Moknine","Cite":"Cite Mesada","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Zebid","CodePostal":"5090"},
    {"Ville":"Bembla","Cite":"Cite El Jazia","CodePostal":"5022"},
    {"Ville":"Moknine","Cite":"Cite Ras El Oued","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"Charaf","CodePostal":"5091"},
    {"Ville":"Bembla","Cite":"Cite Souihria","CodePostal":"5022"},
    {"Ville":"Moknine","Cite":"Cite Souassi","CodePostal":"5050"},
    {"Ville":"Bekalta","Cite":"El Behira","CodePostal":"5092"},
    {"Ville":"Bembla","Cite":"Menzel Ennour","CodePostal":"5022"},
    {"Ville":"Moknine","Cite":"Moknine","CodePostal":"5050"},
    {"Ville":"Sayada Lamta Bou","Cite":"Cite Bir El Ayeb","CodePostal":"5099"},
    {"Ville":"Ksibet El Medioun","Cite":"Touza","CodePostal":"5023"},
    {"Ville":"Moknine","Cite":"Moknine El Jadida","CodePostal":"5051"},
    {"Ville":"Sayada Lamta Bou","Cite":"Cite Ennadhour","CodePostal":"5099"},
    {"Ville":"Moknine","Cite":"Menzel Fersi","CodePostal":"5024"},
    {"Ville":"Ouerdanine","Cite":"Bou Othmane","CodePostal":"5052"},
    {"Ville":"Sayada Lamta Bou","Cite":"Lamta","CodePostal":"5099"},
    {"Ville":"Ksibet El Medioun","Cite":"Bennane","CodePostal":"5025"},
    {"Ville":"Moknine","Cite":"Amirat El Fehoul","CodePostal":"5053"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Air Nouvelle","CodePostal":"5025"},
    {"Ville":"Moknine","Cite":"Amirat Touazra","CodePostal":"5054"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite El Bassatine","CodePostal":"5025"},
    {"Ville":"Monastir","Cite":"Cite Bir Hlou","CodePostal":"5060"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite El Intilaka","CodePostal":"5025"},
    {"Ville":"Monastir","Cite":"Cite Cheraka","CodePostal":"5060"},
    {"Ville":"Beni Hassen","Cite":"Ghenada","CodePostal":"5026"},
    {"Ville":"Monastir","Cite":"Cite Cnrps","CodePostal":"5060"},
    {"Ville":"Monastir","Cite":"Monastir","CodePostal":"5000"},
    {"Ville":"Jemmal","Cite":"Ettiayra","CodePostal":"5027"},
    {"Ville":"Monastir","Cite":"Cite El Amel","CodePostal":"5060"},
    {"Ville":"Ouerdanine","Cite":"Cite 22 Janvier","CodePostal":"5010"},
    {"Ville":"Jemmal","Cite":"Cite El Khadhra","CodePostal":"5028"},
    {"Ville":"Monastir","Cite":"Cite El Faouz","CodePostal":"5060"},
    {"Ville":"Ouerdanine","Cite":"Cite 7 Novembre","CodePostal":"5010"},
    {"Ville":"Jemmal","Cite":"Cite Ettahrir","CodePostal":"5028"},
    {"Ville":"Monastir","Cite":"Cite El Farik","CodePostal":"5060"},
    {"Ville":"Ouerdanine","Cite":"Cite Abdelaziz Thaalbi","CodePostal":"5010"},
    {"Ville":"Jemmal","Cite":"Zaouiet Kontech","CodePostal":"5028"},
    {"Ville":"Monastir","Cite":"Cite El Mzali","CodePostal":"5060"},
    {"Ville":"Ouerdanine","Cite":"Cite Ennouhoudh","CodePostal":"5010"},
    {"Ville":"Jemmal","Cite":"Jemmal Kheireddine","CodePostal":"5030"},
    {"Ville":"Monastir","Cite":"Cite Erriadh","CodePostal":"5060"},
    {"Ville":"Ouerdanine","Cite":"Cite Ennouzha","CodePostal":"5010"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite 18 Janvier","CodePostal":"5031"},
    {"Ville":"Monastir","Cite":"Cite Essaada","CodePostal":"5060"},
    {"Ville":"Ouerdanine","Cite":"Cite Mohamed Ali","CodePostal":"5010"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Commerciale","CodePostal":"5031"},
    {"Ville":"Monastir","Cite":"Cite Essalem","CodePostal":"5060"},
    {"Ville":"Ouerdanine","Cite":"Ouerdanine","CodePostal":"5010"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite De La Liberte","CodePostal":"5031"},
    {"Ville":"Monastir","Cite":"Cite Sidi Nasser","CodePostal":"5060"},
    {"Ville":"Monastir","Cite":"Khenis","CodePostal":"5011"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite El Bassatine","CodePostal":"5031"},
    {"Ville":"Monastir","Cite":"Cite Trabelsia","CodePostal":"5060"},
    {"Ville":"Sahline","Cite":"Cite Echaabia Nlle","CodePostal":"5012"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Ennadhour 1","CodePostal":"5031"},
    {"Ville":"Monastir","Cite":"Monastir Republique","CodePostal":"5060"},
    {"Ville":"Sahline","Cite":"Mootmar","CodePostal":"5012"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Ennadhour 2","CodePostal":"5031"},
    {"Ville":"Monastir","Cite":"Zone Skanes","CodePostal":"5060"},
    {"Ville":"Sahline","Cite":"Sahline","CodePostal":"5012"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Ennadhour 3","CodePostal":"5031"},
    {"Ville":"Sahline","Cite":"Cite Nouvelle","CodePostal":"5061"},
    {"Ville":"Jemmal","Cite":"Menzel Kamel","CodePostal":"5013"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Essakia","CodePostal":"5031"},
    {"Ville":"Sahline","Cite":"Sidi Ameur","CodePostal":"5061"},
    {"Ville":"Beni Hassen","Cite":"Beni Hassen","CodePostal":"5014"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Ezzayatine","CodePostal":"5031"},
    {"Ville":"Beni Hassen","Cite":"Amirat Hatem","CodePostal":"5062"},
    {"Ville":"Beni Hassen","Cite":"Cite Ettahrir","CodePostal":"5014"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Oued Sekkal","CodePostal":"5031"},
    {"Ville":"Ksibet El Medioun","Cite":"Bou Dher","CodePostal":"5063"},
    {"Ville":"Sayada Lamta Bou","Cite":"Bou Hajar","CodePostal":"5015"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Populaire","CodePostal":"5031"},
    {"Ville":"Monastir","Cite":"Monastir Aeroport","CodePostal":"5065"},
    {"Ville":"Sayada Lamta Bou","Cite":"Cite Choobet Romman","CodePostal":"5015"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Rabbah","CodePostal":"5031"},
    {"Ville":"Monastir","Cite":"Zone Hoteliere","CodePostal":"5065"},
    {"Ville":"Sayada Lamta Bou","Cite":"Cite Nouvelle","CodePostal":"5015"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Sidi El Mediouni","CodePostal":"5031"},
    {"Ville":"Teboulba","Cite":"Soukrine","CodePostal":"5066"},
    {"Ville":"Ksar Helal","Cite":"Ksar Helal Riadh","CodePostal":"5016"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Snit","CodePostal":"5031"},
    {"Ville":"Ksar Helal","Cite":"Cite Bir Ali Hellal","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"El Hedadra","CodePostal":"5017"},
    {"Ville":"Ksibet El Medioun","Cite":"Cite Tarek Ibn Zied","CodePostal":"5031"},
    {"Ville":"Ksar Helal","Cite":"Cite Bir Soukra","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite Commerciale 1","CodePostal":"5020"},
    {"Ville":"Ksibet El Medioun","Cite":"Ksibet El Mediouni","CodePostal":"5031"},
    {"Ville":"Ksar Helal","Cite":"Cite Bit Mekka","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite Commerciale 2","CodePostal":"5020"},
    {"Ville":"Bembla","Cite":"Mazdour","CodePostal":"5032"},
    {"Ville":"Ksar Helal","Cite":"Cite Chougar","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Amen","CodePostal":"5020"},
    {"Ville":"Zeramdine","Cite":"Menzel Hayet","CodePostal":"5033"},
    {"Ville":"Ksar Helal","Cite":"Cite Commerciale 1","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Bassatine1","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cherahil","CodePostal":"5034"},
    {"Ville":"Ksar Helal","Cite":"Cite Commerciale 2","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Bassatine2","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite El Bassatine","CodePostal":"5034"},
    {"Ville":"Ksar Helal","Cite":"Cite Dar Bumbla","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Fateh","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite El Omrane","CodePostal":"5034"},
    {"Ville":"Ksar Helal","Cite":"Cite De La Municipalite","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Intilaka","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite Ennasr","CodePostal":"5034"},
    {"Ville":"Ksar Helal","Cite":"Cite Ejjebsa","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Izdihar","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite Ezzayatine","CodePostal":"5034"},
    {"Ville":"Ksar Helal","Cite":"Cite El Gariaa","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Khadhra","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite Populaire","CodePostal":"5034"},
    {"Ville":"Ksar Helal","Cite":"Cite El Maglouba","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Manar 1","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite Trabelsia","CodePostal":"5034"},
    {"Ville":"Ksar Helal","Cite":"Cite Erriadh 1","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Manar 2","CodePostal":"5020"},
    {"Ville":"Moknine","Cite":"Cite Zraraa","CodePostal":"5034"},
    {"Ville":"Ksar Helal","Cite":"Cite Erriadh 2","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Wifak","CodePostal":"5020"},
    {"Ville":"Sayada Lamta Bou","Cite":"Cite Gourraia","CodePostal":"5035"},
    {"Ville":"Ksar Helal","Cite":"Cite Erriadh 3","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite El Yasmine","CodePostal":"5020"},
    {"Ville":"Sayada Lamta Bou","Cite":"Cite Nouvelle","CodePostal":"5035"},
    {"Ville":"Ksar Helal","Cite":"Cite Industrielle","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite Ennasr","CodePostal":"5020"},
    {"Ville":"Sayada Lamta Bou","Cite":"Cite Sidi Ammar","CodePostal":"5035"},
    {"Ville":"Ksar Helal","Cite":"Cite Moueheddine","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite Ennour","CodePostal":"5020"},
    {"Ville":"Sayada Lamta Bou","Cite":"Sayada","CodePostal":"5035"},
    {"Ville":"Ksar Helal","Cite":"Cite Oued Kacem Et El Ourami","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite Ennouzha","CodePostal":"5020"},
    {"Ville":"Sayada Lamta Bou","Cite":"Sidi Abdessalem","CodePostal":"5035"},
    {"Ville":"Ksar Helal","Cite":"Cite Sanit Zaag Et Charef","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite Erriadh","CodePostal":"5020"},
    {"Ville":"Bembla","Cite":"Menzel Harb","CodePostal":"5036"},
    {"Ville":"Ksar Helal","Cite":"Ksar Helal","CodePostal":"5070"},
    {"Ville":"Jemmal","Cite":"Cite Essalem","CodePostal":"5020"},
    {"Ville":"Zeramdine","Cite":"Cite El Kods","CodePostal":"5040"},
    {"Ville":"Moknine","Cite":"Amirat El Hojjej","CodePostal":"5071"},
    {"Ville":"Jemmal","Cite":"Cite Ettaamir","CodePostal":"5020"},
    {"Ville":"Jemmal","Cite":"Cite Ettadhamen","CodePostal":"5020"},
    {"Ville":"Zeramdine","Cite":"Cite El Manar","CodePostal":"5040"},
    {"Ville":"Bembla","Cite":"Menara","CodePostal":"5076"},
    {"Ville":"Jemmal","Cite":"Cite Ezzahra","CodePostal":"5020"},
    {"Ville":"Zeramdine","Cite":"Zeramdine","CodePostal":"5040"},
    {"Ville":"Monastir","Cite":"Monastir Gare","CodePostal":"5079"},
    {"Ville":"Jemmal","Cite":"Cite Ezzouhour 1","CodePostal":"5020"},
    {"Ville":"Ouerdanine","Cite":"Menzel Khir","CodePostal":"5041"},
    {"Ville":"Teboulba","Cite":"Cite Bou Drisse","CodePostal":"5080"},
    {"Ville":"Jemmal","Cite":"Cite Ezzouhour 2","CodePostal":"5020"},
    {"Ville":"Sahline","Cite":"Mesjed Issa","CodePostal":"5042"},
    {"Ville":"Teboulba","Cite":"Cite El Fadhline","CodePostal":"5080"},
    {"Ville":"El Mida","Cite":"Oum Dhouil Village","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Damous El Hajja","CodePostal":"8010"},
    {"Ville":"Nabeul","Cite":"Henchir El Haouaria","CodePostal":"8062"},
    {"Ville":"Beni Khiar","Cite":"El Halfa","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Kelibia","CodePostal":"8090"},
    {"Ville":"El Haouaria","Cite":"Bou Krim","CodePostal":"8036"},
    {"Ville":"El Mida","Cite":"Oum Dhouil Zaouia","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Dar Joundi","CodePostal":"8010"},
    {"Ville":"Nabeul","Cite":"Nabeul Thameur","CodePostal":"8062"},
    {"Ville":"Beni Khiar","Cite":"Somaa","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Kerkouane","CodePostal":"8090"},
    {"Ville":"Bou Argoub","Cite":"Borj Hafaiedh","CodePostal":"8040"},
    {"Ville":"El Mida","Cite":"Sidi Bou Ali","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Dar Nader","CodePostal":"8010"},
    {"Ville":"Nabeul","Cite":"Residence Denene","CodePostal":"8062"},
    {"Ville":"Korba","Cite":"Cite Sidi Amor","CodePostal":"8024"},
    {"Ville":"Kelibia","Cite":"Sidi Madhkour","CodePostal":"8090"},
    {"Ville":"Bou Argoub","Cite":"Bou Ali","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Charaf","CodePostal":"8045"},
    {"Ville":"Menzel Bouzelfa","Cite":"Dar Takelsa","CodePostal":"8010"},
    {"Ville":"Nabeul","Cite":"Residence Oasis Bleu","CodePostal":"8062"},
    {"Ville":"Korba","Cite":"Tazarka","CodePostal":"8024"},
    {"Ville":"Menzel Bouzelfa","Cite":"Errahma","CodePostal":"8091"},
    {"Ville":"Bou Argoub","Cite":"Bou Argoub","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite Des Jeunes","CodePostal":"8045"},
    {"Ville":"Menzel Bouzelfa","Cite":"El Arima","CodePostal":"8010"},
    {"Ville":"Soliman","Cite":"Bou Charray","CodePostal":"8063"},
    {"Ville":"Hammam El Ghez","Cite":"Cite Jardins","CodePostal":"8025"},
    {"Ville":"Grombalia","Cite":"Grombalia Ezzouhour","CodePostal":"8092"},
    {"Ville":"Bou Argoub","Cite":"Bou Rbii","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite Des Rouines","CodePostal":"8045"},
    {"Ville":"Menzel Bouzelfa","Cite":"Kalbousi","CodePostal":"8010"},
    {"Ville":"Menzel Temime","Cite":"Skalba","CodePostal":"8064"},
    {"Ville":"Hammam El Ghez","Cite":"Hammam El Ghezaz","CodePostal":"8025"},
    {"Ville":"Grombalia","Cite":"Ain Tebournok","CodePostal":"8093"},
    {"Ville":"Bou Argoub","Cite":"Bousehem","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite El Amel","CodePostal":"8045"},
    {"Ville":"Menzel Bouzelfa","Cite":"Ksirat","CodePostal":"8010"},
    {"Ville":"Kelibia","Cite":"Oued El Khatef","CodePostal":"8065"},
    {"Ville":"El Haouaria","Cite":"Beni Khira","CodePostal":"8026"},
    {"Ville":"Grombalia","Cite":"Jebel Trif","CodePostal":"8093"},
    {"Ville":"Bou Argoub","Cite":"Chagleb","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite El Intilaka","CodePostal":"8045"},
    {"Ville":"Menzel Bouzelfa","Cite":"Menzel Bouzelfa","CodePostal":"8010"},
    {"Ville":"Beni Khiar","Cite":"Ghardaya","CodePostal":"8066"},
    {"Ville":"El Haouaria","Cite":"El Gorfa","CodePostal":"8026"},
    {"Ville":"Grombalia","Cite":"Zemnit","CodePostal":"8093"},
    {"Ville":"Bou Argoub","Cite":"Cite Cnel","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite El Izdihar","CodePostal":"8045"},
    {"Ville":"Menzel Bouzelfa","Cite":"Oued Sidi Said","CodePostal":"8010"},
    {"Ville":"Kelibia","Cite":"Kelibia Charguia","CodePostal":"8069"},
    {"Ville":"El Haouaria","Cite":"Saheb Jebel","CodePostal":"8026"},
    {"Ville":"Kelibia","Cite":"Melloul","CodePostal":"8094"},
    {"Ville":"Bou Argoub","Cite":"Cite Commerciale","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite El Khadhra","CodePostal":"8045"},
    {"Ville":"Menzel Bouzelfa","Cite":"Zengou","CodePostal":"8010"},
    {"Ville":"Korba","Cite":"Beni Aichoun","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Batrou","CodePostal":"8030"},
    {"Ville":"Hammam El Ghez","Cite":"Ezzahra","CodePostal":"8096"},
    {"Ville":"Bou Argoub","Cite":"Cite De La Carriere","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite Ennour","CodePostal":"8045"},
    {"Ville":"Menzel Bouzelfa","Cite":"Zenich","CodePostal":"8010"},
    {"Ville":"Korba","Cite":"Beni Mechkel","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Belli Halte","CodePostal":"8030"},
    {"Ville":"Hammam El Ghez","Cite":"Hammam Jebli","CodePostal":"8096"},
    {"Ville":"Bou Argoub","Cite":"Cite El Henchir","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite Essalama","CodePostal":"8045"},
    {"Ville":"Dar Chaabane Elfe","Cite":"Bayoub","CodePostal":"8011"},
    {"Ville":"Korba","Cite":"Bou Lazhar","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Beni Ayech","CodePostal":"8030"},
    {"Ville":"Hammam El Ghez","Cite":"Tamazrat","CodePostal":"8096"},
    {"Ville":"Bou Argoub","Cite":"Cite El Houani","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite Ezzouhour","CodePostal":"8045"},
    {"Ville":"Dar Chaabane Elfe","Cite":"Cite Barnoussa","CodePostal":"8011"},
    {"Ville":"Korba","Cite":"Bou Lidine","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite 7 Novembre","CodePostal":"8030"},
    {"Ville":"Beni Khalled","Cite":"Cite El Houda","CodePostal":"8099"},
    {"Ville":"Bou Argoub","Cite":"Cite El Hourria","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite Sprols","CodePostal":"8045"},
    {"Ville":"Dar Chaabane Elfe","Cite":"Cite El Ferdaous","CodePostal":"8011"},
    {"Ville":"Korba","Cite":"Cite Bir Ezzitoun","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Afh","CodePostal":"8030"},
    {"Ville":"Beni Khalled","Cite":"Cite Erriadh 1","CodePostal":"8099"},
    {"Ville":"Bou Argoub","Cite":"Cite Ezzaouia","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Cite Touristique","CodePostal":"8045"},
    {"Ville":"Dar Chaabane Elfe","Cite":"Cite Ennouzha","CodePostal":"8011"},
    {"Ville":"Korba","Cite":"Cite Commerciale","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Antar","CodePostal":"8030"},
    {"Ville":"Beni Khalled","Cite":"Cite Erriadh 2","CodePostal":"8099"},
    {"Ville":"Bou Argoub","Cite":"Cite Jadida","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"El Haouaria","CodePostal":"8045"},
    {"Ville":"Dar Chaabane Elfe","Cite":"Cite Ettakaddoum","CodePostal":"8011"},
    {"Ville":"Korba","Cite":"Cite El Khiri","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Ben Attaya","CodePostal":"8030"},
    {"Ville":"Beni Khalled","Cite":"Cite Jardins 1","CodePostal":"8099"},
    {"Ville":"Bou Argoub","Cite":"Cite Mongi Slim","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"El Kedoua","CodePostal":"8045"},
    {"Ville":"Dar Chaabane Elfe","Cite":"Cite Oued El Ghoula","CodePostal":"8011"},
    {"Ville":"Korba","Cite":"Cite El Majredi","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Ben Zaied","CodePostal":"8030"},
    {"Ville":"Beni Khalled","Cite":"Cite Jardins 2","CodePostal":"8099"},
    {"Ville":"Bou Argoub","Cite":"Cite Sprols","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Ferjoun","CodePostal":"8045"},
    {"Ville":"Dar Chaabane Elfe","Cite":"Dar Chaabane Elfehri","CodePostal":"8011"},
    {"Ville":"Korba","Cite":"Cite Ettaamir","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Cebala","CodePostal":"8030"},
    {"Ville":"Beni Khalled","Cite":"Cite Sidi Bou Said","CodePostal":"8099"},
    {"Ville":"Bou Argoub","Cite":"Douali","CodePostal":"8040"},
    {"Ville":"El Haouaria","Cite":"Menzel Salem","CodePostal":"8045"},
    {"Ville":"Dar Chaabane Elfe","Cite":"El Frinine","CodePostal":"8011"},
    {"Ville":"Korba","Cite":"Cite Jamaa Errahma","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite De La Police","CodePostal":"8030"},
    {"Ville":"Beni Khalled","Cite":"Zaouiet Jedidi","CodePostal":"8099"},
    {"Ville":"Bou Argoub","Cite":"Doukhania","CodePostal":"8040"},
    {"Ville":"Nabeul","Cite":"Ain Kemicha","CodePostal":"8000"},
    {"Ville":"El Haouaria","Cite":"Sidi Hassoun","CodePostal":"8045"},
    {"Ville":"Grombalia","Cite":"Fondouk Jedid","CodePostal":"8012"},
    {"Ville":"Korba","Cite":"Cite Jerad","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Des Juges","CodePostal":"8030"},
    {"Ville":"Bou Argoub","Cite":"El Grabcha","CodePostal":"8040"},
    {"Ville":"Nabeul","Cite":"Cite Abdessalem El Behi","CodePostal":"8000"},
    {"Ville":"El Haouaria","Cite":"Bir El Jedey","CodePostal":"8046"},
    {"Ville":"Grombalia","Cite":"Seltene","CodePostal":"8012"},
    {"Ville":"Korba","Cite":"Cite Jerbi","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Dhamene","CodePostal":"8030"},
    {"Ville":"Bou Argoub","Cite":"El Kharrouba","CodePostal":"8040"},
    {"Ville":"Nabeul","Cite":"Cite Bahroun","CodePostal":"8000"},
    {"Ville":"El Haouaria","Cite":"El Kermania","CodePostal":"8046"},
    {"Ville":"Beni Khiar","Cite":"El Maamoura","CodePostal":"8013"},
    {"Ville":"Korba","Cite":"Cite Sadok Jerbi","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Eddamous","CodePostal":"8030"},
    {"Ville":"Bou Argoub","Cite":"El Machrouha","CodePostal":"8040"},
    {"Ville":"Nabeul","Cite":"Cite Cnrps","CodePostal":"8000"},
    {"Ville":"El Haouaria","Cite":"Zaouiet El Mgaies","CodePostal":"8046"},
    {"Ville":"Beni Khalled","Cite":"Bir Drassen","CodePostal":"8014"},
    {"Ville":"Korba","Cite":"Cite Sidi Salem","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite El Mandra","CodePostal":"8030"},
    {"Ville":"Bou Argoub","Cite":"El Mhedhba","CodePostal":"8040"},
    {"Ville":"Nabeul","Cite":"Cite Cote Or 1","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Chaabet El Mrezga","CodePostal":"8050"},
    {"Ville":"Grombalia","Cite":"Khous","CodePostal":"8014"},
    {"Ville":"Korba","Cite":"Jebel Haddad","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite El Ouerdia","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite Cote Or 2","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Chatt El Khalij","CodePostal":"8050"},
    {"Ville":"Menzel Temime","Cite":"Menzel Horr","CodePostal":"8015"},
    {"Ville":"Korba","Cite":"Korba","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Erriadh","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite Des Oranges","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Cite Ennadhour","CodePostal":"8050"},
    {"Ville":"El Haouaria","Cite":"Sidi Daoud","CodePostal":"8016"},
    {"Ville":"Korba","Cite":"Korchine","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Essomboula","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite El Bassatine","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Cite Erriadh","CodePostal":"8050"},
    {"Ville":"Soliman","Cite":"Cite Gharnata","CodePostal":"8020"},
    {"Ville":"Korba","Cite":"Ksar Saad","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Ezzouhour","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite El Borj 1","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Cite Ibn Badis","CodePostal":"8050"},
    {"Ville":"Soliman","Cite":"Cite Hotel Des Andalous","CodePostal":"8020"},
    {"Ville":"Korba","Cite":"Lathleth","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Ibn Khaldoun","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite El Borj 2","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Cite Kheireddine Pacha","CodePostal":"8050"},
    {"Ville":"Soliman","Cite":"Cite Nouvelle","CodePostal":"8020"},
    {"Ville":"Korba","Cite":"Tebak","CodePostal":"8070"},
    {"Ville":"Grombalia","Cite":"Cite Jardins","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite Ennacim","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Cite Militaire","CodePostal":"8050"},
    {"Ville":"Soliman","Cite":"Cite Sprols","CodePostal":"8020"},
    {"Ville":"El Mida","Cite":"Tefeloun","CodePostal":"8071"},
    {"Ville":"Grombalia","Cite":"Cite Mohamed Ali","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite Ennajeh","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Cite Moussa Ibn Noussaier","CodePostal":"8050"},
    {"Ville":"Soliman","Cite":"Dhahri","CodePostal":"8020"},
    {"Ville":"Soliman","Cite":"Meraissa","CodePostal":"8073"},
    {"Ville":"Grombalia","Cite":"Cite Mongi Slim","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite Ettaamir","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Cite Okba Ibn Naafa","CodePostal":"8050"},
    {"Ville":"Soliman","Cite":"El Abebsa","CodePostal":"8020"},
    {"Ville":"El Haouaria","Cite":"Bou Hbib","CodePostal":"8074"},
    {"Ville":"Grombalia","Cite":"Cite Snit","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite Hali","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Cite Sanit Sheikh","CodePostal":"8050"},
    {"Ville":"Soliman","Cite":"El Marja","CodePostal":"8020"},
    {"Ville":"El Haouaria","Cite":"Tazougrane","CodePostal":"8074"},
    {"Ville":"Grombalia","Cite":"Cite Taieb Mhiri","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite Industrielle","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Echak","CodePostal":"8050"},
    {"Ville":"Soliman","Cite":"Soliman","CodePostal":"8020"},
    {"Ville":"El Haouaria","Cite":"Zougag","CodePostal":"8074"},
    {"Ville":"Grombalia","Cite":"Cite Tarek Ibn Zied","CodePostal":"8030"},
    {"Ville":"Nabeul","Cite":"Cite Jasmins Simpar","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Hammamet","CodePostal":"8050"},
    {"Ville":"Beni Khalled","Cite":"Beni Khalled","CodePostal":"8021"},
    {"Ville":"Dar Chaabane Elfe","Cite":"Dar Chaabane Plage","CodePostal":"8075"},
    {"Ville":"Grombalia","Cite":"Cite Valenza","CodePostal":"8030"},
    {"Ville":"Bou Argoub","Cite":"Harithine","CodePostal":"8040"},
    {"Ville":"Bou Argoub","Cite":"Ouled Abdallah","CodePostal":"8040"},
    {"Ville":"Nabeul","Cite":"Cite Mohsen Limam","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Residence Ellissa","CodePostal":"8050"},
    {"Ville":"Beni Khalled","Cite":"Cite Achibet Amor","CodePostal":"8021"},
    {"Ville":"Korba","Cite":"Korba Hached","CodePostal":"8076"},
    {"Ville":"Grombalia","Cite":"El Argoub Jedid","CodePostal":"8030"},
    {"Ville":"Bou Argoub","Cite":"Zone Industrielle","CodePostal":"8040"},
    {"Ville":"Nabeul","Cite":"Cite Sidi Amor","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Residence Ennacim","CodePostal":"8050"},
    {"Ville":"Beni Khalled","Cite":"Cite Air Nouvelle","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Beni Abdelaziz","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"El Karmia","CodePostal":"8030"},
    {"Ville":"Soliman","Cite":"Korbous","CodePostal":"8041"},
    {"Ville":"Nabeul","Cite":"Cite Sidi Moussa","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Sidi Hmed","CodePostal":"8050"},
    {"Ville":"Beni Khalled","Cite":"Cite Alaya Yakoubi","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Chatt Ezzouhour","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"El Khouine","CodePostal":"8030"},
    {"Ville":"Hammamet","Cite":"Bir Bouregba","CodePostal":"8042"},
    {"Ville":"Nabeul","Cite":"Cite Universitaire","CodePostal":"8000"},
    {"Ville":"Hammamet","Cite":"Zone Hoteliere","CodePostal":"8050"},
    {"Ville":"Beni Khalled","Cite":"Cite Ben Romdhane","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Cite Ettaamir","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"El Kobba El Kebira","CodePostal":"8030"},
    {"Ville":"Hammamet","Cite":"Cite Bir Chaaba","CodePostal":"8042"},
    {"Ville":"Nabeul","Cite":"Henchir Gort","CodePostal":"8000"},
    {"Ville":"Soliman","Cite":"Cherifate","CodePostal":"8051"},
    {"Ville":"Beni Khalled","Cite":"Cite Chelbia Oumaya","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Cite Populaire","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"El Mhedhba","CodePostal":"8030"},
    {"Ville":"Hammamet","Cite":"Cite Bouslama","CodePostal":"8042"},
    {"Ville":"Nabeul","Cite":"Mimosas Villas Et Bungalows","CodePostal":"8000"},
    {"Ville":"Grombalia","Cite":"Nianou","CodePostal":"8052"},
    {"Ville":"Beni Khalled","Cite":"Cite Daghrour","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Cite Sidi Salem","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"Grombalia","CodePostal":"8030"},
    {"Ville":"Hammamet","Cite":"Cite Chraf","CodePostal":"8042"},
    {"Ville":"Nabeul","Cite":"Nabeul","CodePostal":"8000"},
    {"Ville":"Korba","Cite":"Garaat Sassi","CodePostal":"8053"},
    {"Ville":"Beni Khalled","Cite":"Cite Des Jeunes","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Damous","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"Henchir Ettouta","CodePostal":"8030"},
    {"Ville":"Hammamet","Cite":"Cite Ecole","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Bedar","CodePostal":"8010"},
    {"Ville":"Menzel Temime","Cite":"El Ouediane","CodePostal":"8054"},
    {"Ville":"Beni Khalled","Cite":"Cite Du Printemps","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Echaibni","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"Khanguet Gare","CodePostal":"8030"},
    {"Ville":"Hammamet","Cite":"Cite El Bekkey","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Beni Ghanem","CodePostal":"8010"},
    {"Ville":"Kelibia","Cite":"Dar Allouche","CodePostal":"8055"},
    {"Ville":"Beni Khalled","Cite":"Cite El Gaied","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"El Gheris","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"Nouel","CodePostal":"8030"},
    {"Ville":"Hammamet","Cite":"Cite El Fawara","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Beni Jannet","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Barraket Essahel","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Essalem","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"El Gobba","CodePostal":"8080"},
    {"Ville":"Grombalia","Cite":"Sammach","CodePostal":"8030"},
    {"Ville":"Hammamet","Cite":"Cite El Intilaka","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Bouchrik","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite Afh","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Ettaamir","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Garsoline","CodePostal":"8080"},
    {"Ville":"Takelsa","Cite":"Bir Mroua","CodePostal":"8031"},
    {"Ville":"Hammamet","Cite":"Cite El Mellassine","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Charfine","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite Chatteles","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Ezzaouch","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Kef Chami","CodePostal":"8080"},
    {"Ville":"Takelsa","Cite":"Cite Erriadh","CodePostal":"8031"},
    {"Ville":"Hammamet","Cite":"Cite Ennaim","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Ahmed Othman","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite El Izdihar","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Ezzouhour","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Lazidine","CodePostal":"8080"},
    {"Ville":"Takelsa","Cite":"Cite Populaire","CodePostal":"8031"},
    {"Ville":"Hammamet","Cite":"Cite Essafsaf","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Azaiez","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite El Kharroub","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Latrech","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Menzel Issa","CodePostal":"8080"},
    {"Ville":"Takelsa","Cite":"Douala","CodePostal":"8031"},
    {"Ville":"Hammamet","Cite":"Cite Garde Nationale","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Bahroun","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite El Ksour","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Mekki","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Menzel Temime","CodePostal":"8080"},
    {"Ville":"Takelsa","Cite":"El Bekekcha","CodePostal":"8031"},
    {"Ville":"Hammamet","Cite":"Cite Harboun 1","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Ben Hjel","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite El Yasmine","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Mokhtar Rhouma","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Sidi Abdelmonem","CodePostal":"8080"},
    {"Ville":"Takelsa","Cite":"El Brij","CodePostal":"8031"},
    {"Ville":"Hammamet","Cite":"Cite Sidi Ali","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Chaafouri","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite Estah","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Sidi Bou Yahia","CodePostal":"8021"},
    {"Ville":"Menzel Temime","Cite":"Sidi Jameleddine","CodePostal":"8080"},
    {"Ville":"Takelsa","Cite":"El Maisra","CodePostal":"8031"},
    {"Ville":"Hammamet","Cite":"Cite Snit 1","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Cheikh","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite Jawaher1","CodePostal":"8056"},
    {"Ville":"Beni Khalled","Cite":"Cite Souissi","CodePostal":"8021"},
    {"Ville":"Grombalia","Cite":"Khanguet El Hojjaj","CodePostal":"8082"},
    {"Ville":"Takelsa","Cite":"Ghars  Mrad","CodePostal":"8031"},
    {"Ville":"Hammamet","Cite":"Cite Snit 2","CodePostal":"8042"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Cherif","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite Jawaher2","CodePostal":"8056"},
    {"Ville":"Bou Argoub","Cite":"Belli","CodePostal":"8022"},
    {"Ville":"Menzel Temime","Cite":"Rainine","CodePostal":"8083"},
    {"Ville":"Takelsa","Cite":"Henchir Chedli","CodePostal":"8031"},
    {"Ville":"Korba","Cite":"Bou Jerida","CodePostal":"8043"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Daghbagi","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite Merzgui","CodePostal":"8056"},
    {"Ville":"Beni Khiar","Cite":"Cite Bou Jaafar","CodePostal":"8023"},
    {"Ville":"Grombalia","Cite":"Turki","CodePostal":"8084"},
    {"Ville":"Takelsa","Cite":"Oued El Abid","CodePostal":"8031"},
    {"Ville":"El Mida","Cite":"Ain El Ghrab","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Ennouzha","CodePostal":"8010"},
    {"Ville":"Hammamet","Cite":"Cite Snit","CodePostal":"8056"},
    {"Ville":"Beni Khiar","Cite":"Cite Chafrada 1","CodePostal":"8023"},
    {"Ville":"El Mida","Cite":"Fartouna","CodePostal":"8089"},
    {"Ville":"Takelsa","Cite":"Ramel","CodePostal":"8031"},
    {"Ville":"El Mida","Cite":"Cite Dhahret Ezzaouia","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Essahli","CodePostal":"8010"},
    {"Ville":"Beni Khiar","Cite":"Beni Khiar","CodePostal":"8060"},
    {"Ville":"Beni Khiar","Cite":"Cite Chafrada 2","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Abene","CodePostal":"8090"},
    {"Ville":"Takelsa","Cite":"Rtiba","CodePostal":"8031"},
    {"Ville":"El Mida","Cite":"Cite Essoualmia","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Ettahrir","CodePostal":"8010"},
    {"Ville":"Beni Khiar","Cite":"Cite Erriadh","CodePostal":"8060"},
    {"Ville":"Beni Khiar","Cite":"Cite De La Liberte","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Charaf","CodePostal":"8090"},
    {"Ville":"Takelsa","Cite":"Sidi Issa","CodePostal":"8031"},
    {"Ville":"El Mida","Cite":"El Khelaifa","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Jemmali","CodePostal":"8010"},
    {"Ville":"Beni Khiar","Cite":"Diar Ben Salem","CodePostal":"8060"},
    {"Ville":"Beni Khiar","Cite":"Cite Des Jeunes","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Cite Afh","CodePostal":"8090"},
    {"Ville":"Takelsa","Cite":"Takelsa","CodePostal":"8031"},
    {"Ville":"El Mida","Cite":"El Maisra","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Laroui","CodePostal":"8010"},
    {"Ville":"Bou Argoub","Cite":"Borj El Grais","CodePostal":"8061"},
    {"Ville":"Beni Khiar","Cite":"Cite Du Jardin","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Cite Assemmer","CodePostal":"8090"},
    {"Ville":"Hammamet","Cite":"Sidi Jedidi","CodePostal":"8032"},
    {"Ville":"El Mida","Cite":"El Mida","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Municipale Barket","CodePostal":"8010"},
    {"Ville":"Bou Argoub","Cite":"Sidi Dhaher","CodePostal":"8061"},
    {"Ville":"Beni Khiar","Cite":"Cite El Manar","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Cite Ksiba","CodePostal":"8090"},
    {"Ville":"Korba","Cite":"Diar El Hojjej","CodePostal":"8033"},
    {"Ville":"El Mida","Cite":"Erreghine","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Neguira","CodePostal":"8010"},
    {"Ville":"Nabeul","Cite":"Cite El Mahrsi 1","CodePostal":"8062"},
    {"Ville":"Beni Khiar","Cite":"Cite Ezzouhour","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Cite Riadh","CodePostal":"8090"},
    {"Ville":"Menzel Temime","Cite":"Lebna","CodePostal":"8034"},
    {"Ville":"El Mida","Cite":"Errouiguette","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Souihli","CodePostal":"8010"},
    {"Ville":"Nabeul","Cite":"Cite El Mahrsi 2","CodePostal":"8062"},
    {"Ville":"Beni Khiar","Cite":"Cite Mansour","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Dar Chichou","CodePostal":"8090"},
    {"Ville":"Kelibia","Cite":"Azmour","CodePostal":"8035"},
    {"Ville":"El Mida","Cite":"Menzel Touhami","CodePostal":"8044"},
    {"Ville":"Menzel Bouzelfa","Cite":"Cite Souissi","CodePostal":"8010"},
    {"Ville":"Nabeul","Cite":"Cite El Mahrsi 3","CodePostal":"8062"},
    {"Ville":"Beni Khiar","Cite":"Cite Populaire 1","CodePostal":"8023"},
    {"Ville":"Kelibia","Cite":"Dar Dabous","CodePostal":"8090"},
    {"Ville":"Kelibia","Cite":"Beni Ayech","CodePostal":"8035"},
    {"Ville":"El Amra","Cite":"Iffi","CodePostal":"3036"},
    {"Ville":"Sfax Est","Cite":"Cite De La Sante","CodePostal":"3052"},
    {"Ville":"Sfax Ville","Cite":"El Achach","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Ouled Bou Ali","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Cite Habib Bourguiba","CodePostal":"3020"},
    {"Ville":"Agareb","Cite":"Gargour","CodePostal":"3037"},
    {"Ville":"Sfax Est","Cite":"Cite Dounia","CodePostal":"3052"},
    {"Ville":"Sfax Ville","Cite":"Habbana 2","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Ouled Yaneg","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Cite Habib Thameur","CodePostal":"3020"},
    {"Ville":"Sfax Ville","Cite":"Caid Mhamed","CodePostal":"3039"},
    {"Ville":"Sfax Est","Cite":"Cite El Habib","CodePostal":"3052"},
    {"Ville":"Sfax Ville","Cite":"Majen Eddroj","CodePostal":"3000"},
    {"Ville":"Sfax Est","Cite":"Oued Chaabouni","CodePostal":"3071"},
    {"Ville":"Menzel Chaker","Cite":"Cite Merkez Sellami","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Bir Ali Ben Khelifa","CodePostal":"3040"},
    {"Ville":"Sfax Est","Cite":"Cite El Menzah","CodePostal":"3052"},
    {"Ville":"Sfax Ville","Cite":"Merkez Ben Ameur","CodePostal":"3000"},
    {"Ville":"Sfax Est","Cite":"Merkez Chaker","CodePostal":"3072"},
    {"Ville":"Menzel Chaker","Cite":"Cite Taieb Mhiri","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Bir Ouled Mahmoud","CodePostal":"3040"},
    {"Ville":"Sfax Est","Cite":"Cite El Messaadine","CodePostal":"3052"},
    {"Ville":"Sfax Ville","Cite":"Merkez Bermaki","CodePostal":"3000"},
    {"Ville":"Esskhira","Cite":"Sbih","CodePostal":"3073"},
    {"Ville":"Menzel Chaker","Cite":"El Aitha","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Cheraiet","CodePostal":"3040"},
    {"Ville":"Sfax Est","Cite":"Cite El Yasmine","CodePostal":"3052"},
    {"Ville":"Sfax Ville","Cite":"Merkez Bou Khedir","CodePostal":"3000"},
    {"Ville":"Sfax Sud","Cite":"El Aouabed","CodePostal":"3074"},
    {"Ville":"Menzel Chaker","Cite":"Hai Kacem","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Cite Des Roses 1","CodePostal":"3040"},
    {"Ville":"Sfax Est","Cite":"Cite Essaada","CodePostal":"3052"},
    {"Ville":"Sfax Ville","Cite":"Merkez Guirat","CodePostal":"3000"},
    {"Ville":"Agareb","Cite":"Merkez Sghar","CodePostal":"3075"},
    {"Ville":"Menzel Chaker","Cite":"Haj Kacem 1","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Cite Des Roses 2","CodePostal":"3040"},
    {"Ville":"El Hencha","Cite":"Sidi Hassen","CodePostal":"3053"},
    {"Ville":"Sfax Ville","Cite":"Merkez Jellouli","CodePostal":"3000"},
    {"Ville":"Sfax Sud","Cite":"El Aouyet","CodePostal":"3076"},
    {"Ville":"Menzel Chaker","Cite":"Haj Kacem 2","CodePostal":"3020"},
    {"Ville":"Menzel Chaker","Cite":"Ksar Errih","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Cite El Fateh","CodePostal":"3040"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite El Afrane","CodePostal":"3054"},
    {"Ville":"Sfax Ville","Cite":"Merkez Zouayed","CodePostal":"3000"},
    {"Ville":"Sfax Sud","Cite":"Merkez Aloui","CodePostal":"3076"},
    {"Ville":"Menzel Chaker","Cite":"Limaya","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Cite El Menzel","CodePostal":"3040"},
    {"Ville":"Sakiet Eddaier","Cite":"Merkez Sebai","CodePostal":"3054"},
    {"Ville":"Sfax Ville","Cite":"Oued Laachech","CodePostal":"3000"},
    {"Ville":"Jebeniana","Cite":"Douar Louata","CodePostal":"3077"},
    {"Ville":"Menzel Chaker","Cite":"Menzel Hedi Chaker","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"El Aouyed","CodePostal":"3040"},
    {"Ville":"Kerkenah","Cite":"El Kraten","CodePostal":"3055"},
    {"Ville":"Sfax Ville","Cite":"Rouadhi","CodePostal":"3000"},
    {"Ville":"Sfax Sud","Cite":"El Hajeb","CodePostal":"3078"},
    {"Ville":"Menzel Chaker","Cite":"Oued Lazrag","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"El Oudrane","CodePostal":"3040"},
    {"Ville":"Jebeniana","Cite":"Ouled Bousmir","CodePostal":"3056"},
    {"Ville":"Sfax Ville","Cite":"Sfax","CodePostal":"3000"},
    {"Ville":"Sfax Ville","Cite":"Hai El Khiri","CodePostal":"3079"},
    {"Ville":"Menzel Chaker","Cite":"Tlil El Ajla","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Letaifa","CodePostal":"3040"},
    {"Ville":"Agareb","Cite":"Jeberna","CodePostal":"3057"},
    {"Ville":"Sfax Ville","Cite":"Sidi Abdelkefi","CodePostal":"3000"},
    {"Ville":"Jebeniana","Cite":"Ain El Ghezal","CodePostal":"3080"},
    {"Ville":"Menzel Chaker","Cite":"Zaibet","CodePostal":"3020"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Merkez El Hamrouni","CodePostal":"3040"},
    {"Ville":"Sfax Sud","Cite":"El Khazzanette","CodePostal":"3059"},
    {"Ville":"Sfax Ville","Cite":"Sidi Jilani","CodePostal":"3000"},
    {"Ville":"Jebeniana","Cite":"Blitech","CodePostal":"3080"},
    {"Ville":"Sakiet Ezzit","Cite":"Sakiet Ezzit","CodePostal":"3021"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Oued Rekham","CodePostal":"3040"},
    {"Ville":"Mahras","Cite":"Belhouchette","CodePostal":"3060"},
    {"Ville":"Sfax Ville","Cite":"Sidi Liteyem","CodePostal":"3000"},
    {"Ville":"Jebeniana","Cite":"Cite El Bassatine","CodePostal":"3080"},
    {"Ville":"Sfax Sud","Cite":"Merkez Kamoun","CodePostal":"3022"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Ras Fartout","CodePostal":"3040"},
    {"Ville":"Mahras","Cite":"Chaal Gare","CodePostal":"3060"},
    {"Ville":"Sfax Ville","Cite":"Soualmia","CodePostal":"3000"},
    {"Ville":"Jebeniana","Cite":"Cite El Fateh","CodePostal":"3080"},
    {"Ville":"Sfax Est","Cite":"Oued Rmal","CodePostal":"3023"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Ras Souinia","CodePostal":"3040"},
    {"Ville":"Mahras","Cite":"Chaffar","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Bir Chaaba","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"Cite Jardins","CodePostal":"3080"},
    {"Ville":"Ghraiba","Cite":"Belhouichette","CodePostal":"3024"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Rebaya","CodePostal":"3040"},
    {"Ville":"Mahras","Cite":"Cite Bourguiba","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite Algerie","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"El Botria","CodePostal":"3080"},
    {"Ville":"Ghraiba","Cite":"Chaal","CodePostal":"3024"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Sidi Dhaher","CodePostal":"3040"},
    {"Ville":"Mahras","Cite":"Cite El Bouhali","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite Cherif","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"El Gherasa","CodePostal":"3080"},
    {"Ville":"Ghraiba","Cite":"Ettakaddoum","CodePostal":"3024"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Sidi Mansour","CodePostal":"3040"},
    {"Ville":"Mahras","Cite":"Cite El Guidira","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite Commerciale 1","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"El Guelalja","CodePostal":"3080"},
    {"Ville":"Ghraiba","Cite":"Rabta","CodePostal":"3024"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Souinia","CodePostal":"3040"},
    {"Ville":"Mahras","Cite":"Cite Ennajah","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite Commerciale 2","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"Henchir El Euch","CodePostal":"3080"},
    {"Ville":"Ghraiba","Cite":"Skaina","CodePostal":"3024"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Toulb","CodePostal":"3040"},
    {"Ville":"Mahras","Cite":"Cite Ennasr","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite El Fateh","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"Jebeniana","CodePostal":"3080"},
    {"Ville":"Ghraiba","Cite":"Smara","CodePostal":"3024"},
    {"Ville":"Sakiet Ezzit","Cite":"Cite 20 Mars","CodePostal":"3041"},
    {"Ville":"Mahras","Cite":"Cite Ennour","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite El Fheil","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"Ouled Ahmed","CodePostal":"3080"},
    {"Ville":"Ghraiba","Cite":"Tarfaoui","CodePostal":"3024"},
    {"Ville":"Sakiet Ezzit","Cite":"Cite Ennasr","CodePostal":"3041"},
    {"Ville":"Mahras","Cite":"Cite Flemech","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite El Hancha Est","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"Ouled Msallem Edkhal","CodePostal":"3080"},
    {"Ville":"Kerkenah","Cite":"Ouled Kacem","CodePostal":"3025"},
    {"Ville":"Sakiet Ezzit","Cite":"Cite Ennouzha","CodePostal":"3041"},
    {"Ville":"Mahras","Cite":"Cite Huillerie","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite El Hancha Ouest","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"Ouled Youssef","CodePostal":"3080"},
    {"Ville":"Jebeniana","Cite":"Aguegcha","CodePostal":"3026"},
    {"Ville":"Sakiet Ezzit","Cite":"El Mnasria","CodePostal":"3041"},
    {"Ville":"Mahras","Cite":"El Hachichinia","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite El Hancha Sud","CodePostal":"3010"},
    {"Ville":"Jebeniana","Cite":"Sidi Msarra","CodePostal":"3080"},
    {"Ville":"Jebeniana","Cite":"Hazeg","CodePostal":"3026"},
    {"Ville":"Sakiet Ezzit","Cite":"Merkez Chihya","CodePostal":"3041"},
    {"Ville":"Mahras","Cite":"El Mouessette","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite El Ifa","CodePostal":"3010"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite El Mallek","CodePostal":"3081"},
    {"Ville":"Jebeniana","Cite":"Ouled Mnasser","CodePostal":"3026"},
    {"Ville":"Sfax Sud","Cite":"El Ain","CodePostal":"3042"},
    {"Ville":"Mahras","Cite":"Mahras","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite El Kouifla","CodePostal":"3010"},
    {"Ville":"Sakiet Eddaier","Cite":"Essaadi","CodePostal":"3081"},
    {"Ville":"Sfax Ville","Cite":"Sfax El Jadida","CodePostal":"3027"},
    {"Ville":"Sfax Sud","Cite":"Merkez Aloulou","CodePostal":"3042"},
    {"Ville":"Mahras","Cite":"Smara","CodePostal":"3060"},
    {"Ville":"El Hencha","Cite":"Cite El Ojna","CodePostal":"3010"},
    {"Ville":"Sakiet Eddaier","Cite":"Essaltania","CodePostal":"3081"},
    {"Ville":"Agareb","Cite":"Agareb","CodePostal":"3030"},
    {"Ville":"Sfax Sud","Cite":"Merkez Jilani","CodePostal":"3042"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite Ennadhour","CodePostal":"3061"},
    {"Ville":"El Hencha","Cite":"Cite Environnement","CodePostal":"3010"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"El Guendoul","CodePostal":"3082"},
    {"Ville":"Agareb","Cite":"Ben Sahloun","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"Bir Lahmar","CodePostal":"3043"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite Erriadh","CodePostal":"3061"},
    {"Ville":"El Hencha","Cite":"Cite Ouled Aicha","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite Badrani","CodePostal":"3083"},
    {"Ville":"Agareb","Cite":"Cite 2 Mars","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"Cite Populaire","CodePostal":"3043"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite Nouvelle","CodePostal":"3061"},
    {"Ville":"El Hencha","Cite":"Cite Oum Salah","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite El Mghaier","CodePostal":"3083"},
    {"Ville":"Agareb","Cite":"Cite 2 Mars 1","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"Doukhane","CodePostal":"3043"},
    {"Ville":"Sakiet Eddaier","Cite":"Sidi Mansour","CodePostal":"3061"},
    {"Ville":"El Hencha","Cite":"El Ghieb","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite Ennasr","CodePostal":"3083"},
    {"Ville":"Agareb","Cite":"Cite 2 Mars 2","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"El Ghraba","CodePostal":"3043"},
    {"Ville":"Sfax Sud","Cite":"Sidi Abbes","CodePostal":"3062"},
    {"Ville":"El Hencha","Cite":"El Guetatsa","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite Moez 1","CodePostal":"3083"},
    {"Ville":"Agareb","Cite":"Cite Cnel","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"El Khecherma","CodePostal":"3043"},
    {"Ville":"Sakiet Eddaier","Cite":"El Khalij","CodePostal":"3063"},
    {"Ville":"El Hencha","Cite":"El Hachana","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite Moez 2","CodePostal":"3083"},
    {"Ville":"Agareb","Cite":"Cite El Izdihar","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"Esseghara","CodePostal":"3043"},
    {"Ville":"Sfax Est","Cite":"Cite El Bahri","CodePostal":"3064"},
    {"Ville":"El Hencha","Cite":"El Hajjara","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite Moez 4","CodePostal":"3083"},
    {"Ville":"Agareb","Cite":"Cite El Menzah","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"Henchir El Joub","CodePostal":"3043"},
    {"Ville":"Sfax Est","Cite":"Cite El Foll","CodePostal":"3064"},
    {"Ville":"El Hencha","Cite":"El Helalfa","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite Sidi Abid","CodePostal":"3083"},
    {"Ville":"Agareb","Cite":"Cite Ennour","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"Merkez Choucha","CodePostal":"3043"},
    {"Ville":"Sfax Est","Cite":"Cite El Wafa","CodePostal":"3064"},
    {"Ville":"El Hencha","Cite":"El Hencha","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Tyna","CodePostal":"3083"},
    {"Ville":"Agareb","Cite":"Cite Snit","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"Sidi Mhamed Ben Amor","CodePostal":"3043"},
    {"Ville":"Sfax Est","Cite":"Cite Essalem","CodePostal":"3064"},
    {"Ville":"El Hencha","Cite":"Henchir El Mosbah","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite Tyna 1","CodePostal":"3084"},
    {"Ville":"Agareb","Cite":"El Mahrouga","CodePostal":"3030"},
    {"Ville":"El Hencha","Cite":"Soualmia","CodePostal":"3043"},
    {"Ville":"Sfax Est","Cite":"Cite Jaouhara","CodePostal":"3064"},
    {"Ville":"El Hencha","Cite":"Jalaila","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Cite Tyna 2","CodePostal":"3084"},
    {"Ville":"Agareb","Cite":"El Maraania","CodePostal":"3030"},
    {"Ville":"Mahras","Cite":"El Mourouj","CodePostal":"3044"},
    {"Ville":"Sfax Ville","Cite":"Sfax Port","CodePostal":"3065"},
    {"Ville":"El Hencha","Cite":"Ouled Amor","CodePostal":"3010"},
    {"Ville":"Sfax Sud","Cite":"Tyna El Jadida","CodePostal":"3084"},
    {"Ville":"Agareb","Cite":"El Msadga","CodePostal":"3030"},
    {"Ville":"Mahras","Cite":"Nekta","CodePostal":"3044"},
    {"Ville":"Sfax Ville","Cite":"Ain Charfi","CodePostal":"3000"},
    {"Ville":"El Amra","Cite":"Essaadi","CodePostal":"3066"},
    {"Ville":"El Hencha","Cite":"Ouled Tahar","CodePostal":"3010"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Oum Choucha","CodePostal":"3085"},
    {"Ville":"Agareb","Cite":"Merkez Attia","CodePostal":"3030"},
    {"Ville":"Kerkenah","Cite":"Ennajet","CodePostal":"3045"},
    {"Ville":"Sfax Ville","Cite":"Ain El Fellat","CodePostal":"3000"},
    {"Ville":"El Amra","Cite":"Merkez El Khemiri","CodePostal":"3066"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite De Le Liberte","CodePostal":"3011"},
    {"Ville":"Jebeniana","Cite":"Ajenga","CodePostal":"3086"},
    {"Ville":"Agareb","Cite":"Merkez Bou Ledhieb","CodePostal":"3030"},
    {"Ville":"El Amra","Cite":"El Mhara","CodePostal":"3046"},
    {"Ville":"Sfax Ville","Cite":"Ain El Mayel","CodePostal":"3000"},
    {"Ville":"El Amra","Cite":"Oued Laachech","CodePostal":"3066"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite El Amel","CodePostal":"3011"},
    {"Ville":"El Amra","Cite":"Beliana","CodePostal":"3087"},
    {"Ville":"Agareb","Cite":"Merkez Jouaouda","CodePostal":"3030"},
    {"Ville":"El Amra","Cite":"Essalem","CodePostal":"3046"},
    {"Ville":"Sfax Ville","Cite":"Ain Tourkia","CodePostal":"3000"},
    {"Ville":"Sakiet Eddaier","Cite":"Merkez Lajmi","CodePostal":"3067"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite El Haffara","CodePostal":"3011"},
    {"Ville":"Sfax Ville","Cite":"Ennigrou","CodePostal":"3089"},
    {"Ville":"Agareb","Cite":"Merkez Tijani","CodePostal":"3030"},
    {"Ville":"Sfax Ville","Cite":"Bab El Jebli","CodePostal":"3047"},
    {"Ville":"Sfax Ville","Cite":"Ain Turki","CodePostal":"3000"},
    {"Ville":"Sfax Ville","Cite":"Sfax Hached","CodePostal":"3069"},
    {"Ville":"Sakiet Eddaier","Cite":"Merkez Kaaniche","CodePostal":"3011"},
    {"Ville":"Sakiet Ezzit","Cite":"Sidi Salah","CodePostal":"3091"},
    {"Ville":"Agareb","Cite":"Traka","CodePostal":"3030"},
    {"Ville":"Sfax Ville","Cite":"Souk El Feriani","CodePostal":"3048"},
    {"Ville":"Sfax Ville","Cite":"Bir Ben Ayed","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Chergui","CodePostal":"3070"},
    {"Ville":"Sakiet Eddaier","Cite":"Sakiet Eddaier","CodePostal":"3011"},
    {"Ville":"Menzel Chaker","Cite":"Bou Jarbou","CodePostal":"3092"},
    {"Ville":"Agareb","Cite":"Zeliana","CodePostal":"3030"},
    {"Ville":"Sfax Ville","Cite":"Sfax Magreb Arabe","CodePostal":"3049"},
    {"Ville":"Sfax Ville","Cite":"Bir El Mellouli","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Ancienne Kellaline","CodePostal":"3070"},
    {"Ville":"Sfax Sud","Cite":"Merkez Sahnoun","CodePostal":"3012"},
    {"Ville":"Sfax Sud","Cite":"Merkez Ouali","CodePostal":"3093"},
    {"Ville":"Sakiet Ezzit","Cite":"Merkez Bouacida","CodePostal":"3031"},
    {"Ville":"Esskhira","Cite":"Bou Said","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Bir Tebak","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Bouaid Kellaline","CodePostal":"3070"},
    {"Ville":"Sfax Sud","Cite":"Cite 2000","CodePostal":"3013"},
    {"Ville":"Sakiet Eddaier","Cite":"Cite Bourguiba","CodePostal":"3094"},
    {"Ville":"Sfax Ville","Cite":"Merkez Derouiche","CodePostal":"3032"},
    {"Ville":"Esskhira","Cite":"Cherafra","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Bourguiba 2","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Cnel","CodePostal":"3070"},
    {"Ville":"Sfax Sud","Cite":"Merkez Chaabouni","CodePostal":"3013"},
    {"Ville":"El Amra","Cite":"Dhraa Ben Ziad","CodePostal":"3095"},
    {"Ville":"El Hencha","Cite":"Bir Salah","CodePostal":"3033"},
    {"Ville":"Esskhira","Cite":"El Gherairia","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Bouzid","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Dar Oum Dlel","CodePostal":"3070"},
    {"Ville":"Sfax Sud","Cite":"Merkez Dammak","CodePostal":"3013"},
    {"Ville":"El Amra","Cite":"El Ketatna","CodePostal":"3095"},
    {"Ville":"Ghraiba","Cite":"El Hammam","CodePostal":"3034"},
    {"Ville":"Esskhira","Cite":"El Hicha","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Cnrps","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Douirette","CodePostal":"3070"},
    {"Ville":"Sfax Sud","Cite":"Merkez Kassas","CodePostal":"3013"},
    {"Ville":"Bir Ali Ben Khelifa","Cite":"Rebaya Sidi Dhaher","CodePostal":"3097"},
    {"Ville":"Ghraiba","Cite":"Essoudane","CodePostal":"3034"},
    {"Ville":"Esskhira","Cite":"El Maghdhia","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Commerciale","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite El Azezba","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Bou Thadi","CodePostal":"3014"},
    {"Ville":"Sfax Ville","Cite":"El Bousten","CodePostal":"3099"},
    {"Ville":"Ghraiba","Cite":"Ghraiba","CodePostal":"3034"},
    {"Ville":"Esskhira","Cite":"El Melha","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Compensation","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite El Mahjar","CodePostal":"3070"},
    {"Ville":"Kerkenah","Cite":"Cite Groupe 1","CodePostal":"3015"},
    {"Ville":"Kerkenah","Cite":"El Attaya","CodePostal":"3035"},
    {"Ville":"Esskhira","Cite":"Esskhira","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite De Le Liberte","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Houmet Glace","CodePostal":"3070"},
    {"Ville":"Kerkenah","Cite":"Cite Groupe 2","CodePostal":"3015"},
    {"Ville":"El Amra","Cite":"Cite 7 Novembre","CodePostal":"3036"},
    {"Ville":"Esskhira","Cite":"Fondouk Chibani","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Du Jardin 1","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Nouvelle Kellaline","CodePostal":"3070"},
    {"Ville":"Kerkenah","Cite":"Mellita","CodePostal":"3015"},
    {"Ville":"El Amra","Cite":"Cite El Hmerna","CodePostal":"3036"},
    {"Ville":"Esskhira","Cite":"Frichette","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite El Amel","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Ourand","CodePostal":"3070"},
    {"Ville":"Jebeniana","Cite":"El Louza","CodePostal":"3016"},
    {"Ville":"El Amra","Cite":"Cite Ezzayatine","CodePostal":"3036"},
    {"Ville":"Esskhira","Cite":"Jerouala","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite El Maaref","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Populaire","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Bechka","CodePostal":"3020"},
    {"Ville":"El Amra","Cite":"Cite Houma Charguia","CodePostal":"3036"},
    {"Ville":"Esskhira","Cite":"Kenitra","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite El Mourouj","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Cite Rakhani","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Chaaleb","CodePostal":"3020"},
    {"Ville":"El Amra","Cite":"Cite Populaire 1","CodePostal":"3036"},
    {"Ville":"Esskhira","Cite":"Naoual","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Essourour","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"El Abbassia","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Cite 7 Novembre","CodePostal":"3020"},
    {"Ville":"El Amra","Cite":"Cite Populaire 2","CodePostal":"3036"},
    {"Ville":"Esskhira","Cite":"Ouled Haj Moussa","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Ezzitouna","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Jouaber","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Cite De La Gare","CodePostal":"3020"},
    {"Ville":"El Amra","Cite":"Cite Populaire 3","CodePostal":"3036"},
    {"Ville":"Esskhira","Cite":"Sidi Mhamed Nouiguez","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Joua","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Kellabine","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Cite El Habib","CodePostal":"3020"},
    {"Ville":"El Amra","Cite":"El Amra","CodePostal":"3036"},
    {"Ville":"Esskhira","Cite":"Souani","CodePostal":"3050"},
    {"Ville":"Sfax Ville","Cite":"Cite Nouvelle Kerkouan","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Kerkenah","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Cite Erraoudha","CodePostal":"3020"},
    {"Ville":"El Amra","Cite":"El Fidh","CodePostal":"3036"},
    {"Ville":"Sfax Ville","Cite":"Merkez El Alia","CodePostal":"3051"},
    {"Ville":"Sfax Ville","Cite":"Cite Siape","CodePostal":"3000"},
    {"Ville":"Kerkenah","Cite":"Mellita Ouled Bou  Ali","CodePostal":"3070"},
    {"Ville":"Menzel Chaker","Cite":"Cite Ettahrir","CodePostal":"3020"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Oum Ettouboul","CodePostal":"9131"},
    {"Ville":"Regueb","Cite":"Bir Gzaiel","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"El Hawajbia","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Sidi Khlif","CodePostal":"9180"},
    {"Ville":"Regueb","Cite":"El Ghaba Essouda","CodePostal":"9115"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Cite El Kaouafel","CodePostal":"9132"},
    {"Ville":"Regueb","Cite":"Bir Khelifa","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Essadaguia","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"El Abbadette","CodePostal":"9183"},
    {"Ville":"Regueb","Cite":"Essfaya","CodePostal":"9115"},
    {"Ville":"Sidi Bouzid Est","Cite":"El Haouamed","CodePostal":"9133"},
    {"Ville":"Regueb","Cite":"Bir Nsib","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Essandoug","CodePostal":"9100"},
    {"Ville":"Regueb","Cite":"Ouled El Hani","CodePostal":"9115"},
    {"Ville":"Sidi Bouzid Est","Cite":"Remaidia","CodePostal":"9133"},
    {"Ville":"Regueb","Cite":"Bou Allegue","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Melikette","CodePostal":"9100"},
    {"Ville":"Regueb","Cite":"Saida","CodePostal":"9115"},
    {"Ville":"Menzel Bouzaiene","Cite":"El Guellel","CodePostal":"9139"},
    {"Ville":"Regueb","Cite":"Bou Dinar","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Ouithet Gazal","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Dhouibette","CodePostal":"9116"},
    {"Ville":"Maknassy","Cite":"Cite Des Abricots","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite De La Republique","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Sidi Bouzid","CodePostal":"9100"},
    {"Ville":"Ben Oun","Cite":"Baten El Agaag","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite Des Martyrs","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite El Bassatine","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Sidi Salem","CodePostal":"9100"},
    {"Ville":"Ben Oun","Cite":"Ben Bechir","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite El Fateh 1","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite El Intilaka","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Touila","CodePostal":"9100"},
    {"Ville":"Ben Oun","Cite":"Ben Oun","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite El Fateh 2","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite El Khadhra","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Zaafria","CodePostal":"9100"},
    {"Ville":"Ben Oun","Cite":"Ben Oun Nord","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite El Fateh 3","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite El Manar","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Ain Jaffel","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Bir Idriss","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite El Intilaka","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite Ennasr","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Cite Chargui","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Cite El Ilm","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite Ennajah 1","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite Ennouzha","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Cite Ennasr","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Cite Ennasr","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite Ennajah 2","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite Erriadh","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Cite Ouled Zaied","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Cite Ennour","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite Ezzayatine","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite Ezzouhour","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Cite Populaire","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Cite Erriadh","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite Militaire","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Cite Ibn Khaldoun","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"El Fenideg","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Cite Ettahrir","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite Mohamed Ali","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"El Gouadria","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"El Haouia","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Cite Ezzouhour","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite Taieb Mhiri","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"El Houda","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Guellel","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Damdoum","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Cite Unite Ennajah","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"El Mhafdhia","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Jilma","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"El Graoua","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Dakhlet El Alenda","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Essakba","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Labayedh","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"El Khalij","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"El Ayoun","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Ezzadam","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Sed El Yahoudi","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"El Ouaara","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"El Houayez","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Ezzitouna","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Selta","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"El Ouneysia","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Ezzouaraa","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Farch Guerib","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Sidi Ali Ebn Jaballah","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Markez Ben Bechir","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Jabbes","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Guenifida","CodePostal":"9170"},
    {"Ville":"Jilma","Cite":"Zoghmar","CodePostal":"9110"},
    {"Ville":"Ben Oun","Cite":"Ouled Brahim","CodePostal":"9120"},
    {"Ville":"Ben Oun","Cite":"Ouled Neji","CodePostal":"9120"},
    {"Ville":"Maknassy","Cite":"Ksar Khelifa Zenati","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Henchir Khdam","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Bir El Bey","CodePostal":"9111"},
    {"Ville":"Souk Jedid","Cite":"Bou Attouch","CodePostal":"9121"},
    {"Ville":"Maknassy","Cite":"Maknassy","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Ksar El Hammam","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"El Adhla","CodePostal":"9111"},
    {"Ville":"Souk Jedid","Cite":"Cegdel","CodePostal":"9121"},
    {"Ville":"Maknassy","Cite":"Marche Municipal","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Ksar Sai","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"El Ghemamria","CodePostal":"9111"},
    {"Ville":"Souk Jedid","Cite":"Douadnia","CodePostal":"9121"},
    {"Ville":"Maknassy","Cite":"Touahria 2","CodePostal":"9140"},
    {"Ville":"Regueb","Cite":"Matlag","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"El Guetayfia","CodePostal":"9111"},
    {"Ville":"Souk Jedid","Cite":"El Azara","CodePostal":"9121"},
    {"Ville":"Sidi Bouzid Est","Cite":"El Makarem","CodePostal":"9141"},
    {"Ville":"Regueb","Cite":"Ouled Abdallah","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"El Mlikette","CodePostal":"9111"},
    {"Ville":"Souk Jedid","Cite":"El Hessinette","CodePostal":"9121"},
    {"Ville":"Sidi Bouzid Est","Cite":"Ouled Farhan","CodePostal":"9141"},
    {"Ville":"Regueb","Cite":"Ouled Ayouni","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Oued El Brij","CodePostal":"9111"},
    {"Ville":"Souk Jedid","Cite":"El Kessayra","CodePostal":"9121"},
    {"Ville":"Souk Jedid","Cite":"Bou Chmel","CodePostal":"9142"},
    {"Ville":"Regueb","Cite":"Ouled Chabou","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Oum El Adham","CodePostal":"9111"},
    {"Ville":"Souk Jedid","Cite":"El Mesatria","CodePostal":"9121"},
    {"Ville":"Souk Jedid","Cite":"El Bekakria","CodePostal":"9142"},
    {"Ville":"Regueb","Cite":"Regueb","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Est","Cite":"Ain Bouzer","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"El Msabhia","CodePostal":"9121"},
    {"Ville":"Souk Jedid","Cite":"El Hachana","CodePostal":"9142"},
    {"Ville":"Regueb","Cite":"Rihana Nord","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Est","Cite":"Ain Rabbaou","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"Ennouhoudh","CodePostal":"9121"},
    {"Ville":"Souk Jedid","Cite":"Gouleb","CodePostal":"9142"},
    {"Ville":"Regueb","Cite":"Rihana Sud","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Est","Cite":"Bazid","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"Essadlia","CodePostal":"9121"},
    {"Ville":"Souk Jedid","Cite":"Ouled  Dhaher","CodePostal":"9142"},
    {"Ville":"Regueb","Cite":"Sabra","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Est","Cite":"El Ogla","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"Ouled Alaya","CodePostal":"9121"},
    {"Ville":"Souk Jedid","Cite":"Remila","CodePostal":"9142"},
    {"Ville":"Regueb","Cite":"Sarayria","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Est","Cite":"Fayedh","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"Ouled Ammar","CodePostal":"9121"},
    {"Ville":"Souk Jedid","Cite":"Zefzef","CodePostal":"9142"},
    {"Ville":"Regueb","Cite":"Sidi Ameur","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Est","Cite":"Gatrana","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"Ouled Amor","CodePostal":"9121"},
    {"Ville":"Maknassy","Cite":"Borj El Karma","CodePostal":"9143"},
    {"Ville":"Sidi Bouzid Est","Cite":"El Hmaima","CodePostal":"9171"},
    {"Ville":"Sidi Bouzid Est","Cite":"Hania Bazid","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"Ouled Khedher","CodePostal":"9121"},
    {"Ville":"Maknassy","Cite":"Mabrouka","CodePostal":"9144"},
    {"Ville":"Sidi Bouzid Est","Cite":"Graa Bennour","CodePostal":"9171"},
    {"Ville":"Sidi Bouzid Est","Cite":"Jemel","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"Ouled Mhamed","CodePostal":"9121"},
    {"Ville":"Maknassy","Cite":"Ennasr","CodePostal":"9149"},
    {"Ville":"Sidi Bouzid Est","Cite":"Lessouda","CodePostal":"9171"},
    {"Ville":"Sidi Bouzid Est","Cite":"Ouled Boudhiaf","CodePostal":"9112"},
    {"Ville":"Souk Jedid","Cite":"Souk Jedid","CodePostal":"9121"},
    {"Ville":"Mezzouna","Cite":"Cite De La Poste","CodePostal":"9150"},
    {"Ville":"Regueb","Cite":"Goubrar","CodePostal":"9172"},
    {"Ville":"Sidi Bouzid Est","Cite":"Ouled Youssef","CodePostal":"9112"},
    {"Ville":"Cebbala","Cite":"Cebbala","CodePostal":"9122"},
    {"Ville":"Mezzouna","Cite":"Cite Des Martyrs","CodePostal":"9150"},
    {"Ville":"Regueb","Cite":"El Khechem","CodePostal":"9173"},
    {"Ville":"Bir El Haffey","Cite":"Bir Amema","CodePostal":"9113"},
    {"Ville":"Cebbala","Cite":"Charaa","CodePostal":"9122"},
    {"Ville":"Mezzouna","Cite":"Cite El Amel","CodePostal":"9150"},
    {"Ville":"Regueb","Cite":"Fatnassa","CodePostal":"9173"},
    {"Ville":"Bir El Haffey","Cite":"Bir Bousbi","CodePostal":"9113"},
    {"Ville":"Cebbala","Cite":"Cite El Fateh 1","CodePostal":"9122"},
    {"Ville":"Mezzouna","Cite":"Cite Independance","CodePostal":"9150"},
    {"Ville":"Regueb","Cite":"Erradhaa","CodePostal":"9174"},
    {"Ville":"Bir El Haffey","Cite":"Bir El Haffey","CodePostal":"9113"},
    {"Ville":"Cebbala","Cite":"Cite El Fateh 2","CodePostal":"9122"},
    {"Ville":"Mezzouna","Cite":"El Gheris","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Boutique Issa","CodePostal":"9180"},
    {"Ville":"Bir El Haffey","Cite":"Chakhar","CodePostal":"9113"},
    {"Ville":"Cebbala","Cite":"Cite Ennajah","CodePostal":"9122"},
    {"Ville":"Mezzouna","Cite":"El Guezgazia","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Chouachnia","CodePostal":"9180"},
    {"Ville":"Bir El Haffey","Cite":"Eddachra","CodePostal":"9113"},
    {"Ville":"Cebbala","Cite":"Cite Ennouzha","CodePostal":"9122"},
    {"Ville":"Mezzouna","Cite":"El Khima","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Cite Ali Ben Hamid","CodePostal":"9180"},
    {"Ville":"Bir El Haffey","Cite":"El Hanancha","CodePostal":"9113"},
    {"Ville":"Cebbala","Cite":"El Ghemamria","CodePostal":"9122"},
    {"Ville":"Mezzouna","Cite":"El Khobna","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Cite Commerciale","CodePostal":"9180"},
    {"Ville":"Bir El Haffey","Cite":"El Mhamdia","CodePostal":"9113"},
    {"Ville":"Bir El Haffey","Cite":"Henchir Haffouz","CodePostal":"9123"},
    {"Ville":"Mezzouna","Cite":"El Khoui","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Cite El Maarifa","CodePostal":"9180"},
    {"Ville":"Bir El Haffey","Cite":"Ouled Khelifa","CodePostal":"9113"},
    {"Ville":"Bir El Haffey","Cite":"Houch Ben Necib","CodePostal":"9123"},
    {"Ville":"Mezzouna","Cite":"Ferjen","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Cite El Omrane","CodePostal":"9180"},
    {"Ville":"Bir El Haffey","Cite":"Ourgha","CodePostal":"9113"},
    {"Ville":"Bir El Haffey","Cite":"Ksar El Mzara","CodePostal":"9123"},
    {"Ville":"Mezzouna","Cite":"Ghedir Rebaia","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Cite Erriadh","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"Bir El Araria","CodePostal":"9114"},
    {"Ville":"Bir El Haffey","Cite":"Ouled Bouaziz","CodePostal":"9123"},
    {"Ville":"Mezzouna","Cite":"Haddej","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Cite Ezzayatine","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"Dakhlet Haddej","CodePostal":"9114"},
    {"Ville":"Bir El Haffey","Cite":"Rahal","CodePostal":"9123"},
    {"Ville":"Mezzouna","Cite":"Mezzouna","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"Dhouibette 2","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"El Aatizez","CodePostal":"9114"},
    {"Ville":"Menzel Bouzaiene","Cite":"Khorchef","CodePostal":"9124"},
    {"Ville":"Mezzouna","Cite":"Oued Eddam","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"El Araoua","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"El Garaa","CodePostal":"9114"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Cite Ouled Bel Hedi","CodePostal":"9125"},
    {"Ville":"Mezzouna","Cite":"Ouled Dlala","CodePostal":"9150"},
    {"Ville":"Ouled Haffouz","Cite":"El Gouaibia","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"El Maadher","CodePostal":"9114"},
    {"Ville":"Bir El Haffey","Cite":"El Adhla","CodePostal":"9126"},
    {"Ville":"Mezzouna","Cite":"El Founi","CodePostal":"9151"},
    {"Ville":"Ouled Haffouz","Cite":"El Kharwaa","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"El Maloussi","CodePostal":"9114"},
    {"Ville":"Bir El Haffey","Cite":"Ennouaiel","CodePostal":"9126"},
    {"Ville":"Mezzouna","Cite":"El Bouaa","CodePostal":"9154"},
    {"Ville":"Ouled Haffouz","Cite":"El Mbarkia","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"El Mhamdia","CodePostal":"9114"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Ettouansia","CodePostal":"9127"},
    {"Ville":"Maknassy","Cite":"El Meche","CodePostal":"9158"},
    {"Ville":"Sidi Bouzid Est","Cite":"Cite Agricole","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Ennajah","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"El Omrane","CodePostal":"9114"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Mefreg El Frayou","CodePostal":"9127"},
    {"Ville":"Bir El Haffey","Cite":"Essalama","CodePostal":"9159"},
    {"Ville":"Sidi Bouzid Est","Cite":"Dhayaa","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Erranzez","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"Essaada","CodePostal":"9114"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Bir Badra","CodePostal":"9131"},
    {"Ville":"Ben Oun","Cite":"El Fej","CodePostal":"9169"},
    {"Ville":"Sidi Bouzid Est","Cite":"Dhraa","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Hania  102","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"Menzel Bouzaiene","CodePostal":"9114"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Eddagher","CodePostal":"9131"},
    {"Ville":"Ben Oun","Cite":"El Gouedria","CodePostal":"9169"},
    {"Ville":"Sidi Bouzid Est","Cite":"Ezzitoune","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Horbit","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"Menzel Saada","CodePostal":"9114"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"El Fateh","CodePostal":"9131"},
    {"Ville":"Ben Oun","Cite":"Erragba","CodePostal":"9169"},
    {"Ville":"Sidi Bouzid Est","Cite":"Garet Hadid","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Khebina","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"Ouled Zouid","CodePostal":"9114"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"El Hichria","CodePostal":"9131"},
    {"Ville":"Ben Oun","Cite":"Ouled El Achi","CodePostal":"9169"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Bir Bouzid","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Ouled Haffouz","CodePostal":"9180"},
    {"Ville":"Menzel Bouzaiene","Cite":"Rbeayia","CodePostal":"9114"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Henchir Esnab","CodePostal":"9131"},
    {"Ville":"Ben Oun","Cite":"Ouled Mnasser","CodePostal":"9169"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Cite El Brahmia","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Sidi Ellafi 1","CodePostal":"9180"},
    {"Ville":"Regueb","Cite":"Bir El Akerma","CodePostal":"9115"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Ouled Jalal","CodePostal":"9131"},
    {"Ville":"Regueb","Cite":"Bir Charef","CodePostal":"9170"},
    {"Ville":"Sidi Bouzid Ouest","Cite":"Cite El Fraijia","CodePostal":"9100"},
    {"Ville":"Ouled Haffouz","Cite":"Sidi Ellafi 2","CodePostal":"9180"},
    {"Ville":"Regueb","Cite":"Bou Chiha","CodePostal":"9115"},
    {"Ville":"Bou Arada","Cite":"Cite Erriadh 2","CodePostal":"6180"},
    {"Ville":"Kesra","Cite":"Cite El Bassatine","CodePostal":"6114"},
    {"Ville":"Makthar","Cite":"Houch Sfaya","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Erriadh 3","CodePostal":"6180"},
    {"Ville":"Kesra","Cite":"Cite El Intilaka","CodePostal":"6114"},
    {"Ville":"Makthar","Cite":"Khizrane","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Essaada","CodePostal":"6180"},
    {"Ville":"Kesra","Cite":"El Garia Sud","CodePostal":"6114"},
    {"Ville":"Makthar","Cite":"Makthar","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Essafa","CodePostal":"6180"},
    {"Ville":"Kesra","Cite":"Ezzaouia","CodePostal":"6114"},
    {"Ville":"Makthar","Cite":"Ras El Oued","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Essalem","CodePostal":"6180"},
    {"Ville":"Kesra","Cite":"Hammam Kesra","CodePostal":"6114"},
    {"Ville":"Makthar","Cite":"Souk Jomaa","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Ezzayatine Est","CodePostal":"6180"},
    {"Ville":"Kesra","Cite":"Kesra","CodePostal":"6114"},
    {"Ville":"Makthar","Cite":"Zouakra","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Ezzayatine Ouest","CodePostal":"6180"},
    {"Ville":"Bargou","Cite":"Bou Saadia","CodePostal":"6115"},
    {"Ville":"Kesra","Cite":"Bou Abdallah","CodePostal":"6141"},
    {"Ville":"Bou Arada","Cite":"El Fetisse","CodePostal":"6180"},
    {"Ville":"El Aroussa","Cite":"El Aroussa","CodePostal":"6116"},
    {"Ville":"Kesra","Cite":"Cite Beni Sayour","CodePostal":"6141"},
    {"Ville":"Bou Arada","Cite":"El Mejenine","CodePostal":"6180"},
    {"Ville":"El Aroussa","Cite":"El Hajjej","CodePostal":"6116"},
    {"Ville":"Kesra","Cite":"Cite El Bhira","CodePostal":"6141"},
    {"Ville":"Bou Arada","Cite":"Henchir Roumene","CodePostal":"6180"},
    {"Ville":"El Aroussa","Cite":"Mosrata","CodePostal":"6116"},
    {"Ville":"Kesra","Cite":"Cite El Ghrob","CodePostal":"6141"},
    {"Ville":"Bou Arada","Cite":"Jelidi Gare","CodePostal":"6180"},
    {"Ville":"El Aroussa","Cite":"Oued El Araar","CodePostal":"6116"},
    {"Ville":"Kesra","Cite":"Cite El Ksar","CodePostal":"6141"},
    {"Ville":"Bou Arada","Cite":"Khamsa Thenaya","CodePostal":"6180"},
    {"Ville":"Siliana Nord","Cite":"Cite Cnrps","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Abdrabbah 1","CodePostal":"6120"},
    {"Ville":"Kesra","Cite":"Kesra Superieur","CodePostal":"6141"},
    {"Ville":"Bou Arada","Cite":"Ksar Boukhris","CodePostal":"6180"},
    {"Ville":"Siliana Nord","Cite":"Cite El Bassatine","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Abdrabbah 2","CodePostal":"6120"},
    {"Ville":"Makthar","Cite":"Sned Haddad","CodePostal":"6142"},
    {"Ville":"Bou Arada","Cite":"Sidi Abdennour","CodePostal":"6180"},
    {"Ville":"Siliana Nord","Cite":"Cite El Yasmine","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Commerciale","CodePostal":"6120"},
    {"Ville":"Siliana Sud","Cite":"Essafina","CodePostal":"6143"},
    {"Ville":"Siliana Sud","Cite":"Douar Ezzriba","CodePostal":"6196"},
    {"Ville":"Siliana Nord","Cite":"Cite Ennour 1","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite De La Cellule","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Cite El Ouns 1","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"El Ghemalia","CodePostal":"6196"},
    {"Ville":"Siliana Nord","Cite":"Cite Ennour 2","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Du Battoire","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Cite El Ouns 2","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Ghars  Ezzriba","CodePostal":"6196"},
    {"Ville":"Siliana Nord","Cite":"Cite Ennouzha","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Ejjamaa","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Cite Ennour","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Ras El Ma","CodePostal":"6196"},
    {"Ville":"Siliana Nord","Cite":"Cite Ezzouhour","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Ennasr","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Cite Erriadh","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Sidi Ameur","CodePostal":"6196"},
    {"Ville":"Siliana Nord","Cite":"Cite Miskia","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Essanaouber","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Cite Essaada","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Sidi Morched","CodePostal":"6196"},
    {"Ville":"Siliana Nord","Cite":"Cite Office Cereale","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Ettahrir","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Cite Ettahrir","CodePostal":"6150"},
    {"Ville":"Siliana Nord","Cite":"Cite Taieb Mhiri","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Cite Independance","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Cite Hedi Khfacha","CodePostal":"6150"},
    {"Ville":"Siliana Nord","Cite":"Jama","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Fej El Hadoum","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"El Haria","CodePostal":"6150"},
    {"Ville":"Siliana Nord","Cite":"Sidi Jabeur","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Le Krib","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"El Hmaima","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Ain Dissa","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Merkez Ben Njah","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"El Msahla","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Cite De La Republique","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Oued Tessa El Morr","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Fidh Hammed","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Cite Du Lycee","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Ouled Bouzid","CodePostal":"6120"},
    {"Ville":"Rohia","Cite":"Foundouk Debbich","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Cite El Aida","CodePostal":"6100"},
    {"Ville":"Gaafour","Cite":"Bennouria","CodePostal":"6121"},
    {"Ville":"Rohia","Cite":"Jemilette","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Cite Erriadh 1","CodePostal":"6100"},
    {"Ville":"Gaafour","Cite":"El Abarguia","CodePostal":"6121"},
    {"Ville":"Rohia","Cite":"Rohia","CodePostal":"6150"},
    {"Ville":"Siliana Sud","Cite":"Cite Erriadh 2","CodePostal":"6100"},
    {"Ville":"Gaafour","Cite":"El Aksab","CodePostal":"6121"},
    {"Ville":"Makthar","Cite":"El Khalsa","CodePostal":"6151"},
    {"Ville":"Siliana Sud","Cite":"Cite Hached","CodePostal":"6100"},
    {"Ville":"Gaafour","Cite":"El Amel","CodePostal":"6121"},
    {"Ville":"Rohia","Cite":"39100","CodePostal":"6152"},
    {"Ville":"Siliana Sud","Cite":"Cite Mongi Slim","CodePostal":"6100"},
    {"Ville":"Gaafour","Cite":"Ennajah","CodePostal":"6121"},
    {"Ville":"Rohia","Cite":"Hebabsa Sud","CodePostal":"6152"},
    {"Ville":"Siliana Sud","Cite":"El Gabel","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Doukhania","CodePostal":"6122"},
    {"Ville":"Rohia","Cite":"Magrouna","CodePostal":"6152"},
    {"Ville":"Siliana Sud","Cite":"El Megarba","CodePostal":"6100"},
    {"Ville":"Siliana Sud","Cite":"Ain El Jouza","CodePostal":"6123"},
    {"Ville":"Bargou","Cite":"Ain El Fourna","CodePostal":"6170"},
    {"Ville":"Siliana Sud","Cite":"Fidh Arous","CodePostal":"6100"},
    {"Ville":"Siliana Sud","Cite":"El Kantra","CodePostal":"6123"},
    {"Ville":"Bargou","Cite":"Ain Zakkar","CodePostal":"6170"},
    {"Ville":"Siliana Sud","Cite":"Gabr El Ghoul","CodePostal":"6100"},
    {"Ville":"Siliana Sud","Cite":"Zaouiet Sidi Abdelmalek","CodePostal":"6123"},
    {"Ville":"Bargou","Cite":"Bargou","CodePostal":"6170"},
    {"Ville":"Siliana Sud","Cite":"Ksar Hadid","CodePostal":"6100"},
    {"Ville":"Siliana Sud","Cite":"El Ganara","CodePostal":"6124"},
    {"Ville":"Bargou","Cite":"Cite Aboul Kacem Chebbi","CodePostal":"6170"},
    {"Ville":"Siliana Sud","Cite":"Sidi Mansour","CodePostal":"6100"},
    {"Ville":"Siliana Sud","Cite":"Sidi Hamada","CodePostal":"6124"},
    {"Ville":"Bargou","Cite":"Cite Air Nouvelle","CodePostal":"6170"},
    {"Ville":"Siliana Sud","Cite":"Siliana","CodePostal":"6100"},
    {"Ville":"Le Krib","Cite":"Borj Messaoudi","CodePostal":"6125"},
    {"Ville":"Bargou","Cite":"Cite El Kadim","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"Cite Du Battoire","CodePostal":"6110"},
    {"Ville":"Gaafour","Cite":"El Frachich","CodePostal":"6126"},
    {"Ville":"Bargou","Cite":"Cite Essaada","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"Cite Du Lycee","CodePostal":"6110"},
    {"Ville":"Gaafour","Cite":"Sidi Ayed","CodePostal":"6126"},
    {"Ville":"Bargou","Cite":"Cite Ezzouhour","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"Cite El Guebli","CodePostal":"6110"},
    {"Ville":"Siliana Nord","Cite":"Cite Essalah","CodePostal":"6130"},
    {"Ville":"Bargou","Cite":"Cite Ibn Khaldoun","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"Cite El Wafa","CodePostal":"6110"},
    {"Ville":"Siliana Nord","Cite":"Cite Essalah 2","CodePostal":"6130"},
    {"Ville":"Bargou","Cite":"Drija","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"Cite Ezzouhour 1","CodePostal":"6110"},
    {"Ville":"Kesra","Cite":"Beni Abdallah","CodePostal":"6131"},
    {"Ville":"Bargou","Cite":"Marj Aouam","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"Cite Ezzouhour 2","CodePostal":"6110"},
    {"Ville":"Kesra","Cite":"El Mansoura","CodePostal":"6131"},
    {"Ville":"Bargou","Cite":"Sidi Amara","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"Cite Ezzouhour 3","CodePostal":"6110"},
    {"Ville":"Kesra","Cite":"El Mansoura Nord","CodePostal":"6131"},
    {"Ville":"Bargou","Cite":"Sidi Said","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"Cite Touensa","CodePostal":"6110"},
    {"Ville":"Le Krib","Cite":"Hammam Biadha","CodePostal":"6132"},
    {"Ville":"Bargou","Cite":"Sidi Zid","CodePostal":"6170"},
    {"Ville":"Gaafour","Cite":"El Kharrouba","CodePostal":"6110"},
    {"Ville":"Le Krib","Cite":"Souk El Jomaa","CodePostal":"6132"},
    {"Ville":"Gaafour","Cite":"Ain Zrig","CodePostal":"6172"},
    {"Ville":"Gaafour","Cite":"Gaafour","CodePostal":"6110"},
    {"Ville":"Kesra","Cite":"El Garia","CodePostal":"6133"},
    {"Ville":"Gaafour","Cite":"El Barrama","CodePostal":"6172"},
    {"Ville":"Gaafour","Cite":"Gaafour Chergui","CodePostal":"6110"},
    {"Ville":"Sidi Bou Rouis","Cite":"Bou Rouis Filahi","CodePostal":"6134"},
    {"Ville":"Gaafour","Cite":"El Haouam","CodePostal":"6172"},
    {"Ville":"Gaafour","Cite":"Gaafour Gharbi","CodePostal":"6110"},
    {"Ville":"Sidi Bou Rouis","Cite":"Ouled Selit","CodePostal":"6134"},
    {"Ville":"Gaafour","Cite":"Mediouna","CodePostal":"6172"},
    {"Ville":"Gaafour","Cite":"Nouisser","CodePostal":"6110"},
    {"Ville":"El Aroussa","Cite":"Bou Jlida","CodePostal":"6135"},
    {"Ville":"Bargou","Cite":"El Bhirine","CodePostal":"6173"},
    {"Ville":"Gaafour","Cite":"Akhouat Mine","CodePostal":"6111"},
    {"Ville":"Makthar","Cite":"Beni Hazam","CodePostal":"6140"},
    {"Ville":"Bargou","Cite":"Sodga","CodePostal":"6173"},
    {"Ville":"Gaafour","Cite":"El Akhouat","CodePostal":"6111"},
    {"Ville":"Makthar","Cite":"Chouarnia","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Ain El Ghessil","CodePostal":"6180"},
    {"Ville":"Gaafour","Cite":"Mellita","CodePostal":"6111"},
    {"Ville":"Makthar","Cite":"Cite Debbiche","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Baten Zraieb","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Krib Gare","CodePostal":"6112"},
    {"Ville":"Makthar","Cite":"Cite El Bassatine","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Bou Arada","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Ain Achour","CodePostal":"6113"},
    {"Ville":"Makthar","Cite":"Cite El Ouns 1","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Ali Ben Mbarek 1","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Bou Rouis","CodePostal":"6113"},
    {"Ville":"Makthar","Cite":"Cite El Ouns 2","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Ali Ben Mbarek 2","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Cite 7 Novembre","CodePostal":"6113"},
    {"Ville":"Makthar","Cite":"Cite Ennahala","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Ali Ben Mbarek 3","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Cite Cheminots","CodePostal":"6113"},
    {"Ville":"Makthar","Cite":"Cite Essiada","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite El Bassatine","CodePostal":"6180"},
    {"Ville":"Bou Arada","Cite":"Cite El Mellassine","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Cite Du Battoire","CodePostal":"6113"},
    {"Ville":"Makthar","Cite":"Cite Ksiba","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Ennakhil","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Cite Du Lycee","CodePostal":"6113"},
    {"Ville":"Makthar","Cite":"Cite Mongi Slim 1","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Ennour 1","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Cite Ejjamaa","CodePostal":"6113"},
    {"Ville":"Makthar","Cite":"Cite Mongi Slim 2","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Ennour 2","CodePostal":"6180"},
    {"Ville":"Sidi Bou Rouis","Cite":"Nechem","CodePostal":"6113"},
    {"Ville":"Makthar","Cite":"Cite Nouvelle","CodePostal":"6140"},
    {"Ville":"Bou Arada","Cite":"Cite Erriadh 1","CodePostal":"6180"},
    {"Ville":"Kesra","Cite":"Cite 20 Mars","CodePostal":"6114"},
    {"Ville":"Makthar","Cite":"El Garaa","CodePostal":"6140"},
    {"Ville":"Hammam Sousse","Cite":"Cite De La Plage 2","CodePostal":"4017"},
    {"Ville":"Sousse Riadh","Cite":"Sousse Ezzouhour","CodePostal":"4031"},
    {"Ville":"Bou Ficha","Cite":"Sidi Khelifa","CodePostal":"4082"},
    {"Ville":"Hammam Sousse","Cite":"Cite Ennarjes 1","CodePostal":"4017"},
    {"Ville":"Enfidha","Cite":"Menzel Bel Ouaer","CodePostal":"4032"},
    {"Ville":"Hammam Sousse","Cite":"Cite Ezzitouna 1","CodePostal":"4089"},
    {"Ville":"Hammam Sousse","Cite":"Cite Ennarjes 2","CodePostal":"4017"},
    {"Ville":"Msaken","Cite":"Moureddine","CodePostal":"4033"},
    {"Ville":"Hammam Sousse","Cite":"Cite Ezzitouna 2","CodePostal":"4089"},
    {"Ville":"Hammam Sousse","Cite":"Cite Jaouhara","CodePostal":"4017"},
    {"Ville":"Enfidha","Cite":"Chegarnia","CodePostal":"4034"},
    {"Ville":"Hammam Sousse","Cite":"Cite Presidentielle","CodePostal":"4089"},
    {"Ville":"Hammam Sousse","Cite":"Hammam Sousse Gharbi","CodePostal":"4017"},
    {"Ville":"Enfidha","Cite":"Ain Garci","CodePostal":"4035"},
    {"Ville":"Hammam Sousse","Cite":"El Kantaoui","CodePostal":"4089"},
    {"Ville":"Kondar","Cite":"Bir Jedid","CodePostal":"4020"},
    {"Ville":"Sidi Bou Ali","Cite":"Cite El Bassatine","CodePostal":"4040"},
    {"Ville":"Bou Ficha","Cite":"Ain Errahma","CodePostal":"4092"},
    {"Ville":"Kondar","Cite":"Blelma","CodePostal":"4020"},
    {"Ville":"Sidi Bou Ali","Cite":"Cite Ibn Khaldoun","CodePostal":"4040"},
    {"Ville":"Enfidha","Cite":"Ain Medheker","CodePostal":"4095"},
    {"Ville":"Kondar","Cite":"El Bechachma","CodePostal":"4020"},
    {"Ville":"Sidi Bou Ali","Cite":"El Aitha","CodePostal":"4040"},
    {"Ville":"Msaken","Cite":"Msaken El Gueblia","CodePostal":"4099"},
    {"Ville":"Kondar","Cite":"Kondar","CodePostal":"4020"},
    {"Ville":"Sidi Bou Ali","Cite":"El Alouj","CodePostal":"4040"},
    {"Ville":"Kondar","Cite":"Ouled Ameur","CodePostal":"4020"},
    {"Ville":"Sidi Bou Ali","Cite":"El Araydhia","CodePostal":"4040"},
    {"Ville":"Kondar","Cite":"Ouled El Abed","CodePostal":"4020"},
    {"Ville":"Sidi Bou Ali","Cite":"El Mhedhba","CodePostal":"4040"},
    {"Ville":"Kondar","Cite":"Tarhouna","CodePostal":"4020"},
    {"Ville":"Sidi Bou Ali","Cite":"Essad Sud","CodePostal":"4040"},
    {"Ville":"Kalaa Essghira","Cite":"Cite Chabbett Ezzaouia","CodePostal":"4021"},
    {"Ville":"Sidi Bou Ali","Cite":"Kenana","CodePostal":"4040"},
    {"Ville":"Kalaa Essghira","Cite":"Cite Chragui","CodePostal":"4021"},
    {"Ville":"Sidi Bou Ali","Cite":"Ouriemma","CodePostal":"4040"},
    {"Ville":"Kalaa Essghira","Cite":"Cite El Manezeh","CodePostal":"4021"},
    {"Ville":"Sidi Bou Ali","Cite":"Sidi Bou Ali","CodePostal":"4040"},
    {"Ville":"Kalaa Essghira","Cite":"Cite Khazzen El Ma","CodePostal":"4021"},
    {"Ville":"Sousse Riadh","Cite":"Cite 7 Novembre","CodePostal":"4041"},
    {"Ville":"Kalaa Essghira","Cite":"Ennagr","CodePostal":"4021"},
    {"Ville":"Sousse Riadh","Cite":"Cite 7 Novembre 3","CodePostal":"4041"},
    {"Ville":"Kalaa Essghira","Cite":"Kalaa Essghira","CodePostal":"4021"},
    {"Ville":"Sousse Riadh","Cite":"Cite Sinan Pacha","CodePostal":"4041"},
    {"Ville":"Kalaa Essghira","Cite":"Oued Laya","CodePostal":"4021"},
    {"Ville":"Sousse Riadh","Cite":"Ksibet Sousse","CodePostal":"4041"},
    {"Ville":"Akouda","Cite":"Akouda","CodePostal":"4022"},
    {"Ville":"Sousse Riadh","Cite":"Thrayette","CodePostal":"4041"},
    {"Ville":"Sousse Ville","Cite":"Cite Boukhzar","CodePostal":"4000"},
    {"Ville":"Sousse Riadh","Cite":"Sousse Riadh","CodePostal":"4023"},
    {"Ville":"Akouda","Cite":"Chatt Meriem","CodePostal":"4042"},
    {"Ville":"Sousse Ville","Cite":"Cite Cheminots","CodePostal":"4000"},
    {"Ville":"Msaken","Cite":"Msaken Hai Jedid","CodePostal":"4024"},
    {"Ville":"Akouda","Cite":"Cite Du Dispensaire","CodePostal":"4042"},
    {"Ville":"Sousse Ville","Cite":"Cite Cnrps","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"El Mouissette","CodePostal":"4025"},
    {"Ville":"Akouda","Cite":"Cite Ecole Superieure","CodePostal":"4042"},
    {"Ville":"Sousse Ville","Cite":"Cite Garde Nationale","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Ghabghoub","CodePostal":"4025"},
    {"Ville":"Akouda","Cite":"Cite Ejjamaa","CodePostal":"4042"},
    {"Ville":"Sousse Ville","Cite":"Cite Hached","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Ouled Ali B Hani","CodePostal":"4025"},
    {"Ville":"Akouda","Cite":"Halg El Menjel","CodePostal":"4042"},
    {"Ville":"Sousse Ville","Cite":"Cite Hadrumette","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Ouled Alouene","CodePostal":"4025"},
    {"Ville":"Akouda","Cite":"Tantana","CodePostal":"4042"},
    {"Ville":"Sousse Ville","Cite":"Cite Jaouhara","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Ouled Amor","CodePostal":"4025"},
    {"Ville":"Sidi Bou Ali","Cite":"Menzel Gare","CodePostal":"4043"},
    {"Ville":"Sousse Ville","Cite":"Cite Khedher","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Ouled Boubaker","CodePostal":"4025"},
    {"Ville":"Sidi Bou Ali","Cite":"Essad Nord","CodePostal":"4045"},
    {"Ville":"Sousse Ville","Cite":"Cite Militaire","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Ouled El Kbaier","CodePostal":"4025"},
    {"Ville":"Sousse Jaouhara","Cite":"Cite De La Steg","CodePostal":"4051"},
    {"Ville":"Sousse Ville","Cite":"Cite Rizzi","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Ouled El Khechine","CodePostal":"4025"},
    {"Ville":"Sousse Jaouhara","Cite":"Cite Sidi Daher","CodePostal":"4051"},
    {"Ville":"Sousse Ville","Cite":"Cite Sprols","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Sidi El Heni","CodePostal":"4025"},
    {"Ville":"Sousse Jaouhara","Cite":"Sousse Khezama","CodePostal":"4051"},
    {"Ville":"Sousse Ville","Cite":"Sousse","CodePostal":"4000"},
    {"Ville":"Sidi El Heni","Cite":"Zerdoub","CodePostal":"4025"},
    {"Ville":"Sousse Jaouhara","Cite":"Cite Sahloul","CodePostal":"4054"},
    {"Ville":"Bou Ficha","Cite":"Bou Ficha","CodePostal":"4010"},
    {"Ville":"Sidi El Heni","Cite":"Cherachir","CodePostal":"4026"},
    {"Ville":"Sousse Jaouhara","Cite":"Sahloul","CodePostal":"4054"},
    {"Ville":"Bou Ficha","Cite":"Cite 20 Mars","CodePostal":"4010"},
    {"Ville":"Sidi El Heni","Cite":"Kroussia","CodePostal":"4026"},
    {"Ville":"Sousse Ville","Cite":"Sousse Corniche","CodePostal":"4059"},
    {"Ville":"Bou Ficha","Cite":"Cite Erriadh","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite 7 Novembre","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Ahel  Jemiaa","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Cite Ettahrir","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite Cimenterie","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Belaoum","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Cite Ezzouhour","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite De L'Action","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Bir Chemsi","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Cite Hached","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite El Bidhane","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Bir Hlaoua","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Cite Militaire","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite El Yasmine","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Chargui","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Cite Snit","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite Environnement","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Commerciale","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Essalloum","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite Erriadh","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite El Bassatine","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Lenderia","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite Essalem","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite El Intilaka","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Oued El Kharroub","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite Ettahrir","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite El Jebs","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Sidi Mtir","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite Ezzayatine","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite El Mansoura","CodePostal":"4060"},
    {"Ville":"Bou Ficha","Cite":"Sidi Said","CodePostal":"4010"},
    {"Ville":"Enfidha","Cite":"Cite Hached","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Ennouzha 1","CodePostal":"4060"},
    {"Ville":"Hammam Sousse","Cite":"Hammam Sousse","CodePostal":"4011"},
    {"Ville":"Enfidha","Cite":"Cite Mohamed Ali","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Ennouzha 2","CodePostal":"4060"},
    {"Ville":"Hergla","Cite":"El Aribat","CodePostal":"4012"},
    {"Ville":"Enfidha","Cite":"Dhehaibia","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Fadden Aoun","CodePostal":"4060"},
    {"Ville":"Hergla","Cite":"Hergla","CodePostal":"4012"},
    {"Ville":"Enfidha","Cite":"El Frada","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Nouvelle","CodePostal":"4060"},
    {"Ville":"Hergla","Cite":"Souayeh","CodePostal":"4012"},
    {"Ville":"Enfidha","Cite":"El Ghoualif","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Sidi Zaied","CodePostal":"4060"},
    {"Ville":"Msaken","Cite":"Messadine","CodePostal":"4013"},
    {"Ville":"Enfidha","Cite":"El Ghouilet","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Cite Zone Des Metiers","CodePostal":"4060"},
    {"Ville":"Msaken","Cite":"Chihia","CodePostal":"4014"},
    {"Ville":"Enfidha","Cite":"Enfidha","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"El Kraria","CodePostal":"4060"},
    {"Ville":"Msaken","Cite":"El Knaies","CodePostal":"4014"},
    {"Ville":"Enfidha","Cite":"Essafha","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Kalaa El Kebira","CodePostal":"4060"},
    {"Ville":"Msaken","Cite":"Beni Rabia","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Essmaidia","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Ouled Lesseoud","CodePostal":"4060"},
    {"Ville":"Msaken","Cite":"Cite Cimetiere","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Grimit Est","CodePostal":"4030"},
    {"Ville":"Sousse Jaouhara","Cite":"Cite Aeroport","CodePostal":"4061"},
    {"Ville":"Msaken","Cite":"Cite Dar Esseghair","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Grimit Ouest","CodePostal":"4030"},
    {"Ville":"Sousse Jaouhara","Cite":"Cite Batiments","CodePostal":"4061"},
    {"Ville":"Msaken","Cite":"Cite Ecole Primaire","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Hicher","CodePostal":"4030"},
    {"Ville":"Sousse Jaouhara","Cite":"Cite Ben Aleya","CodePostal":"4061"},
    {"Ville":"Msaken","Cite":"Cite El Mansour","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Menzel Fateh","CodePostal":"4030"},
    {"Ville":"Sousse Jaouhara","Cite":"Cite El Ghoudrane","CodePostal":"4061"},
    {"Ville":"Msaken","Cite":"Cite Hedi Gachacha","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Methalithe","CodePostal":"4030"},
    {"Ville":"Sousse Jaouhara","Cite":"Cite Sidi Abdelhamid","CodePostal":"4061"},
    {"Ville":"Msaken","Cite":"Cite Rached El Ouertani","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Mrabet Hached","CodePostal":"4030"},
    {"Ville":"Sousse Jaouhara","Cite":"Sousse Ibn Khaldoun","CodePostal":"4061"},
    {"Ville":"Msaken","Cite":"Cite Slama","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Ouled Abdallah","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Kalaa El Kebira Ksar","CodePostal":"4062"},
    {"Ville":"Msaken","Cite":"El Borjine","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Ouled Bellil","CodePostal":"4030"},
    {"Ville":"Kalaa El Kebira","Cite":"Chiab","CodePostal":"4063"},
    {"Ville":"Msaken","Cite":"El Frada","CodePostal":"4015"},
    {"Ville":"Enfidha","Cite":"Ouled Mohamed","CodePostal":"4030"},
    {"Ville":"Msaken","Cite":"Msaken","CodePostal":"4070"},
    {"Ville":"Msaken","Cite":"Beni Kalthoum","CodePostal":"4016"},
    {"Ville":"Enfidha","Cite":"Sidi Saidane","CodePostal":"4030"},
    {"Ville":"Sousse Jaouhara","Cite":"Khezama Ouest","CodePostal":"4071"},
    {"Ville":"Hammam Sousse","Cite":"Cite De La Plage 1","CodePostal":"4017"},
    {"Ville":"Enfidha","Cite":"Takrouna","CodePostal":"4030"},
    {"Ville":"Sousse Riadh","Cite":"Zaouiet Sousse","CodePostal":"4081"},
    {"Ville":"Ghomrassen","Cite":"Cite Des Martyrs","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Cite El Amel","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite Des Oasis","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Cite Ettadhamen","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite Du Stade","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Cite Ettadhamen 1","CodePostal":"3253"},
    {"Ville":"Dhehiba","Cite":"Cite Haftali","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite El Ain","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Cite Ksar Guedim","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite El Anouar","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Cite Olympique","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite El Bicher","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Dhehiba","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite El Maaraka","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Oum Zoggar","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite Ennakhil","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Ouni","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite Ennasr","CodePostal":"3220"},
    {"Ville":"Ghomrassen","Cite":"Ksar El Hedada","CodePostal":"3261"},
    {"Ville":"Ghomrassen","Cite":"Cite Ettahrir 1","CodePostal":"3220"},
    {"Ville":"Smar","Cite":"Beni Mehira","CodePostal":"3262"},
    {"Ville":"Ghomrassen","Cite":"Cite Ettahrir 2","CodePostal":"3220"},
    {"Ville":"Tataouine Nord","Cite":"Tataouine 7 Novembre","CodePostal":"3263"},
    {"Ville":"Ghomrassen","Cite":"Cite Founesse","CodePostal":"3220"},
    {"Ville":"Tataouine Sud","Cite":"Ras El Oued","CodePostal":"3264"},
    {"Ville":"Ghomrassen","Cite":"Cite Ibn Arafa","CodePostal":"3220"},
    {"Ville":"Ghomrassen","Cite":"Guermessa","CodePostal":"3271"},
    {"Ville":"Ghomrassen","Cite":"Cite Mouzdelifa","CodePostal":"3220"},
    {"Ville":"Tataouine Nord","Cite":"Ezzahra Tataouine","CodePostal":"3272"},
    {"Ville":"Ghomrassen","Cite":"Cite Nouvelle","CodePostal":"3220"},
    {"Ville":"Tataouine Sud","Cite":"Chenini Nouvelle","CodePostal":"3274"},
    {"Ville":"Ghomrassen","Cite":"Cite Romana","CodePostal":"3220"},
    {"Ville":"Tataouine Nord","Cite":"Ksar Ouled Soltan","CodePostal":"3282"},
    {"Ville":"Ghomrassen","Cite":"Cite Rosfa","CodePostal":"3220"},
    {"Ville":"Tataouine Sud","Cite":"Bir Thlathine","CodePostal":"3284"},
    {"Ville":"Ghomrassen","Cite":"Cite Sifri","CodePostal":"3220"},
    {"Ville":"Remada","Cite":"Nekrif","CodePostal":"3286"},
    {"Ville":"Ghomrassen","Cite":"Cite Taousse","CodePostal":"3220"},
    {"Ville":"Tataouine Sud","Cite":"Rogba","CodePostal":"3293"},
    {"Ville":"Ghomrassen","Cite":"Cite Tataouine","CodePostal":"3220"},
    {"Ville":"Ghomrassen","Cite":"El Hencha","CodePostal":"3220"},
    {"Ville":"Ghomrassen","Cite":"Ghomrassen","CodePostal":"3220"},
    {"Ville":"Ghomrassen","Cite":"Mdhilla","CodePostal":"3220"},
    {"Ville":"Smar","Cite":"El Maouna","CodePostal":"3200"},
    {"Ville":"Tataouine Sud","Cite":"Ksar Mguebla","CodePostal":"3221"},
    {"Ville":"Tataouine Nord","Cite":"Cite Abbes","CodePostal":"3200"},
    {"Ville":"Tataouine Sud","Cite":"Chenini","CodePostal":"3222"},
    {"Ville":"Tataouine Nord","Cite":"Cite El Mahrajene","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Cite Caserne","CodePostal":"3223"},
    {"Ville":"Tataouine Nord","Cite":"Cite Ennour","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Cite Du Stade","CodePostal":"3223"},
    {"Ville":"Tataouine Nord","Cite":"Cite Ennouzha","CodePostal":"3200"},
    {"Ville":"Tataouine Nord","Cite":"Khetma","CodePostal":"3200"},
    {"Ville":"Tataouine Nord","Cite":"Ksar Bhir","CodePostal":"3200"},
    {"Ville":"Tataouine Nord","Cite":"Ksar El Galaa","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"El Ghariani","CodePostal":"3223"},
    {"Ville":"Tataouine Nord","Cite":"Remtha","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"El Guetaya","CodePostal":"3223"},
    {"Ville":"Tataouine Nord","Cite":"Tamazout","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"El Morra","CodePostal":"3223"},
    {"Ville":"Tataouine Nord","Cite":"Tamelest","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Gualeb Errakham","CodePostal":"3223"},
    {"Ville":"Tataouine Sud","Cite":"Cite Commerciale","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Guearaat Helal","CodePostal":"3223"},
    {"Ville":"Tataouine Sud","Cite":"Cite Des Roses","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Ksar El Gaa","CodePostal":"3223"},
    {"Ville":"Tataouine Sud","Cite":"Cite Du President","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Oudiete Abdelounis","CodePostal":"3223"},
    {"Ville":"Tataouine Sud","Cite":"Cite El Hedadda","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Ras El Oued","CodePostal":"3223"},
    {"Ville":"Tataouine Sud","Cite":"Cite Erriadh","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Rehach","CodePostal":"3223"},
    {"Ville":"Tataouine Sud","Cite":"Cite Nouvelle","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Sabeg","CodePostal":"3223"},
    {"Ville":"Tataouine Sud","Cite":"Cite Senegal","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Smar","CodePostal":"3223"},
    {"Ville":"Tataouine Sud","Cite":"Ghorghar","CodePostal":"3200"},
    {"Ville":"Ghomrassen","Cite":"El Horria","CodePostal":"3224"},
    {"Ville":"Tataouine Sud","Cite":"Sedra","CodePostal":"3200"},
    {"Ville":"Smar","Cite":"Kirchaou","CodePostal":"3225"},
    {"Ville":"Tataouine Sud","Cite":"Tataouine","CodePostal":"3200"},
    {"Ville":"Tataouine Sud","Cite":"Douiret","CodePostal":"3232"},
    {"Ville":"Tataouine Sud","Cite":"Tounket","CodePostal":"3211"},
    {"Ville":"Tataouine Nord","Cite":"Gattoufa","CodePostal":"3233"},
    {"Ville":"Bir Lahmar","Cite":"Bir Lahmar","CodePostal":"3212"},
    {"Ville":"Ghomrassen","Cite":"Oued El Khil","CodePostal":"3235"},
    {"Ville":"Bir Lahmar","Cite":"Cite El Bassatine","CodePostal":"3212"},
    {"Ville":"Remada","Cite":"Borj Bourguiba","CodePostal":"3240"},
    {"Ville":"Bir Lahmar","Cite":"Cite El Grar","CodePostal":"3212"},
    {"Ville":"Remada","Cite":"Borj El Khadra","CodePostal":"3240"},
    {"Ville":"Bir Lahmar","Cite":"Cite El Menzah","CodePostal":"3212"},
    {"Ville":"Remada","Cite":"El Maghni","CodePostal":"3240"},
    {"Ville":"Bir Lahmar","Cite":"Cite Esned","CodePostal":"3212"},
    {"Ville":"Remada","Cite":"El Morra","CodePostal":"3240"},
    {"Ville":"Bir Lahmar","Cite":"El Argoub","CodePostal":"3212"},
    {"Ville":"Remada","Cite":"El Ouchouch","CodePostal":"3240"},
    {"Ville":"Bir Lahmar","Cite":"Gragar","CodePostal":"3212"},
    {"Ville":"Remada","Cite":"Oum Souigh","CodePostal":"3240"},
    {"Ville":"Bir Lahmar","Cite":"Habhab","CodePostal":"3212"},
    {"Ville":"Remada","Cite":"Remada","CodePostal":"3240"},
    {"Ville":"Bir Lahmar","Cite":"Ksar Ouled Boubaker","CodePostal":"3212"},
    {"Ville":"Ghomrassen","Cite":"El Farech","CodePostal":"3241"},
    {"Ville":"Bir Lahmar","Cite":"Oued El Arfej","CodePostal":"3212"},
    {"Ville":"Tataouine Sud","Cite":"Ksar Debab","CodePostal":"3242"},
    {"Ville":"Bir Lahmar","Cite":"Ouled Yahya","CodePostal":"3212"},
    {"Ville":"Tataouine Nord","Cite":"Oued El Ghar","CodePostal":"3243"},
    {"Ville":"Smar","Cite":"Ksar Oun","CodePostal":"3213"},
    {"Ville":"Tataouine Nord","Cite":"Zmilet Saber","CodePostal":"3243"},
    {"Ville":"Tataouine Nord","Cite":"Tlelet","CodePostal":"3214"},
    {"Ville":"Remada","Cite":"Bir Amir","CodePostal":"3244"},
    {"Ville":"Tataouine Sud","Cite":"Beni Barka","CodePostal":"3215"},
    {"Ville":"Remada","Cite":"Kambout","CodePostal":"3245"},
    {"Ville":"Tataouine Nord","Cite":"El Hachana","CodePostal":"3217"},
    {"Ville":"Ghomrassen","Cite":"Ksar El Mourabtine","CodePostal":"3251"},
    {"Ville":"Tataouine Nord","Cite":"Essaada","CodePostal":"3217"},
    {"Ville":"Tataouine Sud","Cite":"Maztouria","CodePostal":"3252"},
    {"Ville":"Ghomrassen","Cite":"Cite Bakhtit","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Cite 7 Novembre","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite Bir Karma","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Cite Du Bain Maure","CodePostal":"3253"},
    {"Ville":"Ghomrassen","Cite":"Cite Commerciale","CodePostal":"3220"},
    {"Ville":"Dhehiba","Cite":"Cite El Aidi","CodePostal":"3253"},
    {"Ville":"Tameghza","Cite":"Tameghza","CodePostal":"2212"},
    {"Ville":"Tozeur","Cite":"Tozeur Aeroport","CodePostal":"2213"},
    {"Ville":"Degueche","Cite":"Chakmou","CodePostal":"2214"},
    {"Ville":"Degueche","Cite":"El Hamma Du Jerid","CodePostal":"2214"},
    {"Ville":"Hezoua","Cite":"Hezoua","CodePostal":"2223"},
    {"Ville":"Degueche","Cite":"El Mahassen","CodePostal":"2224"},
    {"Ville":"Tozeur","Cite":"Abbes","CodePostal":"2233"},
    {"Ville":"Tozeur","Cite":"Bled El Hadhar","CodePostal":"2233"},
    {"Ville":"Tozeur","Cite":"Bou Lifa","CodePostal":"2233"},
    {"Ville":"Tozeur","Cite":"Essoualmia","CodePostal":"2233"},
    {"Ville":"Tozeur","Cite":"Jahim","CodePostal":"2233"},
    {"Ville":"Tozeur","Cite":"Chetaoua Sahraoui","CodePostal":"2239"},
    {"Ville":"Nefta","Cite":"Cite Erriadh","CodePostal":"2240"},
    {"Ville":"Nefta","Cite":"Cite Khzama","CodePostal":"2240"},
    {"Ville":"Nefta","Cite":"Cite Nouvelle","CodePostal":"2240"},
    {"Ville":"Nefta","Cite":"Nefta","CodePostal":"2240"},
    {"Ville":"Tozeur","Cite":"Cite El Izdihar","CodePostal":"2241"},
    {"Ville":"Tozeur","Cite":"Ras Dhraa","CodePostal":"2241"},
    {"Ville":"Tozeur","Cite":"Chabbia","CodePostal":"2243"},
    {"Ville":"Tozeur","Cite":"Chorfa","CodePostal":"2243"},
    {"Ville":"Nefta","Cite":"Ben Farjallah","CodePostal":"2245"},
    {"Ville":"Tameghza","Cite":"Chebika Du Jerid","CodePostal":"2253"},
    {"Ville":"Tameghza","Cite":"Cite Bou Chagra","CodePostal":"2253"},
    {"Ville":"Tameghza","Cite":"Cite De L'Ecole","CodePostal":"2253"},
    {"Ville":"Tameghza","Cite":"Cite El Bassatine","CodePostal":"2253"},
    {"Ville":"Tameghza","Cite":"Cite El Frada","CodePostal":"2253"},
    {"Ville":"Degueche","Cite":"Cite De La Gare","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Cite Du Stade","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Cite El Bassatine","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Cite El Hasnaoui","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Cite Logement Presidentiel","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Cite Nouveau Stade","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Cite Ouled El Gharbi","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Cite Ouled Hmida","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Degueche","CodePostal":"2260"},
    {"Ville":"Degueche","Cite":"Ouled Majed","CodePostal":"2261"},
    {"Ville":"Degueche","Cite":"Sabaa Obbat","CodePostal":"2261"},
    {"Ville":"Tozeur","Cite":"Cite Commerciale","CodePostal":"2200"},
    {"Ville":"Degueche","Cite":"Zaouiet El Arab","CodePostal":"2261"},
    {"Ville":"Tozeur","Cite":"Tozeur","CodePostal":"2200"},
    {"Ville":"Degueche","Cite":"Dghoumes","CodePostal":"2262"},
    {"Ville":"Tozeur","Cite":"Cite Afh","CodePostal":"2210"},
    {"Ville":"Degueche","Cite":"Bou Helal","CodePostal":"2263"},
    {"Ville":"Tozeur","Cite":"Cite Cnrps","CodePostal":"2210"},
    {"Ville":"Degueche","Cite":"Ceddada","CodePostal":"2263"},
    {"Ville":"Tozeur","Cite":"Cite De La Gare","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite El Guitna","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite El Mahrajene","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Helba","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Hopital","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Maison Populaire","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Route De Degueche","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Route El Hajij","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Route El Hamma","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Route Ennafliette","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Sidi El Hafnaoui","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Cite Tebebsa","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Tozeur Chokratsi","CodePostal":"2210"},
    {"Ville":"Tozeur","Cite":"Zone Touristique","CodePostal":"2210"},
    {"Ville":"Tameghza","Cite":"Dhafria","CodePostal":"2211"},
    {"Ville":"Tameghza","Cite":"Ain El Karma","CodePostal":"2212"},
    {"Ville":"Tameghza","Cite":"Cite Administrative","CodePostal":"2212"},
    {"Ville":"Tameghza","Cite":"Cite El Amel","CodePostal":"2212"},
    {"Ville":"Tameghza","Cite":"El Frid","CodePostal":"2212"},
    {"Ville":"Tameghza","Cite":"Logement Populaire","CodePostal":"2212"},
    {"Ville":"Tameghza","Cite":"Mides","CodePostal":"2212"},
    {"Ville":"Tameghza","Cite":"Remitha","CodePostal":"2212"},
    {"Ville":"Tameghza","Cite":"Sondes","CodePostal":"2212"},
    {"Ville":"La Marsa","Cite":"Marsa Erriadh","CodePostal":"2076"},
    {"Ville":"Bab Bhar","Cite":"Bab Bhar","CodePostal":"1000"},
    {"Ville":"Jebel Jelloud","Cite":"Jebel Jelloud","CodePostal":"1046"},
    {"Ville":"Carthage","Cite":"Cite De La Steg","CodePostal":"2016"},
    {"Ville":"El Hrairia","Cite":"Cite El Mechtel","CodePostal":"2087"},
    {"Ville":"La Marsa","Cite":"Cite Afh","CodePostal":"2078"},
    {"Ville":"Bab Bhar","Cite":"Bab El Jazira","CodePostal":"1000"},
    {"Ville":"La Marsa","Cite":"Berge Du Lac","CodePostal":"1053"},
    {"Ville":"Carthage","Cite":"Cite Mohamed Ali","CodePostal":"2016"},
    {"Ville":"La Goulette","Cite":"Casino La Goulette","CodePostal":"2060"},
    {"Ville":"El Hrairia","Cite":"Cite Hwas","CodePostal":"2087"},
    {"Ville":"La Marsa","Cite":"Cite Ben Chaabane","CodePostal":"2078"},
    {"Ville":"Bab Bhar","Cite":"Sidi Bahri","CodePostal":"1000"},
    {"Ville":"Carthage","Cite":"Amilcar","CodePostal":"1054"},
    {"Ville":"Le Bardo","Cite":"Cite Des Enseignants","CodePostal":"2017"},
    {"Ville":"La Goulette","Cite":"Cite Ennacim La Brise","CodePostal":"2060"},
    {"Ville":"El Hrairia","Cite":"El Hrairia","CodePostal":"2087"},
    {"Ville":"La Marsa","Cite":"Cite Des Vergers","CodePostal":"2078"},
    {"Ville":"La Medina","Cite":"El Medina","CodePostal":"1000"},
    {"Ville":"La Marsa","Cite":"Gammart","CodePostal":"1057"},
    {"Ville":"Le Bardo","Cite":"Cite Star","CodePostal":"2017"},
    {"Ville":"La Goulette","Cite":"El Battah","CodePostal":"2060"},
    {"Ville":"La Marsa","Cite":"Cite Du Stade","CodePostal":"2078"},
    {"Ville":"La Medina","Cite":"El Sabaghine","CodePostal":"1000"},
    {"Ville":"La Medina","Cite":"El Hafsia","CodePostal":"1059"},
    {"Ville":"Le Bardo","Cite":"Khaznadar","CodePostal":"2017"},
    {"Ville":"La Goulette","Cite":"Kheireddine","CodePostal":"2060"},
    {"Ville":"El Kram","Cite":"Bir El Hlou","CodePostal":"2089"},
    {"Ville":"La Marsa","Cite":"Cite El Ahmadi","CodePostal":"2078"},
    {"Ville":"La Medina","Cite":"Sidi Boumendil","CodePostal":"1000"},
    {"Ville":"El Omrane Superi","Cite":"Cite El Intilaka","CodePostal":"1064"},
    {"Ville":"La Goulette","Cite":"La Goulette","CodePostal":"2060"},
    {"Ville":"El Kram","Cite":"Le Kram Ouest","CodePostal":"2089"},
    {"Ville":"La Marsa","Cite":"Cite El Hana","CodePostal":"2078"},
    {"Ville":"Bab Bhar","Cite":"Republique","CodePostal":"1001"},
    {"Ville":"El Omrane Superi","Cite":"El Nassim","CodePostal":"1064"},
    {"Ville":"La Goulette","Cite":"La Goulette Nouvelle","CodePostal":"2060"},
    {"Ville":"El Kram","Cite":"Sidi Amor","CodePostal":"2089"},
    {"Ville":"La Marsa","Cite":"Cite El Moez","CodePostal":"2078"},
    {"Ville":"Bab Bhar","Cite":"Hedi Chaker","CodePostal":"1002"},
    {"Ville":"La Goulette","Cite":"La Goulette Port","CodePostal":"1067"},
    {"Ville":"La Goulette","Cite":"Cite El Mechtel","CodePostal":"2089"},
    {"Ville":"La Marsa","Cite":"Cite El Moustakbel (Sidi Daoud)","CodePostal":"2078"},
    {"Ville":"Bab Bhar","Cite":"Taieb El M'Hiri","CodePostal":"1002"},
    {"Ville":"El Omrane Superi","Cite":"Rommana","CodePostal":"1068"},
    {"Ville":"La Marsa","Cite":"Cite El Moustakbel (La Marsa)","CodePostal":"2078"},
    {"Ville":"Cite El Khadra","Cite":"Cite Jardins","CodePostal":"1002"},
    {"Ville":"El Hrairia","Cite":"Cite Bougatfa 1","CodePostal":"2051"},
    {"Ville":"Bab Bhar","Cite":"Habib Thameur","CodePostal":"1069"},
    {"Ville":"La Marsa","Cite":"Cite Malhatli","CodePostal":"2078"},
    {"Ville":"Cite El Khadra","Cite":"Kheireddine Pacha","CodePostal":"1002"},
    {"Ville":"El Hrairia","Cite":"Cite Errachidia","CodePostal":"2051"},
    {"Ville":"Bab Bhar","Cite":"Montplaisir","CodePostal":"1073"},
    {"Ville":"La Marsa","Cite":"Cite Rimila 1","CodePostal":"2078"},
    {"Ville":"El Menzah","Cite":"Tunis Belvedere","CodePostal":"1002"},
    {"Ville":"El Hrairia","Cite":"Cite Essalama","CodePostal":"2051"},
    {"Ville":"El Kabbaria","Cite":"El Mourouj 2","CodePostal":"1074"},
    {"Ville":"La Marsa","Cite":"Cite Rimila 2","CodePostal":"2078"},
    {"Ville":"Cite El Khadra","Cite":"Cite El Khadra","CodePostal":"1003"},
    {"Ville":"El Hrairia","Cite":"Cite Essoltani","CodePostal":"2051"},
    {"Ville":"Bab Souika","Cite":"Bab El Khadra","CodePostal":"1075"},
    {"Ville":"La Marsa","Cite":"Marsa Safsaf","CodePostal":"2078"},
    {"Ville":"Cite El Khadra","Cite":"Cite El Wafa","CodePostal":"1003"},
    {"Ville":"El Hrairia","Cite":"Cite Jlas","CodePostal":"2051"},
    {"Ville":"El Menzah","Cite":"Cite Caravelles","CodePostal":"1082"},
    {"Ville":"Cite El Khadra","Cite":"Cite Olympeade","CodePostal":"1003"},
    {"Ville":"El Hrairia","Cite":"Cite Medjerda","CodePostal":"2051"},
    {"Ville":"El Menzah","Cite":"Cite El Mahrajene","CodePostal":"1082"},
    {"Ville":"El Omrane Superi","Cite":"Cite Ibn Khaldoun I","CodePostal":"2062"},
    {"Ville":"Cite El Khadra","Cite":"Cite Oplympique","CodePostal":"1003"},
    {"Ville":"El Hrairia","Cite":"Ezzahrouni","CodePostal":"2051"},
    {"Ville":"El Menzah","Cite":"Cite Snit","CodePostal":"1082"},
    {"Ville":"El Omrane Superi","Cite":"Cite Ibn Khaldoun Vi","CodePostal":"2062"},
    {"Ville":"Cite El Khadra","Cite":"Cite Star","CodePostal":"1003"},
    {"Ville":"El Hrairia","Cite":"Cite Bouzaiene","CodePostal":"2052"},
    {"Ville":"El Menzah","Cite":"El Menzah 2","CodePostal":"1082"},
    {"Ville":"El Menzah","Cite":"El Menzah 1","CodePostal":"1004"},
    {"Ville":"El Hrairia","Cite":"Ezzouhour 5","CodePostal":"2052"},
    {"Ville":"El Menzah","Cite":"El Menzah 3","CodePostal":"1082"},
    {"Ville":"El Omrane","Cite":"Bir Atig","CodePostal":"1005"},
    {"Ville":"Ezzouhour (Tunis)","Cite":"Cite Des Officiers","CodePostal":"2052"},
    {"Ville":"El Menzah","Cite":"El Menzah 4","CodePostal":"1082"},
    {"Ville":"El Omrane","Cite":"Cite Centrale","CodePostal":"1005"},
    {"Ville":"Ezzouhour (Tunis)","Cite":"Cite Essaada","CodePostal":"2052"},
    {"Ville":"El Menzah","Cite":"Mutuelle Ville","CodePostal":"1082"},
    {"Ville":"El Omrane","Cite":"Cite Des Oliviers","CodePostal":"1005"},
    {"Ville":"Ezzouhour (Tunis)","Cite":"Cite Essomrane","CodePostal":"2052"},
    {"Ville":"Sidi El Bechir","Cite":"Abou El Kacem Chebbi","CodePostal":"1089"},
    {"Ville":"El Omrane","Cite":"Cite El Habib","CodePostal":"1005"},
    {"Ville":"Ezzouhour (Tunis)","Cite":"Cite Ezzouhour","CodePostal":"2052"},
    {"Ville":"Sidi El Bechir","Cite":"Bab Alioua","CodePostal":"1089"},
    {"Ville":"El Omrane","Cite":"Cite Militaire","CodePostal":"1005"},
    {"Ville":"Ezzouhour (Tunis)","Cite":"Ezzouhour 4","CodePostal":"2052"},
    {"Ville":"Sidi El Bechir","Cite":"El Gourjani","CodePostal":"1089"},
    {"Ville":"El Omrane","Cite":"Djbal El Ahmar","CodePostal":"1005"},
    {"Ville":"El Kabbaria","Cite":"Cite Bou Hjar","CodePostal":"2053"},
    {"Ville":"Sidi El Bechir","Cite":"Essaida Mannoubia","CodePostal":"1089"},
    {"Ville":"El Omrane","Cite":"El Omrane","CodePostal":"1005"},
    {"Ville":"El Kabbaria","Cite":"El Kabbaria 1","CodePostal":"2053"},
    {"Ville":"Sidi El Bechir","Cite":"Monfleury","CodePostal":"1089"},
    {"Ville":"El Omrane","Cite":"Oued El Sebai","CodePostal":"1005"},
    {"Ville":"El Kabbaria","Cite":"El Kabbaria 2","CodePostal":"2053"},
    {"Ville":"Sidi El Bechir","Cite":"Saida Manoubia","CodePostal":"1089"},
    {"Ville":"Bab Souika","Cite":"Bab El Akoues","CodePostal":"1006"},
    {"Ville":"El Kabbaria","Cite":"El Kabbaria 3","CodePostal":"2053"},
    {"Ville":"Sidi El Bechir","Cite":"Sidi El Bechir","CodePostal":"1089"},
    {"Ville":"Bab Souika","Cite":"Bab El Alouj","CodePostal":"1006"},
    {"Ville":"El Kabbaria","Cite":"El Kabbaria 4","CodePostal":"2053"},
    {"Ville":"Sidi El Bechir","Cite":"Sidi Mansour","CodePostal":"1089"},
    {"Ville":"Bab Souika","Cite":"Bab El Assel","CodePostal":"1006"},
    {"Ville":"El Kabbaria","Cite":"El Ouerdia 4","CodePostal":"2053"},
    {"Ville":"El Omrane Superi","Cite":"El Omrane Superieur","CodePostal":"1091"},
    {"Ville":"Bab Souika","Cite":"Bab Sidi Abdessalem","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Birine","CodePostal":"1095"},
    {"Ville":"Bab Souika","Cite":"Bab Souika","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Borj Chakir","CodePostal":"1095"},
    {"Ville":"Bab Souika","Cite":"Borj Zouara","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Cite 25 Juillet","CodePostal":"1095"},
    {"Ville":"Bab Souika","Cite":"El Halfaouine","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Cite 7 Novembre","CodePostal":"1095"},
    {"Ville":"Bab Souika","Cite":"Hammam El Remimi","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Cite El Bettoumi","CodePostal":"1095"},
    {"Ville":"El Kabbaria","Cite":"Ibn Sina","CodePostal":"2066"},
    {"Ville":"Bab Souika","Cite":"Sidi Djebeli","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Cite El Gaafouri","CodePostal":"1095"},
    {"Ville":"Essijoumi","Cite":"Cite Helal","CodePostal":"2072"},
    {"Ville":"Bab Souika","Cite":"Tronja","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Cite El Mestiri","CodePostal":"1095"},
    {"Ville":"Bab Souika","Cite":"Zaouiet El Bakria","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Cite Mohamed Ali","CodePostal":"1095"},
    {"Ville":"La Medina","Cite":"El Taoufik","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Cite Mrad 1","CodePostal":"1095"},
    {"Ville":"La Medina","Cite":"Souk Nel Nhes","CodePostal":"1006"},
    {"Ville":"Sidi Hassine","Cite":"Cite Mrad 2","CodePostal":"1095"},
    {"Ville":"Essijoumi","Cite":"El Mellassine","CodePostal":"1007"},
    {"Ville":"Sidi Hassine","Cite":"Jayara","CodePostal":"1095"},
    {"Ville":"La Medina","Cite":"Bab Djedid","CodePostal":"1008"},
    {"Ville":"Sidi Hassine","Cite":"Sidi Hassine","CodePostal":"1095"},
    {"Ville":"El Menzah","Cite":"Cite Des Medecins","CodePostal":"2092"},
    {"Ville":"La Medina","Cite":"Bab Menara","CodePostal":"1008"},
    {"Ville":"Cite El Khadra","Cite":"El Charguia","CodePostal":"2035"},
    {"Ville":"El Menzah","Cite":"Cite Du Paradis","CodePostal":"2092"},
    {"Ville":"Ettahrir","Cite":"Cite Du Jardin","CodePostal":"2042"},
    {"Ville":"La Medina","Cite":"El Zraria","CodePostal":"1008"},
    {"Ville":"El Menzah","Cite":"Cite Faiza","CodePostal":"2092"},
    {"Ville":"Ettahrir","Cite":"Cite El Ferdaous","CodePostal":"2042"},
    {"Ville":"La Medina","Cite":"Sidi Ali Azouz","CodePostal":"1008"},
    {"Ville":"El Omrane","Cite":"Ras Tabia","CodePostal":"2000"},
    {"Ville":"El Menzah","Cite":"Cite Saba","CodePostal":"2092"},
    {"Ville":"Ettahrir","Cite":"Cite Essadik","CodePostal":"2042"},
    {"Ville":"La Medina","Cite":"Tourbet El Bey","CodePostal":"1008"},
    {"Ville":"Le Bardo","Cite":"Bouchoucha","CodePostal":"2000"},
    {"Ville":"El Menzah","Cite":"El Manar 2","CodePostal":"2092"},
    {"Ville":"Ettahrir","Cite":"Cite Ettahrir Sup.","CodePostal":"2042"},
    {"Ville":"Sidi El Bechir","Cite":"Maakel Ezza¤M","CodePostal":"1008"}
    ,
    {"Ville":"Le Bardo","Cite":"Cite Alten","CodePostal":"2000"},
    {"Ville":"Ettahrir","Cite":"Ettahrir 1","CodePostal":"2042"},
    {"Ville":"El Ouerdia","Cite":"Bellevue","CodePostal":"1009"},
    {"Ville":"Le Bardo","Cite":"Cite Du Stade","CodePostal":"2000"},
    {"Ville":"Ettahrir","Cite":"Ettahrir 2","CodePostal":"2042"},
    {"Ville":"El Ouerdia","Cite":"Borj Ali Errais","CodePostal":"1009"},
    {"Ville":"Le Bardo","Cite":"Le Bardo","CodePostal":"2000"},
    {"Ville":"Jebel Jelloud","Cite":"Cite Thameur","CodePostal":"2023"},
    {"Ville":"Ettahrir","Cite":"Ettahrir 3","CodePostal":"2042"},
    {"Ville":"El Ouerdia","Cite":"Cite El Izdihar","CodePostal":"1009"},
    {"Ville":"Le Bardo","Cite":"Bortal Hayder","CodePostal":"2009"},
    {"Ville":"Jebel Jelloud","Cite":"El Afrane","CodePostal":"2023"},
    {"Ville":"El Ouerdia","Cite":"Cite Mohamed Ali","CodePostal":"1009"},
    {"Ville":"Le Bardo","Cite":"Ksar Said","CodePostal":"2009"},
    {"Ville":"Jebel Jelloud","Cite":"Sidi Fathallah","CodePostal":"2023"},
    {"Ville":"El Ouerdia","Cite":"Dibouzville","CodePostal":"1009"},
    {"Ville":"La Marsa","Cite":"Cite El Mhiri","CodePostal":"2045"},
    {"Ville":"El Ouerdia","Cite":"El Ouerdia","CodePostal":"1009"},
    {"Ville":"La Marsa","Cite":"Cite Sprols","CodePostal":"2045"},
    {"Ville":"El Ouerdia","Cite":"Lakagnia","CodePostal":"1009"},
    {"Ville":"Carthage","Cite":"Salambo","CodePostal":"2025"},
    {"Ville":"La Marsa","Cite":"Cite Borj El Kouki","CodePostal":"2076"},
    {"Ville":"La Marsa","Cite":"Cite Aziza","CodePostal":"2046"},
    {"Ville":"El Ouerdia","Cite":"Les Martyrs","CodePostal":"1009"},
    {"Ville":"Carthage","Cite":"Sidi Bousaid","CodePostal":"2026"},
    {"Ville":"La Marsa","Cite":"Cite De La Telediffusion 1","CodePostal":"2076"},
    {"Ville":"La Marsa","Cite":"Cite Bhar Lazreg","CodePostal":"2046"},
    {"Ville":"El Ouerdia","Cite":"Mathul De Ville","CodePostal":"1009"},
    {"Ville":"La Marsa","Cite":"Cite De La Telediffusion 2","CodePostal":"2076"},
    {"Ville":"La Marsa","Cite":"Cite Mongi Slim","CodePostal":"2046"},
    {"Ville":"El Ouerdia","Cite":"Monhomme","CodePostal":"1009"},
    {"Ville":"La Marsa","Cite":"Cite Des Juges 2","CodePostal":"2076"},
    {"Ville":"La Marsa","Cite":"Sidi Daoud","CodePostal":"2046"},
    {"Ville":"El Menzah","Cite":"El Menzah 9","CodePostal":"1013"},
    {"Ville":"La Marsa","Cite":"Cite Des Mimosas","CodePostal":"2076"},
    {"Ville":"La Medina","Cite":"Bab Bnet","CodePostal":"1019"},
    {"Ville":"La Marsa","Cite":"Cite El Fateh","CodePostal":"2076"},
    {"Ville":"Sidi El Bechir","Cite":"Bab El Falla","CodePostal":"1027"},
    {"Ville":"El Kram","Cite":"Erriadh","CodePostal":"2015"},
    {"Ville":"La Marsa","Cite":"Cite El Khalil","CodePostal":"2076"},
    {"Ville":"Bab Souika","Cite":"Bab Saadoun","CodePostal":"1029"},
    {"Ville":"El Kram","Cite":"Le Kram","CodePostal":"2015"},
    {"Ville":"La Marsa","Cite":"Cite El Ouns","CodePostal":"2076"},
    {"Ville":"El Omrane","Cite":"Bab Saadoun Gare","CodePostal":"1029"},
    {"Ville":"El Kram","Cite":"Le Kram Est","CodePostal":"2015"},
    {"Ville":"La Marsa","Cite":"Cite Erriadh","CodePostal":"2076"},
    {"Ville":"Jebel Jelloud","Cite":"Ali Bach-Hamba","CodePostal":"1046"},
    {"Ville":"Carthage","Cite":"Carthage","CodePostal":"2016"},
    {"Ville":"Carthage","Cite":"El Yasmina","CodePostal":"2085"},
    {"Ville":"La Marsa","Cite":"Cite Essalama","CodePostal":"2076"},
    {"Ville":"Jebel Jelloud","Cite":"El Garjouma","CodePostal":"1046"},
    {"Ville":"Carthage","Cite":"Cartage Byrsa","CodePostal":"2016"},
    {"Ville":"La Marsa","Cite":"Cite Essirene","CodePostal":"2076"},
    {"Ville":"Jebel Jelloud","Cite":"El Sebkha","CodePostal":"1046"},
    {"Ville":"Carthage","Cite":"Cartage Plage","CodePostal":"2016"},
    {"Ville":"Zaghouan","Cite":"Jimla","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Ben Halaoua","CodePostal":"1160"},
    {"Ville":"Zaghouan","Cite":"Kantra El Kahla","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Chaalil Nord","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Bir Moukra","CodePostal":"1140"},
    {"Ville":"Zaghouan","Cite":"Merkez Ali Besseghaier","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Chaalil Sud","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Borj Abdeljalil","CodePostal":"1140"},
    {"Ville":"Zaghouan","Cite":"Mhiris","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Dhorbania","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Bou Garnine","CodePostal":"1140"},
    {"Ville":"Zaghouan","Cite":"Oued Sbaihia","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Ennadhour","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Chenanfa","CodePostal":"1140"},
    {"Ville":"Zaghouan","Cite":"Sidi Mraieh","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Sidi Bannour","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Cite 20 Mars","CodePostal":"1140"},
    {"Ville":"Zaghouan","Cite":"Zaghouan","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Sidi Naji","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Cite Bou Hmida","CodePostal":"1140"},
    {"Ville":"Ennadhour","Cite":"Souar","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Cite Des Enseignants","CodePostal":"1140"},
    {"Ville":"Ennadhour","Cite":"Zbidine","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Cite El Amel","CodePostal":"1140"},
    {"Ville":"Ennadhour","Cite":"Zouagha","CodePostal":"1160"},
    {"Ville":"El Fahs","Cite":"Cite Ennasr","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Bent Saidane","CodePostal":"1162"},
    {"Ville":"El Fahs","Cite":"Cite Ennisma","CodePostal":"1140"},
    {"Ville":"Ennadhour","Cite":"Bir Chaouch","CodePostal":"1163"},
    {"Ville":"El Fahs","Cite":"Cite Erriadh","CodePostal":"1140"},
    {"Ville":"Ennadhour","Cite":"Chebaana","CodePostal":"1163"},
    {"Ville":"El Fahs","Cite":"Cite Essaada 1","CodePostal":"1140"},
    {"Ville":"Ennadhour","Cite":"Kef Agueb","CodePostal":"1163"},
    {"Ville":"El Fahs","Cite":"Cite Essaada 2","CodePostal":"1140"},
    {"Ville":"Bir Mcherga","Cite":"Jebel El Oust","CodePostal":"1111"},
    {"Ville":"Ennadhour","Cite":"Meidher","CodePostal":"1163"},
    {"Ville":"El Fahs","Cite":"Cite Essalem","CodePostal":"1140"},
    {"Ville":"Hammam Zriba","Cite":"Jeradou","CodePostal":"1112"},
    {"Ville":"Ennadhour","Cite":"Mrigueb","CodePostal":"1163"},
    {"Ville":"El Fahs","Cite":"Cite Industrille","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Cite Snit Nouvelle","CodePostal":"1140"},
    {"Ville":"Bir Mcherga","Cite":"Bir Mcherga Gare","CodePostal":"1193"},
    {"Ville":"El Fahs","Cite":"El Amaiem","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Dhraa Ben Jouder","CodePostal":"1194"},
    {"Ville":"El Fahs","Cite":"El Fahs","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"El Knaziz","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Gherifat","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Glib Jemal","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Henchir Brouta","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Henchir Dhomda","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Jabbes","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Jebel Mansour","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Jougar","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Kef Ezzroug","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Khemayssia","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Koudouat Chair","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Oued El Khadhra","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Oum El Abouab","CodePostal":"1140"},
    {"Ville":"El Fahs","Cite":"Rehahla","CodePostal":"1140"},
    {"Ville":"Saouef","Cite":"Charchara","CodePostal":"1115"},
    {"Ville":"El Fahs","Cite":"Sed Oued El Kebir","CodePostal":"1140"},
    {"Ville":"Saouef","Cite":"Dghafla","CodePostal":"1115"},
    {"Ville":"El Fahs","Cite":"Sidi Amara","CodePostal":"1140"},
    {"Ville":"Saouef","Cite":"Douar El Haj Amor","CodePostal":"1115"},
    {"Ville":"El Fahs","Cite":"Sidi Naoui","CodePostal":"1140"},
    {"Ville":"Saouef","Cite":"El Hmira Sud","CodePostal":"1115"},
    {"Ville":"El Fahs","Cite":"Tebika","CodePostal":"1140"},
    {"Ville":"Saouef","Cite":"El Khadhra","CodePostal":"1115"},
    {"Ville":"El Fahs","Cite":"Tlil Essalhi","CodePostal":"1140"},
    {"Ville":"Saouef","Cite":"Halg Ennab","CodePostal":"1115"},
    {"Ville":"Bir Mcherga","Cite":"Bir Mcherga","CodePostal":"1141"},
    {"Ville":"Saouef","Cite":"Henchir El Hamira","CodePostal":"1115"},
    {"Ville":"Bir Mcherga","Cite":"Boucha","CodePostal":"1141"},
    {"Ville":"Saouef","Cite":"Oued Touil","CodePostal":"1115"},
    {"Ville":"Bir Mcherga","Cite":"Delaiel El Arouss","CodePostal":"1141"},
    {"Ville":"Saouef","Cite":"Saouef","CodePostal":"1115"},
    {"Ville":"Bir Mcherga","Cite":"Ouled Helel","CodePostal":"1141"},
    {"Ville":"Zaghouan","Cite":"Ain Essaboune","CodePostal":"1100"},
    {"Ville":"Saouef","Cite":"Sidi Dghim","CodePostal":"1115"},
    {"Ville":"Zaghouan","Cite":"Ain Lansarine","CodePostal":"1100"},
    {"Ville":"Saouef","Cite":"Sidi Farjallah","CodePostal":"1115"},
    {"Ville":"Zaghouan","Cite":"Ain Ledhieb","CodePostal":"1100"},
    {"Ville":"Saouef","Cite":"Sidi Mansour","CodePostal":"1115"},
    {"Ville":"Zaghouan","Cite":"Ain Safsaf","CodePostal":"1100"},
    {"Ville":"Saouef","Cite":"Zagtoun","CodePostal":"1115"},
    {"Ville":"Zaghouan","Cite":"Bni Darraj","CodePostal":"1100"},
    {"Ville":"Saouef","Cite":"Zeguidane","CodePostal":"1115"},
    {"Ville":"Zaghouan","Cite":"Bni Mar","CodePostal":"1100"},
    {"Ville":"Zaghouan","Cite":"Cite 20 Mars","CodePostal":"1100"},
    {"Ville":"Zaghouan","Cite":"Cite Administrative","CodePostal":"1100"},
    {"Ville":"Zaghouan","Cite":"El Magren","CodePostal":"1121"},
    {"Ville":"Zaghouan","Cite":"Cite Bourguiba","CodePostal":"1100"},
    {"Ville":"Hammam Zriba","Cite":"Ain Batria","CodePostal":"1122"},
    {"Ville":"Zaghouan","Cite":"Cite De La Municipalite","CodePostal":"1100"},
    {"Ville":"El Fahs","Cite":"Sidi Aouidette","CodePostal":"1146"},
    {"Ville":"Hammam Zriba","Cite":"Bou Achir","CodePostal":"1122"},
    {"Ville":"Zaghouan","Cite":"Cite De La Sonede","CodePostal":"1100"},
    {"Ville":"Hammam Zriba","Cite":"Cite Du Lycee","CodePostal":"1152"},
    {"Ville":"Hammam Zriba","Cite":"Oued El Kenz","CodePostal":"1122"},
    {"Ville":"Zaghouan","Cite":"Cite Des Nymphes","CodePostal":"1100"},
    {"Ville":"Hammam Zriba","Cite":"Cite El Houda","CodePostal":"1152"},
    {"Ville":"Hammam Zriba","Cite":"Zriba","CodePostal":"1122"},
    {"Ville":"Zaghouan","Cite":"Cite Du Lycee","CodePostal":"1100"},
    {"Ville":"Hammam Zriba","Cite":"Hammam Zriba","CodePostal":"1152"},
    {"Ville":"Bir Mcherga","Cite":"Ain El Asker","CodePostal":"1123"},
    {"Ville":"Zaghouan","Cite":"Cite El Bassatine","CodePostal":"1100"},
    {"Ville":"Bir Mcherga","Cite":"Sminja","CodePostal":"1131"},
    {"Ville":"Zaghouan","Cite":"Cite El Menzah","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Ain El Battoum","CodePostal":"1154"},
    {"Ville":"Zaghouan","Cite":"Bou Slim","CodePostal":"1132"},
    {"Ville":"Zaghouan","Cite":"Cite Ennouzha","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Bou Araara","CodePostal":"1154"},
    {"Ville":"Zaghouan","Cite":"Hammam Jedidi","CodePostal":"1132"},
    {"Ville":"Zaghouan","Cite":"Cite Equipement","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"El Hnainia","CodePostal":"1154"},
    {"Ville":"Zaghouan","Cite":"Oued Ezzit","CodePostal":"1132"},
    {"Ville":"Zaghouan","Cite":"Cite Ezzouhour","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Erragba","CodePostal":"1154"},
    {"Ville":"Zaghouan","Cite":"Cite Industrielle 1","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Ouled Jaballah","CodePostal":"1154"},
    {"Ville":"Zaghouan","Cite":"Cite Industrielle 2","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Soughas Nord","CodePostal":"1154"},
    {"Ville":"Zaghouan","Cite":"Cite Nesrine","CodePostal":"1100"},
    {"Ville":"Ennadhour","Cite":"Soughas Sud","CodePostal":"1154"},
    {"Ville":"Zaghouan","Cite":"El Mhedhba","CodePostal":"1100"},
    {"Ville":"Zaghouan","Cite":"Bir Halima","CodePostal":"1155"},
    {"Ville":"Zaghouan","Cite":"Hemiane","CodePostal":"1100"},
    {"Ville":"Zaghouan","Cite":"Sidi Mediene","CodePostal":"1155"}
    ]