import React, { Component } from 'react'
import './anouncement.css';

import axios from 'axios';
import {config} from '../../../../../config';
import {
    Row,
    Col,
    Image,
    Button,
    Container
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

//images 
const ANNOUNCEMENT_ICON = require('../../../../../asset/image/Dashboard/icon_Announcement.svg');

export default class Announcement extends Component {
    constructor() {
        super();
        this.state = {
            announcement:null
        }
    }
    componentDidMount () {
        axios.get(`${config.url}pub`)
        .then(response => {
            this.setState({announcement:response.data})
        })
        .catch(error =>
            {console.log(error)})
    }
    render() {
        return (
        <>
        {this.state.announcement ?
        <Container fluid className="full-content mx-0">
            
            <Row className="announcement-bloc mx-lg-5 mx-sm-0 px-0  mb-5">

                <Col className="my-3 my-lg-auto text-center" lg="3" md="12" sm="12" >
                    <div>
                        <Image src={ANNOUNCEMENT_ICON} className="anouncement-icon" fluid/>
                    </div>
                    <div className="mt-2">
                        <p className="announcement-title"> {this.state.announcement[0].Titre} </p>
                    </div>  
                    <div>
                        {/* <p className="announcement-text">
                            {this.state.announcement[0].Description}
                        </p> */}
                        <Button className="offre-eco-btn px-4" onClick={()=> window.location.replace(this.state.announcement[0].Lien)} > Découvrir l'offre </Button>
                    </div>
                </Col>
                
                <Col className="px-0 py-0" lg="9" md="12" sm="12">
                    <Image fluid src={config.img+this.state.announcement[0].Image} className="announcement-image"/>
                </Col>
            </Row>

        </Container>
        :null} 
        </>
        )
    }
}