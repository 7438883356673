import React, { Component } from 'react'
import '../contact.css';

import {
  Card,
} from 'react-bootstrap';
import {
    Link
  } from "react-router-dom";
const CONTACT_IMG = require('../../../../../../asset/image/Dashboard/contact.png');


export default class ContactExpert extends Component {

    render() {
        return (
            <Card className="mx-5 contact-card-container" style={{ width: '20rem' }} as={Link} to='/particulier/faq'>
                <Card.Img variant="top" className="contact-card-img" src={CONTACT_IMG} />
                <Card.Body className="px-0 py-4 mt-1">

                    <Card.Text>
                    <div>
                        <p className="mb-0"> CONTACTEZ UN EXPERT EN LIGNE </p>
                        <p className="mb-0"> 71 100 440 - FAQ </p> 
                        <small> Notre assistance en ligne est à votre disposition</small>
                    </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }
}