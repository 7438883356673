import React, { Component } from 'react';
//css
import './offreEco.css';
//react-bootstrap
import {
  Row,
  Col,
  Image,
  Button

} from 'react-bootstrap';

import {config} from '../../../../../config';
import { Link } from 'react-router-dom';

export default class OffreEco extends Component {
    render() {
        let {offer}=this.props;
        return (

        <> 
        {offer?   
        <Row className="mx-0 px-lg-5 px-2 py-5 offre-eco-container justify-content-center">

            <Col lg="3" md="5" sm="6" xs="6" className="" >
                <Image fluid src={config.img+offer[0].image} />
            </Col>
            <Col lg="6" md="7" sm="6" xs="6" className="offre-eco-text my-auto px-0">
                <h1> {offer[0].offre} </h1>
                <h3> {offer[0].Titre} </h3>
                <p className="" > 
                    {offer[0].Description}
                </p>
                <Button className="offre-eco-btn px-5" 
                        onClick={()=> window.location.replace(offer[0].Lien)}
                > 
                    Découvrir
                </Button>
            </Col>
     
        </Row>
        :null}
        </>
        );
    }
}