const config = {
    url : "https://back-off.bee.net.tn/api/",
    product : "https://back-off.bee.net.tn/api/produits",
    img : "https://back-off.bee.net.tn/public/",

    abonnement : "https://easybo.bee.net.tn:17443/api/addAbonnement/"
    // abonnement : "https://172.20.0.112/api/addAbonnement/"
    // abonnement : "http://easybo.bee.net.tn:8000/WebServicePostBee/web/app_dev.php/api/addAbonnement/"
    // abonnement : "http://172.20.0.176/api/addAbonnement/"

}

export {
    config
}