import React, { Component } from 'react';

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

export class CoordMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {
        lat: 36.744,
        lng: 10.208
      },
    };
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          this.setState({ position: { lat: latitude, lng: longitude } });
          this.props.handleLatLng(latitude, longitude);
        },
        error => this.setState({ error: error.message }),
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
      return;
    }
    this.props.handleLatLng(this.position.lat, this.position.lng);
  }

  handleMapClick = (mapProps, map, clickEvent) => {
    this.setState({
      position: {
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng()
      }
    });

    this.props.handleLatLng(clickEvent.latLng.lat(), clickEvent.latLng.lng());
  };


  render() {
    return (
      <Map
        google={this.props.google}
        center={this.state.position}
        zoom={10}
        onClick={this.handleMapClick}
        initialCenter={this.state.position}
      >
        <Marker
          position={{ lat: this.state.position.lat, lng: this.state.position.lng }}
        />
      </Map>
    );
  }
}

// export default ContactMap;

export default GoogleApiWrapper({
  apiKey: ('AIzaSyCe3eMb74HI0_hA5Dzmcd2pozsCEP9fZPw')
})(CoordMap);