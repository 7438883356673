import React, { Component } from 'react';
import axios from 'axios';
import { config } from '../../../../config';
import { tag } from '../../../../const/metaTags';
import Helmet from 'react-helmet';

//component
import Announcement from './Anouncement/anouncement';
import Contact from './Contact/contact';
import ZoneCoverage from './CouvertureZone/couvertureZone';
import Description from './Description/description';
import Offre from './Offre/Offre';
import OffreEco from './OffreEco/OffreEconomique';
import SocialMedia from './SocialMedia/socialMedia';
import Product from './Products/product';
import Spinner from '../../../UI/spinner/spinner';
import Ribbon from '../../../UI/ribbon/ribbon';
export default class HomePage extends Component {
    state = {
        catOffers: [],
        offerPromo: null,
        actuality: null,

    };
    componentDidMount() {
        axios.get(`${config.url}catoffres`)
            .then(response => {
                this.setState({ catOffers: response.data });
            }).catch(error => console.log(error, "related to catoffre"));
        axios.get(`${config.url}promotions`)
            .then(response => {
                this.setState({ offerPromo: response.data });
            }).catch(error => console.log(error, "related to offre promotionnelle"));
        axios.get(`${config.url}slideactu`)
            .then(response => {
                this.setState({ actuality: response.data });
            }).catch(error => { console.log(error, 'related to slide actuality'); });
    }

    render() {
        let offerItems = this.props.offerItems ? this.props.offerItems : null;
        let catOffers = this.props.catOffers ? this.props.catOffers : null;
        return (
            <>
                {(offerItems && catOffers) ?
                    <div className="mx-0">
                        <Helmet>
                            <title> {tag.particulier.title} </title>
                            <meta name="description" content={tag.particulier.description} />
                        </Helmet>
                        <Description />
                        <SocialMedia />
                        <Offre offerItems={offerItems} categories={catOffers}
                            offrePackCat={this.props.offrePackCat} offrePack={this.props.offrePack}
                        />
                        <OffreEco offer={this.state.offerPromo} />
                        <Announcement />
                        {/* <ZoneCoverage/> */}
                        {/* <Product/> */}
                        <Contact />
                        <Ribbon />
                    </div>
                    : <Spinner />}
            </>
        );
    }
}