import React, { Component } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { withRouter } from 'react-router-dom';
import './search.css';

     
  export class SearchBar extends Component {

       handleOnSelect = (item) => {
        // the item selected
        if(item.type==="service"){
            this.props.history.push(`/particulier/service/${item.name.replace(/ /g, '')}`)
        }
        if(item.type==="produit"){
            this.props.history.push(`/particulier/produits/${item.name.replace(/ /g, '')}`)
        }
        if(item.type==="offre"){
            this.props.history.push(`/particulier/offre/${item.name.replace(/ /g, '')}`)
        }
        if(item.type==="offrePack"){
          this.props.history.push(`/particulier/offre-packagée/${item.name.replace(/ /g, '')}`)
      }
        
      }
    
    render() {
        let services=this.props.serviceItems?this.props.serviceItems:[];
        let offers=this.props.offreItems?this.props.offreItems:[];
        let products=this.props.productItems?this.props.productItems:[];
        let offersPack=this.props.offrePack;
        let items=[]
        products.map((el,id)=>{
            items.push({param:id,cat:el.id_cat,name:el.Nom,type:"produit"})
        })
        services.map((el,id)=>{
            items.push({param:id,name:el.Titre,type:"service"})
        })
        offers.map((el,id)=>{
            items.push({param:id,name:el.nom,type:"offre",idCat:el.categorie_offres_id})
        })
        offersPack.map((el,id)=>{
          items.push({param:el.id,name:el.type,type:"offrePack"})
      })
        return (
        <div className="search-bee">
          <ReactSearchAutocomplete
            items={items}
            // fuseOptions={{ keys: ["Nom"] }}
            // resultStringKeyName="Nom"
            onSelect={this.handleOnSelect}
            showIcon={false}
            showClear={true}
            placeholder="Recherche"
            styling={style}
          />
        </div>
  )

}
}
const style= {
    height: "32px",
    border: "none",
    borderRadius: "24px",
    backgroundColor: "white",
    boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
    hoverBackgroundColor: "#eee",
    color: "#212121",
    fontSize: "10px",
    fontFamily: "Neo Sans Std",
    iconColor: "grey",
    lineColor: "rgb(232, 234, 237)",
    placeholderColor: "grey",
    clearIconMargin: '3px 14px 0 0',
    searchIconMargin: '0 0 0 16px'
  };

export default withRouter(SearchBar);