import React, { Component } from 'react';
//css
import '../debit.css';
//react-bootstrap
import {Form} from 'react-bootstrap';

export default class ServiceBox extends Component {
    render() {
        return (
        <>
            <div className="flow-box-header p-3">
                <p className="flow-title">Service</p>
                <p className="flow-text"> Je choisis le service </p>
            </div>

            <div className="services-radio px-3">
                {this.props.serviceList.map(el=>{
                return(
                <Form.Check 
                    name="serviceRadio"
                    value={el.Prix}
                    // defaultChecked={el.defaultChecked}
                    type="radio" 
                    label={el.Titre}
                    onChange={this.props.changed}
                />
                    )
                })}  
            </div>
         
            <div className="flow-box-footer px-3">
                <p className="offer-price"> 
                    <strong> <var> {Math.floor(this.props.price)} <sup>,{Math.round((this.props.price - parseInt(this.props.price)) * 10)}</sup></var> </strong> TTC/Mois  
                    </p>
                <p className="flow-text2">
                    Le service Bee Safe est soumis
                    à un engagement de 12 mois
                </p>
            </div>  
        </>
        );
    }
}