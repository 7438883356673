const tag = {

    about:{
        title:"Bee | A propos",
        description:"Bee fournisseur d’Internet en Tunisie propose des offres Internet à très haut débit et des services complémentaires pour les particuliers et professionnels.",
    },
    particulier:{
        title:"Bee | Fournisseur d’accès internet",
        description:"Profitez des meilleures offres ADSL, VDSL avec Bee le meilleur fournisseur d’accès internet en Tunisie et bénéficiez d’une connexion internet à très haut débit."
    },
    products:{
        title:"Bee | Nos produits",
        description:"Retrouvez le meilleur du modem, point d’accès et répéteur Bee. Débit rapide, retrouvez les boitiers idéals pour partager votre connexion entre plusieurs ordinateurs"
    },
    network:{
        title:"Bee | Notre réseau",
        description:"Retrouvez l’agence ou revendeur Bee le plus proche de vous. Bee se rapproche de vous et met à votre disposition son réseau de revendeurs."
    },
    contact:{
        title:"Bee | Contact",
        description:"Contactez le service client Bee. Retrouvez le numéro de service client, l’adresse Bee. Posez-nous vos questions, nos conseillers Bee vous y répondrons."
    },
    faq:{
        title:"Bee | FAQ",
        description:"Trouvez la réponse aux questions les plus posées : internet Bee, les offres ADSL,VDSL illimitées, les débits proposés, passer un abonnement, payer facture."
    },
    business:{
        title:"Bee | Business",
        description:"Retrouvez très prochainement nos offres Internet dédiés aux entreprises et aux professionnels. En attendant, restez connectés !"
    }  
}
const offreTag = [
    {
        name:"ADSL Bee Speed\u00a0",
        title:"Bee | Offre ADSL Bee Speed",
        description:"Découvrez ADSL Bee Speed, une gamme d’offres ADSL en facture unique. Profitez d’une connexion internet illimitée, avec des débits allant de 4 à 20 Méga."
    },
    {
        name:"VDSL Bee Speedy",
        title:"Bee | Offre VDSL Bee Speedy",
        description:"Découvrez VDSL Bee Speedy, une gamme d’offres VDSL en facture unique. Profitez d’une connexion internet illimitée, avec des débits allant de 20 à 100 Méga."
    },
]

const offrePackTag = [
    {
        name:"Bee Home",
        title:"Bee | Offre Bee Home",
        description:"Découvrez Bee home, offre packagée qui comporte : connexion internet Bee+pc Asus+licence Windows 10+antivirus bitdefender+espace de stockage 10go cloud beews."
    },
    {
        name:"Bitdefender",
        title:"Bee | Offre Bitdefender",
        description:"Découvrez l’offre packagée Bitdefender, pour une navigation en toute sécurité. Sécurisez votre vie numérique. Bitdefender pour une connexion à haute protection."
    },
]

const serviceTag = [
    {
        name:"Contrôle Parental",
        title:"Bee | Service Contrôle Parental",
        description:"Protégez vos enfants des menaces d’Internet en leur offrant une connexion Internet sécurisée avec le service contrôle parental de Bee."
    },
    {
        name:"Antivirus Bitdefender",
        title:"Bee | Service Bitdefender",
        description:"Profitez de la meilleure suite de sécurité contre les menaces d’Internet avec le service Bitdefender de Bee pour une connexion infaillible."
    },
    {
        name:"Adresse IP Fixe",
        title:"Bee | Service Adresse IP Fixe",
        description:"Profitez d’une connexion à des serveurs internationaux infaillible avec le service Adresse IP Fixe de Bee."
    },
]
const productTag = [
    {
        name:"Modem ADSL",
        title:"Bee | Produit Modem ADSL",
        description:"Profitez d’un réseau sans fil ultra-rapide avec le Modem ADSL de Bee. Bénéficiez d’un réseau sans fil fiable et performant."
    },
    {
        name:"Modem VDSL",
        title:"Bee | Produit Modem VDSL",
        description:"Profitez d’un réseau sans fil ultra-rapide avec le Modem VDSL de Bee. Bénéficiez d’un réseau sans fil fiable et performant."
    },
    {
        name:"Répéteur",
        title:"Bee | Produit Répéteur Wifi",
        description:"Bénéficiez d’une forte qualité de signal, à la maison ou au bureau. Branchez le Répéteur WIFI de Bee n’importe où pour un meilleur champs de couverture réseau."
    },
    {
        name:"Point d'accès",
        title:"Bee | Produit Point d’accès",
        description:"Avec le Point d’accès Bee, partagez un accès internet sans fil à haut débit avec plusieurs ordinateurs ou périphériques."
    },
]

export {
    tag,
    offreTag,
    offrePackTag,
    serviceTag,
    productTag
}