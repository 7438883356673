import React, { Component } from 'react';
//react elastic carousel
import Carousel, {consts} from 'react-elastic-carousel';
import './productList.css';
import { 
    Row,
    Col,
    Image
} from 'react-bootstrap';
import {config} from '../../../../../config';
import { NavLink, Link } from 'react-router-dom';

//images 
const PRODUCT1 =require('../../../../../asset/image/Particulier/product1.png');
const PRODUCT2 =require('../../../../../asset/image/Particulier/product2.png');
const PRODUCT3 =require('../../../../../asset/image/Particulier/product-3.png');
const LEFT_ARROW =require('../../../../../asset/image/Particulier/left-arrow.svg');
const RIGHT_ARROW =require('../../../../../asset/image/Particulier/right-arrow.svg');

export default class ListProducts extends Component {

    customArrow({ type, onClick, isEdge }) {
        const pointer = type === consts.PREV ? 
        <Image src={LEFT_ARROW}/>:<Image width="30px" src={RIGHT_ARROW}/>
        return (
          <button className={`${type}Btn`}  onClick={onClick} disabled={isEdge}>
            {pointer}
          </button>
        )
      }

    render() {
        const breakPoint= [
            { width: 1, itemsToShow: 1 , pagination: false},
            { width: 550, itemsToShow: 2, itemsToScroll: 1, pagination: false },
            { width: 850, itemsToShow: 2, itemsToScroll: 1, pagination: false },
            { width: 1150, itemsToShow: 3, itemsToScroll: 1, pagination: false },
            { width: 1450, itemsToShow: 3, itemsToScroll: 1, pagination: false },
            { width: 1750, itemsToShow: 3, itemsToScroll: 1, pagination: false },
          ]
        return (
            <Row className="mx-0 px-0 my-0 py-4  products-container justify-content-center">
                <Col xl="11" lg="12" md="12" xs="12" className="mx-0 px-0">
                <Carousel className="product-list-carousel m-0 p-0" breakPoints={breakPoint} renderArrow={this.customArrow}>
                {this.props.products.map( (el,id) => {
                return(
                <Link to={`/particulier/produits/${el.Nom.replace(/[()]/g,'')}`} style={{textDecoration:'none'}}>
                  <div className="mt-4 mb-2">
                      <Image fluid className="product-logo" src={config.img+el.img} alt={el.Nom}/>
                      <p className="product-logo-title my-2"> {el.Nom} </p>
                  </div>
                </Link>
                )}
                )}
                </Carousel> 
                </Col>
            </Row> 
        );
    }
}