import React, { useState } from 'react';
import './modal.css';
import Spinner from '../../../../UI/spinner/spinner';
import {
    Modal,
  } from 'react-bootstrap';

export default function SubscriptionModal(props) {


  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} className="subscriptionModal">
        {props.loading?
        <Spinner/>
        :
        <>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="p-0">
            {props.error?
            <p className="subscriptionModalError m-5">Un Problème Est Survenu Lors De La Connexion Au Serveur! <br/>  Veuillez Réessayer Plus Tard</p>      
            :
            <p className="subscriptionModalTitle m-5"> {props.message} </p>
            }
        </Modal.Body>
        </>
        }
      </Modal>
    </>
  );
}