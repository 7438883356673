import React, { Component } from 'react';
import FormData from 'form-data';
import { Col, Form, Button, Image } from 'react-bootstrap';
//css
import './subscription.css';
import axios from 'axios';
import { config } from '../../../../config';
import ReCAPTCHA from "react-google-recaptcha";
import { gouvernorats } from '../../../../const/gouvernorateList';
import Input from './input/input';
import Radio from './Radio/radio';
import Select from './select/select';
import Spinner from '../../../UI/spinner/spinner';
import { checkValidity } from './validity';
import SubscriptionModal from './modal/modal';
import InputImage from './inputImage/inputImage';
import SelectedOffer from './selectedOffer/selectedOffer';
import ClientCoordinates from './ClientCoordinates/ClientCoordinates';
import CoordMap from './ClientCoordinates/map';



const formDefaults = {
    Type_identifiant: {
        label: 'Type identifiant', value: '0', type: 'radio',
        options: [{ label: 'CIN', value: '0', defaultChecked: true }, { label: 'Passeport', value: '1', defaultChecked: false }],
        isValid: true, touched: false, validation: { required: true }
    },
    Civilite: {
        label: 'Civilité', value: '0', type: 'radio',
        options: [{ label: 'Mr', value: '0', defaultChecked: true }, { label: 'Mme', value: '1', defaultChecked: false }],
        isValid: true, touched: false, validation: { required: true }
    },
    Identifiant: {
        label: 'Numéro de carte d\'identité nationale CIN (ou passeport) * ', value: '', type: 'input', inputType: 'text', placeholder: 'Tapez CIN ou Passeport',
        isValid: false, touched: false, message: '',
        validation: { identifier: true }
    },
    Nom: { label: 'Nom *', value: '', type: 'input', inputType: 'text', placeholder: 'Votre nom', isValid: false, touched: false, message: '', validation: { required: true } },


    Prenom: { label: 'Prénom *', value: '', type: 'input', inputType: 'text', placeholder: 'Votre prénom', isValid: false, touched: false, message: '', validation: { required: true } },
    DateNaiss: { label: 'Date de naissance *', value: '', type: 'input', inputType: 'date', placeholder: 'DD/MM/YYYY', isValid: false, touched: false, message: '', validation: { required: true } },
    Nationnalite: { label: 'Nationalité *', value: '', type: 'input', inputType: 'text', placeholder: 'Tunisienne', isValid: false, touched: false, message: '', validation: { required: true } },
    Telephone: { label: 'Numéro de téléphone mobile1 *', value: '', type: 'input', inputType: 'text', placeholder: 'xxxxxxxx', isValid: false, touched: false, message: '', validation: { isTelRequired: true } },
    ContactGSM: { label: 'Numéro de téléphone mobile2 *', value: '', type: 'input', inputType: 'text', placeholder: 'xxxxxxxx', isValid: false, touched: false, message: '', validation: { isTelRequired: true } },
    TelephoneAbonnement: { label: 'Numéro de téléphone fixe (optionnel)', value: '', type: 'input', inputType: 'text', placeholder: '7xxxxxxx', isValid: true, touched: false, message: '', validation: { isFixeNonRequired: true } },
    CodeGPS: { label: 'Coordonnées *', value: '', type: 'input', inputType: 'text', placeholder: 'Vos Coordonnées', isValid: false, touched: false, message: '', validation: { required: true } },
    AdresseClient: { label: 'Adresse Client *', value: '', type: 'input', inputType: 'text', placeholder: 'Votre adresse', isValid: false, touched: false, message: '', validation: { required: true } },
    AdresseComplement: { label: 'Complément d’adresse (indice adresse)', value: '', type: 'input', inputType: 'text', placeholder: 'Votre complément d\'adresse', isValid: true, touched: false, message: '', validation: { required: false, maxLength: 35 } },
    Gouvernorat: {
        label: 'Gouvernorat *', value: 'Tunis', type: 'select', isValid: true, touched: false,
        options: [...gouvernorats],
        message: '', validation: { required: true }
    },
    Ville: { label: 'Ville *', value: '', type: 'select', isValid: false, touched: false, options: "ville", message: '', validation: { required: true } },
    Localite: { label: 'Cité *', value: '', type: 'select', isValid: false, touched: false, options: "cité", message: '', validation: { required: true } },
    CodePostal: { label: 'Code Postal *', value: '', type: 'input', inputType: 'text', placeholder: 'xxxx', isValid: false, touched: false, message: '', validation: { required: true, minLength: 4, maxLength: 4, isNumeric: true } },
    Email: { label: 'Adresse Mail*', value: '', type: 'input', inputType: 'email', placeholder: 'Votre mail', isValid: true, touched: false, message: '', validation: { required: false, isEmail: true } },
    FrequenceAbonnement: {
        label: 'Fréquence de paiement (Mois)', value: '1', type: 'radio',
        options: [{ label: '1', value: '1', defaultChecked: true }, { label: '3', value: '3' }, { label: '6', value: '6' }, { label: '12', value: '12' }],
        isValid: true, touched: false, validation: { required: true }
    },
    cin_r: { label: 'CIN RECTO *', value: '', type: 'file', isValid: false, touched: false, message: 'La taille maximale autosisée est de 3mb', validation: { imageSize: true } },
    cin_v: { label: 'CIN VERSO *', value: '', type: 'file', isValid: false, touched: false, message: 'La taille maximale autosisée est de 3mb', validation: { imageSize: true } },

};
export default class Subscription extends Component {

    state = {
        form: {
            ...formDefaults
        },
        formIsValid: false,

        reference: null,
        offreId: null,
        offrePackId: "",
        offre: "",

        loading: false,
        modalShow: false,
        message: null,
        error: false,
        captcha: false,
        isShowingMap: false
    };
    componentDidMount() {
        let type = this.props.match.params.type;
        let id = this.props.match.params.Reference;
        if (type == "offre") {
            axios.get(`${config.url}detailsOffres?id=${id}`)
                .then(response => {
                    this.setState({ reference: response.data.reference, offreId: response.data.offres_id });

                    axios.get(`${config.url}offres?id=${response.data.offres_id}`)
                        .then(res => {
                            this.setState({ offre: res.data.nom });
                        }).catch(error => console.log(error, "related to offers list"));

                }).catch(error => console.log(error, "related to offre detail selected"));
        }
        if (type == "pack") {
            axios.get(`${config.url}detailsOffrespack?id=${id}`)
                .then(response => {
                    this.setState({ reference: response.data.reference, offrePackId: response.data.offresPackgers_id });

                    axios.get(`${config.url}offrespack?id=${response.data.offresPackgers_id}`)
                        .then(res => {
                            this.setState({ offre: res.data.nom });
                        }).catch(error => console.log(error, "related to offers list"));

                }).catch(error => console.log(error, "related to offre pack selected"));
        }
    }
    //verif captcha
    captchaChange = (value) => {
        this.setState({ captcha: true });
    };

    // changement de débit et référence 
    flowChange = (reference, name) => {
        this.setState({ reference });
    };

    handleLatLng = (lat, lng) => {
        this.setState({
            lat: lat,
            lng: lng

        });
    };
    //value change handler
    handleChange = (event, name) => {
        const updatedForm = {
            ...this.state.form
        };
        const updatedFormElement = {
            ...updatedForm[name]
        };
        const pattern = /[ء-ي]/;
        if (pattern.test(event.target.value)) {
            alert('veuillez saisir uniquement des caractères alphanumériques');
            updatedFormElement.value = "";
            updatedFormElement.isValid = false;
            updatedFormElement.touched = true;
        } else {
            if (name === "cin_r" || name === "cin_v") {
                updatedFormElement.value = event.target.files[0];
                updatedFormElement.isValid = checkValidity(event.target.files[0], updatedFormElement.validation, this.state.form);
                updatedFormElement.touched = true;
            }
            else {
                updatedFormElement.value = event.target.value;
                updatedFormElement.isValid = checkValidity(updatedFormElement.value, updatedFormElement.validation, this.state.form);
                updatedFormElement.touched = true;
            }
        }
        updatedForm[name] = updatedFormElement;
        if (name === "Telephone" && updatedForm["ContactGSM"].touched === false) {
            updatedForm["ContactGSM"] = { label: 'Numéro de téléphone mobile2 *', value: event.target.value, type: 'input', inputType: 'text', placeholder: 'xxxxxxxx', isValid: true, touched: false, message: '', validation: { isTelRequired: true } };
        }

        let formValid = true;
        for (let name in updatedForm) {
            formValid = updatedForm[name].isValid && formValid;
        }
        this.setState({ form: updatedForm, formIsValid: formValid });
    };
    //modal handler
    handleModalClose = () => {
        this.setState({ modalShow: false });
    };
    ///submit function
    formHandler = (event) => {
        event.preventDefault();
        this.setState({ loading: true, modalShow: true });

        let formData = {};
        let d = new Date();
        for (let formElement in this.state.form) {
            formData[formElement] = this.state.form[formElement].value;
        }
        let additionalForm = {
            "ReferencePack": this.state.reference, "TypeAbonnement": "8571", "Moral": "0", "Pays": "Tunisie",
            "CodePosteTun": "3105", "idLieuEtablissement": "Tunis",
            "idDateEtablissement": `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`,
            "AdresseFacturation": this.state.form.AdresseClient.value, "AdresseInstallation": this.state.form.AdresseClient.value,
            "Contact": this.state.form.Nom.value + ' ' + this.state.form.Prenom.value
        };

        /********** format json to post for api bee */
        let form = JSON.stringify({ ...formData, ...additionalForm });
        /********** format json to post for api bee */

        /********* to upload the form with image for api lezarts */
        let myForm = document.getElementById('myForm');
        var data = new FormData(myForm);
        for (let formElement in additionalForm) {
            data.append(formElement, additionalForm[formElement]);
        }
        /********* to upload the form with image for api lezarts */

        console.log(form);

        axios.post(config.abonnement, form, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    axios.post(`${config.url}abonnement`, data,
                        {
                            headers: {
                                'accept': 'application/json',
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(res => {
                            this.setState({ form: formDefaults, formIsValid: false, loading: false, message: "Votre abonnement a été crée avec succés " });
                            console.log(res);
                        })
                        .catch(error => {
                            this.setState({ form: formDefaults, formIsValid: false, loading: false, message: "Votre abonnement a été crée avec succés !" });
                            console.log(error.response.data, 'send to bee but error with api lezarts');
                        });
                }
                else {
                    this.setState({ form: formDefaults, formIsValid: false, loading: false, message: response.data.msg });
                    console.log(response.data, "data");
                }
            })
            .catch(error => {
                this.setState({ form: formDefaults, formIsValid: false, loading: false, error: true });
                console.log(error, 'relative api bee');
            })
            .finally(() => console.log(this.state));

        console.log(this.state.message);
    };

    render() {
        const formList = [];
        for (let key in this.state.form) {
            formList.push({
                id: key,
                property: this.state.form[key]
            });
        }

        window.addEventListener('click', (e) => {
            if (!e.target.closest(".coordonnees") && this.state.isShowingMap) {
                this.setState({ isShowingMap: false });
            }
        });
        return (
            <>
                <SubscriptionModal show={this.state.modalShow} handleClose={this.handleModalClose} loading={this.state.loading} error={this.state.error} message={this.state.message} />
                {/* <p className="selected-offre my-5"> 
                Vous avez choisi l'offre {this.props.location.state}
            </p> */}
                <SelectedOffer pack={this.props.location.state}
                    offerId={this.state.offreId} offerPackId={this.state.offrePackId}
                    type={this.props.match.params.type} reference={this.state.reference}
                    changed={this.flowChange}
                    offre={this.state.offre}
                />
                <Form onSubmit={this.formHandler} className="subscription-container" id="myForm" name="myForm">
                    <Form.Row className="align-items-center px-0 py-5 mx-0 justify-content-center">
                        {formList.map(el => {
                            if (el.property.type == 'input') {
                                if (el.property.placeholder === 'Vos Coordonnées') {
                                    return <ClientCoordinates el={el} handleChange={this.handleChange} />;
                                }

                                return (
                                    <Col xl="5" md="5" xs="10" className="mx-2 my-2">
                                        <Input
                                            label={el.property.label}
                                            name={el.id}
                                            type={el.property.inputType}
                                            placeholder={el.property.placeholder}
                                            isValid={el.property.isValid}
                                            touched={el.property.touched}
                                            errorMessage={el.property.message}
                                            changed={this.handleChange}
                                            value={el.property.value}
                                        />
                                    </Col>);
                            }

                            if (el.property.type == 'radio') {
                                return (
                                    <Col xl="5" md="5" xs="10" className="mx-2 my-2">
                                        <Radio
                                            label={el.property.label}
                                            name={el.id}
                                            options={el.property.options}
                                            changed={this.handleChange}
                                        />
                                    </Col>);
                            }
                            if (el.property.type == 'select') {
                                return (
                                    <Col xl="5" md="5" xs="10" className="mx-2 my-2">
                                        <Select
                                            label={el.property.label}
                                            name={el.id}
                                            value={el.property.value}
                                            touched={el.property.touched}
                                            isValid={el.property.isValid}
                                            options={el.property.options}
                                            changed={this.handleChange}
                                            gouvernorat={this.state.form.Gouvernorat.value}
                                            ville={this.state.form.Ville.value}
                                        />
                                    </Col>);
                            }
                            if (el.property.type == 'file') {
                                return (
                                    <Col xl="5" md="5" xs="10" className="mx-2 my-2">

                                        <InputImage
                                            label={el.property.label}
                                            name={el.id}
                                            changed={this.handleChange}
                                            touched={el.property.touched}
                                            isValid={el.property.isValid}
                                            errorMessage={el.property.message}
                                        />
                                    </Col>);
                            }


                        })}

                        <Col xl="10" md="10" xs="10" className="mx-2 my-2">
                            <ReCAPTCHA
                                sitekey="6LeoftoaAAAAAMRxgmwn-BLLvW4qa5f3uiOuH-dQ"
                                onChange={this.captchaChange}
                            />
                        </Col>

                        {/* <Col xs="6" md={{ span: 4, offset: 7 }} xl={{ span: 3, offset: 8 }} className="mb-5 mt-3">
                        <Button className="subscription-btn" type="submit"  > Submit  </Button>
                    </Col> */}
                        <Col xs="6" md={{ span: 4, offset: 7 }} xl={{ span: 3, offset: 8 }} className="mb-5 mt-3">
                            <Button className="subscription-btn" type="submit" disabled={!(this.state.formIsValid && this.state.captcha)}> Submit  </Button>
                        </Col>
                    </Form.Row>
                </Form>

            </>
        );
    }
}