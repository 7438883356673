import React, { Component } from 'react';
import { 
    Row,
    Col,
    Image,
    Container
} from 'react-bootstrap';
import './product.css';

import {config} from '../../../../../config';
import Breadcrumb from '../../../../Navigation/breadcrumb/breadcrumb';

export default class Product extends Component {

    render() {
        let items=[{name:'Produits',path:'/particulier/produits'}]
        return (
            <Container>

                {/******breadcrumb */}
                <Row className='my-5 mx-0'>
                    <Col xl="11" md="12" xs="12">
                        <Breadcrumb active={this.props.product.Nom} items={items}/>
                    </Col>
                </Row>
                {/******breadcrumb */}
                <Row className="mx-0 mb-5 product-content px-0 py-5">
                    <Col xl="4" md="5" xs="12">
                        <Image className="product-img" fluid src={config.img+this.props.product.img} alt={this.props.product.name} />
                    </Col>
                    <Col xl="7" md="7" xs="12" className="my-auto">
                        <div>
                            <div className="d-flex product-title-container pt-3">
                                <h1 className="product-title"> {this.props.product.Nom} </h1>
                                {/* {(this.props.product.Disponibilite==="oui") ? 
                                <small className="ml-auto product-instock"> InStock </small> : 
                                <small className="ml-auto product-offstock"> OffStock </small>
                                } */}
                                
                            </div>
                            <div>
                                <p className="product-description pr-xl-5 py-3">
                                    {this.props.product.Description}
                                </p>
                                <p className="product-price"> {this.props.product.Prix.toFixed(2)} dt </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container> 
        );
    }
}