import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
//css
import './autocomplete.css';

export default function SearchInMap(props) {
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  });

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
    props.pickCoord(latLng.lat,latLng.lng);
    // console.log(latLng.lat,latLng.lng,"voila");
  };
 

  return (
    <div className="mx-0 px-0">
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="suggestions-container">
            <label className="search-text"> Ou 
            <input placeholder="Ville" className="map-search-input ml-3" {...getInputProps({ placeholder: "Ville" })} />
            </label>
            <div className="input-suggestions ml-3">
              {loading ? <div>...loading</div> : null}

              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#f8f8f7"
                };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })} className>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}