import React, { Component } from 'react';
//css
import './navbar.css';
//react-bootstrap
import {
    Row, Col, Navbar, Nav, NavDropdown, Image, Dropdown
} from 'react-bootstrap';

import axios from 'axios';
import { config } from '../../../../config';
//import react router
import {
    NavLink,
    withRouter
} from "react-router-dom";


class NavParticulier extends Component {

    state = {
        navExpanded: false,
        isClicked: false,
        menuImg: null
    };

    componentDidMount() {
        axios.get(`${config.url}menu_img`)
            .then(response => {
                this.setState({ menuImg: response.data });
            }).catch(error => console.log(error));
        axios.get(`${config.url}getArticles`)
            .then(response => {
                this.setState({ ...this.state, allArticles: response.data });
                console.log(this.state);
            }).catch(error => console.log(error, "related to fetching articles"));
    }

    setNavExpanded = (expanded) => {
        let x = expanded;
        this.setState({ navExpanded: x });
    };
    closeNav = () => {
        this.setState({ navExpanded: false });
    };



    render() {
        // let productList = this.props.productItems? [...this.props.productItems]:[];
        let serviceList = this.props.serviceItems ? [...this.props.serviceItems] : [];
        let categoryList = this.props.offreCategories ? [...this.props.offreCategories] : [];
        let offreList = this.props.offreItems ? [...this.props.offreItems] : [];
        let imgOffre = this.state.menuImg ? this.state.menuImg[0].menu_offres : null;
        // let imgProduct = this.state.menuImg? this.state.menuImg[0].menu_produits:null;
        let imgService = this.state.menuImg ? this.state.menuImg[0].menu_services : null;

        console.log(this.state.allArticles === false);
        return (
            <Row className="navbar-container mx-0">
                <Col xl="12" lg="12" className="m-auto px-0">
                    <Navbar onToggle={this.setNavExpanded} expanded={this.state.navExpanded} expand="lg" className="navbar-bee">
                        <Navbar.Brand />
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="m-auto" activeKey={window.location.pathname}>

                                {/***********Offres Dropdown ****/}
                                <NavDropdown
                                    active={window.location.pathname.includes("/particulier/offre/") || window.location.pathname.includes("/particulier/offre-packagée/")}
                                    title="Nos offres" id="nav-dropdown-offre" className="mx-0"
                                >
                                    <div className="dropdown-container mx-xl-5 px-xl-5">
                                        <div className="my-auto">
                                            {categoryList.map((el, id) => {
                                                return (
                                                    <Dropdown drop="down" className="dropdown-rubrique">
                                                        <Dropdown.Toggle className="rubrique mb-0">
                                                            {el.type}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-rubrique-menu">
                                                            {offreList.map((offre, idOffre) => {
                                                                if (el.id == offre.categorie_offres_id)
                                                                    return (
                                                                        <Dropdown.Item as={NavLink} activeClassName="active-link-bee" to={`/particulier/offre/${offre.nom.replace(/ /g, '')}`} onClick={() => this.closeNav()}> {offre.nom} </Dropdown.Item>
                                                                    );
                                                            })}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                );
                                            })}
                                            <Dropdown drop="down" className="dropdown-rubrique">
                                                <Dropdown.Toggle className="rubrique mb-0">
                                                    Offres Packagées
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-rubrique-menu">
                                                    {this.props.offrePack.map((offre, id) => {
                                                        return (
                                                            <Dropdown.Item as={NavLink} activeClassName="active-link-bee"
                                                                to={`/particulier/offre-packagée/${offre.type.replace(/ /g, '')}`} onClick={() => this.closeNav()}>
                                                                {offre.type} </Dropdown.Item>
                                                        );
                                                    })}

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div>
                                            {imgOffre && <Image className="dropdown-img" fluid src={config.img + imgOffre} />}
                                        </div>
                                    </div>
                                </NavDropdown>
                                {/***********Offres Dropdown ****/}

                                {/**********Services Dropdown ****/}
                                <NavDropdown
                                    active={window.location.pathname.includes("/particulier/service/")}
                                    title="Nos services" id="nav-dropdown-service" className="mx-0"
                                >
                                    <div className="dropdown-container mx-xl-5 px-xl-5">
                                        <div className="my-auto">
                                            {serviceList.map((el, id) => {
                                                return (
                                                    <NavDropdown.Item onClick={() => this.closeNav()} as={NavLink} activeClassName="active-link-bee" to={`/particulier/service/${el.Titre.replace(/ /g, '')}`}> {el.Titre} </NavDropdown.Item>
                                                );
                                            }
                                            )}
                                        </div>
                                        <div>
                                            {imgService && <Image className="dropdown-img" fluid src={config.img + imgService} />}
                                        </div>
                                    </div>
                                </NavDropdown>
                                {/**********Services Dropdown ****/}

                                <Nav.Link onClick={() => this.closeNav()} className="mx-0" as={NavLink} to="/particulier/produits"> Nos produits </Nav.Link>
                                {this.state.allArticles?.length ? <Nav.Link onClick={() => this.closeNav()} className="mx-0" as={NavLink} to="/particulier/actuality"> Nos articles </Nav.Link> : null}
                                <Nav.Link onClick={() => this.closeNav()} className="mx-0" href="https://beews.tn" target="_blank"> Cloud beeWS </Nav.Link>
                                <Nav.Link onClick={() => this.closeNav()} as={NavLink} className="mx-0" exact to="/particulier/contact"> Contact </Nav.Link>
                                {/* <Nav.Link onClick={()=>this.closeNav()} className="mx-0" style={{border:"none"}} as={NavLink} exact to="/particulier/abonnement">Demande d'abonnemement </Nav.Link> */}

                                {/****** On small devices links  */}
                                <Nav.Link onClick={() => this.closeNav()} as={NavLink} className="mx-0 responsive-link" exact to="/particulier/about"> A propos </Nav.Link>
                                <Nav.Link onClick={() => this.closeNav()} as={NavLink} className="mx-0 responsive-link" exact to="/particulier/contact"> Contact </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>

        );
    }
}
export default withRouter(NavParticulier);