import React, { Component } from 'react';
//css
import './footer.css';
//react-bootstrap
import {
    Row,
    Col,
    Image,
    Dropdown

} from 'react-bootstrap';

//import react router
import {
    Link,
    NavLink
} from "react-router-dom";
import { ContactMap } from '../../Home/Particulier/ContactPage/Map/map';
//logo
const FB_LOGO = require('../../../asset/image/Dashboard/facebook.svg');
const INSTAGRAM_LOGO = require('../../../asset/image/Dashboard/instagram.svg');
const LINKEDIN_LOGO = require('../../../asset/image/Dashboard/linkedin.svg');
const LOGO = require('../../../asset/image/navigation/logo-bee.png');
const ADRESS_LOGO = require('../../../asset/image/navigation/adress-logo.svg');
const MOBILE_LOGO = require('../../../asset/image/navigation/mobile-logo.svg');
const MAIL_LOGO = require('../../../asset/image/navigation/email-logo.svg');

export default class Footer extends Component {

    //to get the id of category
    getCatId(x) {
        for (let key in this.props.offreCategories) {
            if (x === this.props.offreCategories[key].id) {
                return key;
            }
        }
    }
    render() {
        let services = this.props.services ? this.props.services : [];
        let offres = this.props.offres ? this.props.offres : [];
        let offreCategories = this.props.offreCategories ? this.props.offreCategories : [];
        return (
            <footer className="mx-0 pt-2 px-0 footer-containerr">
                <Row className="mx-0 pt-5">
                    <Col xl={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }} md="4" xs="12" className="my-auto footer-logo-container">
                        <div>
                            <Link to="/"><Image className="footer-logo" src={LOGO} /></Link>
                        </div>
                        <div className="footer-contact-bloc">
                            <div className="my-2">
                                <Image fluid src={ADRESS_LOGO} className="mr-2" />
                                <span>
                                    52 Rue 8600 Z.I 2035-<br />charguia  1 à côté de la banque STB
                                </span>
                            </div>
                            <div className="my-2">
                                <Image fluid src={MOBILE_LOGO} className="mr-2" />
                                <span>+216 71 100 440</span>
                            </div>
                            <div className="my-2">
                                <Image fluid src={MAIL_LOGO} className="mr-2" />
                                <span>Contact@bee.net.tn</span>
                            </div>
                        </div>
                        <div className="footer-social-media mx-5 pt-2">
                            <div className="mx-2">
                                <a href="https://www.facebook.com/bee.tunisie/" target="_blank">
                                    <Image fluid src={FB_LOGO} />
                                </a>
                            </div>
                            <div className="mx-2">
                                <a href="https://instagram.com/beetunisie/" target="_blank">
                                    <Image fluid src={INSTAGRAM_LOGO} />
                                </a>
                            </div>
                            <div className="mx-2">
                                <a href="https://www.linkedin.com/company/bee-tunisie/" target="_blank">
                                    <Image fluid src={LINKEDIN_LOGO} />
                                </a>
                            </div>
                        </div>
                    </Col>
                    {/**** Footer Links ****/}
                    <Col xl="8" lg="8" md="8" xs="8" className="my-auto footer-links">
                        <Row className="mx-0">
                            <Col xl="3" lg="3" md="6" xs="6" className="footer-link-bloc py-1">
                                <p className="footer-link-title"> Nos Offres </p>

                                {offreCategories.map((el, id) => {
                                    return (
                                        <>
                                            {/* <p className="footer-link-text bold"> {el.type} </p> */}
                                            {offres.map((offre, idOffre) => {
                                                if (el.id == offre.categorie_offres_id)
                                                    return (
                                                        <Link to={`/particulier/offre/${offre.nom.replace(/ /g, '')}`} className="footer-link-text"><p> {offre.nom}  </p></Link>
                                                    );
                                            })}
                                        </>
                                    );
                                })}
                                {/* <p className="footer-link-text bold"> Offres Packagées </p> */}
                                {this.props.offrePackCat.map((offre, id) => {
                                    return (
                                        <Link to={`/particulier/offre-packagée/${offre.type.replace(/ /g, '')}`} className="footer-link-text"><p> {offre.type}  </p></Link>
                                    );
                                })}
                            </Col>

                            <Col xl="3" lg="3" md="6" xs="6" className="footer-link-bloc py-1">
                                <p className="footer-link-title"> Nos Services </p>
                                {services.map((el, id) => {
                                    return (
                                        <Link to={`/particulier/service/${el.Titre.replace(/ /g, '')}`} className="footer-link-text"><p> {el.Titre}  </p></Link>
                                    );
                                }
                                )}
                            </Col>

                            <Col xl="3" lg="3" md="6" xs="6" className="footer-link-bloc py-1">
                                <Link to="/particulier/produits" className="footer-link-title"><p> Nos Produits  </p></Link>
                                <a href="https://paiementexpress.bee.net.tn/login" className="footer-link-title" target="_blank"><p> Paiements en ligne  </p></a>
                            </Col>

                            <Col xl="3" lg="3" md="6" xs="6" className="py-1">
                                <Link to="/particulier/contact" className="footer-link-title"><p> Contact  </p> </Link>
                                <Link to="/particulier/about" className="footer-link-title"><p> A propos  </p> </Link>
                                {/* <Link to="/particulier/actuality"><p className="footer-link-title"> Actualité </p></Link> */}
                                <Link to="/particulier/faq" className="footer-link-title"><p> FAQ </p></Link>
                            </Col>
                            {/**** Footer Links ****/}
                        </Row>
                    </Col>
                </Row>

                {/***footer copyright ***/}
                <Row className="mx-0 mt-2">
                    <div className="footer-copyright-text mx-auto">
                        <hr />
                        {/* <p></p> */}
                        <p>
                            Powered By
                            <a href="https://lezarts.digital/" target="_blank"> Lezarts Digital</a>
                        </p>
                    </div>
                </Row>
                {/***footer copyright ***/}
            </footer>
        );
    }
}