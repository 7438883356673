import React, { Component } from 'react'
import './product.css';

import {
  Carousel,
  Button,
  Row,
  Col,
  Image, 
} from 'react-bootstrap';
//images
const MODEM_IMG = require('../../../../../asset/image/Dashboard/modem.png');


export default class Product extends Component {
    render() {
        return (
<div>     
    <Row className="mx-0 px-0 products-carousel-container">  

        <Col xl="10" md="12" sm="12" xs="12" className="mx-auto my-0">
            <p className="products-slide-title"> DÉCOUVREZ LE MEILLEUR DES PRIX BEE !</p>

            <Carousel indicators={false}>
      
                <Carousel.Item>
                    <Carousel.Caption>
                        <div className="d-flex">
                            <div className="product-slide-img">
                                <Image fluid src={MODEM_IMG} />
                            </div>
                            <div className="my-auto product-slide-content">
                                <p className="product-slide-title"> Lorem ipsum dolor 1 </p>
                                <p className="product-slide-text"> Lorem ipsum dolor sit amet , consectetur adipscing elit, sed do eiusmod tempor </p>
                                <Button className="product-slide-btn px-5"> Découvrir</Button>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Carousel.Caption>
                        <div className="d-flex">
                            <div className="product-slide-img">
                                <Image fluid src={MODEM_IMG} />
                            </div>
                            <div className="my-auto product-slide-content">
                                <p className="product-slide-title"> Lorem ipsum dolor 2 </p>
                                <p className="product-slide-text"> Lorem ipsum dolor sit amet , consectetur adipscing elit, sed do eiusmod tempor </p>
                                <Button className="product-slide-btn px-5"> Découvrir</Button>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>

            </Carousel>

        </Col>

    </Row>

    <Row className="mx-0">
        <div className="mx-auto my-5 text-center">
            <h5 className="products-text"> Découvrez d'autres produits </h5>
            <Button className="products-btn px-5"> Découvrir</Button> 
        </div>
    </Row>
</div> 
        )
    }
}