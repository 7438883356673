import React, { Component } from 'react';
//css
import './services.css';
import {Container,Row,Col,Image,Button} from 'react-bootstrap';
import {config} from '../../../../config';
import {serviceTag} from '../../../../const/metaTags';
import Helmet from 'react-helmet';

//component
import Spinner from '../../../UI/spinner/spinner';
import Breadcrumb from '../../../Navigation/breadcrumb/breadcrumb';


export default class Service extends Component {

    render() {
        let service=this.props.services[this.props.id];
        return (
            <>
            {this.props.services ? 
                <Container fluid className="mx-0">
                    {/**Meta tags */}
                    {serviceTag.map(el=>{
                    if(el.name===service.Titre){
                    return(
                    <Helmet>
                        <title> {el.title} </title>
                        <meta name="description" content={el.description} />
                    </Helmet>
                    )}
                        
                    })}
                    {/****breadcrumb */}
                    <Row className="justify-content-center">
                        <Col xl="10" md="10" xs="12"className="mt-5">
                            <Breadcrumb active={service.Titre}/>
                        </Col>
                    </Row>
                    {/****breadcrumb */}
                    {/**description of service */}
                    <Row className="justify-content-center">
                        <Col xl="10" className="mt-5">
                            <h1 className="service-title"> {service.Titre} </h1>
                            <hr className="service-line"/>
                        </Col>
                        <Col xl="5" lg="8" md="10" xs="12" className="service-description my-auto" >
                            <p className="description-service-title"> 
                                {service.Attache} 
                            </p>
                            <p className="description-service-text">
                                {service.Description}
                            </p>
                            {/* <Button className="px-4 service-btn"> Commander </Button> */}
                        </Col>
                        <Col xl="5" lg="8" md="10" xs="12" className="service-image">
                            <Image fluid src={config.img+service.Image_desc} />
                        </Col>
                    </Row>

                    {/**advantages of service */}
                    <Row className="justify-content-center advantages-container mt-4">
                        <Col xl="5" md="10" xs="12" className="advantages-image">
                            <Image fluid src={config.img+service.Image_avan} />
                        </Col>
                        <Col xl="5" md="10" xs="12" className="my-auto service-advantages">
                            <p className="advantages-service-title"> Avantages </p>
                            <ul className="advantages-service-list">
                                {service.Avantages.split(/\r\n|\n|\r/gm).map(line => {
                                return <li> {line} </li>
                                })}
                            </ul> 
                        </Col>
                    </Row>
                </Container>
                :
                <Spinner/>
                }
            </>   
        );
    }
}