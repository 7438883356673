import React, { Component } from 'react';
import { Row,  Col,} from 'react-bootstrap';

import axios from 'axios';
import {config} from '../../../../../config';
import {tag} from '../../../../../const/metaTags';
import Helmet from 'react-helmet';

//component
 import CategoryFiltre from '../FiltreCategory/filtreCat';
import Categories from '../ProductCategory/productCategories';
import Spinner from '../../../../UI/spinner/spinner'
import Breadcrumb from '../../../../Navigation/breadcrumb/breadcrumb';
import { withRouter } from 'react-router-dom';

class allCategory extends Component {
    state = {
        filterList:[],
        catproducts:[]
    }
    componentDidMount () {
        axios.get(`${config.url}catprods`)
            .then( response => {
                this.setState({catproducts:response.data})
            }).catch(error=> console.log(error,"related to product category") )
            // console.log(this.props, "------------------+++++++++++++++++++++++-----------")
        let list = this.state.filterList;
        let id = this.props?.location?.state?.id;
        if(id){
            list.push(id);
            this.setState({filterList:[...list]})
        }


    }
    handleFilterChange= (event) => {
        let x = event.target.value;
        // console.log("--------------------------", event.target)
        let list = this.state.filterList;
        if(event.target.checked){
            list.push(x);
            this.setState({filterList:[...list]})
            // console.log(this.state.filterList)
        }
        if(!event.target.checked){
            let index = list.indexOf(x)
            list.splice(index,1);
            this.setState({filterList:[...list]})
            // console.log(this.state.filterList)
        }
    }

    render() {
        let products=this.props.products?this.props.products:null;
        return (
            <>
                {!products? <Spinner/>
                :<Row className="mx-0 px-0 mt-5 justify-content-center">
                    
                    <Helmet>
                        <title> {tag.products.title} </title>
                        <meta name="description" content={tag.products.description} />
                    </Helmet>
                    {/***********breadcrumb */}
                    <Col xl="10" md="12" xs="12" className="mx-5 mb-2">
                        <Breadcrumb active='Produits'/>
                    </Col>
                    <Col xl="2" md="3" xs="12" className="">
                        <CategoryFiltre changed={this.handleFilterChange} items={this.state.catproducts} />     
                    </Col>
                    <Col xl="8" md="8" xs="12" className="">
                        <Categories filterList={this.state.filterList} products={products} />   
                    </Col>      
                </Row>}
            </>
        );
    }
}

export default withRouter (allCategory)