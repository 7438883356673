import React, { Component } from 'react';
import { Row, Col, Button, } from 'react-bootstrap';
import axios from 'axios';
import { config } from '../../../../config';
import { tag } from '../../../../const/metaTags';
import Helmet from 'react-helmet';
import './index.css';



//component
import CategoryFiltre from './FiltreCategory/filtreCat';
import Categories from './ProductCategory/productCategories';
import Spinner from '../../../UI/spinner/spinner';
import Breadcrumb from '../../../Navigation/breadcrumb/breadcrumb';
import { Link } from 'react-router-dom';

export default class Products extends Component {
    state = {
        filterList: [],
        catproducts: []
    };
    componentDidMount() {
        axios.get(`${config.url}catprods`)
            .then(response => {
                this.setState({ catproducts: response.data });
            }).catch(error => console.log(error, "related to product category"));

    }
    handleFilterChange = (event) => {
        let x = event.target.value;
        let list = this.state.filterList;
        if (event.target.checked) {
            list.push(x);
            this.setState({ filterList: [...list] });
            // console.log(this.state.filterList)
        }
        if (!event.target.checked) {
            let index = list.indexOf(x);
            list.splice(index, 1);
            this.setState({ filterList: [...list] });
            // console.log(this.state.filterList)
        }
    };

    render() {
        let products = this.props.products ? this.props.products : null;
        return (
            <>
                {!products ? <Spinner />
                    : <Row className="mx-0 px-0 mt-5 justify-content-center">

                        <Helmet>
                            <title> {tag.products.title} </title>
                            <meta name="description" content={tag.products.description} />
                        </Helmet>
                        {/***********breadcrumb */}
                        <Col xl="10" md="12" xs="12" className="mx-5 mb-2">
                            <Breadcrumb active='Produits' />
                        </Col>
                        {/* <Col xl="2" md="3" xs="12" className="">
                        <CategoryFiltre changed={this.handleFilterChange} items={this.state.catproducts} />     
                    </Col> */}
                        <Col xl="8" md="8" xs="12" className="">
                            {/* <Categories filterList={this.state.filterList} products={products} />    */}

                            <Row>
                                <Col >
                                    <img src={require('../../../../asset/image/Particulier/Groupe 3335.png')} style={{ width: "178%", height: "110%", display: "flex", marginLeft: "-29%" }} />
                                    <p className='tite'> Smartphone </p>
                                    <Link to={{ pathname: '/particulier/produits/Category', state: { id: "1" } }}>
                                        <Button className="prodat-btn">
                                            Découvrir
                                        </Button>
                                    </Link>

                                </Col>
                                <Col >
                                    <img src={require('../../../../asset/image/Particulier/Groupe 3336.png')} style={{ width: "71%", height: "83%", display: "flex", marginLeft: "47%", marginTop: "6%", zIndex: "0", position: "relative" }} />
                                    <p className='tite2' style={{ whiteSpace: 'nowrap' }}> Modem ADSL </p>
                                    <Link to={{ pathname: '/particulier/produits/Category', state: { id: "2" } }}>

                                        <Button

                                            className="prodat-btn2">
                                            Découvrir
                                        </Button>
                                    </Link>

                                </Col>

                            </Row>
                            <Row style={{ marginBottom: "18%" }}>
                                <Col >
                                    <img src={require('../../../../asset/image/Particulier/Groupe 3337.png')} style={{ width: "110%", height: "110%", display: "flex", marginLeft: "-31%", marginTop: "6%", zIndex: "0", position: "relative" }} />
                                    <p className='tite3' style={{ marginLeft: "-20%" }}> Modem VDSL </p>
                                    <Link to={{ pathname: '/particulier/produits/Category', state: { id: "3" } }}>

                                        <Button

                                            className="prodat-btn3"
                                            style={{ marginLeft: "-20%" }}>
                                            Découvrir
                                        </Button>
                                    </Link>


                                </Col>
                                <Col >
                                    <img src={require('../../../../asset/image/Particulier/Groupe 3338.png')} style={{ width: "110%", height: "110%", display: "flex", marginLeft: "-5%", marginTop: "6%", zIndex: "0", position: "relative" }} />
                                    <p className='tite3' style={{ marginLeft: "5%" }}> Point d'accès </p>
                                    <Link to={{ pathname: '/particulier/produits/Category', state: { id: "4" } }}>

                                        <Button

                                            className="prodat-btn3"
                                            style={{ marginLeft: "5%" }}>
                                            Découvrir
                                        </Button>
                                    </Link>


                                </Col>
                                <Col >
                                    <img src={require('../../../../asset/image/Particulier/Groupe 3339.png')} style={{ width: "110%", height: "110%", display: "flex", marginLeft: "18%", marginTop: "6%", zIndex: "0", position: "relative" }} />
                                    <p className='tite3' style={{ marginLeft: "27%" }}> Répéteur </p>
                                    <Link to={{ pathname: '/particulier/produits/Category', state: { id: "5" } }}>

                                        <Button

                                            className="prodat-btn3"
                                            style={{ marginLeft: "27%" }}>
                                            Découvrir
                                        </Button>
                                    </Link>

                                </Col>

                            </Row>

                        </Col>
                    </Row>}
            </>
        );
    }
}