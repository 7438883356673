import React, { Component } from 'react';

import ReCAPTCHA from "react-google-recaptcha";
import ContactModal from './Modal/modal'
//css
import './contact.css';
//react-bootstrap
import {
  Row,
  Col,
  Form,
  Button
} from 'react-bootstrap';
import axios from 'axios';
import {config} from '../../../../config';

const contactDefault = {
        name: { value: '', isValid: false,touched:false,validation: {required: true} },
        mailAdress: { value: '', isValid: false,touched:false,validation: {required: true,isEmail:true} },
        subject: { value: '', isValid: false,touched:false,validation: {required: true} },
        tel: { value: '', isValid: false,touched:false,validation: {isTel:true} },
        message: { value: '', isValid: false,touched:false,validation: {required: true} }

}
export default class ContactForm extends Component {
    state={
        form:{
        ...contactDefault
        },
        captcha:false,
        formValidity:false,
        modalShow:false,
        loading:false,
        error:false   
      }

      handleFormChange = (event) => {
       const x=event.target.name.toString()
       
       const updatedForm={
        ...this.state.form
        }
        const formElement = { 
            ...updatedForm[x]
        };
       formElement.value=event.target.value;
       formElement.isValid=this.validity(formElement.value,formElement.validation);
       formElement.touched=true;

       updatedForm[x] = formElement;
       let formValid = true;
        for (let name in updatedForm) {
            formValid = updatedForm[name].isValid && formValid;
        }
       this.setState({ form:updatedForm,formValidity:formValid })
      }

      /**function to check validity */
      validity = (value, rules) => {
        let valid = true;
    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        valid = value.trim() !== '' && valid;
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        rules.required? valid = pattern.test(value) && valid :valid = value==''|| (pattern.test(value) && valid) 
    }

    if(rules.isTel) {
        const pattern = /^\d+$/;
        valid = value.trim() !== '' && value.length ==8 && pattern.test(value) && valid;
    }
    return valid;
    }
        ///submit function
  contactFormHandler = ( event ) => {
    event.preventDefault();
    this.setState({modalShow:true});
    this.setState({loading:true});
    
    axios.post( `${config.url}contact`, {
        "Nom":this.state.form.name.value,
        "Email":this.state.form.mailAdress.value,
        "Telephone":this.state.form.tel.value,
        "Objet":this.state.form.subject.value,
        "feedback":this.state.form.message.value

    },{
        headers: {
          'Access-Control-Allow-Origin': true,
        },
        })
        .then( response => {
            this.setState( { loading: false } );
            this.setState( { formValidity: false,form:{...contactDefault} } );
        } )
        .catch( error => {
            this.setState( { loading: false, error: true } );
            this.setState( { formValidity: false,form:{...contactDefault} } );
            console.log(error);
        } );
}

    captchaChange= (value) => {
        this.setState({captcha:true})
    }
    handleModalClose = () => {
        this.setState({modalShow:false});
    }

    render() {
        let {form, modalShow,loading,formValidity,captcha,error}=this.state
        return (
            <>
            <ContactModal show={modalShow} handleClose={this.handleModalClose} loading={loading} error={error}/>
            <Row className="mx-0 px-0 justify-content-center">
                <Col xl="6" md="8" xs="10" className="pt-4 my-4 contact-form-title-container">
                    <p className="contact-form-title"> Avez-vous des questions ? </p>
                </Col>
                <Col xl="10" md="12" xs="12" className="mx-0 px-0">
                    <Form onSubmit={this.contactFormHandler} className="">
                    <Form.Row className="align-items-center px-0 mx-0 justify-content-center">
                        <Col xl="5" md="5" xs="11">    
                            <Form.Label className="contact-label"> Nom </Form.Label>
                            <Form.Control 
                                className="contact-input" 
                                name="name" type="text"
                                value={form.name.value}
                                isInvalid={!form.name.isValid && form.name.touched}
                                onChange={this.handleFormChange} 
                                />     
                        </Col>
                        <Col xl="5" md="5" xs="11">
                            <Form.Label className="contact-label"> Email </Form.Label>
                                <Form.Control 
                                className="contact-input" type="email"
                                name="mailAdress"
                                value={form.mailAdress.value}
                                isInvalid={!form.mailAdress.isValid && form.mailAdress.touched}
                                onChange={this.handleFormChange} 

                                />
                        </Col>
                        <Col xl="5" md="5" xs="11">
                            <Form.Label className="contact-label"> Objet </Form.Label>
                            <Form.Control 
                                className="contact-input" 
                                type="text" name="subject"
                                value={form.subject.value}
                                isInvalid={!form.subject.isValid && form.subject.touched}
                                onChange={this.handleFormChange}  
                                />
                        </Col>
                        <Col xl="5" md="5" xs="11">
                            <Form.Label className="contact-label"> Téléphone </Form.Label>
                            <Form.Control 
                                className="contact-input" 
                                type="text" name="tel"
                                value={form.tel.value}
                                isInvalid={!form.tel.isValid && form.tel.touched}
                                onChange={this.handleFormChange}  
                                />
                        </Col>
                        <Col xl="10" md="10" xs="11">
                            <Form.Label className="contact-label"> Message </Form.Label>
                            <Form.Control 
                                className="contact-input" 
                                as="textarea" rows={5} name="message"
                                value={form.message.value}
                                isInvalid={!form.message.isValid && form.message.touched}
                                onChange={this.handleFormChange}  
                                />
                        </Col>
                        
                        <Col xl="10" md="10" xs="11" className="mt-4" >
                        <ReCAPTCHA
                            sitekey="6LeoftoaAAAAAMRxgmwn-BLLvW4qa5f3uiOuH-dQ"
                            onChange={this.captchaChange}
                        />
                        </Col>
                        <Col xl="3" md={{ span: 4, offset: 0 }} xs={{ span: 6, offset: 5 }} className="mb-5 mt-3">
                            <Button 
                                className="contact-form-btn" type="submit"
                                disabled={!(formValidity&&captcha)}
                                 > 
                                Envoyer 
                            </Button>
                        </Col>
                    </Form.Row>
                    </Form>
                </Col>
                
            </Row>
            </>
        );
    }
}