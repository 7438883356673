import React, { useState } from 'react';
import './modal.css';
import IMG from '../../../../../../src/asset/image/Particulier/assistant.png';
import Spinner from '../../../../UI/spinner/spinner';
import {
    Modal,
    Image,
    Row,
    Col,
    Container
  } from 'react-bootstrap';

export default function ContactModal(props) {


  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} size="lg" className="contactModal">
        {props.loading?
        <Spinner/>
        :
        <>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="p-0">
            {props.error?
            <p className="contactModalError m-5">Un Problème Est Survenu Lors De La Connexion Au Serveur! Veuillez Réessayer Plus Tard</p>      
            :<Row>
                <Col xl="4" md="4" xs="4">
                    <Image fluid src={IMG} alt="assistant"/>
                </Col>
                <Col xl="7" md="8" xs="8" className="my-auto mx-0">
                    <p className="contactModalTitle"> Un assistant prendra en charge votre demande </p>
                </Col>
            </Row>}
        </Modal.Body>
        </>
        }
      </Modal>
    </>
  );
}
