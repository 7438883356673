import React, { Component } from 'react';
//css
import '../offre.css';
//react-bootstrap
import { Row, Col, Button, Image } from 'react-bootstrap';
//react router
import { Link, withRouter } from 'react-router-dom';

import axios from 'axios';
import { config } from '../../../../../config';

import STICKER from '../../../../../asset/image/Particulier/promo.png';
import gadget_image from "../../../../../asset/image/Particulier/gadget.png";

class OfferDetails extends Component {
    state = {
        offerDetails: null
    };
    componentDidMount() {
        axios.get(`${config.url}detailsOffres`)
            .then(response => {
                this.setState({ offerDetails: response.data });
            })
            .catch(error =>
                console.log(error, "related to offer details")
            );
    }

    render() {
        let offerDetails = this.state.offerDetails ? this.state.offerDetails : [];
        return (
            <Row className="mx-0 justify-content-center">
                {offerDetails.map((el, id) => {
                    console.log(el);
                    if ((el.offres_id == this.props.offer.id) && el.visibilite)
                        return (
                            !el.Prix_red ? (
                                <Col xl="2" lg="2" md="5" xs="10" className="offer-container px-0 m-3" key={id}>
                                    <div className="mb-3 pt-0 pb-3 not-promo">
                                        <p className="offer-title-flow">
                                            <var>{el.Vitesse}
                                                <sup>
                                                    jusqu'a <br />  Mbps
                                                </sup>
                                            </var>
                                        </p>
                                        <p className="offer-price">
                                            <var> {Math.floor(el.Prix)} <sup>,{Math.round((el.Prix - parseInt(el.Prix)) * 10)}</sup></var> TTC/Mois
                                        </p>
                                        {el.widget && <div className="offer-gadget">
                                            <img src={config.img + el.widget} alt="" srcset="" />
                                        </div>}
                                    </div>
                                    <div className="offer-description-container py-4 px-2">
                                        <div className="offer-description">
                                            <p> {el.Facture} </p>
                                            <hr />
                                            <p> {el.description_debit} </p>
                                            <hr />
                                            <p> {el.Duree} </p>
                                            <hr />
                                            <p> {el.Raccordement} </p>
                                        </div>
                                        <Link exact to={{
                                            pathname: `/particulier/abonnement/offre/${el.id}`,
                                            // state: `${this.props.offer.nom} ${el.Vitesse} Mbps`
                                            state: `${this.props.offer.nom}`
                                        }}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Button className="command-btn mx-auto"> Commander </Button>
                                        </Link>

                                    </div>

                                </Col>) : (
                                <Col xl="2" lg="2" md="5" xs="10" className="offer-container px-0 m-3" key={id}>
                                    <div className="mb-3 pt-0 pb-0 promo">
                                        <Image src={STICKER} alt="promo" className="promotional-img" />
                                        {/* <p className="promotional-title my-auto"> Promo </p> */}
                                        <p className="offer-title-flow">
                                            <var>{el.Vitesse}<sup> jusqu'a <br /> Mbps </sup></var>
                                        </p>
                                        <p className="offer-price">
                                            <var> {Math.floor(el.Prix_red)} <sup>,{Math.round((el.Prix_red - parseInt(el.Prix_red)) * 10)}</sup></var> TTC/Mois
                                        </p>
                                        <p className="promo-text mx-auto"> au lieu de </p>
                                        <p className="promo-ttc">
                                            <var> {Math.floor(el.Prix)} <sup>,{Math.round((el.Prix - parseInt(el.Prix)) * 10)}</sup></var> TTC/Mois
                                        </p>
                                        {/* <p className="offer-title-text"> Adresse Ip fixe Gratuite </p>  */}
                                    </div>
                                    <div className="offer-description-container py-4 px-2">
                                        <div className="offer-description">
                                            <p> {el.Facture} </p>
                                            <hr />
                                            <p> {el.description_debit} </p>
                                            <hr />
                                            <p> {el.Duree} </p>
                                            <hr />
                                            <p> {el.Raccordement} </p>
                                        </div>
                                        <Link exact to={{
                                            pathname: `/particulier/abonnement/offre/${el.id}`,
                                            // state: `${this.props.offer.nom} ${el.Vitesse} Mbps`
                                            state: `${this.props.offer.nom}`
                                        }}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Button className="command-btn mx-auto"> Commander </Button>
                                        </Link>
                                    </div>

                                </Col>)
                        );
                })}
            </Row>
        );
    }
}
export default withRouter(OfferDetails);