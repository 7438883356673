import React, { Component } from 'react';
//css
import './socialMedia.css';
//react-bootstrap
import {
  Image,
} from 'react-bootstrap';

//logo
const FB_LOGO =require('../../../../../asset/image/Dashboard/facebook.svg')
const INSTAGRAM_LOGO =require('../../../../../asset/image/Dashboard/instagram.svg')
const LINKEDIN_LOGO =require('../../../../../asset/image/Dashboard/linkedin.svg')
const YOUTUBE_LOGO =require('../../../../../asset/image/Dashboard/youtube.svg')


export default class SocialMedia extends Component {    
    render() {
        return (
            <div className="social-media-icons px-2 py-3">
                <div className="mx-1 my-2">
                    <a href="https://www.facebook.com/bee.tunisie/" target="_blank">
                        <Image fluid src={FB_LOGO}/>
                    </a>
                </div>
                <div className="mx-auto my-2">
                    <a href="https://instagram.com/beetunisie/" target="_blank">
                        <Image fluid src={INSTAGRAM_LOGO}/>
                    </a>
                </div>
                <div className="mx-auto my-2">
                    <a href="https://www.linkedin.com/company/bee-tunisie/" target="_blank">
                        <Image  fluid src={LINKEDIN_LOGO}/>
                    </a>
                </div>
                <div className="mx-auto my-2">
                    <a href="https://www.youtube.com/channel/UCw9xHp7hNGznejMl_9j3c1A" target="_blank">
                        <Image  fluid src={YOUTUBE_LOGO}/>
                    </a>
                </div>
            </div>
        );
    }
}
