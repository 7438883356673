import React from 'react';
import { Row, Col} from 'react-bootstrap';

import './ribbon.css';

const ribbon = () => (
<div className="content">

  <div style={{ flexDirection:'row',  display:'flex',}}>
  <div >


  <p className="ribbon" style={{width:'2400px'}}>
  Bee Level Up To 10 Mbps, fait bénéficier automatiquement les clients ADSL 4Mbps et 8Mbps d’un upgrade vers le débit 10Mbps avec une période d’essai de deux mois et demi. Le client peut déposer une demande de Downgrade et revenir à son débit initial jusqu’au 31 Mai.</p>
  </div>
  
  <div >

  <p className="ribbon" style={{width:'2400px',}}>
  Dans le cadre d’appliquer et s’aligner à la mise à jour de la redevance mensuelle de l’abonnement à la téléphonie fixe procédée par Tunisie Télécom
   et approuvée par l’INT, une augmentation tarifaire de 1,500 DT (TTC) sera mise en œuvre 
  et ce à partir du 01 septembre 2022, concernera aussi bien les nouveaux et anciens abonnés.
  </p>

  </div>
  </div>
  
 
  
</div>
);

export default ribbon;