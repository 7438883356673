import React, { Component } from 'react';
import {
    Row,
    Col,
    Image,
    Button
} from 'react-bootstrap';
import './productCategory.css';

import { config } from '../../../../../config';
import { Link } from 'react-router-dom';

export default class Categories extends Component {

    render() {
        let { products, filterList } = this.props;
        let filteredList = products;
        return (
            <Row className="mx-0 px-0">
                {filteredList
                    .filter(el => {
                        if (this.props.filterList.length == 0) {
                            return el
                        }
                        else if (filterList.length > 0 && filterList.indexOf((el.id_cat.toString())) > -1) {
                            return el
                        }

                    })
                    .map((el, id) => {
                        return (
                            <Col xl="4" md="4" xs="12" className="mb-4" key={id}>
                                <div className="category-img-container">
                                    <Image fluid className="productCat-img" src={config.img + el.img} alt={el.Nom} />
                                </div>
                                <div>
                                    <h3 className="productCat-title"> {el.Nom}</h3>
                                    <p className="productCat-text">
                                        {el.Description}
                                    </p>
                                </div>
                                <div>
                                    <Button as={Link} to={`/particulier/produits/${el.Nom.replace(/[()]/g, '')}`}
                                        className="productCat-btn mb-2 mx-auto">
                                        Découvrir
                                    </Button>
                                </div>
                            </Col>
                        )
                    })}
            </Row>
        );
    }
}