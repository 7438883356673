import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

//css
import './map.css';
//react-bootstrap
import {
  Button,
  Image,
  Row,
  Col
} from 'react-bootstrap';
import SearchInMap from '../AutocompleteSearch/autocomplete-search';

// image
const BTN_ICON=require('../../../../../asset/image/Particulier/btn-icon.png');

export class MapContainer extends Component {
  constructor(){
    super();
  
        this.state = {
          currentLatitude:null,
          currentLongitude:null,
          currentPosition:{},

          showingInfoWindow: false,  //Hides or the shows the infoWindow
          activeMarker: {},          //Shows the active marker upon click
          selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
        }
      }
          componentDidMount() {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position) => {   
               this.setState({ currentPosition: {lat:position.coords.latitude, long:position.coords.longitude} }); 
              });
            }
            
          }
        //event handlers of info window (when the map and the marker are clicked)
        onMarkerClick = (props, marker) =>
          this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
          });

        onInfoWindowClose = () =>
          this.setState({
            activeMarker: null,
            showingInfoWindow: false
          });

        onMapClicked = () => {
          if (this.state.showingInfoWindow)
              this.setState({
                activeMarker: null,
                showingInfoWindow: false
              });
        };

          //to display markers of agencies on the map
          displayMarkersAgencies = () => {
            return this.props.agencies
            .filter(agency =>{
              if(agency.Type==="agence")
                return agency
            })
            .map((agency) => {
              return(
                <Marker 
                  name={agency.Nom}
                  adress={agency.Adresse} tel={agency.Tel} email={agency.Email}
                  key={agency.id} id={agency.id} 
                  options={{icon:{url:require("../../../../../asset/image/Particulier/map-icon.svg")}}}
                  position={{lat: agency.Latitude,lng: agency.Longitude}}
                  onClick={this.onMarkerClick}
                />)
            })
          }

          //to display markers of stores on the map
          displayMarkersStores = () => {
            return this.props.agencies
            .filter(store =>{
              if(store.Type==="revendeur")
                return store
            })
            .map((store, index) => {
              return(
                <Marker 
                  key={store.id} id={store.id} 
                  name={store.Nom}
                  adress={store.Adresse} tel={store.Tel} email={store.Email}
                  options={{icon:{url:require("../../../../../asset/image/Particulier/blue-dot.svg")}}}
                  position={{lat: store.Latitude,lng: store.Longitude}}
                  onClick={this.onMarkerClick} 
                />)
            })
          }
          //to display the current localisation on the map
          geolocate = () => {
            let x=this.state.currentPosition.lat
            let y =this.state.currentPosition.long
            this.setState({currentLatitude: x })
            this.setState({currentLongitude: y })
            let z=13
            this.props.handleMapPosition(x,y,z)
          }

          // to pick up lat and long from autocomplete
          pickCoord = (x,y) => {
            this.setState({currentLatitude: x})
            this.setState({currentLongitude: y })
          }
          // handle map position
          handlePosition = () => {
            let x=this.state.currentLatitude
            let y=this.state.currentLongitude
            if(x!==null&&y!==null){
              this.props.handleMapPosition(x,y,14)
            }
          }
    
    render() {
        return (
        <Row className="mx-0 my-0 px-0 justify-content-center">
            {/****Search location***/}
            <Col xl="3" lg="3" md="3" xs="10" className="px-0 mx-0">
              <Button className="geolocation-btn px-4" onClick={this.geolocate}> 
                <Image width="20px" fluid className="my-auto" src={BTN_ICON} /> Me localiser 
              </Button>
            </Col>
            <Col xl="4" lg="4" md="5" xs="6" className="mx-0">
              <SearchInMap pickCoord={this.pickCoord}/>
            </Col>
            <Col xl="4" lg="4" md="4" xs="6" className="mx-0">
              <Button className="map-search-btn px-xl-4" onClick={this.handlePosition}> Trouver la boutique </Button>
            </Col>

           {/******Map****/}
            <Col xl="10" lg="10" md="12" xs="12" className="px-0 mx-0 my-5">
              {/***** Map component */}
              <Map
                google={this.props.google}
                zoom={this.props.zoom}
                className="mapView"
                onClick={this.onMapClicked}
                initialCenter={{
                  lat: 36.444,
                  lng: 10.176
                }}
                center= {{ lat: parseFloat(this.props.mapPosition.lat), lng: parseFloat(this.props.mapPosition.lng)}}
              >
              {/****** Agencies Markers */}
                {this.displayMarkersAgencies()}

              {/****** Stores Markers (revendeurs)*/}
                {this.displayMarkersStores()}

              {/*****to display the marker position */}
                {(this.state.currentLatitude!==null)? (
                  <Marker
                  position={{ lat: this.state.currentLatitude, lng: this.state.currentLongitude }}
                  />
                ):null}

              {/***** Display the info window on the marker */}
                <InfoWindow
                  marker={this.state.activeMarker}
                  onClose={this.onInfoWindowClose}
                  visible={this.state.showingInfoWindow}
                >
                  <div>
                    <p className="info-window-title">{this.state.selectedPlace.name}</p>
                    <p className="info-window-adress"> Adresse : {this.state.selectedPlace.adress}</p>
                    <p className="info-window-adress"> Téléphone : {this.state.selectedPlace.tel}</p>
                    {this.state.selectedPlace.email?
                    <p className="info-window-adress"> Mail : {this.state.selectedPlace.email}</p>
                    :null}
                  </div>
                </InfoWindow>
              </Map>

            </Col>

        </Row>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyCe3eMb74HI0_hA5Dzmcd2pozsCEP9fZPw'
  })(MapContainer);