import React, { Component } from 'react';

//css
import './comingSoon.css';
//react-bootstrap
import {
  Row,
  Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {tag} from '../../const/metaTags';
import Helmet from 'react-helmet';

export default class Network extends Component {

    render() {
        return (

            <Row className="Soon-header justify-content-center mx-0">
                <Helmet>
                    <title> {tag.business.title} </title>
                    <meta name="description" content={tag.business.description} />
                </Helmet>
                <Col xl="8" md="10" xs="10" className="soon-text">
                    <h1 className="soon-header-title"> Coming Soon </h1>
                    <p className="soon-header-text">
                    Retrouvez très prochainement nos offres Internet dédiés aux entreprises et aux professionnels.
                    <br/> En attendant, restez connectés !
                    </p>
                    <Link  to='/' className="soon-link px-5 py-1 mx-auto">
                        Accueil
                    </Link>
                </Col>
            </Row>

        );
    }
}