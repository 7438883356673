import React, { Component } from 'react';
import './index.css';

//component
import ListProducts from './Products/productList';
import CurrentProduct from './Product/product';
import ProductDescription from './ProductDescription/productDescription';
import {productTag} from '../../../../const/metaTags';
import Helmet from 'react-helmet';
import axios from 'axios';
import {config} from '../../../../config';
import Spinner from '../../../UI/spinner/spinner';


export default class Product extends Component {
    state={
        productCategory:null
    }
    componentDidMount() {
        let productCat=this.props.productCat
        axios.get(`${config.url}catprods?id=${productCat}`)
        .then( response => {
            this.setState({productCategory:response.data.type})
        }).catch(error=> console.log(error,"related to product cat") )
    }

    render() {
        let currentProduct=this.props.products?this.props.products[this.props.id]:null;
        return (
            <>
            {(this.props.products && this.state.productCategory) ? 
                <div>
                    {productTag.map(el=>{
                    if(el.name===this.state.productCategory){
                    return(
                    <Helmet>
                        <title> {el.title} </title>
                        <meta name="description" content={el.description} />
                    </Helmet>
                    )}})}
 
                    <CurrentProduct product={currentProduct}/>
                    <ProductDescription product={currentProduct}/> 
                    <p className="see-more-product"> voir plus </p>
                    <div className="product-line mx-auto my-0"/>
                    <ListProducts products={this.props.products}/>
                </div>
                :
                <Spinner/>
                }
            </>   
        );
    }
}