import React, { Component } from 'react';
//css
import '../debit.css';
import InputSlider from './flowSlider';

export default class FlowBox extends Component {
    render() {

        return (
        <>
            <div className="flow-box-header p-3">
                <p className="flow-title"> Débit</p>
                <p className="flow-text"> Je choisis mon débit</p>
                <InputSlider changed={this.props.handleFlowChange} flows={this.props.flows} defaultFlow={this.props.currentFlowValue} idOffre={this.props.idOffre}/>
            </div>
            <div className="flow-description mx-0 px-5">
                <p> Une seule facture contient : Frais ADSL BEE+ Frais TT </p>
                <hr/>
                <p> Accès internet avec des débits qui varient de 4 méga à 20 Méga</p>
                <hr/>
                <p> Périodicité de facturation : Mensuelle, Trimestrielle, Semestrielle</p>
                <hr/>
                <p> Frais de raccordement TT 20 TND </p>
            </div>
            <div className="flow-box-footer px-3">
                <p className="offer-price"> 
                    <strong> <var> {Math.floor(this.props.flowPrice)} <sup>,{Math.round((this.props.flowPrice - parseInt(this.props.flowPrice)) * 10)}</sup></var> </strong> TTC/Mois  
                </p>
                <p className="flow-text2">
                L’augmentation de la redevance à la téléphonie fixe, 
                engendre 1 TND de majoration sur votre facture xDSL 
                Offre ADSL soumise à un engagement de 12 mois
                </p>
            </div>
        </>
        );
    }
}