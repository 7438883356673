import React, { Component } from 'react';
import { Row,Col,Form,Button, Image } from 'react-bootstrap';

export default class Radio extends Component {

    render(){
        return (
            <Form.Group as={Row}>
                <Form.Label column xl={4} xs={5} className="subscription-label"> {this.props.label} </Form.Label>
                {this.props.options.map(el=>{
                    return(
                        <Form.Check 
                            name={this.props.name}
                            value={el.value}
                            defaultChecked={el.defaultChecked}
                            column xl={3} xs={3} type="radio" 
                            label={el.label}
                            className="my-auto mx-2 subscription-label"
                            onChange={(event) => this.props.changed(event, this.props.name)}
                        />
                    )
                })}  
            </Form.Group>
        );
      } }